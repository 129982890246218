import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/configureStore';
import { SubscriptionPlanCardDefaultInfo } from '../static/chooseSubscriptionPlanConstants';
import { SubscriptionPlanCardInfo } from '../static/chooseSubscriptionTypes';

const initialState: ChooseSubscriptionPlanState = {
  BOPPVBSubscription: SubscriptionPlanCardDefaultInfo,
  BOPPPAYG1Subscriptio: SubscriptionPlanCardDefaultInfo,
  BOPPPFREESubscriptio: SubscriptionPlanCardDefaultInfo,
};

export const chooseSubscriptionPlanSlice = createSlice({
  name: 'chooseSubscriptionPlan',
  initialState,
  reducers: {
    setBOPPVBSubscription: (state, action: PayloadAction<SubscriptionPlanCardInfo>) => {
      state.BOPPVBSubscription = action.payload;
    },
    setBOPPPAYG1Subscriptio: (state, action: PayloadAction<SubscriptionPlanCardInfo>) => {
      state.BOPPPAYG1Subscriptio = action.payload;
    },
    setBOPPFREESubscription: (state, action: PayloadAction<SubscriptionPlanCardInfo>) => {
      state.BOPPPFREESubscriptio = action.payload;
    },
  },
});

export const {
  setBOPPVBSubscription,
  setBOPPPAYG1Subscriptio,
  setBOPPFREESubscription
} = chooseSubscriptionPlanSlice.actions;

export const selectBOPPVBSubscription = (state: RootState) => state.chooseSubscriptionPlan.BOPPVBSubscription;
export const selectBOPPPAYG1Subscriptio = (state: RootState) => state.chooseSubscriptionPlan.BOPPPAYG1Subscriptio;
export const selectBOPPFREESubscriptio = (state: RootState) => state.chooseSubscriptionPlan.BOPPPFREESubscriptio;

export default chooseSubscriptionPlanSlice.reducer;

export interface ChooseSubscriptionPlanState {
  BOPPVBSubscription: SubscriptionPlanCardInfo,
  BOPPPAYG1Subscriptio: SubscriptionPlanCardInfo,
  BOPPPFREESubscriptio: SubscriptionPlanCardInfo,
}
