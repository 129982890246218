import React, {useState} from 'react';
import classNames from "classnames";
import { StylizedWrapperWithRadius } from "../../../components/stylizedWrapper/StylizedWrapperWithRadius";
import { ToastIcon } from "../../../components/toast/static/toastCommonDefinitions";
import { Toast } from "../../../components/toast/Toast";
import { 
  applyTabFocusSupport, 
  isDesktopEnv 
} from "../../../helpers";
import { SortingType } from "../../activity/static/activityCommonDefinitions";
import { sortRecurringRequestsTableAction } from "../logic/recurringRequestActivitySaga";
import { 
  selectFiltereRecurringPaymentRequests, 
  selectRecurringPaymentRequests, 
  selectRecurringRequestsTablePaginationData, 
  selectRecurringRequestsTableSorting, 
  updateRecurringRequestsTableSorting 
} from "../logic/recurringRequestActivitySlice";
import { 
  requestsTableTitles, 
  requestTableTitlesMobile 
} from "../static/recurringRequestActivityConstants";
import { RecurringPaymentRequest } from "../static/recurringRequestActivityTypes";
import { 
  useAppDispatch, 
  useAppSelector 
} from '../../../app/hooks';
import { TableRow } from './RecurringActivityTableRow';
import styles from './RecurringRequestActivity.module.scss';
import mobileStyles from './RecurringRequestActivityMobile.module.scss';

interface TableProps {
  areFiltersClear: boolean;
}
  
export const Table = ({ areFiltersClear }: TableProps) => {
  const dispatch = useAppDispatch();
  const sorting = useAppSelector(selectRecurringRequestsTableSorting);
  const requests = useAppSelector(selectRecurringPaymentRequests);
  const filteredRequests = useAppSelector(selectFiltereRecurringPaymentRequests);
  const paginationData = useAppSelector(selectRecurringRequestsTablePaginationData);
  const visibleRequests = filteredRequests.length || !areFiltersClear ? filteredRequests : requests;
  const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);
  const isDesktop = isDesktopEnv();

  const handleSortOrder = (name: string) => {
    const value = sorting[name];
    Object.keys(sorting).forEach(key => {
      if (sorting[key] !== SortingType.INACTIVE) {
        dispatch(updateRecurringRequestsTableSorting({ [key]: SortingType.INACTIVE }));
      }
    });

    switch (value) {
      case SortingType.DESCENDING:
        dispatch(updateRecurringRequestsTableSorting({ [name]: SortingType.ASCENDING }));
        break;
      case SortingType.INACTIVE:
        dispatch(updateRecurringRequestsTableSorting({ [name]: SortingType.ASCENDING }));
        break;
      case SortingType.ASCENDING:
        dispatch(updateRecurringRequestsTableSorting({ [name]: SortingType.DESCENDING }));
        break;
      default:
        return;
    }
    dispatch(sortRecurringRequestsTableAction())
  };

  const targetStyles = isDesktop ? styles : mobileStyles;
  const targetTitles = isDesktop ? requestsTableTitles : requestTableTitlesMobile;

  const Titles = () =>
    Object.keys(targetTitles).map((key: string) => {
      const innerStyles = classNames([
        targetStyles.table__title_inner,
        {
          [targetStyles['table__title_inner--asc']]:
          sorting[key] === SortingType.ASCENDING,
          [targetStyles['table__title_inner--desc']]:
          sorting[key] === SortingType.DESCENDING,
          [targetStyles['table__title_inner--inactive']]:
          sorting[key] === SortingType.INACTIVE,
        },
      ]);

      const getMobileTitlesText = () => {
        switch(key) {
          case 'amount':
            return <span>Payment <br/> Amount </span>
          case 'totalPaid':
            return <span>Total <br/> Paid</span>
          default:
            return <span>{targetTitles[key]}</span>
        }
      }

      return (
        <>
        { key === 'status' ? <th className={targetStyles.table__title}/> : 
        <th
          { ...applyTabFocusSupport() }
          className={targetStyles.table__title}
          key={key}
          onClick={() => handleSortOrder(key)}>
            <div className={innerStyles}>
              {isDesktopEnv() ? <span>{targetTitles[key]}</span> : getMobileTitlesText()}
            </div>
        </th>
        }
        </>
      );
    });

  return (
      <>
        <StylizedWrapperWithRadius>
          <>
            <div className={targetStyles.table}>
              <table className={targetStyles.table__table}>
                <thead>
                <tr className={targetStyles.table__row}>{Titles()}</tr>
                </thead>
                <tbody>
                    {visibleRequests.slice(paginationData.paginationRangeStart, paginationData.paginationRangeEnd).map((request: RecurringPaymentRequest) => (
                        <TableRow
                          key={request.id}
                          request={request}
                        />
                    ))}
                </tbody>
              </table>
              
            </div>
            {
              showInfoPopup &&
                <Toast
                  icon={ToastIcon.Success}
                  title='Paylink Copied'
                  onClose={() => setShowInfoPopup(false)}
                />
            }
          </>
        </StylizedWrapperWithRadius>
      </>
  );
}