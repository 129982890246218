import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { isDesktopEnv, isEmptyString } from '../../../../../helpers';
import { validateNotes, validateNotesCaption } from '../../../../../helpers/dataFieldsValidation';
import { selectInputsErrors, selectPaymentSettings, setInputsErrors } from '../../../logic/paymentRequestSlice';
import { NotesSettingType, SettingsInputType } from '../../../static/paymentRequestConstants';
import { SettingsCheckboxButton } from '../../additionalComponents/settingsCheckboxButton/SettingsCheckboxButton';
import { SettingsRadioButton } from '../../additionalComponents/settingsRadioButton/SettingsRadioButton';
import { SettingsVariantProps } from '../activeSwitchSettingsVariants/ActiveSwitchSettingsVariants';
import styles from '../CommonSettingsStyles.module.scss';
import mobileStyles from '../CommonSettingsStylesMobile.module.scss';

export const NotesSettingsVariant = ({ settings, onSettingChange }: SettingsVariantProps) => {
  const dispatch = useAppDispatch();
  const paymentSettings = useAppSelector(selectPaymentSettings);
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;
  const inputsErrors = useAppSelector(selectInputsErrors);

  const items = useMemo(() => [
    {
      title: 'Set by me',
      subtitle: 'Attach additional message for payer',
      notesType: NotesSettingType.SetByMe,
      placeholder: 'Add notes...',
      inputRequirement: 'Max 200 characters allowed',
    },
    {
      title: 'Set by payer',
      subtitle: 'Ask the payer to send additional information to you, for example ‘indicate shoes size’ or ‘type in name of child’. This information will not appear in the bank transaction, just in BOPP.',
      notesType: NotesSettingType.SetByPayer,
      placeholder: 'Caption for payer...',
      inputRequirement: 'Max 100 chars',
      noteMandatoryTitle: 'Make notes mandatory - user won’t be able to pay if they don’t supply notes.',
    }
  ], []);

  const handleNotesInputChange = (notesType: NotesSettingType, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const targetValueKey = notesType === NotesSettingType.SetByMe ? 'notesValue' : 'notesCaption';

    if (notesType === NotesSettingType.SetByMe) {
      if (!validateNotes(value)) {
          return;
      }
      else {
        dispatch(setInputsErrors({
          ...inputsErrors,
          notes: false,
        }));
      }
    }
    else if (!validateNotesCaption(value)) {
      return;
    }

    onSettingChange({
      ...paymentSettings,
      notes: {
          ...paymentSettings.notes,
          [targetValueKey]: value
      }
    })
  }

  const handleNotesInputBlur = (settingType: NotesSettingType) => {
    if (settingType === NotesSettingType.SetByMe) {
      dispatch(setInputsErrors({
        ...inputsErrors,
        notes: isEmptyString(paymentSettings.notes.notesValue),
      }));
    }
  }

  const getValueForNotesInput = (notesType: NotesSettingType) => {
    return notesType === NotesSettingType.SetByMe ?
      paymentSettings.notes.notesValue :
      paymentSettings.notes.notesCaption;
  }

  return (
    <>
      {items.map(item => {
          const checked = settings.notes.type === item.notesType;
          return (
            <React.Fragment key={item.title}>
                <SettingsRadioButton
                  title={item.title}
                  subtitle={item.subtitle}
                  checked={checked}
                  showInput={true}
                  value={item.notesType}
                  style={targetStyles.amount_settings_expire_input}
                  settingType={SettingsInputType.TextInput}
                  placeholder={item.placeholder}
                  inputRequirement={item.inputRequirement}
                  inputValue={getValueForNotesInput(item.notesType)}
                  inputError={item.notesType === NotesSettingType.SetByMe && inputsErrors.notes}
                  handleRadioChange={(e) => {
                    onSettingChange({
                      ...paymentSettings,
                      notes: {
                        ...paymentSettings.notes,
                        type: e.target.value
                      }})
                  }}
                  handleInputValueChange={(e) => {
                    handleNotesInputChange(item.notesType, e)
                  }}
                  handleInputBlur={() => handleNotesInputBlur(item.notesType)}
                />
                {(item.notesType === NotesSettingType.SetByPayer && checked) &&
                <div className={targetStyles.checkbox_wrapper}>
                  <SettingsCheckboxButton
                      checked={settings.notes.makeNotesMandatory}
                      title={item.noteMandatoryTitle}
                      handleCheckboxChange={(e) => {
                        onSettingChange({
                          ...paymentSettings,
                          notes: {
                            ...paymentSettings.notes,
                            makeNotesMandatory: !paymentSettings.notes.makeNotesMandatory
                          }
                        })
                      }}
                  />
                </div>}
            </React.Fragment>
        )
      })}
    </>
  )
}
