import { PaymentTrigger } from "../pages/paymentRequest/static/paymentRequestConstants";

export enum ValidationStatuses {
    Expired = 'Expired',
    AlreadyPaid = 'AlreadyPaid',
    Valid = 'Valid',
    Cancelled = 'Cancelled'
}

interface PaylinkResponse {
    properties?: {
        paymentTerms?: PaymentTerms,
        activationLifetime: {
            expiryDate: Date | string
        }
    };
    state?: {
        instructions?: Instruction
        activationState?: string
    }
}

interface Instruction {
    [key:string]: InstructionValue
}

interface InstructionValue {
    id: string,
    amount: {
        value: string,
        unit: string,
    },
    entityState: string
    activityState: string
    charges: {
        value: string
        unit: string
    }
}

interface PaymentTerms {
    paymentTrigger?: PaymentTrigger;
    amountTerm?: {
        canBeExecutedXTimes?: number | string;
    }
}

export function isPaylinkValid(response: PaylinkResponse) {
    const activationState = response?.state?.activationState; 
    if (activationState === "Cancelled:Accepted:NonePending" || activationState === "Cancelled:Processed:NonePending") {
        return ValidationStatuses.Cancelled;
    }
    if (getIsPaylinkExpired(response)) {
        return ValidationStatuses.Expired;
    }

    const maxInstructions = getMaxInstructionForPaylink(response?.properties?.paymentTerms)

    if (maxInstructions === -1) {
        return ValidationStatuses.Valid;
    } else {
        const instructions = getCompletedInstructionsCountForpaylink(response?.state?.instructions)
        return instructions < maxInstructions ? ValidationStatuses.Valid : ValidationStatuses.AlreadyPaid;
    }
}

function getIsPaylinkExpired(response: PaylinkResponse) {
    if (response.properties?.activationLifetime) {
        let date = new Date(response.properties.activationLifetime.expiryDate);
        let now = new Date();
        date.setHours(0,0,0,0);
        now.setHours(0,0,0,0);
        return now > date;
    }
    return false;
}

function getMaxInstructionForPaylink(paymentTerms?: PaymentTerms) {
    if (paymentTerms?.paymentTrigger === PaymentTrigger.OnInstruction) {
        return 1;
    } else if (paymentTerms?.amountTerm?.canBeExecutedXTimes) {
        return paymentTerms.amountTerm.canBeExecutedXTimes;
    } else {
        return -1;
    }
}

function getCompletedInstructionsCountForpaylink(instructions?: Instruction) {
    if (instructions) {
        let completedInstructionsCount = 0;
        let inst = Object.entries(instructions);
        inst.forEach(instruction => {
            //@ts-ignore
            if(isInstructionCompleted(instruction)) {
                completedInstructionsCount++;
            }
        });
        return completedInstructionsCount;
    } else {
        return 0;
    }
}

function isInstructionCompleted(instruction: InstructionValue) {
    const obj = findFirstValidKeyInObject(instruction, 'entityState')
    if (obj != null) {
        return obj === 'Complete'
    }
    return false
}

function findFirstValidKeyInObject(obj: InstructionValue, keyToFind: string): string | null {
    const keys = findAllByKeyInObject(obj, keyToFind);
    if (keys.length > 0) {
        return keys[0];
    } else {
        return null;
    }
}

function findAllByKeyInObject(obj: InstructionValue, keyToFind: string) {
    const result: string[] = Object.entries(obj ? obj : {})
        .reduce((acc, [key, value]) => (key === keyToFind)
            ? acc.concat(value)
            : (typeof value === 'object')
                ? acc.concat(findAllByKeyInObject(value, keyToFind))
                : acc
            , [] as string[])
    return result;
}