import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import FontFaceObserver from 'fontfaceobserver';

import { Login } from "./pages/login/ui/Login";
import { Activity } from "./pages/activity/ui/Activity";
import { HomePage } from "./pages/homePage/ui/HomePage";
import { CreatePin } from "./pages/createPin/ui/desktop/CreatePin";
import { CreatePin as CreatePinMobile } from "./pages/createPin/ui/mobile/CreatePin";
import { ChooseBank } from "./pages/chooseBank/ui/ChooseBank";
import { BankAccount } from "./pages/bankAccount/ui/BankAccount";
import { KeyManagement } from "./pages/keyManagement/ui/KeyManagement";
import { LoadingScreen } from "./pages/loadingScreen/ui/LoadingScreen";
import { AddBankAccount } from "./pages/addBankAccount/ui/AddBankAccount";
import { ActivateAccount } from "./pages/activateAccount/ui/ActivateAccount";
import { BankAccountAdded } from "./pages/bankAccountAdded/ui/BankAccountAdded";
import { ActivateAccountError } from "./pages/activateAccountError/ui/ActivateAccountError";
import { ChooseSubscriptionPlan } from "./pages/chooseSubscriptionPlan/ui/ChooseSubscriptionPlan";
import { RetrieveAccountDetails } from "./pages/retrieveAccountDetails/ui/RetrieveAccountDetails";
import { ActivateAccountSuccess } from "./pages/activateAccountSuccess/ui/ActivateAccountSuccess";
import { SubscriptionManagement } from "./pages/subscriptionManagement/ui/SubscriptionManagement";
import "./App.css";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { selectIsSignedIn } from "./core/reducers/appSlice";
import { Cb } from "./pages/cb/Cb";
import { selectSelectedBank } from "./pages/retrieveAccountDetails/logic/retrieveAccountDetailsSlice";
import { SavingAccountDetails } from "./pages/savingAccountDetails/ui/SavingAccountDetails";
import { LinkAccount } from "./pages/linkAccount/ui/LinkAccount";
import { LinkAccountError } from "./pages/linkAccountError/ui/LinkAccountError";
import { TooManyActivatioAttempts } from "./pages/tooManyActivationAttempts/ui/TooManyActivatioAttempts";
import { RequestDetails } from "./pages/requestDetails/ui/RequestDetails";
import { refreshAppAction } from "./core/sagas/initSaga";
import { isDesktopEnv } from "./helpers";
import { GiftAid } from "./pages/GiftAid/ui/GiftAid";
import { RequestActivity } from "./pages/requestActivity/ui/RequestActivity";
import { ErrorScreen } from "./pages/errorScreens/ui/ErrorScreen";
import { ErrorScreenType } from "./pages/errorScreens/static/errorScreenCommonDefinitions";
import { PaymentRequestScreen } from "./pages/paymentRequest/ui/PaymentRequest";
import { isEnvSetupProperly } from './config';
import { useLocation } from 'react-router-dom';
import { RequestSummary } from './pages/requestSummary/ui/RequestSummary';
import { RecurringRequestActivity } from './pages/recurringRequestsActivity/ui/RecurringRequestActivity';
import { UpgradeNow } from "./pages/upgradeNow/ui/UpgradeNow";


function App() {
  const location = useLocation();

  const onboardingPaths = [
    "/choose-subscription-plan",
    "/create-pin",
    "/login",
    "/bank-account",
    "/bank-account/retrieve",
    "/bank-account/choose",
    "/bank-account/added",
    "/bank-account/link",
    "/bank-account/link/error",
    "/bank-account/activate/success",
    "/bank-account/choose",
    "/error-save-account-details",
    "/error-save-bank-account",
    "/permission-denied",
    "/loading",
    "/bank-account/saving",
    "/bank-account/transferring",
    "/cb"
  ];

  const appPaths = [
    "/bankAccount",
    "/home",
    "/payment-summary",
    "/payment-request",
    "/gift-aid",
    "/request-activity",
    "/request-summary",
    "/key-management",
    "/account-management",
    "/request-details",
    "/recurring-request-activity",
    "/upgrade-now"
  ];

  const commonPaths = [
    "/bank-account/activate",
    "/bank-account/activate/error",
    "/bank-account/too-many-attempts",
  ];

  const history = useHistory();
  const dispatch = useAppDispatch();

  const isSignedIn = useAppSelector(selectIsSignedIn);
  const selectedBank = useAppSelector(selectSelectedBank);
  const [envErrorScreenClosed, setEnvErrorScreenClosed] = useState(false);
  const [previousLocation, setPreviousLocation] = useState('');

  useEffect(() => {
    const mulish = new FontFaceObserver('Mulish');
    const roboto = new FontFaceObserver('Roboto');
    const nunito = new FontFaceObserver('Nunito Sans');

    Promise.all([mulish.load(), roboto.load(), nunito.load()]).then(() => {
      document.getElementById('root')?.classList.add('root--visible');
    });
  }, []);

  useEffect(() => {
    const callback = () => {
      document.getElementById('root')?.style.setProperty('height', window.innerHeight + 'px');
    }
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", function() {
      if(document.visibilityState === 'visible') {
          dispatch(refreshAppAction())
      }
    });
  }, []);


  useEffect(() => {
    let viewport = document.querySelector("meta[name=viewport]");
    viewport?.setAttribute(
      "content",
      `width=device-width, height=${window.innerHeight}px, initial-scale=1.0, maximum-scale=1.0, user-scalable=0`
    );
  }, []);

  useEffect(() => {
    locationChanged()
  }, [location]);

  function locationChanged() {
    if(location.pathname === previousLocation) {
      return
    } else {
      setPreviousLocation(location.pathname)
    }

    if(!isSupportedPath(location.pathname)) {
      if (isSignedIn) {
        history.replace("/home");
        return
      }
      else {
        history.replace("/login");
        return
      }
    }

    if(isCommonPath(location.pathname)) {
      return;
    }

    if (isOnboardingPath(location.pathname)) {
      if (isSignedIn) {
        history.replace("/home");
        return;
      }
      else {
        return;
      }
    }

    if (!isSignedIn) {
      history.push("/login");
    } else if (history.location.pathname === "/") {
      history.replace("/home");
    }
  }

  function isAppPath(path : string) {
    return appPaths.includes(path)
  }

  function isOnboardingPath(path : string) {
    return onboardingPaths.includes(path)
  }

  function isCommonPath(path: string) {
    return commonPaths.includes(path);
  }

  function isSupportedPath(path : string) {
    return isAppPath(path) || isOnboardingPath(path) || isCommonPath(path);
  }

  if (!isEnvSetupProperly() && !envErrorScreenClosed) {
    return <ErrorScreen type={ErrorScreenType.EnvSetup} onCloseButtonClick={() => setEnvErrorScreenClosed(true)} />
  }

  return (
    <>
      <Switch>
        <Route
          path="/bankAccount"
          component={BankAccount}
        />
        <Route path="/upgrade-now" component={UpgradeNow} />
        <Route path="/home" component={HomePage} />
        <Route path="/payment-summary" component={Activity} />
        <Route path="/payment-request" component={PaymentRequestScreen} />
        <Route path="/gift-aid" component={GiftAid} />
        <Route path="/request-activity" component={RequestActivity} />
        <Route path="/recurring-request-activity" component={RecurringRequestActivity} />
        <Route path="/request-summary" component={RequestSummary} />
        <Route path="/key-management" component={KeyManagement} />
        <Route path="/account-management" component={SubscriptionManagement} />
        <Route
          path="/choose-subscription-plan"
          component={ChooseSubscriptionPlan}
        />
        <Route
          path="/create-pin"
          component={isDesktopEnv() ? CreatePin : CreatePinMobile}
        />
        <Route path="/login" component={Login} />
        <Route
          exact
          path="/bank-account"
          component={AddBankAccount}
        />
        <Route
          exact
          path="/bank-account/retrieve"
          component={RetrieveAccountDetails}
        />
        <Route path="/bank-account/choose" component={ChooseBank} />
        <Route path="/bank-account/added" component={BankAccountAdded} />
        <Route
          exact
          path="/bank-account/activate"
          component={ActivateAccount}
        />
        <Route
          path="/bank-account/activate/error"
          component={ActivateAccountError}
        />
        <Route exact path="/bank-account/link" component={LinkAccount} />
        <Route
          exact
          path="/bank-account/link/error"
          component={LinkAccountError}
        />
        <Route
          exact
          path="/bank-account/too-many-attempts"
          component={TooManyActivatioAttempts}
        />
        <Route
          path="/bank-account/activate/success"
          component={ActivateAccountSuccess}
        />
        <Route exact path="/bank-account/choose" component={ChooseBank} />
        <Route
          path="/error-save-account-details"
          render={() => <ErrorScreen type={ErrorScreenType.SaveAccountDetails} />}
        />
        <Route
          path="/error-save-bank-account"
          render={() => <ErrorScreen type={ErrorScreenType.SaveBankAccount} />}
        />
        <Route
          path="/permission-denied"
          render={() => <ErrorScreen type={ErrorScreenType.PermissionDenied} />}
        />
        <Route
          path="/loading"
          render={() => <LoadingScreen message="Saving account details" />}
        />
        <Route
          exact
          path="/bank-account/saving"
          render={() => <SavingAccountDetails />}
        />
        <Route
          exact
          path="/bank-account/transferring"
          render={() => (
            <LoadingScreen
              message={`Securely transferring to ${selectedBank?.friendly_name}`}
            />
          )}
        />
        <Route path="/cb" component={Cb} />
        <Route path="/request-details" component={RequestDetails} />
      </Switch>
    </>
  );
}

export default App;
