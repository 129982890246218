import { Base62 } from './base62';

export const decodeExecutingEntityId = (reportId: string): string => {
    try {
        if (!reportId.includes('cri')) {
            return reportId
        }

        const splittedId = reportId.split(/:/);
        const bytes = Base62.decode(splittedId[splittedId.length - 1]);
        if(bytes[0] !== 21) {
            return splittedId[splittedId.length - 1]
        }
        const id = new TextDecoder('utf-8').decode(bytes.slice(2));
        return id || reportId
    } catch (e) {
        return typeof reportId === 'string' ? reportId : '';
    }
};