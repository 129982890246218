import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

import { Base62 } from '../utils/base62';

import DefaultLogoCircle from "../assets/images/defoult-icon-black_circle.png";

import {getBoppKid, getBoppSig2} from './../controllers/KeyController'
import { RequestHeader } from '../static/objectTypes';
import { DateRange } from '../components/dateRangeInput/static/dateRangeInputTypes';
import { RangesList } from '../components/dateRangeInput/static/dateRangePickerCommonDefinitions';
import { SortingType } from '../pages/activity/static/activityCommonDefinitions';
import { Reference } from '../pages/paymentRequest/logic/paymentRequestSlice';
import { ReferenceSettingType } from '../pages/paymentRequest/static/paymentRequestConstants';

export const SPLIT = 'split'

export const formatAmountToString = (amount: number, currency = 'GBP', minimumFractionDigits = 2): string => {
  const poundFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits,
  });

  return poundFormatter.format(amount);
}
export const getNumbersInRange = (from:number, to:number, step = 1): number[] => {
  let i = from;
  const range: number[] = [];

  if (step <= 0) return range;
  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

export type IPaginationButtons = (number | typeof SPLIT)[];
export type IFetchPageNumbersWithSplits = (
  currentPage: number,
  totalPagesCount: number,
  pageButtonNeighbors: number,
) => IPaginationButtons;

export const fetchPageNumbersWithSplits: IFetchPageNumbersWithSplits = (currentPage, totalPagesCount, pageButtonNeighbors) => {
  const { totalCountOfNumericButtons, maxCountOfButtons } = getCountOfPagesNumbersAndButtonsCount(pageButtonNeighbors);

  if (totalPagesCount > maxCountOfButtons) {
    const startPage = Math.max(2, currentPage - pageButtonNeighbors);
    const endPage = Math.min(totalPagesCount - 1, currentPage + pageButtonNeighbors);
    let pagesButtons:IPaginationButtons = getNumbersInRange(startPage, endPage);

    const hasLeftSplit = startPage > 2;
    const hasRightSplit = (totalPagesCount - endPage) > 1;
    const spillOffset = totalCountOfNumericButtons - (pagesButtons.length + 1); // number of hidden pages either to the left or to the right

    switch (true) {
      case (hasLeftSplit && !hasRightSplit): {
        const extraPages = getNumbersInRange(startPage - spillOffset, startPage - 1);
        pagesButtons = [SPLIT, ...extraPages, ...pagesButtons];
        break;
      }
      case (!hasLeftSplit && hasRightSplit): {
        const extraPages = getNumbersInRange(endPage + 1, endPage + spillOffset);
        pagesButtons = [...pagesButtons, ...extraPages, SPLIT];
        break;
      }
      default: {
        pagesButtons = [SPLIT, ...pagesButtons, SPLIT];
        break;
      }
    }

    return [1, ...pagesButtons, totalPagesCount];
  }

  return getNumbersInRange(1, totalPagesCount);
}

export type IGetCountOfPagesNumbersAndButtonsCount = (countOfNeighbors: number) => { totalCountOfNumericButtons: number, maxCountOfButtons: number };
const getCountOfPagesNumbersAndButtonsCount: IGetCountOfPagesNumbersAndButtonsCount = (countOfNeighbors) => {
  const MIN_COUNT_OF_NUMERIC_BUTTON = 3;
  const COUNT_OF_SPLITS = 2;

  const totalCountOfNumericButtons = (countOfNeighbors * 2) + MIN_COUNT_OF_NUMERIC_BUTTON;
  const maxCountOfButtons = totalCountOfNumericButtons + COUNT_OF_SPLITS;

  return { totalCountOfNumericButtons, maxCountOfButtons };
}

export const getDateRangeByPeriodTime = (period: string): DateRange => {
  const now = new Date();
  const todayStart = new Date(Date.UTC(now.getFullYear(),now.getMonth(), now.getDate()));
  const todayEnd = new Date(Date.UTC(now.getFullYear(),now.getMonth(), now.getDate()));

  const isSunday = todayStart.getDay() === 0;
  const todayStartDayIndex = isSunday ? 7 : todayStart.getDay();
  const todayEndDayIndex = isSunday ? 7 : todayEnd.getDay();

  switch (period) {
    case RangesList.Today:
      todayEnd.setHours(23,59,59,999);
      break

    case RangesList.ThisWeek:
      todayStart.setDate(todayStart.getDate() - todayStartDayIndex + 1);
      todayEnd.setHours(23,59,59,999);
      break;

    case RangesList.LastWeek:
      todayStart.setDate((todayStart.getDate() - todayStartDayIndex) - 6);
      todayEnd.setDate(todayEnd.getDate() - todayEndDayIndex);
      todayEnd.setHours(23,59,59,999);
      break;

    case RangesList.ThisMonth:
      todayStart.setDate(1);
      todayEnd.setHours(23,59,59,999);
      break;

    case RangesList.LastMonth:
      todayStart.setMonth(todayStart.getMonth() - 1, 1);
      todayEnd.setMonth(todayEnd.getMonth() - 1);
      todayEnd.setMonth(todayEnd.getMonth() + 1, 0);
      todayEnd.setHours(23,59,59,999);
      break;

    case RangesList.ThisYear:
      todayStart.setMonth(0, 1);
      todayEnd.setHours(23,59,59,999);
      break;

    case RangesList.YearFromToday:
      todayStart.setDate(todayStart.getDate() - 365);
      todayEnd.setHours(23,59,59,999);
      break;

    default:
      throw new Error("Something went wrong");
  }

  return { start_date: todayStart.toISOString(), end_date: todayEnd.toISOString() };
}

export const wrap = (method: string, msg: any): string => {
  const JSONRPCMsg = {
    jsonrpc: '2.0',
    method: method,
    params: [msg],
  };
  return JSON.stringify(JSONRPCMsg);
};

export const _append = (buffer1:Uint8Array, buffer2:Uint8Array): Uint8Array => {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp;
};

export const createId = (isPrefix:boolean): string => {
  const scheme = Uint8Array.of(15, 0)
  const id = uuidv4(null, new Uint8Array(16))
  if(isPrefix) {
    return `cri:${Base62.encode(_append(scheme, id))}`;
  } else {
    return Base62.encode(_append(scheme, id))
  }
};

export const returnDate = (date: Date | string, separator?: string, withoutTime: boolean = false): string => {
  let nDate = new Date(date).setHours(0,0,0,0);

  if(separator) {
    return moment(nDate).format(
      withoutTime
        ? `DD${separator}MM${separator}YYYY`
        : `DD${separator}MM${separator}YYYY, H:mm`
    );
  }

  return moment(nDate).format("MMM DD, YYYY");
};

export const stringWithCommas = (num: string): string =>  {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getCircleLogo = (linkToLogo: string) => {
  return linkToLogo
      ? linkToLogo.replace('.png', '_circle.png')
      : DefaultLogoCircle;
}

export const sortCodeRemoveDash = (sortCode: string): string => {
  if(sortCode !== undefined) {
    const regex = /-/g;
    return sortCode.replace(regex, '');
  } else {
    return "";
  }
}

export const insertSpaces = (value: string, interval: number): string => {
  const regex = new RegExp(`.{${interval}}`, "g");
  return value.match(regex)?.join(" ") || value;
}

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function base64ToArrayBuffer(base64:string):ArrayBuffer {
  var binary_string =  window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array( len );
  for (var i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export const getRequestHeader = async(): Promise<RequestHeader> => {
  const boppKid = getBoppKid();
  const boppSig = await getBoppSig2();

  return {
      'x-bopp-kid': boppKid,
      'x-bopp-sig': boppSig,
    }
}

export const isDesktopEnv = ():boolean => {
  if (process.env.NODE_ENV === 'test') {
    // @ts-ignore
    return window.screen.testWidth > 1024;
  }
  return window.screen.width > 1024
}

export const resizeWindow = (x: number) => {
  // @ts-ignore
  window.screen.testWidth = x;
}

export const checkIsDateInFilterRange = (date: string, filterDate: DateRange) => {
  let min = +moment(filterDate.start_date).set({hour: 0, minute: 0, second: 0, millisecond: 0}).format('X')
  let max =  +moment(filterDate.end_date).set({hour: 23, minute: 59, second: 59, millisecond: 999}).format('X')
  let paymentDateSeconds =  +moment(date).format('X')

  let result = checkIsNumberinRange(paymentDateSeconds, min, max)
  return result
}

export const checkIsNumberinRange = (num:number, min:number, max:number) => num >= min && num <= max

export const removeSpecialCharacters = (str: string) => {
  let pattern = new RegExp("[^a-zA-Z0-9]+", "g");

  return str.replace(pattern, ' ');
};

export const isLastCharacterIsSpace = (value: string) => value[value.length - 1] === ' ';

export const isEmptyString = (value: string) => {
  if(typeof value != 'string') {
    return true
  } else {
    return value.length === 0
  }
}

export const getNumberOfDigitsAfterDot = (value: string) => {
  if (value.includes('.')) {
     return value.split('.')[1].length;
  };

  return 0;
}

export const isTheSameDateRanges = (firstRange: DateRange, secondRange: DateRange) => {
  return firstRange.start_date === secondRange.start_date && firstRange.end_date === secondRange.end_date;
}

export const cssVhToPx = (vh: number): string => `${window.innerHeight * vh / 100}px`;


export const stringsSortingCallback = (stringA: string, stringB: string, sortingType: SortingType) => {
  if (sortingType === SortingType.ASCENDING) {
    return stringA.toLocaleLowerCase() < stringB.toLowerCase() ? -1 : 1;
  }
  
  return stringA.toLocaleLowerCase() > stringB.toLowerCase() ? -1 : 1;
}

export const numbersSortingCallback = (numberA: number, numberB: number, sortingType: SortingType) => {
  if (sortingType === SortingType.DESCENDING) {
    return numberB - numberA;
  }

  return numberA - numberB;
}

export function calculatePages(itemsCount:number, rowsPerPage: number): number {
  let count = itemsCount / rowsPerPage
  return Math.ceil(count)
}

export const getReferenceValueFromSettings = (referenceSetting: Reference): string => {
  switch(referenceSetting.type) {
    case ReferenceSettingType.SetByMe:
      return referenceSetting.setByMeValue;
    case ReferenceSettingType.Autonumber:
      return referenceSetting.autonumberValue;
    default:
      return '';
  }
}

export const applyTabFocusSupport = (forceClick?: () => void) => {
  return {
    tabIndex: 0,
    onKeyPress: (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        forceClick ? forceClick() : e.currentTarget.click();
      }
    },
  }
}

export const findFirstValidKeyInObject = (obj: any, keyToFind: string): any => {
  var result = null;
  if(obj instanceof Array) {
      for(var i = 0; i < obj.length; i++) {
          result = findFirstValidKeyInObject(obj[i], keyToFind);
      }
  }
  else
  {
      for(var prop in obj) {
          console.log(prop + ': ' + obj[prop]);
          if(prop === keyToFind) {
              return obj[prop];
          }

          if(obj[prop] instanceof Object || obj[prop] instanceof Array) {
              result = findFirstValidKeyInObject(obj[prop], keyToFind);
              if(result != null) {
                  return result;
              }
          }
      }
  }
  return result;
}