import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TipModal } from "../../modals/tipModal/TipModal";
import { Button } from "../../../components/button/Button";
import TSB from "../../../assets/images/banks-logo/TSB.svg";
import InfoIcon from "../../../assets/images/info-icon.svg";
import ScanIcon from "../../../assets/images/scan.svg";
import MenuIcon from "../../../assets/images/menu/bars-menu.svg"
import Vector from "../../../assets/images/banks-logo/Vector.svg";
import FD_Bank from "../../../assets/images/banks-logo/FD_Bank.svg";
import Halifax from "../../../assets/images/banks-logo/Halifax.svg";
import Natwest from "../../../assets/images/banks-logo/Natwest.svg";
import Revolut from "../../../assets/images/banks-logo/Revolut.svg";
import Barclays from "../../../assets/images/banks-logo/Barclays.svg";
import Santander from "../../../assets/images/banks-logo/Santander.svg";
import Monzo_Bank from "../../../assets/images/banks-logo/Monzo_Bank.svg";
import { ButtonColor, ButtonStyle } from "../../../static/CommonDefinitions";
import Danske_Bank from "../../../assets/images/banks-logo/Danske_Bank.svg";
import Lloyds_Bank from "../../../assets/images/banks-logo/Lloyds_Bank.svg";
import { BackgroundContainer } from "../../../components/background/BackgroundContainer";
import Bank_of_Scotland from "../../../assets/images/banks-logo/Bank_of_Scotland.svg";

import styles from "./AddBankAccount.module.scss";
import mobileStyles from "./AddBankAccountMobile.module.scss";
import { applyTabFocusSupport, cssVhToPx, isDesktopEnv } from "../../../helpers";

export function AddBankAccount() {
  const history = useHistory();
  const bankLogos = [
    Barclays,
    Lloyds_Bank,
    Halifax,
    Santander,
    TSB,
    Monzo_Bank,
    FD_Bank,
    Revolut,
    Natwest,
    Bank_of_Scotland,
    Vector,
    Danske_Bank,
  ];
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickStart = () => {
    history.push("/bank-account/retrieve");
  };

  if (isDesktopEnv()) {
    return (
      <>
        {openModal && <TipModal handleClick={handleCloseModal} />}
        <BackgroundContainer>
          <div className={styles.container}>
            <h5 className={styles.container__title}>Start BOPP’ing Today</h5>
            <p className={styles.container__text}>
              To request a payment you need to add a bank account and supply
              additional details
            </p>
            <div className={styles.container__banks}>
              <div className={styles.container__grid}>
                {bankLogos.map((item, index) => (
                  <div className={styles.container__grid_cell} key={index}>
                    <img src={item} alt="bank" />
                  </div>
                ))}
              </div>
              <div className={styles.container__text_right}>... and others</div>
            </div>
            <div className={styles.container__button}>
              <Button
                title="START NOW"
                color={ButtonColor.Black}
                style={ButtonStyle.Rounded}
                buttonHeight="50px"
                handleClick={handleClickStart}
              />
            </div>
            <div className={styles.container__footer}>
              <div
                className={styles.container__tip_block}
                onClick={handleOpenModal}
                { ...applyTabFocusSupport() }
              >
                <img src={InfoIcon} alt="info" />
                <span>Why do we need this?</span>
              </div>
              <p>
                BOPP is a registered trademark for payment services of Mia Pago
                Ltd, a company registered in England and Wales (No. 11263859). Mia
                Pago Ltd is regulated by the Financial Conduct Authority. Our
                Financial Services Register number is 826380.
              </p>
            </div>
          </div>
        </BackgroundContainer>
      </>
    );
  }

  return(
    <>
        {openModal && <TipModal handleClick={handleCloseModal}/>}
        <div className={mobileStyles.add_bank_account} style={inlineStyles.add_bank_account}>
          <header className={mobileStyles.add_bank_account__header} style={inlineStyles.add_bank_account__header}>
            <img src={ScanIcon} alt="scan" style={inlineStyles.add_bank_account__header_img} />
            <img src={MenuIcon} alt="menu" style={inlineStyles.add_bank_account__header_img} />
          </header>
            <div className={mobileStyles.add_bank_account__main}>
              <div className={mobileStyles.add_bank_account__main_header}>
                <h5 className={mobileStyles.add_bank_account__title} style={inlineStyles.add_bank_account__title}>Start BOPP’ing Today</h5>
                <p className={mobileStyles.add_bank_account__text} style={inlineStyles.add_bank_account__text}>To request a payment you need to add a bank
                    account and supply additional details</p>
              </div>
              <div className={mobileStyles.add_bank_account__main_center}>
                <div className={mobileStyles.add_bank_account__banks}>
                    <div className={mobileStyles.add_bank_account__grid} style={inlineStyles.add_bank_account__grid}>
                        {bankLogos.map((item, index) =>
                            <div className={mobileStyles.add_bank_account__grid_cell} style={inlineStyles.add_bank_account__grid_cell} key={index}>
                                <img src={item} alt='bank'/>
                            </div>
                        )}
                    </div>
                    <div className={mobileStyles.add_bank_account__text_right} style={inlineStyles.add_bank_account__text_right}>... and others</div>
                </div>
                <div className={mobileStyles.add_bank_account__button} style={inlineStyles.add_bank_account__button}>
                    <Button title="START NOW" color={ButtonColor.Black} style={ButtonStyle.Rounded}
                            buttonHeight='54px' handleClick={handleClickStart}/>
                </div>
              </div>
            </div>
            <div className={mobileStyles.add_bank_account__footer} style={inlineStyles.add_bank_account__footer}>
              <div className={mobileStyles.add_bank_account__tip_block} style={inlineStyles.add_bank_account__tip_block} onClick={handleOpenModal}>
                  <img src={InfoIcon} alt='info' style={inlineStyles.add_bank_account__tip_block_img} /><span>Why do we need this?</span>
              </div>
              <p style={inlineStyles.add_bank_account__footer_p}>BOPP is a registered trademark for payment services of Mia Pago Ltd, a company
                  registered in England and Wales (No. 11263859). Mia Pago Ltd is regulated by the
                  Financial Conduct Authority. Our Financial Services Register number is 826380.</p>
            </div>
        </div>
    </>
  )
}

const inlineStyles = {
  add_bank_account: {
    height: cssVhToPx(100),
  },
  add_bank_account__header: {
    htight: cssVhToPx(18.1),
    padding: `${cssVhToPx(5.2)} 7.73vw ${cssVhToPx(7.9)}`
  },
  add_bank_account__header_img: {
    width: cssVhToPx(4.8),
    height: cssVhToPx(4.8),
  },
  add_bank_account__footer: {
    marginTop: cssVhToPx(2.8),
  },
  add_bank_account__title: {
    fontSize: cssVhToPx(2.7),
  },
  add_bank_account__text: {
    fontSize: cssVhToPx(2.1),
    marginTop: cssVhToPx(1.5),
  },
  add_bank_account__text_right: {
    fontSize: cssVhToPx(2.1),
  },
  add_bank_account__grid: {
    gap: cssVhToPx(3),
    padding: `${cssVhToPx(3.3)} 0 ${cssVhToPx(2.7)}`
  },
  add_bank_account__grid_cell: {
    height: cssVhToPx(5.8),
    width: cssVhToPx(5.8),
  },
  add_bank_account__button: {
    marginTop: cssVhToPx(7.35),
  },
  add_bank_account__footer_p: {
    fontSize: cssVhToPx(1.5),
  },
  add_bank_account__tip_block: {
    marginBottom: cssVhToPx(1.35),
    fontSize: cssVhToPx(2.1),
  },
  add_bank_account__tip_block_img: {
    width: cssVhToPx(2.7),
    height: cssVhToPx(2.7),
  },
}