import classNames from 'classnames';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import BackIcon from '../../../../assets/images/back.svg';
import { setSignedIn } from '../../../../core/reducers/appSlice';
import { PinInputForm } from '../../../../components/pinInputForm/PinInputForm';
import { MobileHeader } from '../../../../components/mobileHeader/MobileHeader';
import { PinInputErrorPosition } from '../../../../components/pinInputForm/static/pinInputFormCommonDefinitions';
import { secureStoreKey } from '../../../../controllers/KeyController';
import styles from './CreatePin.module.scss';

const PIN_LENGTH = 6;

export function CreatePin() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [value, setValue] = useState('');
  const [confirmValue, setConfirmValue] = useState('');
  const [isConfirmStep, setIsConfirmStep] = useState(false);

  const validate = () => {
    if (isConfirmStep) {
      return value === confirmValue;
    }

    return value.length === PIN_LENGTH;
  };

  const isValid = validate();
  const showError =
    isConfirmStep && !isValid && confirmValue.length === PIN_LENGTH;

  const style = classNames([
    styles.create_pin,
    { [styles['create_pin--confirm']]: isConfirmStep },
  ]);

  const submitHandler = async () => {
      if (isConfirmStep) {
          dispatch(setSignedIn(true));
          await secureStoreKey(value);
          history.replace('/home');
      } else {
          setIsConfirmStep(true);
      }
  }

  return (
    <div className={style}>
      <MobileHeader
        isMenu={false}
        leftIcon={
          <img
            src={BackIcon}
            className={styles.create_pin__back_icon}
            onClick={() => setIsConfirmStep(false)}
          />
        }
      />
      <div className={styles.create_pin__top}>
        <h2 className={styles.create_pin__title}>Create a PIN</h2>
        <span className={styles.create_pin__subtitle}>
          You will need the PIN to authenticate
        </span>
      </div>
      <main className={styles.create_pin__center}>
        <PinInputForm
          key={+isConfirmStep}
          title={isConfirmStep ? 'Confirm PIN' : 'Create a PIN'}
          errorMessage={showError ? "PINs don't match" : ''}
          errorMessagePosition={PinInputErrorPosition.Bottom}
          submitButtonTitle="Save & Continue"
          length={PIN_LENGTH}
          onChange={isConfirmStep ? setConfirmValue : setValue}
          onSubmit={submitHandler}
          submitButtonDisabled={!isValid}
        />
      </main>
    </div>
  );
}
