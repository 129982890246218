import React from 'react';
import classNames from 'classnames';
import { Modal } from '../../../components/modal/Modal';
import { Button } from '../../../components/button/Button';
import { ButtonColor, ButtonStyle } from '../../../static/CommonDefinitions';

import styles from './SelectionModal.module.scss';
import { isDesktopEnv } from '../../../helpers';

export interface ISelectionModal {
  selectOptionTop: () => void;
  selectOptionBottom: () => void;
  text: string;
  message?: string;
  optionTop?: string;
  optionBottom?: string;
  handleOutsideClick?: () => void;
}

export function SelectionModal({
  selectOptionTop,
  selectOptionBottom,
  text,
  message,
  optionTop = 'Leave anyway',
  optionBottom = 'Cancel',
  handleOutsideClick,
}: ISelectionModal) {
  const contentStyles = classNames([
    styles.content,
    { [styles['content--mobile']]: !isDesktopEnv() },
  ]);

  const textClassName = classNames([
    styles.content__text,
    {[styles['content__text--title']]: message},
  ]);

  return (
    <Modal>
      <div className={styles.background} onClick={handleOutsideClick} />
      <div className={contentStyles}>
        <p className={textClassName}>{text}</p>
        {
          message &&
            <span className={styles.content__message}>{ message }</span>
        }
        <div className={styles.content__button}>
          <Button
            fontSize="18px"
            handleClick={selectOptionTop}
            title={optionTop}
            style={ButtonStyle.Square}
            buttonHeight="44px"
            color={ButtonColor.ModalYellow}
          />
        </div>
        <div className={styles.content__button}>
          <Button
            fontSize="18px"
            handleClick={selectOptionBottom}
            title={optionBottom}
            style={ButtonStyle.Square}
            buttonHeight="44px"
            color={ButtonColor.Black}
          />
        </div>
      </div>
    </Modal>
  );
}
