import { BankDetails, GiftAidKeys } from "./bankAccountTypes";

export const defaultBankDetails: BankDetails = {
  accountId: '',
  currency: '',
  accountType: '',
  accountSubType: '',
  accountNickname: '',
  accountName: "",
  bankName: '',
  schemeName: '',
  identification: '',
  status: '',
  accountDID: '',
  payeeUniqueIdentifier: '',
  accountNumber: "",
  sortCode: "",
  logo: "",
  orderNumber: "",
}

export const defaultGiftAidConstants: GiftAidKeys = {
  publicKey: '',
  secretKey: ''
}
