import classNames from "classnames";
import { useRef } from "react";
import { isDesktopEnv } from "../../helpers";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { ToastIcon } from "./static/toastCommonDefinitions";
import WarningIcon from '../../assets/images/warning-icon.svg';
import SuccessIcon from '../../assets/images/check-icon-yellow.svg';
import styles from "./Toast.module.scss";

export interface ToastProps {
  title: string,
  icon: ToastIcon;
  className?: string;
  onClose: () => void;
}

export function Toast({icon, title, className, onClose}: ToastProps) {
  const toastRef = useRef(null);
  const style = classNames([styles.toast, className, {
    [styles["toast--mobile"]]: !isDesktopEnv()
  }]);
  
  useOutsideClick(toastRef, onClose);

  const renderIcon = () => {
    switch(icon) {
      case ToastIcon.Warning:
        return <img src={WarningIcon} alt="" className={classNames([styles.toast__icon, styles.toast__warning_icon])} />
      case ToastIcon.Success:
        return <img src={SuccessIcon} alt="" className={classNames([styles.toast__icon])} />
    }
  }

  return (
    <div className={style} ref={toastRef}>
      {renderIcon()}
      <span className={styles.toast__title}>{ title }</span>
    </div>
  )
}
