import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";

import style from "./ChooseSubscription.module.scss";
import mobileStyle from "./ChooseSubscriptionMobile.module.scss";
import { Button } from "../../../components/button/Button";
import config from "./../../../config";
import { BackgroundContainer } from "../../../components/background/BackgroundContainer";
import { StylizedWrapperWithRadius } from "../../../components/stylizedWrapper/StylizedWrapperWithRadius";
import { StepsSubscriptionPlan } from "../../stepsSubscriptionPlan/ui/StepsSubscriptionPlan";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { MobileHeader } from "../../../components/mobileHeader/MobileHeader";
import { MobileHeaderPadding } from "../../../components/mobileHeader/static/mobileHeaderCommonDefinitions";
import { parseSubscriptionPlansAction } from "../logic/ChooseSubscriptionPlanSaga";
import { selectBOPPVBSubscription, selectBOPPPAYG1Subscriptio } from "../logic/ChooseSubscriptionPlanSlice";
import { applyTabFocusSupport, isDesktopEnv } from "../../../helpers";
import MostPopularIcon from "./../../../assets/images/skewed-rectangle-blue-gradient.svg";
import FreeIcon from "./../../../assets/images/three-quarters-circle.svg";
import ValueBundleIcon from "./../../../assets/images/red-frame-gradient.svg";

export function ChooseSubscriptionPlan() {
	const dispatch = useAppDispatch();
	const [signingPopup, setSigningPopup] = useState(true);
	const history = useHistory();
	const [subscribeButtonPressed, setSubscribeButtonPressed] = useState(false);
	const BOPPVBSubscription = useAppSelector(selectBOPPVBSubscription);
	const BOPPPAYG1Subscriptio = useAppSelector(selectBOPPPAYG1Subscriptio);

	useEffect(() => {
		dispatch(parseSubscriptionPlansAction());
	}, []);

	const handleLinkClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		history.push("/bank-account");
	};

	const handleSingingPopupClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setSigningPopup(false);
	};

	const hadleSubscribeButtonClick = (subscriptionUrl: string) => {
		setSubscribeButtonPressed(true);
		window.open(subscriptionUrl, "_blank");
	};

	const renderCards = () => {
		return (
			<>
				<FreeSubscriptionPlanCard
					headerWidth={""}
					onSubscribeButtonClick={() => {
						hadleSubscribeButtonClick(config.BOPPFREESubscriptionUrl);
					}}
				/>
				<MostPopularSubscriptionPlanCard
					headerWidth={"116%"}
					onSubscribeButtonClick={() => {
						hadleSubscribeButtonClick(config.BOPPPAYG1SubscriptionUrl);
					}}
				/>
				<ValueSubscriptionPlanCard
					headerWidth={""}
					onSubscribeButtonClick={() => {
						hadleSubscribeButtonClick(config.BOPPVBSubscriptionUrl);
					}}
				/>
			</>
		);
	};

	const bottomLinkText = subscribeButtonPressed ? "Use my activation code" : "I have my activation code";

	if (signingPopup) {
		return isDesktopEnv() ? (
			<BackgroundContainer
				isMenu={false}
				backButton={true}
				backButtonText={"Back to login"}
			>
				<StepsSubscriptionPlan handleSingingPopupClick={handleSingingPopupClick} />
			</BackgroundContainer>
		) : (
			<StepsSubscriptionPlan handleSingingPopupClick={handleSingingPopupClick} />
		);
	}

	if (isDesktopEnv()) {
		return (
			<BackgroundContainer
				isMenu={false}
				backButton={true}
			>
				<div className={style.choose_subscription_plan}>
					<StylizedWrapperWithRadius
						style="bRadius12"
						className={style.choose_subscription_plan__wrapper}
					>
						<div className={style.choose_subscription_plan__main}>
							<h2 className={style.choose_subscription_plan__title}>Select the subscription plan that best suits you</h2>
							<div className={style.choose_subscription_plan__cards}>{renderCards()}</div>
							<a
								{...applyTabFocusSupport()}
								href=""
								onClick={handleLinkClick}
								className={style.choose_subscription_plan__link}
							>
								{bottomLinkText}
							</a>
						</div>
					</StylizedWrapperWithRadius>
				</div>
			</BackgroundContainer>
		);
	}

	return (
		<div className={mobileStyle.choose_subscription_plan}>
			<MobileHeader
				backButton
				isMenu={false}
				padding={MobileHeaderPadding.small}
			/>
			<div className={mobileStyle.choose_subscription_plan__top}>
				<h2 className={mobileStyle.choose_subscription_plan__title}>Select the subscription plan that best suits you</h2>
			</div>
			<div className={mobileStyle.choose_subscription_plan__center}>{renderCards()}</div>
			<div className={mobileStyle.choose_subscription_plan__bottom}>
				<a
					href=""
					onClick={handleLinkClick}
					className={mobileStyle.choose_subscription_plan__link}
				>
					{bottomLinkText}
				</a>
			</div>
		</div>
	);
}

export interface SubscriptionPlanCardProps {
	icon: string;
	title: string;
	price: string;
	text: string;
	textStyle: string;
	backgroundStyle: string;
	symbol: string;
	symbolBefore: boolean;
	paymentOptions: string;
	buttonStyle: string;
	descriptionStyle?: string;
	onSubscribeButtonClick: () => void;
}

export function SubscriptionPlanCard({
	icon,
	title,
	price,
	paymentOptions,
	text,
	onSubscribeButtonClick,
	symbol,
	symbolBefore,
	textStyle,
	backgroundStyle,
	buttonStyle,
	descriptionStyle
}: SubscriptionPlanCardProps) {
	const targetStyle = isDesktopEnv() ? style : mobileStyle;

	return (
		<div className={`${targetStyle.subscription_plan_card} ${backgroundStyle}`}>
			<img
				src={icon}
				alt=""
				className={targetStyle.subscription_plan_card__icon}
			/>
			<h2 className={`${targetStyle.subscription_plan_card__title} ${textStyle}`}>{title}</h2>
			<div className={`${targetStyle.subscription_plan_card__price} ${textStyle}`}>
				{symbolBefore && <span className={targetStyle.subscription_plan_card__type_price}>{symbol}</span>}
				<span>{price}</span>
				{!symbolBefore && <span className={targetStyle.subscription_plan_card__type_price}>{symbol}</span>}
			</div>
			<p className={`${targetStyle.subscription_plan_card__payment_options} ${textStyle}`}>{paymentOptions}</p>
			<Button
				onClick={onSubscribeButtonClick}
				title="Sign up for 30-day free trial"
				buttonHeight="4.5vh"
				className={`${targetStyle.subscription_plan_card__button} ${buttonStyle}`}
			/>
			<span className={`${targetStyle.subscription_plan_card__description} ${textStyle} ${descriptionStyle}`}>{text}</span>
		</div>
	);
}

export interface SCardProps {
	isUpgrade?: boolean;
	headerWidth: string;
	onSubscribeButtonClick: () => void;
}

export function MostPopularSubscriptionPlanCard({isUpgrade= false, headerWidth = "116%", onSubscribeButtonClick }: SCardProps) {
	const desctop = isDesktopEnv();
	const targetStyle = desctop ? style : mobileStyle;

	return (
		<div className={targetStyle.most_popular_card}>
			{desctop ? (
				<div
					className={targetStyle.most_popular_card__header}
					style={{ width: headerWidth }}
				>
					{" "}
					<span className={targetStyle.most_popular_card__header__text}>MOST POPULAR</span>
				</div>
			) : (
				<div className={targetStyle.most_popular_card__header}>
					{" "}
					<span className={targetStyle.most_popular_card__header__text}>MOST POPULAR</span>
				</div>
			)}

			<img
				src={MostPopularIcon}
				alt=""
				className={targetStyle.most_popular_card__icon}
			/>
			<div>
				<h2 className={targetStyle.most_popular_card__title}>PAY AS YOU GO PLAN</h2>
				<div className={targetStyle.most_popular_card__price}>
					<span>0.5%</span>
				</div>
				<p className={targetStyle.most_popular_card__payment_options}>Transaction fee</p>
			</div>
			<div className={targetStyle.group_container}>
				<div className={targetStyle.most_popular_card__separator} />
				<span className={targetStyle.most_popular_card__description__bold}>
					{"5p "}
					<span className={targetStyle.most_popular_card__description}>minimum fee per transaction</span>
				</span>
				<div className={targetStyle.most_popular_card__separator} />
				<span className={targetStyle.most_popular_card__description__bold}>
					{"50p "}
					<span className={targetStyle.most_popular_card__description}>maximum fee per transaction</span>
				</span>
				<div className={targetStyle.most_popular_card__separator} />
			</div>
			<Button
				onClick={() => {
					onSubscribeButtonClick();
				}}
				title={isUpgrade ? "SIGN UP" : "SIGN UP FOR 30-day free trial"}
				buttonHeight="4.5vh"
				className={targetStyle.most_popular_card__button}
			/>
		</div>
	);
}

export function FreeSubscriptionPlanCard({ onSubscribeButtonClick }: SCardProps) {
	const targetStyle = isDesktopEnv() ? style : mobileStyle;

	return (
		<div className={targetStyle.free_card}>
			<img
				src={FreeIcon}
				alt=""
				className={targetStyle.free_card__icon}
			/>
			<div>
				<h2 className={targetStyle.free_card__title}>STARTER PLAN</h2>
				<div className={targetStyle.free_card__price}>
					<span>Free</span>
				</div>
				<p className={targetStyle.free_card__payment_options}>No Usage Restrictions</p>
			</div>
			<div className={targetStyle.group_container}>
				<div className={targetStyle.free_card__separator} />
				<span className={targetStyle.free_card__payment_descr}>No Fees</span>
				<div className={targetStyle.free_card__separator} />
				<span className={targetStyle.free_card__payment_descr}>No Time Limits</span>
				<div className={targetStyle.free_card__separator} />
			</div>
			<div className={targetStyle.value_card__description_container}>
				<span className={targetStyle.value_card__description__bold}>Upgrade subject to KYC</span>
				<span className={targetStyle.value_card__description}>{"Payments <£100 per month"}</span>
			</div>
			<Button
				onClick={() => {
					onSubscribeButtonClick();
				}}
				title="SIGN UP"
				buttonHeight="4.5vh"
				className={targetStyle.free_card__button}
			/>
		</div>
	);
}

export function ValueSubscriptionPlanCard({isUpgrade=false, onSubscribeButtonClick }: SCardProps) {
	const targetStyle = isDesktopEnv() ? style : mobileStyle;

	return (
		<div className={targetStyle.value_card}>
			<img
				src={ValueBundleIcon}
				alt=""
				className={targetStyle.value_card__icon}
			/>
			<div>
				<h2 className={targetStyle.value_card__title}>VALUE BUNDLE PLAN</h2>
				<div className={targetStyle.value_card__price}>
					<span>£10</span>
				</div>
				<p className={targetStyle.value_card__payment_options}>per month</p>
			</div>
			<div className={targetStyle.group_container}>
				<div className={targetStyle.value_card__separator} />
				<span className={targetStyle.value_card__description__bold}>
					<span className={targetStyle.value_card__description}>includes</span>
					{" £5,000 "}
					<span className={targetStyle.value_card__description}>transactions per month</span>
				</span>
				<div className={targetStyle.value_card__separator} />
			</div>
			<div className={targetStyle.value_card__description_container}>
				<span className={targetStyle.value_card__description__bold}>All transactions above this level</span>
				<span className={targetStyle.value_card__description__semi_bold}>
					{"0.5% "}
					<span className={targetStyle.value_card__description}>fee for transaction</span>
				</span>
				<span className={targetStyle.value_card__description__semi_bold}>
					{"5p "}
					<span className={targetStyle.value_card__description}>minimum fee per transaction</span>
				</span>
				<span className={targetStyle.value_card__description__semi_bold}>
					{"50p "}
					<span className={targetStyle.value_card__description}>maximum fee per transaction</span>
				</span>
			</div>
			<Button
				onClick={() => {
					onSubscribeButtonClick();
				}}
				title={isUpgrade ? "SIGN UP" : "SIGN UP FOR 30-day free trial"}
				buttonHeight="4.5vh"
				className={targetStyle.value_card__button}
			/>
		</div>
	);
}
