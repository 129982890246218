import React from "react";
import { HeaderButton, HeaderButtonPosition } from "../../static/headerCommonDefinitions";

import MenuIcon from '../../../../assets/images/menu/bars-menu.svg';
import CloseIcon from '../../../../assets/images/close-arrow-24px-rounded.svg';
import RepeatIcon from '../../../../assets/images/repeat.svg';

import mobileStyles from '../HeaderMobile.module.scss';
import classNames from "classnames";
import { cssVhToPx, isDesktopEnv } from "../../../../helpers";

export interface MobileHeaderButtonProps {
  type: HeaderButton,
  position: HeaderButtonPosition,
  onClick: () => void,
}

export const MobileHeaderButton = ({ type, onClick, position}: MobileHeaderButtonProps) => {
  let icon = '';

  switch (type) {
    case HeaderButton.Close:
      icon = CloseIcon;
      break;
    case HeaderButton.Menu:
      icon = MenuIcon;
      break;
    case HeaderButton.Refresh:
      icon = RepeatIcon;
      break;
  }

  if (icon) {
    const className = classNames([
      mobileStyles.header__button,
      {[mobileStyles['header__button--left']]: position === HeaderButtonPosition.Left},
      {[mobileStyles['header__button--right']]: position === HeaderButtonPosition.Right},
    ])

    const imgStyle = isDesktopEnv() ? {} : {
      width: cssVhToPx(4.8),
      height: cssVhToPx(4.8),
    }
  
    
    return <img src={icon} className={className} style={imgStyle} onClick={onClick} />
  }

  return <></>
}