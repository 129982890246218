import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import styles from './Login.module.scss';
import mobileStyles from './LoginMobile.module.scss';
import { Button } from '../../../components/button/Button';
import PinInput from '../../../components/pinInput/PinInput';
import ForgotPin from '../../../assets/images/login/forgot_pin.svg';
import { ButtonColor, ButtonStyle } from '../../../static/CommonDefinitions';
import ConnectWithBank from '../../../assets/images/login/connect_with_bank.svg';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import {
    LoginStep,
    PinState, selectEnteredPin,
    selectIsBackButton,
    selectLoginStep,
    selectPinState,
    setEnteredPin,
    setPinState,
} from '../logic/loginSlice';
import {
    alreadyHaveAccountAction,
    cancelAccountNotFoundAction,
    connectWithBankAction,
    connectWithExistingBankAction,
    createNewAccountAction,
    detectStateAction,
    doYouHaveBoppAccountAction,
    forgotPinAction,
    goBackAction,
    handlePinAction,
} from '../logic/loginSaga';
import { signIn } from '../../../controllers/KeyController';
import { applyTabFocusSupport, isDesktopEnv } from '../../../helpers';
import { MobileHeader, MobileHeaderProps } from '../../../components/mobileHeader/MobileHeader';
import classNames from 'classnames';
import { MobileHeaderPadding } from '../../../components/mobileHeader/static/mobileHeaderCommonDefinitions';
import CreateSubscription from '../../../assets/images/login/create_subscription.svg';
import { PinInputForm } from '../../../components/pinInputForm/PinInputForm';

export function Login() {
    const dispatch = useAppDispatch();

    const pin = useAppSelector(selectEnteredPin);
    const step = useAppSelector(selectLoginStep);
    const pinState = useAppSelector(selectPinState);
    const isBackButton = useAppSelector(selectIsBackButton);

    useEffect(() => {
        dispatch(detectStateAction());

        return () => {
            dispatch(setPinState(PinState.None))
        }
    }, []);

    const createNewAccountPressed = () => {
        dispatch(createNewAccountAction());
    };

    const onLoginHandler = async () => {
        const isSignInSuccessful = await signIn(pin);

        if (isSignInSuccessful) {
            dispatch(handlePinAction());
        } else {
            dispatch(setPinState(PinState.Incorrect));
        }
    }

    const mobileHeaderProps: MobileHeaderProps = {
        title: '',
        isMenu: false,
        backButton: isBackButton,
        padding: MobileHeaderPadding.small,
        onBackButtonPressed:()=>{dispatch(goBackAction());}
    };

    const renderEnterPin = () => {
        if (isDesktopEnv()) {
            return (
                <>
                    <div className={styles.data_container__top}>
                        <span className={styles.data_container__title}>LOG IN</span>
                        <span className={styles.data_container__text}>Welcome!</span>
                    </div>
                    <div className={styles.data_container__center}>
                        <span className={styles.data_container__pin_title}>PIN</span>
                        {pinState === PinState.Incorrect && (
                            <p className={styles.incorrect_pin__text}>Your PIN isn’t correct</p>
                        )}
                        <PinInput
                            length={6}
                            inputStyle={{
                                ...(pinState === PinState.Incorrect && { color: '#fe776e' }),
                            }}
                            onChange={() => {
                                if (pinState === PinState.Incorrect) {
                                    dispatch(setPinState(PinState.None));
                                }
                            }}
                            onComplete={(v: any) => {
                                dispatch(setEnteredPin(v));
                            }}
                            secret
                        />
                        <p
                            className={styles.data_container__pin_forgot}
                            { ...applyTabFocusSupport() }
                            onClick={() => {
                                dispatch(forgotPinAction());
                            }}>
                            Forgot your PIN?
                        </p>
                    </div>
                    <div className={styles.data_container__bottom}>
                        <Button
                            title="LOG IN"
                            style={ButtonStyle.Login}
                            handleClick={onLoginHandler}
                            buttonHeight="50px"
                            color={ButtonColor.Black}
                            disabled={pinState === PinState.Incorrect}
                        />
                        <p
                            { ...applyTabFocusSupport() }
                            className={styles.data_container__pin_forgot}
                            onClick={createNewAccountPressed}>
                            Create a new account
                        </p>
                    </div>
                </>
            );
        }

        return (
            <div className={mobileStyles.login}>
                <MobileHeader {...mobileHeaderProps} />
                <main className={mobileStyles.login__main}>
                    <div className={mobileStyles.top_text}>
                        <h2 className={classNames([mobileStyles.top_text__title, mobileStyles['top_text__title--normal'],])}>
                            LOG IN
                        </h2>
                        <span className={mobileStyles.top_text__subtitle}>Welcome!</span>
                    </div>
                    <div className={mobileStyles.login__main_content}>
                        <PinInputForm
                            underInputComponent={
                                <span
                                    className={mobileStyles.login__forgot_pin_link}
                                    onClick={() => {
                                        dispatch(forgotPinAction());
                                    }}>
                  Forgot your PIN?
                </span>
                            }
                            underSubmitComponent={
                                <span
                                    className={mobileStyles.login__new_account_link}
                                    onClick={createNewAccountPressed}>
                  Create a new account
                </span>
                            }
                            submitButtonTitle="LOG IN"
                            submitButtonDisabled={pinState === PinState.Incorrect}
                            onSubmit={onLoginHandler}
                            title="PIN"
                            length={6}
                            onChange={(value: string) => {
                                dispatch(setPinState(PinState.None));
                                dispatch(setEnteredPin(value));
                            }}
                            errorMessage={
                                pinState === PinState.Incorrect ? 'Your PIN isn’t correct' : ''
                            }
                        />
                    </div>
                </main>
            </div>
        );
    };

    const renderAccountNotStored = () => {
        if (isDesktopEnv()) {
            return (
                <>
                    <div
                        className={styles.data_container__top}
                        style={{ height: '40%' }}
                    >
                    <span className={styles.data_container__title_small}>
                        Have you used the business dashboard before?
                    </span>
                    <span className={styles.data_container__text}>
                        It doesn't look like your account details are saved in the browser. Please take a minute to reconnect.
                    </span>
                    </div>
                    <div
                        className={styles.data_container__center}
                        style={{ height: '15%' }}
                    />
                    <div className={styles.data_container__bottom}
                        style={{ height: '40%' }}>
                        <Button
                            title="YES"
                            style={ButtonStyle.Login}
                            handleClick={() => {
                                dispatch(alreadyHaveAccountAction());
                            }}
                            buttonHeight="50px"
                            color={ButtonColor.Black}
                        />
                        <Button
                            title="NO"
                            style={ButtonStyle.Login}
                            handleClick={() => {
                                dispatch(doYouHaveBoppAccountAction())
                            }}
                            buttonHeight="50px"
                            color={ButtonColor.Black}
                        />
                    </div>
                </>
            );
        }

        return (
            <div className={mobileStyles.container}>
                <MobileHeader {...mobileHeaderProps} />
                <div className={mobileStyles.container__center}>
                    <div className={mobileStyles.top_text}>
                        <h2 className={classNames([ mobileStyles.top_text__title, mobileStyles['top_text__title--small']])}>
                            Have you used the business dashboard before?
                        </h2>
                        <span className={mobileStyles.top_text__subtitle}>
                            It doesn't look like your account details are saved in the browser. Please take a minute to reconnect.
                        </span>
                    </div>
                </div>
                <div className={mobileStyles.container__footer}>
                    <Button
                        title="YES"
                        style={ButtonStyle.LittleRounded}
                        color={ButtonColor.Black}
                        handleClick={() => {
                            dispatch(alreadyHaveAccountAction());
                        }}
                    />
                    <Button
                        title="NO"
                        style={ButtonStyle.LittleRounded}
                        color={ButtonColor.Black}
                        handleClick={() => {
                            dispatch(doYouHaveBoppAccountAction())
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderDoYouHaveBoppAccount = () => {
        if (isDesktopEnv()) {
            return (
                <>
                    <div className={styles.data_container__top} style={{ height: '40%' }}>
                    <span className={styles.data_container__title_small}>Do you already have a BOPP account?</span>
                    <span className={styles.data_container__text}>To use the business dashboard you need a BOPP Business account.</span>
                    </div>
                    <div
                        className={styles.data_container__center}
                        style={{ height: '15%' }}
                    />
                    <div className={styles.data_container__bottom}
                        style={{ height: '40%' }}>
                        <Button
                            title="YES"
                            style={ButtonStyle.Login}
                            handleClick={() => {
                                dispatch(connectWithExistingBankAction());
                            }}
                            buttonHeight="50px"
                            color={ButtonColor.Black}
                        />
                        <Button
                            title="NO"
                            style={ButtonStyle.Login}
                            handleClick={() => {
                                dispatch(createNewAccountAction());
                            }}
                            buttonHeight="50px"
                            color={ButtonColor.Black}
                        />
                    </div>
                </>
            );
        }

        return (
            <div className={mobileStyles.container}>
                <MobileHeader {...mobileHeaderProps} />
                <div className={mobileStyles.container__center}>
                    <div className={mobileStyles.top_text}>
                        <h2 className={classNames([mobileStyles.top_text__title, mobileStyles['top_text__title--small']])}>
                            Do you already have a BOPP account?
                        </h2>
                        <span className={mobileStyles.top_text__subtitle}>To use the business dashboard you need a BOPP Business account.</span>
                    </div>
                </div>
                <div className={mobileStyles.container__footer}>
                    <Button
                        title="YES"
                        style={ButtonStyle.LittleRounded}
                        color={ButtonColor.Black}
                        handleClick={() => {
                            dispatch(connectWithExistingBankAction());
                        }}
                    />
                    <Button
                        title="NO"
                        style={ButtonStyle.LittleRounded}
                        color={ButtonColor.Black}
                        handleClick={() => {
                            dispatch(createNewAccountAction());
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderForgottentPin = () => {
        if (isDesktopEnv()) {
            return (
                <>
                    <div className={styles.data_container__top}>
                        <span className={styles.data_container__title}>Forgotten your PIN?</span>
                        <span className={styles.data_container__text}>You need to reconnect with your bank account</span>
                    </div>
                    <div className={styles.data_container__center}>
                        <img
                            src={ForgotPin}
                            alt=""
                            style={{ width: '190px', height: '143px' }}
                        />
                    </div>
                    <div className={styles.data_container__bottom}>
                        <Button
                            title="PROCEED"
                            style={ButtonStyle.Login}
                            handleClick={() => {
                                dispatch(connectWithBankAction());
                            }}
                            buttonHeight="50px"
                            color={ButtonColor.Black}
                        />
                    </div>
                </>
            );
        }

        return (
            <div className={mobileStyles.container}>
                <MobileHeader {...mobileHeaderProps} />
                <div className={mobileStyles.container__center}>
                    <div className={mobileStyles.top_text}>
                        <h2 className={classNames([mobileStyles.top_text__title, mobileStyles['top_text__title--small']])}>Forgotten your PIN?</h2>
                        <span className={mobileStyles.top_text__subtitle}>You need to reconnect with your bank account</span>
                    </div>
                    <img
                        src={ForgotPin}
                        alt="forgot pin"
                        className={mobileStyles.forgotten_img}
                    />
                </div>
                <div className={mobileStyles.container__footer}>
                    <Button
                        title="Proceed"
                        style={ButtonStyle.LittleRounded}
                        color={ButtonColor.Black}
                        handleClick={createNewAccountPressed}
                    />
                </div>
            </div>
        );
    };

    const renderConnectWithBank = () => {
        if (isDesktopEnv()) {
            return (
                <>
                    <div className={styles.data_container__top}>
                        <span
                            className={styles.data_container__title}>LOG IN</span>
                        <span className={styles.data_container__text}>
                Please connect with your bank to start using BOPP dashboard in this
                browser
            </span>
                    </div>
                    <div className={styles.data_container__center}>
                        <img
                            src={ConnectWithBank}
                            alt=""
                            style={{ width: '138px', height: '135px' }}
                        />
                    </div>
                    <div className={styles.data_container__bottom}>
                        <Button
                            title="CONNECT WITH BANK"
                            style={ButtonStyle.Login}
                            handleClick={() => {
                                dispatch(connectWithBankAction());
                            }}
                            buttonHeight="50px"
                            color={ButtonColor.Black}
                        />
                    </div>
                </>
            );
        }

        return (
            <div className={mobileStyles.container}>
                <MobileHeader {...mobileHeaderProps} />
                <div className={mobileStyles.container__center}>
                    <div className={mobileStyles.top_text}>
                        <h2 className={classNames([
                                mobileStyles.top_text__title,
                                mobileStyles['top_text__title--normal'],
                            ])}>
                            LOG IN
                        </h2>
                        <span className={mobileStyles.top_text__subtitle}>
              Please connect with your bank to start using BOPP dashboard in
              this browser
            </span>
                    </div>
                    <img
                        src={ConnectWithBank}
                        alt="connect with bank"
                        className={mobileStyles.connect_with_bank_img}
                    />
                </div>
                <div className={mobileStyles.container__footer}>
                    <Button
                        title="Connect with bank"
                        style={ButtonStyle.LittleRounded}
                        color={ButtonColor.Black}
                        handleClick={() => dispatch(connectWithBankAction())}
                    />
                </div>
            </div>
        );
    };

    const renderAccountNotFound = () => {
        if (isDesktopEnv()) {
            return (
                <>
                    <div className={styles.data_container__top}>
                        <span className={styles.data_container__title}>
                            ACCOUNT NOT FOUND
                        </span>
                        <span className={styles.data_container__text}>
                This bank account is not associated with a BOPP dashboard account.
                Do you want to create a subscription?
            </span>
                    </div>
                    <div className={styles.data_container__center}>
                        <div className={styles.data_container__account_not_found_image} />
                    </div>
                    <div className={styles.data_container__bottom}>
                        <Button
                            title="CREATE SUBSCRIPTION"
                            style={ButtonStyle.Login}
                            onClick={createNewAccountPressed}
                            buttonHeight="50px"
                            color={ButtonColor.Black}
                        />
                        <p
                            className={styles.data_container__pin_forgot}
                            onClick={() => dispatch(cancelAccountNotFoundAction())}>
                            Cancel
                        </p>
                    </div>
                </>
            );
        }

        return (
            <div className={mobileStyles.container}>
                <MobileHeader {...mobileHeaderProps} />
                <div className={mobileStyles.container__center}>
                    <div className={mobileStyles.top_text}>
                        <h2 className={classNames([
                                mobileStyles.top_text__title,
                                mobileStyles['top_text__title--normal'],
                            ])}>
                            Account not found
                        </h2>
                        <span className={mobileStyles.top_text__subtitle}>
              This bank account is not associated with a BOPP dashboard account.
              Do you want to create a subscription?
            </span>
                    </div>
                    <img
                        src={CreateSubscription}
                        alt="create subscription"
                        className={mobileStyles.account_not_found_img}
                    />
                </div>
                <div className={mobileStyles.container__footer}>
                    <Button
                        title="Create subscription"
                        style={ButtonStyle.LittleRounded}
                        color={ButtonColor.Black}
                        onClick={createNewAccountPressed}
                    />
                    <span className={mobileStyles.container__footer_link}
                          onClick={() => dispatch(cancelAccountNotFoundAction())}>
            Cancel</span>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        switch (step) {
            case LoginStep.AccountNotStored:
                return renderAccountNotStored();
            case LoginStep.AccountNotFound:
                return renderAccountNotFound();
            case LoginStep.EnterPin:
                return renderEnterPin();
            case LoginStep.ForgotPin:
                return renderForgottentPin();
            case LoginStep.HaveBOPPAccount:
                return renderDoYouHaveBoppAccount();
            default:
                return renderConnectWithBank();
        }
    };

    const goBackHandler = () => {
        dispatch(goBackAction());
    };

    if (isDesktopEnv()) {
        return (
            <BackgroundContainer
                backButton={isBackButton}
                onBackButtonPressed={goBackHandler}>
                <div className={styles.container}>
                    <div className={styles.data_container}>{renderContent()}</div>
                </div>
            </BackgroundContainer>
        );
    }

    return renderContent();
}
