import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';
import PinItem from './PinItem';
import Eye from '../../assets/images/eye.svg';
import OpenEye from "../../assets/images/open-eye.svg"
import style from "./PinInput.module.scss";
import { PIN_ITEM_GAP, PIN_ITEM_WIDTH } from './pinInputConstants';
import { applyTabFocusSupport } from '../../helpers';

/**
 * Copied from https://github.com/arunghosh/react-pin-input
 * Modified by Dmyrto Vovk
 */

const scale = window.devicePixelRatio;

class PinInput extends Component {
  constructor(props) {
    super(props);

    this.values = Array(props.length)
      .fill('')
      .map((x, i) => props.initialValue.toString()[i] || '');
    this.elements = [];
    this.currentIndex = 0;

    this.onPaste = this.onPaste.bind(this);
  }

  state = {
    isSecret:false
  }

  componentDidMount() {
    // Setting focus on the first element
    if (this.props.focus && this.props.length) this.elements[0].focus();

    this.setState({isSecret:this.props.secret})
  }

  clear() {
    this.elements.forEach(e => e.clear());
    this.values = this.values.map(() => undefined);
    this.elements[0].focus();
  }

  focus() {
    if (this.props.length) this.elements[0].focus();
  }

  /**
   */
  onItemChange(value, isPasting, index) {
    const { length, onComplete, onChange } = this.props;
    let currentIndex = index;

    this.values[index] = value;

    // Set focus on next
    if (value.length === 1 && index < length - 1) {
      currentIndex += 1;
      this.elements[currentIndex].focus();
    }

    // Notify the parent
    const pin = this.values.join('');

    if (!isPasting) {
      onChange(pin, currentIndex);
    }

    if (pin.length === length) {
      // for pasting, trigger onComplete only when the last input triggers onChange
      if (isPasting && index < length - 1) {
        return;
      }

      onComplete(pin, currentIndex);
    }
  }

  onBackspace(index) {
    if (index > 0) {
      this.elements[index - 1].focus();
    }
  }

  onPaste(value) {
    const { length } = this.props;
    if (value.length !== length) {
      return;
    }

    this.elements.forEach((el, index) => el.update(value[index], true));
  }


  render() {
    const width = this.props.length * PIN_ITEM_WIDTH + (this.props.length - 1) * PIN_ITEM_GAP;
    let currentValues = this.values;

    if (this.props.value !== null) {
      let splittedValue = this.props.value.toString().split("");
      currentValues = Array(this.props.length).fill("").map((e, i) => splittedValue[i] || e);
    }

    const styles = classNames([
      style.pin_input,
      {[style["pin_input--mobile"]]: this.props.mobile},
    ])

    const inlineStyle = this.props.mobile ? null : {width: width + 'px', marginTop: '9px'};

    return (
      <div className={this.props.wrapperClassName}>
      {this.props.label && <span className={style.pin_input__label}>{this.props.label}</span>}
      <div className={styles} style={inlineStyle} >
        {currentValues.map((e, i) => (
          <PinItem
            initialValue={e}
            value={this.props.value && e}
            ref={n => (this.elements[i] = n)}
            key={i}
            disabled={this.props.disabled}
            onBackspace={() => this.onBackspace(i)}
            secret={this.state.isSecret}
            onChange={(v, isPasting) => this.onItemChange(v, isPasting, i)}
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
            type={this.props.type}
            inputMode={this.props.inputMode}
            validate={this.props.validate}
            inputStyle={this.props.inputStyle}
            inputFocusStyle={this.props.inputFocusStyle}
            autoSelect={this.props.autoSelect}
            onPaste={i === 0 ? this.onPaste : null}
            regexCriteria={this.props.regexCriteria}
          />
        ))}
        <img
          { ...applyTabFocusSupport() }
          src={this.state.isSecret ? OpenEye : Eye }
          alt=""
          onClick={()=>{this.setState({isSecret:!this.state.isSecret})}}
        />
      </div>
      </div>
    );
  }
}

PinInput.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  length: PropTypes.number.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  onComplete: PropTypes.func,
  validate: PropTypes.func,
  secret: PropTypes.bool,
  mobile: PropTypes.bool,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  inputMode: PropTypes.string,
  wrapperClassName: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  inputStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  inputFocusStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  autoSelect: PropTypes.bool,
  regexCriteria: PropTypes.any,
};

PinInput.defaultProps = {
  initialValue: '',
  value: null,
  type: 'numeric',
  secret: false,
  mobile: false,
  validate: null,
  focus: false,
  disabled: false,
  onChange: () => {},
  onComplete: () => {},
  onBlur: () => {},
  onFocus: () => {},
  inputMode: undefined,
  style: {},
  inputStyle: {},
  inputFocusStyle: {},
  autoSelect: true,
  regexCriteria: /^[a-zA-Z0-9]+$/,
};

export default PinInput;
