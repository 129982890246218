let REACT_APP_NO_AISP_BANKS = [
  {
    accountName: 'Co-operative Bank',
    api: 'did:mia:ob:ss:general#coop',
    status: 'healthy',
    update_time: '2021-04-12T15:24:26.786037524Z',
    friendly_name: 'Co-operative Bank',
    logo_uri: 'https://boppapp-dev.netlify.app/banklogos/coop.png',
    short_description: '',
  },
  {
    accountName: 'Metro Bank',
    api: 'did:mia:ob:ss:general#metro',
    status: 'healthy',
    update_time: '2021-04-12T15:24:26.786037524Z',
    friendly_name: 'Metro Bank',
    logo_uri: 'https://boppapp-dev.netlify.app/banklogos/metro.png',
    short_description: '',
  }
];

const usedEnvVariables = [
  process.env.REACT_APP_DYNAMIC_MESSAGE,
  process.env.REACT_APP_PAYLINK_SERVER_URL,
  process.env.REACT_APP_ROWS_COUNT_PER_TABLE_DESKTOP,
  process.env.REACT_APP_ROWS_COUNT_PER_TABLE_MOBILE,
  process.env.REACT_APP_AIS_SERVER_URL,
  process.env.REACT_APP_MERCHANT_DASHBOARD_SERVER_URL,
  process.env.REACT_APP_SUBSCRIPTION_BOPPPAYG1_URL,
  process.env.REACT_APP_SUBSCRIPTION_BOPPFREE_URL,
  process.env.REACT_APP_SUBSCRIPTION_BOPPVB_URL,
  process.env.REACT_APP_BANK_LIST,
  process.env.REACT_APP_STORE_ENCRYPT_KEY,
  process.env.REACT_APP_ENV,
  REACT_APP_NO_AISP_BANKS,
  process.env.REACT_APP_SUBSCRIPTION_BOPPVB,
  process.env.REACT_APP_SUBSCRIPTION_BOPPPAYG1,
  process.env.REACT_APP_REDIRECT_URL,
  process.env.REACT_APP_TARGET_CALLBACK_URL,
  process.env.REACT_APP_AUTH_SIGNATURE,
];

export const isEnvSetupProperly = () => !usedEnvVariables.includes(undefined);

const config = {
  dynamicMessage: `${process.env.REACT_APP_DYNAMIC_MESSAGE}`,
  payLinkServerURL: `${process.env.REACT_APP_PAYLINK_SERVER_URL}/paylink?x-bopp-api-key=${process.env.REACT_APP_API_KEY}`,
  rowsCountPerTableDesktop: (process.env.REACT_APP_ROWS_COUNT_PER_TABLE_DESKTOP && +process.env.REACT_APP_ROWS_COUNT_PER_TABLE_DESKTOP) || 0,
  rowsCountPerTableMobile: (process.env.REACT_APP_ROWS_COUNT_PER_TABLE_MOBILE && +process.env.REACT_APP_ROWS_COUNT_PER_TABLE_MOBILE) || 0,
  accountsServerURL: `${process.env.REACT_APP_AIS_SERVER_URL}/ad`,
  dashboardServerUrl: `${process.env.REACT_APP_MERCHANT_DASHBOARD_SERVER_URL}`,
  BOPPPAYG1SubscriptionUrl: `${process.env.REACT_APP_SUBSCRIPTION_BOPPPAYG1_URL}`,
  BOPPFREESubscriptionUrl: `${process.env.REACT_APP_SUBSCRIPTION_BOPPFREE_URL}`,
  BOPPVBSubscriptionUrl: `${process.env.REACT_APP_SUBSCRIPTION_BOPPVB_URL}`,
  bankListUrl: `${process.env.REACT_APP_BANK_LIST}`,
  storeEncryptKey: `${process.env.REACT_APP_STORE_ENCRYPT_KEY}`,
  appEnvironment: process.env.REACT_APP_ENV,
  noAISPBanks: REACT_APP_NO_AISP_BANKS,
  BOPPVBSubscription: process.env.REACT_APP_SUBSCRIPTION_BOPPVB,
  BOPPPAYG1Subscriptio: process.env.REACT_APP_SUBSCRIPTION_BOPPPAYG1,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL,
  targetCallbackUrl: process.env.REACT_APP_TARGET_CALLBACK_URL,
  signature: process.env.REACT_APP_AUTH_SIGNATURE,
};

export default config;
