import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    ReferenceSettingType,
    PaymentTrigger,
    TermType,
    NotesSettingType
} from "../static/paymentRequestConstants";
import { RootState } from "../../../core/store/configureStore";

export const defaultNotesSettings: Notes = {
    enabled: false,
    type: NotesSettingType.SetByMe,
    notesValue: "",
    notesCaption: "",
    makeNotesMandatory: false,
}

export const defaultEmailSettings: RequestEmail = {
    enabled: false,
    mandatory: false,
    displayMarketingOptIn: false,
    organizationName: '',
}

export const defaultThankyouNoteSettings: ThankYouNote = {
    enabled: false,
    message: "",
}

export const defaultSettings: DefaultSettings = {
    reference: {
        type: ReferenceSettingType.SetByMe,
        setByMeValue: 'BOPP payment',
        autonumberValue: ""
    },
    expire: {
        type: PaymentTrigger.OnInstruction,
        xInstructionsValue: "",
        onCertainDate: false,
        certainDate: null,
    },
    amount: {
        type: TermType.FixedAmount,
        fixedInputValue: '',
        suggestedInputValue: '',
        minInputValue: '',
    },
    requestEmail: defaultEmailSettings,
    notes: defaultNotesSettings,
    thankYouNote: defaultThankyouNoteSettings,
    giftAid: {
        enabled: false,
    },
}

export const defaultInputsErrors: InputsErrors = {
    fixedAmount: false,
    suggestedAmount: false,
    minAmount: false,
    reference: false,
    xInstructions: false,
    notes: false,
    thankYouMessage: false,
    expiryDate: false,
}

const initialState: PaymentRequestState = {
    paymentSettings: defaultSettings,
    defaultPaymentSettings: defaultSettings,
    inputsErrors: defaultInputsErrors,
    isSettingsValid: true,
    error: {
        code: 0,
        message: "",
    }
};

export const paymentRequestSlice = createSlice({
    name: 'paymentRequest',
    initialState,
    reducers: {
        setPaymentSettings: (state, action: PayloadAction<DefaultSettings>) => {
            state.paymentSettings = { ...state.paymentSettings, ...action.payload }
        },
        setDefaultPaymentSettings: (state, action: PayloadAction<DefaultSettings>) => {
            state.defaultPaymentSettings = { ...state.paymentSettings, ...action.payload }
        },
        setInputsErrors: (state, action: PayloadAction<InputsErrors>) => {
            state.inputsErrors = { ...action.payload }
        },
        setIsSettingsValid: (state, action: PayloadAction<boolean>) => {
            state.isSettingsValid = action.payload;
        },
        setPaylinkErrorStatus: (state, action: PayloadAction<Error>) => {
            state.error =  action.payload;
        }
    }
})

export const {
    setPaymentSettings,
    setDefaultPaymentSettings,
    setInputsErrors,
    setIsSettingsValid,
    setPaylinkErrorStatus,
} = paymentRequestSlice.actions;

export const selectPaymentSettings = (state: RootState) => state.paymentRequestSettings.paymentSettings;
export const selectDefaultPaymentSettings = (state: RootState) => state.paymentRequestSettings.defaultPaymentSettings;
export const selectInputsErrors = (state: RootState) => state.paymentRequestSettings.inputsErrors;
export const selectIsSettingsValid = (state: RootState) => state.paymentRequestSettings.isSettingsValid;
export const selectError = (state: RootState) => state.paymentRequestSettings.error;

export default paymentRequestSlice.reducer;

export interface PaymentRequestState {
    paymentSettings: DefaultSettings;
    defaultPaymentSettings: DefaultSettings;
    inputsErrors: InputsErrors;
    isSettingsValid: boolean;
    error: Error;
}

export interface DefaultSettings {
    reference: Reference;
    expire: Expire;
    amount: Amount;
    requestEmail: RequestEmail;
    notes: Notes;
    thankYouNote: ThankYouNote;
    giftAid: GiftAid;
}

export interface SettingChanges {
    reference: boolean;
    expire: boolean;
    amount: boolean;
    requestEmail: boolean;
    notes: boolean;
    thankYouNote: boolean;
    giftAid: boolean;
}

export interface Reference {
    type: ReferenceSettingType;
    setByMeValue: string;
    autonumberValue: string;
}

export interface Expire {
    type: string;
    xInstructionsValue: string;
    onCertainDate: boolean;
    certainDate: string | null;
}

export interface Amount {
    type: string;
    fixedInputValue: string;
    suggestedInputValue: string;
    minInputValue: string;
}

export interface RequestEmail {
    enabled: boolean;
    mandatory: boolean;
    displayMarketingOptIn: boolean;
    organizationName: string;
}

export interface Notes {
    enabled: boolean;
    type: string;
    notesValue: string;
    notesCaption: string;
    makeNotesMandatory: boolean;
}

export interface ThankYouNote {
    enabled: boolean;
    message: string;
}

export interface GiftAid {
    enabled: boolean;
}

export interface InputsErrors {
    fixedAmount: boolean;
    suggestedAmount: boolean;
    minAmount: boolean;
    reference: boolean;
    xInstructions: boolean;
    notes: boolean;
    thankYouMessage: boolean;
    expiryDate: boolean;
}

export interface Error {
    code?: number;
    message: string;
}
