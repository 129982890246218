import {RequestSummaryTableTitles, RequestSummaryTableTitlesMobile} from "./requestSummaryTypes";

enum PaymentTrigger { OnInstruction = "OnInstruction", EachInstruction = "EachInstruction", XInstructions = "XInstructions" };
enum ReferenceSettingType { SetByMe = "SetByMe", SetByPayer = "SetByPayer", Autonumber = "Autonumber" };
enum TermType { FixedAmount = "FixedAmount", AnyAmount = "AnyAmount", SuggestedAmount = "SuggestedAmount", MinAmount = "MinAmount" };
enum NotesSettingType { SetByMe = "SetByMe", SetByPayer = "SetByPayer" };
enum AccountType {Personal = "Personal", Business = "Business", Charity = "Charity"};
enum SettingsInputType {Amount = "Amount", TextInput = "TextInput", ExpiryDate = "ExpiryDate"};

export {
    PaymentTrigger,
    ReferenceSettingType,
    TermType,
    NotesSettingType,
    AccountType,
    SettingsInputType
}


export const tableTitlesForRequestSummary: RequestSummaryTableTitles = {
    icon: 'icon',
    datetime: 'Date/Time',
    reference: 'Reference',
    payerEmail: 'Email',
    notes: 'Notes',
    amount: 'Amount',
};

export const tableTitlesForRequestSummaryMobile: RequestSummaryTableTitlesMobile = {
    datetime: 'Date/Time',
    reference: 'Reference',
    amount: 'Amount',
};
