import { calculatePages, stringsSortingCallback } from './../../../helpers/index';
import moment from 'moment';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  ActivityHistoryRequest,
  GetPaymentsPayload,
  WrappedSocket,
  SortTableState,
  HandlePaymentsResponsePayload,
  giftaidOut,
  giftaidData,
  GetAllPaymentsPayload,
} from '../static/activityTypes';
import {
  checkIsDateInFilterRange,
  checkIsNumberinRange,
  createId,
  getDateRangeByPeriodTime,
  isEmptyString,
  numbersSortingCallback,
  wrap,
} from '../../../helpers';
import {
  updatePaginationData,
  selectPayments,
  IPayment,
  setFilteredPayments,
  selectFilters,
  IActivityFilters,
  selectFilteredPayments,
  addPayment,
  updateTableSorting,
  selectTableSorting,
  setAccountState,
  updateFilters,
  IAmountFilter,
  selectPaginationData,
  PaymentGiftAid,
  setAreFiltersClear,
  resetFilters,
  setPaymentsRefreshTime,
  selectPaymentsRefreshTime,
  selectPaymentSubscriber,
  addPendingPayment,
  selectPendingPayments,
} from './activitySlice';
import {
  SortingType,
  TableColumn,
} from '../static/activityCommonDefinitions';
import { RootSagaSockets } from '../../../core/sagas/rootSaga';
import { AnyAction } from '@reduxjs/toolkit';

import nacl from 'tweetnacl';
import {
  encodeUTF8,
  decodeBase64
} from 'tweetnacl-util';
import { keyPairFromSecret } from '../../../controllers/KeyController';
import { selectGiftAidKeys } from '../../bankAccount/logic/bankAccountSlice';
import { GiftAidKeys } from '../../bankAccount/static/bankAccountTypes';
import { selectSubscriptionType } from '../../subscriptionManagement/logic/subscriptionManagementSlice';
import { SubscriptionType } from '../../../static/CommonDefinitions';
import { PaginationData } from '../../../components/pagination/static/paginationTypes';
import { DateRange } from '../../../components/dateRangeInput/static/dateRangeInputTypes';
import { RangesList } from '../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions';
import { RequestsApiKeys, selectRequestsApiKeys } from '../../requestActivity/logic/requestActivitySlice';
import { getAllRequests, getRequests } from '../../requestActivity/logic/requestActivitySaga';
import { ReferenceSettingType } from '../../paymentRequest/static/paymentRequestConstants';
import { log } from '../../../static/Logger';

export const SET_FILTERS = 'activity/set/filters';
export const FILTER_PAYMENTS = 'activity/filter/payments';
export const GET_ACTIVITY_PAYMENTS = 'activity/get/payments';
export const GET_ALL_ACTIVITY_PAYMENTS = 'activity/get/allPayments';
export const SET_TABLE_SORTING = 'activity/set/tableSorting';
export const SORT_ACTIVITY_TABLE = 'activity/sort/table';
export const SET_PAGINATION_DATA = 'activity/set/paginationData';
export const SET_CURRENT_PAYMENTS = 'activity/set/currentPayments';
export const SET_QUERY_BY_DATE = 'activity/set/filters/queryByDate';
export const SET_QUERY_BY_AMOUNT = 'activity/set/filters/queryByAmount';
export const HANDLE_PAYMENTS_RESPONSE = 'activity/handle/paymentsResponse';
export const REFRESH_ACTIVITY_FILTERS = 'activity/refresh/filters';

export interface ISetFilters {
  type: typeof SET_FILTERS;
  payload: Partial<IActivityFilters>;
}
export interface TableSorting {
  type: typeof SET_TABLE_SORTING;
  payload: Partial<SortTableState>;
}
export interface GetPayments {
  type: typeof GET_ACTIVITY_PAYMENTS;
  payload: GetPaymentsPayload;
}
export interface GetAllPayments {
  type: typeof GET_ALL_ACTIVITY_PAYMENTS;
  payload: GetAllPaymentsPayload;
}
export interface ISetPaginationData {
  type: typeof SET_PAGINATION_DATA;
  payload: PaginationData;
}

export interface HandlePaymentsResponse {
  type: typeof HANDLE_PAYMENTS_RESPONSE;
  payload: HandlePaymentsResponsePayload;
}

export function filterPaymentsAction():AnyAction {return {type: FILTER_PAYMENTS}}
export function sortActivityTableAction():AnyAction {return {type: SORT_ACTIVITY_TABLE}}
export function refreshActivityFiltersAction():AnyAction {return {type: REFRESH_ACTIVITY_FILTERS}}

export const setFiltersAction = (
    payload: Partial<IActivityFilters>,
): ISetFilters => ({
  type: SET_FILTERS,
  payload,
});

export const setPaginationData = (
  payload: PaginationData,
): ISetPaginationData => ({
  type: SET_PAGINATION_DATA,
  payload,
});

export const getPayments = (payload: GetPaymentsPayload): GetPayments => ({
  type: GET_ACTIVITY_PAYMENTS,
  payload,
});

export const getAllPayments = (payload: GetAllPaymentsPayload): GetAllPayments => ({
  type: GET_ALL_ACTIVITY_PAYMENTS,
  payload,
});

export const setTableSortingAction = (
    payload: Partial<SortTableState>,
): TableSorting => ({
  type: SET_TABLE_SORTING,
  payload,
});

export const handlePaymentsResponse = (
    payload: HandlePaymentsResponsePayload,
): HandlePaymentsResponse => ({
  type: HANDLE_PAYMENTS_RESPONSE,
  payload,
});

function* sortActivityTable() {
  const filteredPayments: IPayment[] = yield select(selectFilteredPayments);
  const sorting: SortTableState = yield select(selectTableSorting);
  const sortType = Object.keys(sorting).filter(
      el => sorting[el] !== SortingType.INACTIVE,
  )[0];

  const paymentsCopy = filteredPayments.slice();
  let targetFieldName: keyof IPayment | null = null;

  switch (sortType) {
    case TableColumn.Amount:
      targetFieldName = 'amount';
      break;
    case TableColumn.DateTime:
      targetFieldName = 'activityTime';
      break;
    case TableColumn.Reference:
      targetFieldName = 'reference';
      break;
    case TableColumn.Url:
      targetFieldName = 'siteUrl';
      break;
    case TableColumn.Id:
      targetFieldName = 'id';
      break;
    case TableColumn.PayersEmail:
      targetFieldName = 'payerEmail';
      break
    case TableColumn.Fee:
      targetFieldName = 'fee';
      break;
  }

  if (targetFieldName) {
    sortPaymentByField(paymentsCopy, sorting[sortType], targetFieldName);
  }

  yield put(setFilteredPayments(paymentsCopy))
  yield put(setAccountState());
}

const yearFromTodayRange = getDateRangeByPeriodTime(RangesList.YearFromToday);

function isDefaultFilters(filters: IActivityFilters) {
  return (filters.reference === '') &&
    (filters.amount.min === '') &&
    (filters.amount.max === '') &&
    (filters.date.start_date === yearFromTodayRange.start_date) &&
    (filters.date.end_date === yearFromTodayRange.end_date) &&
    (filters.searchQuery === '');
}

function* setFilters({ payload }: ISetFilters) {
  const filters: IActivityFilters = yield select(selectFilters);
  const newFilters = {...filters, ...payload };

  yield put(updateFilters(newFilters));
  yield put(setAreFiltersClear(isDefaultFilters(newFilters)));
  yield put(filterPaymentsAction());
}

export function filterPaymentsByReference(payments:IPayment[], reference:string) {
  if(reference === '') {
    return payments
  }

  let result = payments.filter(payment => {
    return payment.reference.toLowerCase().includes(reference.toLowerCase())
  });

  return result
}

export function filterPaymentsBySearchQuery(payments: IPayment[], searchQuery: string) {
  if (isEmptyString(searchQuery)) {
    return payments;
  }

  const lowerCaseQuery = searchQuery.toLowerCase();

  const result = payments.filter(payment => {
    return payment.reference.toLowerCase().includes(lowerCaseQuery) ||
      payment.payerEmail.toLowerCase().includes(lowerCaseQuery) ||
      payment.amount.value.includes(lowerCaseQuery) ||
      payment.siteUrl.toLowerCase().includes(lowerCaseQuery) ||
      payment.id.toLowerCase().includes(lowerCaseQuery);
  });

  return result;
}

export function filterPaymentsByDate(payments:IPayment[], date: DateRange) {
  let result = payments.filter(payment => {
    return checkIsDateInFilterRange(payment.activityTime, date);
  });

  return result
}

function filterPaymentsByAmount(payments:IPayment[], amount:IAmountFilter) {
  let result = payments.filter(payment => {
    return checkIsPaymentAmountInFilterRange(payment, amount)
  });

  return result
}

function checkIsPaymentAmountInFilterRange(payment:IPayment, amount:IAmountFilter) {
  let min = amount.min === '' ? 0 : +amount.min
  let max = amount.max === '' ? Number.MAX_VALUE : +amount.max

  return checkIsNumberinRange(+payment.amount.value, min, max)
}

function* setPaginationDataWatcher({ payload }: ISetPaginationData) {
  yield put(updatePaginationData(payload));
}

function* filterPaymentsWatcher(): any {
  const payments: IPayment[] = yield select(selectPayments);
  if(payments.length > 0) {
    const filters: IActivityFilters = yield select(selectFilters);
    const paginationData: PaginationData = yield select(selectPaginationData);
    const { reference, amount, date, searchQuery }: IActivityFilters = filters;

    let filtered = filterPaymentsByAmount(payments, amount)
    filtered = filterPaymentsByDate(filtered, date)
    filtered = filterPaymentsByReference(filtered, reference)
    filtered = filterPaymentsBySearchQuery(filtered, searchQuery);

    let pcount = calculatePages(filtered.length, paginationData.pageLimit)


    yield put(updatePaginationData({totalPages:pcount, currentPage:1, paginationRangeStart:0, paginationRangeEnd:paginationData.pageLimit}))

    yield put(setFilteredPayments(filtered))
    yield put(setAccountState());
    yield put(sortActivityTableAction())
  }
}

function getLatestPaymentDate(payments: IPayment[]) : Date {
  if(payments.length > 0) {
    let pp = payments.reduce((a, b) => {
      return new Date(a.activityTime) > new Date(b.activityTime) ? a : b;
    })

    let dd = new Date(pp.activityTime);
    return new Date(dd.getTime() - 30*60000);
  } else {
    return new Date(+0)
  }
}

function* sendActivityHistoryRequest(socket: WrappedSocket, id: string, startDate: Date) {
  const paymentSubscriber: string = yield select(selectPaymentSubscriber);
  const createdId: string = createId(true);

  if (!createdId || !paymentSubscriber) {
    return;
  }

  try {
    const request: ActivityHistoryRequest = {
      '@type': 'https://dvschema.io/activation/StatusRequest',
      id: createdId,
      entitySelector: {
        entityType: 'https://miapago.io/paylink/request/PaylinkRequest',
        id,
        propertiesSelector: {
          '@type': 'https://miapago.io/paylink/request#PropertiesSelector',
          paymentMethods: [{
              methodType: 'OpenBankingDomesticPayment',
          }],
          parties: {
            "payment:Payee": paymentSubscriber,
          },
        },
      },
      reportSelector: {
        stateSelector: {
          "@type": "https://miapago.io/paymentreport#StateSelector",
          "parties": {
            "payment:Payee": paymentSubscriber,
          },
          startDate,
        }
      },
      visit: true,
      timestamp: new Date(),
      possibleResend: false,
    };

    yield socket.send(wrap('paylink-initiator', request));
  } catch (error) {
    log('ERROR: ' + error);
  }
}


function* getPaymentsWatcher(socket: WrappedSocket, { payload }: GetPayments) {
  const { id }: GetPaymentsPayload = payload;
  const payments: IPayment[] = yield select(selectPayments);

  yield sendActivityHistoryRequest(socket, id, getLatestPaymentDate(payments));
  yield put(getRequests());
}

function* getAllPaymentsWatcher(socket: WrappedSocket, { payload }: GetAllPayments) {
  const { id }: GetAllPaymentsPayload = payload;
  const refreshTime: Date = yield select(selectPaymentsRefreshTime);

  if (moment().unix() - moment(refreshTime).unix() > 60) {
    yield sendActivityHistoryRequest(socket, id, new Date(+0));
    yield put(setPaymentsRefreshTime(new Date()));
    yield put(getAllRequests());
  }
}

function* setTableSorting({ payload }: Partial<SortTableState>) {
  yield put(updateTableSorting(payload));
}

function* handlePaymentsResponseWatcher({ payload }: HandlePaymentsResponse) : any {
  try {
    let payment: IPayment = yield getPaymentFromResponse(payload);

    if (payment.siteUrl) {
      yield put(addPayment(payment));
    } else {
      yield put(addPendingPayment(payment));
    }

  } catch(err) {
   
  }
}

function getReferenceFromPaymentResponse(metainfo: any) {
  const value = metainfo.reference.value || 'BOPP payment';

  return metainfo.reference.type === ReferenceSettingType.Autonumber 
    ? value.replace('autonumber:', '')
    : value;
}

export function *getPaymentFromResponse(resp : any) :Generator<any, IPayment | never, any>  {
  const response = resp.message.params[1];

  let report = getPaymentReportFromResponse(response);
  let isPaymentExist = yield checkIsPaymentWithIdExistsInHistory(report.id);
  const requestsApiKeys: RequestsApiKeys = yield select(selectRequestsApiKeys);

  if (!isPaymentExist) {
    let giftAidobject = yield getGiftAidDataFromReport(report);
    let metainfo = getMetainfoObjectFromReport(report)
    let reference = getReferenceFromPaymentResponse(metainfo);

    const requestId = resp.message.params[1].originatingEntityId;

    let payment: IPayment = {
      originatingEntityId: response.originatingEntityId,
      amount: {
        value: report.amount.value,
        unit: report.amount.unit,
      },
      activityTime: report.activityTime,
      requestId,
      id: report.id,
      executingEntityId: report.executingEntityId,
      siteUrl: requestsApiKeys[requestId] || '',
      fee: report.charges?.value || '0',
      reportState: report.reportState,
      reference,
      payerEmail: metainfo?.requestEmail?.email || '',
      giftAid: giftAidobject,
      receiveMarketingInfo: metainfo?.requestEmail?.displayMarketingOptIn || false,
      notes: metainfo?.notes?.notesValue || '',
    };

    return payment;
  }

  throw new Error('This payment already presist in history');
}

function getPaymentReportFromResponse(response : any) : any | never {
  if (response.reportState) {
    if (response.reportState.length > 0) {
      return response.reportState[0];
    }
  }

  throw new Error('Cannot find payment report in response from server');
}

function *checkIsPaymentWithIdExistsInHistory(identifier : string) :Generator<any, boolean, any>  {
  const payments: IPayment[] = yield select(selectPayments);
  const pendingPayments: IPayment[] = yield select(selectPendingPayments);
  const allPayments = [...payments, ...pendingPayments];

  let index = allPayments.findIndex(payment => payment.id === identifier);
  if (index === -1) {
    return false
  } else {
    return true
  }
}

function *getGiftAidDataFromReport(report : any) :Generator<any, PaymentGiftAid | null, any>  {
  let giftAidobject = null;
  const subscriptionType = yield select(selectSubscriptionType);
  if (subscriptionType === SubscriptionType.Charity) {
    let metainfo = getMetainfoObjectFromReport(report)
    const secretGiftAid: GiftAidKeys = yield select(selectGiftAidKeys);
    if(secretGiftAid.secretKey !== '') {
      const keyPair = yield keyPairFromSecret(secretGiftAid.secretKey)

      if(metainfo?.giftAid?.payloadBase64) {
        let giftAid = unpackGiftAidData(metainfo.giftAid, keyPair)

        if(giftAid !== null) {
          giftAidobject = {
            donorName: giftAid.fullName,
            address: giftAid.address,
            postcode: giftAid.postCode,
            amount: (report.amount.value / 4) + "",
          }
        }
      }
    }
  }

  return giftAidobject
}

function getMetainfoObjectFromReport(report : any) : any {
  if(report.metainfo) {
    return JSON.parse(report.metainfo)
  } else {
    return {"encryption":{"serviceProvider":{"pk":"bopp-public-key"},"payer":{"pk":"payer-public-key"},"payee":{"pk":"payee-public-key"}},"reference":{"type":"SetByMe", "value":"BOPP payment"},"notes":{"enabled":false,"type":"SetByMe","notesValue":"","notesCaption":"","makeNotesMandatory":false},"requestEmail":{"enabled":false,"mandatory":false,"displayMarketingOptIn":false,"organizationName":""},"thankYouNote":{"enabled":false,"message":""},"giftAid":{"enabled":false,"payee":{}}}
  }
}

function* handleRefreshActivityFilters() {
  yield put(resetFilters());
  const filters: IActivityFilters = yield select(selectFilters);
  yield put(setFiltersAction(filters));
}

function unpackGiftAidData(metainfo:giftaidOut, payee:nacl.BoxKeyPair):giftaidData|null {
  let nonce = decodeBase64(metainfo.nonceBase64)
  let payerPublicKey = decodeBase64(metainfo.payer.publicKey)
  if (!metainfo.payloadBase64) {
    return null
  }
  let packedMessage = decodeBase64(metainfo.payloadBase64)
  let messageAB = nacl.box.open(packedMessage, nonce, payerPublicKey, payee.secretKey)
  if (!messageAB) {
    return null
  }

  let msg = encodeUTF8(messageAB)
  let message = JSON.parse(msg)
  return message
}

export const sortPaymentByField = (items: IPayment[], sortingType: SortingType, fieldName: keyof IPayment ) => {
  if (!items.length) {
    return;
  }

  const targetFieldType = typeof items[0][fieldName]; 

  switch(fieldName) {
    case 'amount':
      items.sort((a, b) => numbersSortingCallback(+a.amount.value, +b.amount.value, sortingType));
      return;
    case 'activityTime':
      items.sort((a, b) => numbersSortingCallback(moment(a.activityTime).unix(), moment(b.activityTime).unix(), sortingType));
      return;
  }

  switch(targetFieldType) {
    case 'string':
      items.sort((a, b) => stringsSortingCallback(a[fieldName] as string, b[fieldName] as string, sortingType));
      break;
    case 'number':
      items.sort((a, b) => numbersSortingCallback(a[fieldName] as number, b[fieldName] as number, sortingType));
      break;
  }
}

export default function* activitySaga({ payLinkSocket }: RootSagaSockets) {
  yield takeLatest(SET_FILTERS, setFilters);
  yield takeLatest(FILTER_PAYMENTS, filterPaymentsWatcher);
  yield takeLatest(GET_ACTIVITY_PAYMENTS, getPaymentsWatcher, payLinkSocket);
  yield takeLatest(GET_ALL_ACTIVITY_PAYMENTS, getAllPaymentsWatcher, payLinkSocket);
  yield takeLatest(SET_TABLE_SORTING, setTableSorting);
  yield takeLatest(SET_PAGINATION_DATA, setPaginationDataWatcher);
  yield takeLatest(HANDLE_PAYMENTS_RESPONSE, handlePaymentsResponseWatcher);
  yield takeLatest(SORT_ACTIVITY_TABLE, sortActivityTable);
  yield takeLatest(REFRESH_ACTIVITY_FILTERS, handleRefreshActivityFilters);
}
