import { takeLatest } from 'redux-saga/effects';
import { RootSagaSockets } from '../../core/sagas/rootSaga';
import { ErrorObject } from '../../entities/errorObject';
import { wrap } from '../../helpers';
import { WrappedSocket } from '../../sockets/types';

export const SEND_ERROR = 'error/send';

export interface SendErrorPayload {
    error: ErrorObject;
}

export interface SendError {
    type: typeof SEND_ERROR,
    payload: SendErrorPayload,
}

export const sendError = (payload: SendErrorPayload): SendError => ({
    type: SEND_ERROR,
    payload
});

function* dispatchSendError(socket: WrappedSocket, payload: SendError) {
    const { error } = payload.payload;
    yield socket.send(wrap('paylink-initiator', error.makeError()));
}

export function* errorResolver({ payLinkSocket }: RootSagaSockets) {
  yield takeLatest(SEND_ERROR, dispatchSendError, payLinkSocket);
}