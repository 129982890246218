import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRange } from '../../../components/dateRangeInput/static/dateRangeInputTypes';
import { RangesList } from '../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions';
import { RootState } from '../../../core/store/configureStore';
import { getDateRangeByPeriodTime } from '../../../helpers';
import { IPayment, IRangeDateFilter } from '../../activity/logic/activitySlice';
import { defaultGiftAidTableSorting } from '../static/giftAidConstants';
import {SortTableState} from "../static/giftAidTypes";

const initialState: GiftAidState = {
  filters:{
    reference: '',
    date: getDateRangeByPeriodTime(
      RangesList.YearFromToday,
    ),
    searchQuery: '',
  },
  giftAidPayments: [],
  filteredPayments: [],
  tableSorting: defaultGiftAidTableSorting,
  rangeDateFilter: getDateRangeByPeriodTime(RangesList.ThisMonth),
  referenceSearchValue: '',
};

export const giftAidSlice = createSlice({
  name: 'giftAid',
  initialState,
  reducers: {
    updateGiftAidPayments: (state, action: PayloadAction<IPayment[]>) => {
      state.giftAidPayments = [...action.payload];
    },
    setGiftAidRangeDateFilter: (state, action: PayloadAction<IRangeDateFilter>) => {
      state.rangeDateFilter = action.payload;
    },
    setGiftAidFilteredPayments: (state, action: PayloadAction<IPayment[]>) => {
      state.filteredPayments = [...action.payload];
    },
    updateGiftAidFilters:  (state, action: PayloadAction<GiftAidFilters>) => {
      state.filters = action.payload
    },
    resetGiftAidFilters: state => {
      state.filters = {
        reference: '',
        date: getDateRangeByPeriodTime(
          RangesList.YearFromToday,
        ),
        searchQuery: '',
      };
      state.referenceSearchValue = '';
    },
    setReferenceSearchValue: (state, action: PayloadAction<string>) => {
      state.referenceSearchValue = action.payload;
    },
    updateTableSorting: (
      state,
      action: PayloadAction<Partial<SortTableState>>,
    ) => {
      state.tableSorting = {
        ...state.tableSorting,
        ...action.payload,
      };
    },
  },
});

export const {
  updateGiftAidPayments,
  setGiftAidRangeDateFilter,
  setGiftAidFilteredPayments,
  updateGiftAidFilters,
  setReferenceSearchValue,
  updateTableSorting,
  resetGiftAidFilters
} = giftAidSlice.actions;

export const selectGiftAidFilters = (state: RootState) => state.giftAid.filters;
export const selectGiftAidRangeDateFilter = (state: RootState) =>
  state.giftAid.rangeDateFilter;
export const selectGiftAidPayments = (state: RootState) => state.giftAid.giftAidPayments;
export const selectGiftAidFilteredPayments = (state: RootState) =>
  state.giftAid.filteredPayments;
export const selectReferenceSearchValue = (state: RootState) => state.giftAid.referenceSearchValue;
export const selectTableSorting = (state: RootState) =>
  state.giftAid.tableSorting;


export default giftAidSlice.reducer;

export interface GiftAidState {
  giftAidPayments: IPayment[];
  filters: GiftAidFilters;
  filteredPayments: IPayment[];
  tableSorting: SortTableState;
  rangeDateFilter: IRangeDateFilter;
  referenceSearchValue: string;
}

export interface GiftAidFilters {
  date: DateRange;
  reference: string;
  searchQuery: string;
}
