import { put, select, takeLatest } from "redux-saga/effects";
import {
  filterPaymentsByDate,
  filterPaymentsByReference,
  sortPaymentByField,
} from "../../activity/logic/activitySaga";
import {
  IPayment,
  PaymentGiftAid,
  setAccountState,
} from "../../activity/logic/activitySlice";
import {
  GiftAidFilters,
  resetGiftAidFilters,
  selectGiftAidFilteredPayments,
  selectGiftAidFilters,
  selectGiftAidPayments, selectTableSorting,
  setGiftAidFilteredPayments,
  updateGiftAidFilters,
  updateGiftAidPayments,
  updateTableSorting
} from "./giftAidSlice";
import {AnyAction} from "@reduxjs/toolkit";
import {SortTableState} from "../../activity/static/activityTypes";
import {SortingType, GiftAidTableColumn} from "../../activity/static/activityCommonDefinitions";
import {isEmptyString, numbersSortingCallback, stringsSortingCallback} from "../../../helpers";
import { PaymentReportState } from "../../../static/CommonDefinitions";

export const SET_GIFT_AID_FILTERS = "set/giftAid/filters";
export const RETRIEVE_GIFT_AID_PAYMENTS = "retrieve/giftAid/payments";
export const SORT_GIFT_AID_TABLE = "giftAid/sort/table"
export const SET_GIFT_AID_TABLE_SORTING = "giftAid/set/tableSorting";
export const REFRESH_GIFT_AID_FILTERS = "giftAid/refresh/filters";

export interface SetGiftAidFilters {
  type: typeof SET_GIFT_AID_FILTERS;
  payload: Partial<GiftAidFilters>;
}

export interface RetrieveGiftAidPayments {
  type: typeof RETRIEVE_GIFT_AID_PAYMENTS;
  payload: IPayment[];
}

export interface TableSorting {
  type: typeof SET_GIFT_AID_TABLE_SORTING;
  payload: Partial<SortTableState>;
}

export const setGiftAidFilters = (payload: Partial<GiftAidFilters>): SetGiftAidFilters => ({
  type: SET_GIFT_AID_FILTERS,
  payload,
});

export const retrieveGiftAidPayments = (payload: IPayment[]): RetrieveGiftAidPayments => ({
  type: RETRIEVE_GIFT_AID_PAYMENTS,
  payload,
});

export const setTableSortingAction = (
  payload: Partial<SortTableState>,
): TableSorting => ({
  type: SET_GIFT_AID_TABLE_SORTING,
  payload,
});

export function sortGiftAidTableAction():AnyAction {return {type: SORT_GIFT_AID_TABLE}}
export function refreshGiftAidFiltersAction():AnyAction {return {type: REFRESH_GIFT_AID_FILTERS}}

const filterPaymentsBySearchQuery = (payments: IPayment[], searchQuery: string) => {
  if (isEmptyString(searchQuery)) {
    return payments;
  }

  const lowerCaseQuery = searchQuery.toLowerCase();

  const result = payments.filter(payment => {
    return payment.giftAid?.donorName.toLowerCase().includes(lowerCaseQuery) ||
      payment.amount.value.includes(lowerCaseQuery) ||
      payment.reference.toLowerCase().includes(lowerCaseQuery)
  });

  return result;
}

function* filterPayments() {
  const payments: IPayment[] = yield select(selectGiftAidPayments);

  if (payments.length > 0) {
    const filters: GiftAidFilters = yield select(selectGiftAidFilters);
    const { date, reference, searchQuery }: GiftAidFilters = filters;

    let filtered = payments;

    filtered = filterPaymentsByDate(payments, date)
    filtered = filterPaymentsByReference(filtered, reference);
    filtered = filterPaymentsBySearchQuery(filtered, searchQuery)

    yield put(setGiftAidFilteredPayments(filtered))
  }
}

function* setGiftAidFiltersSaga({ payload }: SetGiftAidFilters) {
  const filters: GiftAidFilters = yield select(selectGiftAidFilters);
  yield put(updateGiftAidFilters({...filters, ...payload}));
  yield filterPayments();
}

function* retrieveGiftAidPaymentsSaga({ payload }: RetrieveGiftAidPayments) {
  const paymentsWithGiftAid = payload.filter(payment => payment.giftAid && (payment.reportState === PaymentReportState.Activated || payment.reportState === PaymentReportState.Confirmed));
  yield put(updateGiftAidPayments(paymentsWithGiftAid));
  yield filterPayments();
  yield put(sortGiftAidTableAction());
}

function* setTableSorting({ payload }: Partial<SortTableState>) {
  yield put(updateTableSorting(payload));
}

function* sortGiftAidTable() {
  const filteredPayments: IPayment[] = yield select(selectGiftAidFilteredPayments);
  const sorting: SortTableState = yield select(selectTableSorting);
  const sortType = Object.keys(sorting).filter(
    el => sorting[el] !== SortingType.INACTIVE,
  )[0];
  const paymentsCopy = filteredPayments.slice();

  let targetFieldName: keyof IPayment | null = null;
  let targetGiftAidFieldName: keyof PaymentGiftAid | null = null;

  switch (sortType) {
    case GiftAidTableColumn.Datetime:
      targetFieldName = 'activityTime';
      break;
    case GiftAidTableColumn.DonorName:
      targetGiftAidFieldName = 'donorName';
      break;
    case GiftAidTableColumn.PayerEmail:
      targetFieldName = 'payerEmail';
      break;
    case GiftAidTableColumn.Address:
      targetGiftAidFieldName = 'address';
      break;
    case GiftAidTableColumn.Postcode:
      targetGiftAidFieldName = 'postcode';
      break;
    case GiftAidTableColumn.Reference:
      targetFieldName = 'reference';
      break;
    case GiftAidTableColumn.GiftAid:
      targetGiftAidFieldName = 'amount';
      break;
    case GiftAidTableColumn.Amount:
      targetFieldName = 'amount';
      break;
  }

  if(targetFieldName) {
    sortPaymentByField(paymentsCopy, sorting[sortType], targetFieldName);
  }
  else if(targetGiftAidFieldName) {
    sortPaymentByGiftAidFields(paymentsCopy, sorting[sortType], targetGiftAidFieldName);
  }

  yield put(setGiftAidFilteredPayments(paymentsCopy))
  yield put(setAccountState());
}

export const sortPaymentByGiftAidFields = (items: IPayment[], sortingType: SortingType, fieldName: keyof PaymentGiftAid ) => {
  if (!items.length) {
    return;
  }

  if (fieldName === 'amount') {
    items.sort((a, b) => {
      const giftAidA = a.giftAid;
      const giftAidB = b.giftAid;

      if (!giftAidA || !giftAidB) {
        return 0;
      }

      return numbersSortingCallback(+giftAidA.amount, +giftAidB.amount, sortingType);
    });
  }
  else {
    items.sort((a, b) => {
      const giftAidA = a?.giftAid;
      const giftAidB = b?.giftAid;

      if (!giftAidA || !giftAidB) {
        return 0;
      }

      return stringsSortingCallback(giftAidA[fieldName] as string, giftAidB[fieldName] as string, sortingType);
    });
  }
}

function* refreshActivityFilters() {
  yield put(resetGiftAidFilters());
  yield filterPayments();
}

export default function* giftAidSagaSaga() {
  yield takeLatest(SET_GIFT_AID_FILTERS, setGiftAidFiltersSaga);
  yield takeLatest(RETRIEVE_GIFT_AID_PAYMENTS, retrieveGiftAidPaymentsSaga);
  yield takeLatest(SORT_GIFT_AID_TABLE, sortGiftAidTable);
  yield takeLatest(SET_GIFT_AID_TABLE_SORTING, setTableSorting);
  yield takeLatest(REFRESH_GIFT_AID_FILTERS, refreshActivityFilters);
}
