import React from "react";
import { cssVhToPx, isDesktopEnv } from "../../../../helpers";
import styles from '../Header.module.scss';
import mobileStyles from '../HeaderMobile.module.scss';
import BackIcon from '../../../../assets/images/back-button-small.svg';
import { useHistory } from "react-router";

export const HeaderBackButton = () => {
  const history = useHistory();
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  const handleClick = () => history.goBack();
  
  const imgStyle = isDesktopEnv() ? {} : {
    width: cssVhToPx(4.8),
    height: cssVhToPx(4.8),
  }

  return (
    <>
      <img src={BackIcon} className={targetStyles.header__back_button} style={imgStyle} onClick={handleClick} />
      { isDesktopEnv() && <div className={targetStyles.header__back_button_splitter} />}
    </>
  )
}
