import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import classNames from "classnames";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { formatAmountToString, isDesktopEnv } from "../../../helpers";
import styles from './RequestSummary.module.scss';
import mobileStyles from './RequestSummaryMobile.module.scss';
import activityStyles from "../../activity/ui/Activity.module.scss";
import activityMobileStyles from "../../activity/ui/ActivityMobile.module.scss";
import { tableTitlesForRequestSummary, tableTitlesForRequestSummaryMobile } from "../static/requestSummaryConstants";
import { StylizedWrapperWithRadius } from "../../../components/stylizedWrapper/StylizedWrapperWithRadius";
import { HeaderCTAButton } from "../../../components/header/ui/additionalComponents/HeaderCTAButton";
import { filterPaymentsAction, getPayments, refreshActivityFiltersAction, setTableSortingAction, sortActivityTableAction } from "../../activity/logic/activitySaga";
import { PaymentDetailsPopUp } from "../../../components/paymentDetailsPopUp/PaymentDetailsPopUp";
import { DefaultSettings, selectError } from "../../paymentRequest/logic/paymentRequestSlice";
import { selectPaymentRequests } from "../../requestActivity/logic/requestActivitySlice";
import { BackgroundContainer } from "../../../components/background/BackgroundContainer";
import ErrorHandler from "../../paymentRequest/ui/errorHandlerComponent/errorHandler";
import { SortingType } from "../../activity/static/activityCommonDefinitions";
import { ButtonColor, ButtonStyle } from "../../../static/CommonDefinitions";
import { TablePagination, TableRow } from "../../activity/ui/Activity";
import { CSVPayment } from "../../activity/static/activityTypes";
import { Header } from "../../../components/header/ui/Header";
import { RequestReview } from "./requestReview/RequestReview";
import { Button } from "../../../components/button/Button";
import config from "../../../config";
import {
    IPayment,
    resetFilters,
    selectAreFiltersClear,
    selectFilteredPayments,
    selectPaginationData,
    selectPayments,
    selectPaymentSubscriber,
    selectTableSorting,
    updatePaginationData
} from "../../activity/logic/activitySlice";
import DoubleArrowIcon from "./../../../assets/images/double-arrow.svg";
import UpArrow from "./../../../assets/images/forward-arrow.svg";
import { defaultSortTableState } from "../../activity/static/activityConstants";

interface RequestSummaryTableProps {
    currentPayments: IPayment[];
    paginationStep?: number;
}

export const RequestSummaryTable = ({currentPayments, paginationStep}: RequestSummaryTableProps) => {
    const dispatch = useAppDispatch();
    const isDesktop = isDesktopEnv();
    const sorting = useAppSelector(selectTableSorting);
    const paginationData = useAppSelector(selectPaginationData);
    const [clickedPayment, setClickedPayment] = useState<IPayment | null>(null);

    const targetTitles = isDesktop ? tableTitlesForRequestSummary : tableTitlesForRequestSummaryMobile;
    const targetStyles = isDesktop ? activityStyles : activityMobileStyles;

    const handleSortOrder = (name: string) => {
        const value = sorting[name];
        Object.keys(sorting).forEach(key => {
            if (sorting[key] !== SortingType.INACTIVE) {
                dispatch(setTableSortingAction({[key]: SortingType.INACTIVE}));
            }
        });

        switch (value) {
            case SortingType.DESCENDING:
                dispatch(setTableSortingAction({[name]: SortingType.ASCENDING}));
                break;
            case SortingType.INACTIVE:
                dispatch(setTableSortingAction({[name]: SortingType.ASCENDING}));
                break;
            case SortingType.ASCENDING:
                dispatch(setTableSortingAction({[name]: SortingType.DESCENDING}));
                break;
            default:
                return;
        }
        dispatch(sortActivityTableAction())
    };

    const Titles = () =>
        Object.keys(targetTitles).map((key: string) => {
            const titleStyles = classNames([
                targetStyles.tWrapper__title,
                {
                    [targetStyles['tWrapper__title--asc']]:
                    sorting[key] === SortingType.ASCENDING,
                    [targetStyles['tWrapper__title--desc']]:
                    sorting[key] === SortingType.DESCENDING,
                    [targetStyles['tWrapper__title--inactive']]:
                    sorting[key] === SortingType.INACTIVE,
                },
            ]);

            if(targetTitles[key] === 'BOPP Fee') {
                return null    
            }

            if(targetTitles[key] === 'Notes') {
                return (
                    <th
                        className={titleStyles}
                        key={key}
                        style={{width:"auto"}}
                        onClick={() => handleSortOrder(key)}>
                        {
                            targetTitles[key]
                        }
                    </th>
                );
            }

            return (
                <th
                    className={titleStyles}
                    key={key}
                    id={targetTitles[key] === 'Notes' ? "notesHeader" : undefined}
                    onClick={() => handleSortOrder(key)}>
                    {
                        targetTitles[key] === 'icon'
                         ? null // ? renderFeeTitleWithIcon(targetTitles[key])
                         : targetTitles[key]
                    }
                </th>
            );
        });

    const tableContainerClassname = classNames([
        targetStyles.tWrapper,
        {[targetStyles['tWrapper_withoutItems']]: !currentPayments.length},
    ]);

    return (
        <>
            <StylizedWrapperWithRadius>
                <div className={tableContainerClassname}>
                    {
                        currentPayments.length
                        && <table className={targetStyles.tWrapper__table}>
                            <thead className={targetStyles.tWrapper__table_head}>
                            <tr className={targetStyles.tWrapper__row}>{Titles()}</tr>
                            </thead>
                            <tbody>
                            {isDesktopEnv()
                                ? currentPayments.slice(paginationData.paginationRangeStart, paginationData.paginationRangeEnd).map((payment: IPayment) => (
                                    <TableRow isForRequestSummary={true}
                                              key={payment.id}
                                              payment={payment}
                                              onClick={() => setClickedPayment(payment)}/>
                                ))
                                : currentPayments.slice(paginationData.paginationRangeStart, paginationStep).map((payment: IPayment) => (
                                    <TableRow isForRequestSummary={true}
                                              key={payment.id}
                                              payment={payment}
                                              onClick={() => setClickedPayment(payment)}/>
                                ))}
                            </tbody>
                        </table>
                        ||
                        <div className={targetStyles.tWrapper_emptyContainer}>
                            <img className={targetStyles.tWrapper_emptyContainer_icon} src={DoubleArrowIcon}/>
                            <div className={targetStyles.tWrapper_emptyContainer_subtitle}>You have no associated
                                transactions yet
                            </div>
                        </div>
                    }
                </div>
            </StylizedWrapperWithRadius>
            {clickedPayment && <PaymentDetailsPopUp payment={clickedPayment} onClose={() => setClickedPayment(null)}/>}
        </>
    );
};

interface LocationState {
    selectedRequestId: string;
}

export function RequestSummary() {
    const dispatch = useAppDispatch();
    const isDesktop = isDesktopEnv();
    const filteredPayments = useAppSelector(selectFilteredPayments);
    const paginationData = useAppSelector(selectPaginationData);
    const requests = useAppSelector(selectPaymentRequests);
    const errorStatus = useAppSelector(selectError);
    const payments = useAppSelector(selectPayments);
    const paymentSubscriber = useAppSelector(selectPaymentSubscriber);
    const [paginationStep, setPaginationStep] = useState(paginationData.pageLimit);
    const [csvData, setCsvData] = useState<CSVPayment[]>([]);
    const [showUpButton, setShowUpButton] = useState<boolean>(false);
    let headerRef = useRef<HTMLDivElement>(null);
    const location = useLocation<LocationState>();
    const selectedRequestId = location.state.selectedRequestId;
    const request = requests.find(request => request.id === selectedRequestId);
    const areFiltersClear = useAppSelector(selectAreFiltersClear);
    const visiblePayments = filteredPayments.length || !areFiltersClear ? filteredPayments : payments;
    const targetRowsCountPerTable = isDesktop
        ? config.rowsCountPerTableDesktop
        : config.rowsCountPerTableMobile;
    const showPagination = visiblePayments.length > targetRowsCountPerTable;
    const currentPayments = visiblePayments.filter((p) => p.originatingEntityId === request?.id);

    useEffect(() => {
        dispatch(setTableSortingAction(defaultSortTableState));

        dispatch(getPayments({ id: paymentSubscriber }));
        dispatch(refreshActivityFiltersAction());

        dispatch(
            updatePaginationData({
                ...paginationData,
                pageLimit: targetRowsCountPerTable,
            }),
        );

        window.addEventListener('scroll', onScrollHandler, true);
        
        return () => {
            dispatch(resetFilters());
            window.removeEventListener('scroll', onScrollHandler, true);
        };
    }, []);

    useEffect(() => {
        dispatch(filterPaymentsAction());
    }, [payments]);

    const upButtonClassname = classNames([
        mobileStyles.payment_request_upButton,
        {[mobileStyles['payment_request_upButton_visible']]: showUpButton},
    ]);

    const headersCSV = [
        {label: 'Date/Time', key: 'activityTime'},
        {label: 'ID', key: 'id'},
        {label: 'Reference', key: 'reference'},
        {label: 'Website URL', key: 'siteUrl'},
        {label: "Payer's Email", key: 'payerEmail'},
        {label: "Marketing opt-in", key: 'receiveMarketingInfo'},
        {label: "Notes", key: 'notes'},
        {label: "Gift-aid", key: 'giftAid'},
        // { label: 'BOPP Fee', key: 'fee' },
        {label: 'Amount', key: 'amount'},
    ];

    const exportToCSV = () => {
        const data = currentPayments.map(payment => {
            let formattedUnit = payment.amount.unit;
            if (payment.amount.unit.includes(':')) {
                formattedUnit = payment.amount.unit.split(':')[1];
            }
            return {
                amount: formatAmountToString(
                    +payment.amount.value,
                    formattedUnit,
                ),
                activityTime: moment(payment.activityTime).format('DD-MM-YYYY HH:mm'),
                id: payment.id,
                reference: payment.reference,
                siteUrl: payment.siteUrl,
                // fee: formatAmountToString(payment.fee),
                payerEmail: payment.payerEmail,
                receiveMarketingInfo: payment.receiveMarketingInfo ? 'Yes' : 'No',
                notes: payment.notes,
                giftAid: payment.giftAid ? 'Enabled' : 'Disabled',
            }
        });
        setCsvData(data);
    }

    const CSVButtonInfo = {
        CSVData: csvData,
        CSVHeaders: headersCSV,
        CSVFileName: "BOPP-payment-activity.csv",
        onClick: exportToCSV,
    }

    const scrollToTheTop = () => {
        if (headerRef?.current) {
            headerRef.current.scrollIntoView();
        }
    }

    const handleLoadMore = () => {
        setPaginationStep(paginationStep + +paginationData.pageLimit);
    }

    const onScrollHandler = (e: Event) => {
        const element = e.target as Element;
        if (element.scrollTop > 300) {
            setShowUpButton(true);
        } else {
            setShowUpButton(false)
        }
    }

    if (isDesktopEnv()) {
        return (
            <>
                <BackgroundContainer isMenu>
                    <div className={styles.payment_request}>
                        <Header title='Request Summary' withBackButton={true}/>
                        {
                            !!errorStatus.code && <ErrorHandler error={errorStatus}/>
                        }
                        <div className={styles.payment_request_subtitle}>
                            Associated Transactions
                            {currentPayments.length && <HeaderCTAButton CSVButtonInfo={CSVButtonInfo}/> || ''}
                        </div>
                        <div className={styles.payment_request_container}>
                            <div className={styles.payment_request_table}>
                                {
                                    request !== undefined && <RequestSummaryTable currentPayments={currentPayments}/>
                                }
                                {showPagination && (
                                    <div className={activityStyles.activity__pagination}>
                                        <TablePagination
                                            totalTableRecords={currentPayments.length}/>
                                    </div>
                                )}
                            </div>
                            {isDesktopEnv() && !!request &&
                            <RequestReview request={request} />
                            }
                        </div>
                    </div>
                </BackgroundContainer>
            </>
        )
    }

    return (
        <div className={mobileStyles.payment_request}>
            <Header ref={headerRef} withCloseButton={true} title='Request Summary'/>
            {!!errorStatus.code &&
            <div className={mobileStyles.payment_request__error}>
                <div className={mobileStyles.payment_request__error_border}/>
                <ErrorHandler error={errorStatus}/>
            </div>
            }
            {request && <RequestReview request={request}/>}
            <div className={mobileStyles.payment_request_csvItem}>
                <div className={mobileStyles.payment_request_csvItem_subtitle}>Associated Transactions</div>
                {currentPayments.length && <HeaderCTAButton CSVButtonInfo={CSVButtonInfo}/> || ''}
            </div>
            <div className={mobileStyles.payment_request_table}>
                {
                    !!request &&
                    <RequestSummaryTable paginationStep={paginationStep} currentPayments={currentPayments}/>
                }
                {showPagination && (
                    <div className={mobileStyles.payment_request_bottom}>
                        {currentPayments.length > paginationStep &&
                        <Button
                            spinner={false}
                            disabled={false}
                            title='LOAD MORE'
                            color={ButtonColor.TransparentWithBorder}
                            style={ButtonStyle.Rounded}
                            className={mobileStyles.payment_request_bottom_loadButton}
                            onClick={handleLoadMore}
                        />}
                    </div>
                )}
            </div>
            {!!currentPayments.length &&
            <Button
                spinner={false}
                disabled={false}
                title={''}
                icon={UpArrow}
                color={ButtonColor.Black}
                style={ButtonStyle.Rounded}
                className={upButtonClassname}
                onClick={scrollToTheTop}
            />
            }
        </div>
    )
}

export interface SettingsProps {
    settings: DefaultSettings;
    onSettingChange: (changed: DefaultSettings) => void;
}
