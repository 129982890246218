import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import classNames from 'classnames';
import {useLocation} from 'react-router';
import {BackgroundContainer} from '../../../components/background/BackgroundContainer';
import {Button} from '../../../components/button/Button';
import {
  StylizedInputForFilters,
  StylizedInputStyles,
  StylizedInputStyleType,
  StylizedInputTypes
} from '../../../components/StylizedInput/StylizedInputForFilters';
import {ButtonColor, ButtonStyle} from '../../../static/CommonDefinitions';
import SearchIco from '../../../assets/images/loupe.svg';
import SettingsIcon from '../../../assets/images/filter-menu.svg';

import styles from './RequestActivity.module.scss';
import mobileStyles from './RequestActivityMobile.module.scss';
import moment from 'moment';
import {applyTabFocusSupport, formatAmountToString, getDateRangeByPeriodTime, isDesktopEnv, stringWithCommas} from '../../../helpers';
import {DropdownLabelPosition, DropdownWithSearch} from '../../../components/dropdownWithSearch/DropdownWithSearch';
import {StylizedWrapperWithRadius} from '../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {
  clearCancelRequests,
  selectCancelResult,
  selectFilteredPaymentRequests,
  selectIsCancelInProgress,
  selectIsShowModalForCancel,
  selectPaymentRequests,
  selectRequestsTableFilters,
  selectRequestsTablePaginationData,
  selectRequestsTableSorting,
  setCancelResult,
  setIsCancelInProgress,
  setIsShowModalForCancel,
  updateHistoryRequest,
  updateRequestsTablePaginationData,
  updateRequestsTableSorting,
} from '../logic/requestActivitySlice';
import {SortingType} from '../../activity/static/activityCommonDefinitions';
import {
  defaultTableSortState,
  REQUEST_REF_SET_BY_PAYER_VALUE,
  requestsTableTitles,
  requestTableTitlesMobile
} from '../static/requestActivityConstants';
import {PaymentRequest, RequestCSV, RequestFilters} from '../static/requestActivityTypes';
import {
  fetchPaylinkStatusForCancelRequest,
  filterRequestsAction,
  getRequests,
  refreshRequestsFilters,
  setRequestsTableFilters,
  sortRequestsTableAction,
} from '../logic/requestActivitySaga';
import {
  PaymentCancelRequestResults,
  PaymentRequestStatus,
  PaymentRequestStatusReason
} from '../static/requestActivityCommonDefinitions';
import {Pagination} from '../../../components/pagination/ui/Pagination';
import {PaginationData} from '../../../components/pagination/static/paginationTypes';
import ThreeDotsIcon from "../../../assets/images/three-dots-gray.svg";
import config from '../../../config';
import {PopupList, PopupListItem} from '../../../components/popupList/PopupList';
import CopyIcon from '../../../assets/images/copy-v2.svg';
import CancelIcon from '../../../assets/images/cancel-rounded.svg';
import CloseIcon from "../../../assets/images/close-arrow-24px-rounded.svg";
import {DateRangeInput} from '../../../components/dateRangeInput/ui/DateRangeInput';
import {RangesList} from '../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions';
import {useMultipleRefsOutsideClick, useOutsideClick} from '../../../hooks/useOutsideClick';
import {copyToClipboard} from '../../../utils/clipboard';
import {RequestDetails, RequestDetailsocationState} from '../../requestDetails/ui/RequestDetails';
import {FilterTagsContainer} from '../../../components/filterTagsContainer/FilterTagsContainer';
import {Header} from '../../../components/header/ui/Header';
import {ReferenceSettingType, TermType} from '../../paymentRequest/static/paymentRequestConstants';
import {formatPaylinkUrl} from '../../../utils/paylinkUrl';
import {Toast} from '../../../components/toast/Toast';
import {ToastIcon} from '../../../components/toast/static/toastCommonDefinitions';
import {Modal} from "../../../components/modal/Modal";

const { start_date: start_date_default, end_date: end_date_default } = getDateRangeByPeriodTime(
  RangesList.YearFromToday,
);

export function RequestActivity() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const requests = useAppSelector(selectPaymentRequests);
  const filteredRequests = useAppSelector(selectFilteredPaymentRequests);
  const paginationData = useAppSelector(selectRequestsTablePaginationData);
  const { searchQuery, reference, queryByDate: {start_date, end_date} }: RequestFilters = useAppSelector(selectRequestsTableFilters);
  const [referenceInputValue, setReferenceInputValue] = useState('');
  const [filtersAreOpened, setFiltersAreOpened] = useState(false);
  const [showInfoPopupForCopying, setShowInfoPopupForCopying] = useState(false);
  const [paymentRequestToCancel, setPaymentRequestToCancel] = useState<PaymentRequest | null>(null);
  const [csvData, setCsvData] = useState<RequestCSV[]>([]);

  const areFiltersClear = (searchQuery === '') && (reference === '')  && (start_date === start_date_default) && (end_date === end_date_default);
  const visibleRequests = filteredRequests.length || !areFiltersClear ? filteredRequests : requests;
  const isDesktop = isDesktopEnv();
    const targetRowsCountPerTable = isDesktop
    ? config.rowsCountPerTableDesktop
    : config.rowsCountPerTableMobile;
  const showPagination = visibleRequests.length > targetRowsCountPerTable;

  useEffect(() => {
    dispatch(getRequests());
    dispatch(refreshRequestsFilters());
  }, []);

  useEffect(() => {
    dispatch(filterRequestsAction());
  }, [requests]);

  useEffect(() => {
    dispatch(
      updateRequestsTablePaginationData({
          ...paginationData,
          pageLimit: targetRowsCountPerTable,
        }),
    );
  }, [isDesktop]);

  useEffect(() => {
    dispatch(updateRequestsTableSorting(defaultTableSortState));
    dispatch(sortRequestsTableAction());
  }, []);

  if (location.state) {
    const { requestId } = location.state as RequestDetailsocationState;
    if (requestId !== undefined) {
      return <RequestDetails />
    }
  }

  const getCSVAmountType = (request: PaymentRequest) : string => {
    switch (request.amount.type) {
      case TermType.AnyAmount:
        return 'Set by payer';
      case TermType.FixedAmount:
        return 'Fixed';
      case TermType.MinAmount:
        return 'Min';
      case TermType.SuggestedAmount:
        return 'Suggested';
    }

    return 'not defined';
  }

  const exportToCSV = () => {
    const data = filteredRequests.map(request => ({
      creationTime: moment(request.creationTime).format('DD-MM-YYYY HH:mm'),
      status: request.status,
      reference: request.reference,
      requestedTo: request.requestedTo,
      associatedPayments: request.receivedPayments.length.toString(),
      amountValue: request.amount.value.value ? formatAmountToString(+request.amount.value.value, 'GBP') : '',
      amountType: getCSVAmountType(request),
      totalPaid: 	formatAmountToString(request.totalPaid, 'GBP'),
    }));
    setCsvData(data);
  }

  const headersCSV = [
    { label: 'Creation Date/Time', key: 'creationTime' },
    { label: 'Status', key: 'status' },
    { label: 'Reference', key: 'reference' },
    { label: 'Requested To', key: 'requestedTo' },
    { label: 'No. associate payments', key: 'associatedPayments' },
    { label: 'Payment Amount', key: 'amountValue' },
    { label: 'Payment Amount Type', key: 'amountType' },
    { label: 'Total Paid', key: 'totalPaid' },
  ];

  const handleReferenceValueChange = (value: string) => {
    setReferenceInputValue(value);
    dispatch(setRequestsTableFilters({ reference: value }));
  }

  const handleReferenceSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setReferenceInputValue(value);

    if (!value.length) {
      dispatch(setRequestsTableFilters({ reference: value }));
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRequestsTableFilters({searchQuery: e.target.value}));
  }

  const handleSearchClear = () => {
    dispatch(setRequestsTableFilters({searchQuery: ''}));
  }

  const requestsReferences: string[] = [];

  requests.forEach(request => {
    const targetReference = request.reference || REQUEST_REF_SET_BY_PAYER_VALUE;

    if (!requestsReferences.includes(targetReference)) {
      requestsReferences.push(targetReference);
    }
  });

  if (isDesktop) {
    return (
      <BackgroundContainer isMenu>
        <div className={styles.request_activity}>
          <Header
            title='Request Activity'
            CTAButton={{
              CSVButtonInfo: {
                CSVData: csvData,
                CSVHeaders: headersCSV,
                CSVFileName: 'BOPP-request-activity.csv',
                onClick: () => {exportToCSV()},
              }
            }}
          />
          <div className={styles.request_activity__filters}>
            <div className={styles.request_activity__search_filter_wrap}>
              <StylizedInputForFilters
                  useClearButton
                  handleClear={handleSearchClear}
                  name="searachQuery"
                  value={searchQuery}
                  icon={SearchIco}
                  placeholder="Search"
                  handleChange={handleSearchChange}
                  type={StylizedInputTypes.Text}
                  style={StylizedInputStyles.Search}
                  styleType={StylizedInputStyleType.Solid}
              />
            </div>
            <div className={styles.request_activity__filters_right}>
              <DateRangeInput startDate={start_date} endDate={end_date} onRangeByDate={(range) => dispatch(setRequestsTableFilters({queryByDate: range}))} />
              <DropdownWithSearch
                label='Reference'
                placeholder='All'
                values={requestsReferences}
                searchValue={referenceInputValue}
                onSearchValueChange={handleReferenceSearchChange}
                onValueChange={handleReferenceValueChange}
                labelPosition={DropdownLabelPosition.Left}
              />
            </div>
          </div>
          <StylizedWrapperWithRadius>
            <Table
                showInfoPopupForCopying={showInfoPopupForCopying}
                setShowInfoPopupForCopying={setShowInfoPopupForCopying}
                paylinkToCancel={paymentRequestToCancel}
                setPaylinkToCancel={setPaymentRequestToCancel}
                areFiltersClear={areFiltersClear}
            />
          </StylizedWrapperWithRadius>
          {
            showPagination && <div className={styles.request_activity__pagination}>
              <Pagination
                totalTableRecords={visibleRequests.length}
                currentPage={paginationData.currentPage}
                handlePaginationUpdate={(info: PaginationData) => {
                  dispatch(updateRequestsTablePaginationData(info));
                }}
              />
            </div>
          }
        </div>
      </BackgroundContainer>
    )
  }

  const filtersContainerClassName = classNames([
    mobileStyles.request_activity__filters,
    {[mobileStyles['request_activity__filters--opened']]: filtersAreOpened},
  ]);

  const handleFiltersToggleButtonClick = () => {
    setFiltersAreOpened(!filtersAreOpened);
  }

  return (
    <div className={mobileStyles.request_activity}>
      <Header
        title='Request Activity'
        CTAButton={{
          CSVButtonInfo: {
            CSVData: csvData,
            CSVHeaders: headersCSV,
            CSVFileName: 'BOPP-request-activity.csv',
            onClick: () => {exportToCSV()},
          }
        }}
      />
      <div className={filtersContainerClassName}>
        <div className={mobileStyles.request_activity__filters_top}>
          <div className={mobileStyles.request_activity__search_filter}>
            <StylizedInputForFilters
              useClearButton
              handleClear={handleSearchClear}
              name="searchQuery"
              value={searchQuery}
              icon={SearchIco}
              placeholder="Search"
              handleChange={handleSearchChange}
              type={StylizedInputTypes.Text}
              style={StylizedInputStyles.Search}
              styleType={StylizedInputStyleType.Solid}
            />
          </div>
          <img
            alt={''}
            src={filtersAreOpened ? CloseIcon : SettingsIcon}
            className={mobileStyles.request_activity__settings_icon}
            onClick={handleFiltersToggleButtonClick}
          />
        </div>
        <div className={mobileStyles.request_activity__filters_bottom}>
          <div className={mobileStyles.request_activity__date_filter}>
            <DateRangeInput startDate={start_date} endDate={end_date} onRangeByDate={(range) => dispatch(setRequestsTableFilters({queryByDate: range}))} />
          </div>
          <DropdownWithSearch
            label='Reference'
            placeholder='All'
            values={requestsReferences}
            searchValue={referenceInputValue}
            onSearchValueChange={handleReferenceSearchChange}
            onValueChange={handleReferenceValueChange}
            labelPosition={DropdownLabelPosition.Top}
          />
        </div>
      </div>
      {
        !filtersAreOpened &&
          <FilterTagsContainer
            referenceFilter={reference}
            dateFilter={{ start_date, end_date }}
            onReferenceFilterClear={() => dispatch(setRequestsTableFilters({reference: ''}))}
            onDateFilterClear={() => dispatch(setRequestsTableFilters({queryByDate: getDateRangeByPeriodTime(RangesList.YearFromToday)}))}
          />
      }
      <Table
          showInfoPopupForCopying={showInfoPopupForCopying}
          setShowInfoPopupForCopying={setShowInfoPopupForCopying}
          paylinkToCancel={paymentRequestToCancel}
          setPaylinkToCancel={setPaymentRequestToCancel}
          areFiltersClear={areFiltersClear}
      />
      {
        showPagination && <div className={mobileStyles.request_activity__pagination}>
          <Pagination
            totalTableRecords={visibleRequests.length}
            currentPage={paginationData.currentPage}
            handlePaginationUpdate={(info: PaginationData) => {
              dispatch(updateRequestsTablePaginationData(info));
            }}
          />
        </div>
      }
    </div>
  )
}

interface TableProps {
  areFiltersClear: boolean;
  paylinkToCancel: PaymentRequest | null;
  setPaylinkToCancel: (value: PaymentRequest | null) => void;
  showInfoPopupForCopying: boolean;
  setShowInfoPopupForCopying: (value: boolean) => void;
}

let cancelTimeout: NodeJS.Timeout | null = null;

export const Table = ({ areFiltersClear, paylinkToCancel, setPaylinkToCancel }: TableProps) => {
  const dispatch = useAppDispatch();
  const sorting = useAppSelector(selectRequestsTableSorting);
  const requests = useAppSelector(selectPaymentRequests);
  const filteredRequests = useAppSelector(selectFilteredPaymentRequests);
  const isCancelInProgress = useAppSelector(selectIsCancelInProgress);
  const isShowModalForCancel = useAppSelector(selectIsShowModalForCancel);
  const cancelResult = useAppSelector(selectCancelResult);
  const paginationData = useAppSelector(selectRequestsTablePaginationData);
  const visibleRequests = filteredRequests.length || !areFiltersClear ? filteredRequests : requests;
  const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);
  const [popupTimeout, setPopupTimeout] = useState<NodeJS.Timeout>();
  const [selectedRequestSettingsId, setSelectedRequestSettingsId] = useState<string | null>(null);
  const isDesktop = isDesktopEnv();

  useEffect(() => {
    if (isCancelInProgress) {
      cancelTimeout = setTimeout(() => {
        dispatch(setIsShowModalForCancel(false));
        dispatch(setIsCancelInProgress(false));
        dispatch(clearCancelRequests());
        dispatch(setCancelResult({
          success: false,
          message: PaymentCancelRequestResults.Default
        }));
        if (paylinkToCancel) {
          const req = {
            ...paylinkToCancel,
            status: PaymentRequestStatus.Open,
            statusReason: PaymentRequestStatusReason.NotCancelled
          } as PaymentRequest;
          dispatch(updateHistoryRequest(req));
        }
      }, 30000);
    } else if (cancelTimeout) {
      clearTimeout(cancelTimeout);
      cancelTimeout = null;
    }
  }, [isCancelInProgress])

  useEffect(() => {
    dispatch(setIsShowModalForCancel(false));
    dispatch(setIsCancelInProgress(false));
    dispatch(clearCancelRequests());
  }, [])

  useEffect(() => {
    if (cancelResult) {
      setPopupTimeout(setTimeout(() => dispatch(setCancelResult(undefined)), 3000));
      setPaylinkToCancel(null);
    }
  }, [cancelResult])

  const handleSortOrder = (name: string) => {
    const value = sorting[name];
    Object.keys(sorting).forEach(key => {
      if (sorting[key] !== SortingType.INACTIVE) {
        dispatch(updateRequestsTableSorting({ [key]: SortingType.INACTIVE }));
      }
    });

    switch (value) {
      case SortingType.DESCENDING:
        dispatch(updateRequestsTableSorting({ [name]: SortingType.ASCENDING }));
        break;
      case SortingType.INACTIVE:
        dispatch(updateRequestsTableSorting({ [name]: SortingType.ASCENDING }));
        break;
      case SortingType.ASCENDING:
        dispatch(updateRequestsTableSorting({ [name]: SortingType.DESCENDING }));
        break;
      default:
        return;
    }
    dispatch(sortRequestsTableAction())
  };

  const handlePaylinkCopied = (request: PaymentRequest) => {
    const paylink = formatPaylinkUrl(request.paylink);
    copyToClipboard(paylink);
    setShowInfoPopup(true);

    if (popupTimeout !== undefined) {
      clearTimeout(popupTimeout);
    }
    setPopupTimeout(setTimeout(() => setShowInfoPopup(false), 3000));
  }

  const handleCancelRequestClick = (paylink: PaymentRequest) => {
    dispatch(setIsShowModalForCancel(true));
    setPaylinkToCancel(paylink);
  }

  const handleSendCancelRequest = () => {
    if (paylinkToCancel) {
      dispatch(fetchPaylinkStatusForCancelRequest(paylinkToCancel));
    }
  }

  const targetStyles = isDesktop ? styles : mobileStyles;
  const targetTitles = isDesktop ? requestsTableTitles : requestTableTitlesMobile;

  const hideModal = () => {
    dispatch(setIsShowModalForCancel(false));
    dispatch(setIsCancelInProgress(false));
  };

  const Titles = () =>
      Object.keys(targetTitles).map((key: string) => {
        const innerStyles = classNames([
          targetStyles.table__title_inner,
          {
            [targetStyles['table__title_inner--asc']]:
            sorting[key] === SortingType.ASCENDING,
            [targetStyles['table__title_inner--desc']]:
            sorting[key] === SortingType.DESCENDING,
            [targetStyles['table__title_inner--inactive']]:
            sorting[key] === SortingType.INACTIVE,
          },
        ]);

        const getMobileTitlesText = () => {
          switch(key) {
            case 'amount':
              return <span>Payment <br/> Amount </span>
            case 'totalPaid':
              return <span>Total <br/> Paid</span>
            default:
              return <span>{targetTitles[key]}</span>
          }
        }

        return (
          <th
            { ...applyTabFocusSupport() }
            className={targetStyles.table__title}
            key={key}
            onClick={() => handleSortOrder(key)}>
              <div className={innerStyles}>
                {isDesktopEnv() ? <span>{targetTitles[key]}</span> : getMobileTitlesText()}
              </div>
          </th>
        );
      });

  const showCancelRequestStatusPopup = () => {
    if (!cancelResult) {
      return null;
    }

    const icon = cancelResult.success ? ToastIcon.Success : ToastIcon.Warning;

    return (
      <Toast
        icon={icon}
        title={cancelResult.message}
        onClose={() => dispatch(setCancelResult(undefined))}
      />
    )
  }

  return (
      <>
        <StylizedWrapperWithRadius>
          <>
            <div className={targetStyles.table}>
              <table className={targetStyles.table__table}>
                <thead>
                <tr className={targetStyles.table__row}>{Titles()}</tr>
                </thead>
                <tbody>
                    {visibleRequests.slice(paginationData.paginationRangeStart, paginationData.paginationRangeEnd).map((request: PaymentRequest) => (
                        <TableRow
                          key={request.id}
                          request={request}
                          onCopyPaylink={() => handlePaylinkCopied(request)}
                          onCancelRequestClick={() => handleCancelRequestClick(request)}
                          selectedRequestSettingsId={selectedRequestSettingsId}
                          setSelectedRequestSettingsId={setSelectedRequestSettingsId}
                        />
                    ))}
                </tbody>
              </table>
              {
                showCancelRequestStatusPopup()
              }
              { isShowModalForCancel &&
                <CancelRequestPopup
                  isShowSpinner={isCancelInProgress}
                  onYesClick={handleSendCancelRequest}
                  onNoClick={hideModal}
                  onOutsideClick={hideModal}
                />
              }
            </div>
            {
              showInfoPopup &&
               <Toast
                 icon={ToastIcon.Success}
                 title='Paylink Copied'
                 onClose={() => setShowInfoPopup(false)}
               />
            }
          </>
        </StylizedWrapperWithRadius>
      </>
  );
}

interface TableRowProps {
  request: PaymentRequest,
  onCopyPaylink: () => void,
  onCancelRequestClick: () => void,
  selectedRequestSettingsId: string | null
  setSelectedRequestSettingsId: (value: string | null) => void,
}

export const TableRow = ({
  request,
  onCopyPaylink,
  onCancelRequestClick: onCancelRequestClick,
  selectedRequestSettingsId,
  setSelectedRequestSettingsId,
}: TableRowProps) => {
  const isDesktop = isDesktopEnv();
  const threeDotsRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const popupRef = useRef() as React.MutableRefObject<HTMLTableRowElement>;
  const history = useHistory();
  const targetStyles = isDesktop ? styles : mobileStyles;

  useMultipleRefsOutsideClick([threeDotsRef, popupRef], () => {
    if (selectedRequestSettingsId === request.id) {
      setSelectedRequestSettingsId(null);
    }
  });

  const statusValueClassName = classNames([
    {[targetStyles['table_row__value--status-open']]: request.status === PaymentRequestStatus.Open},
    {[targetStyles['table_row__value--status-closed']]: request.status === PaymentRequestStatus.Closed},
  ])

  const statusValue = request.status === PaymentRequestStatus.Open ? 'open' : 'closed';
  const delimeter = isDesktop ? '/' : '.';
  const dateFormat = `DD${delimeter}MM${delimeter}YYYY ${isDesktop ? 'HH:mm' : ''}`;
  const dateValue =  moment(request.creationTime).calendar({
    sameDay: '[Today] HH:mm',
    nextDay: dateFormat,
    nextWeek: dateFormat,
    lastDay: dateFormat,
    lastWeek: dateFormat,
    sameElse: dateFormat,
  })

  const handleDotsClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!selectedRequestSettingsId) {
      setSelectedRequestSettingsId(request.id);
    }

    if (selectedRequestSettingsId === request.id) {
      setSelectedRequestSettingsId(null);
    }
  }

  const handleCopyItemClick = () => {
    setSelectedRequestSettingsId(null);
    onCopyPaylink();
  }

  const handleCancelItemClick = () => {
    setSelectedRequestSettingsId(null);
    onCancelRequestClick();
  }

  const handleOnRequestsItemClick = () => {
    history.push('/request-summary', { selectedRequestId: request.id });
  };

  const getReferenceToDisplay = () => {
    if (request.paymentSettings.referenceType === ReferenceSettingType.SetByPayer) {
      return 'Set by payer';
    }

    return request.reference;
  }

  const getAmountToDisplay = (): string => {
    const formattedAmount = 
      request.paymentSettings.amountType === TermType.AnyAmount ? '' : stringWithCommas(Number(request.amount.value.value).toFixed(2));

    switch(request.paymentSettings.amountType) {
      case TermType.AnyAmount:
        return 'Set by payer';
      case TermType.FixedAmount:
        return `£${formattedAmount}`;
      case TermType.MinAmount:
        return `£${formattedAmount} (min)`;
      case TermType.SuggestedAmount:
        return `£${formattedAmount} (suggested)`;
    }

    return 'Not defined'
  }

  const renderLastValue = () => {
    if (isDesktop) {
      return (
        <>
          {'£' + (stringWithCommas(request.totalPaid.toFixed(2)))}
          <img { ...applyTabFocusSupport() } ref={threeDotsRef} src={ThreeDotsIcon} className={targetStyles.table_row__three_dots} onClick={handleDotsClick} />
          {renderPopupList()}
        </>
      )
    }

    return (
      <>
        <div className={targetStyles.table_row__last_value_text_container}>
          {'£' + (stringWithCommas(request.totalPaid.toFixed(2)))}
        </div>
        <img src={ThreeDotsIcon} className={targetStyles.table_row__three_dots} onClick={handleDotsClick}/>
        {renderPopupList()}
      </>
    )
  }

  const renderPopupList = () => {
    const showPopupList = selectedRequestSettingsId === request.id;
    if (showPopupList) {
      return (
        <PopupList className={targetStyles.table_row__popup}>
          <PopupListItem icon={CopyIcon} title='Copy Paylink' onClick={handleCopyItemClick} />
          {request.status === PaymentRequestStatus.Closed ? <></> : <PopupListItem icon={CancelIcon} title='Cancel Request' onClick={handleCancelItemClick} /> }
        </PopupList>
      )
    }

    return <></>
  }

  return (
    <tr  ref={popupRef} className={targetStyles.table_row} onClick={handleOnRequestsItemClick}>
      <td className={targetStyles.table_row__value}>{dateValue}</td>
      <td className={targetStyles.table_row__value}>
        { isDesktop
            ? <span className={statusValueClassName}>{statusValue}</span>
            : <span className={targetStyles.table_row__value}>{getReferenceToDisplay()}</span>}
      </td>
      { isDesktop &&
        <>
          <td className={targetStyles.table_row__value}>{getReferenceToDisplay()}</td>
          <td className={targetStyles.table_row__value}>{request.requestedTo}</td>
          <td className={targetStyles.table_row__value}>{request.receivedPayments.length}</td>
        </>
      }
      <td className={targetStyles.table_row__value}>{getAmountToDisplay()}</td>
      <td className={classNames([targetStyles.table_row__value, targetStyles.table_row__last_value])}>
       {renderLastValue()}
      </td>
    </tr>
);
}

interface CancelRequestPopupProps {
  onYesClick: () => void,
  onNoClick: () => void,
  onOutsideClick: () => void,
  isShowSpinner: boolean,
}

export const CancelRequestPopup = ({ onYesClick, onNoClick, onOutsideClick, isShowSpinner}: CancelRequestPopupProps) => {
  const ref = useRef(null);
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  useOutsideClick(ref, onOutsideClick);

  return (
      <Modal>
        <div className={styles.background} onClick={onOutsideClick}/>
        <div className={targetStyles.cancel_popup} ref={ref}>
          <div className={targetStyles.cancel_popup__top}>
            <span className={targetStyles.cancel_popup__title}>Cancel Request</span>
          </div>
          <div className={targetStyles.cancel_popup__center}>
        <span className={targetStyles.cancel_popup__text}>
          Do you want to cancel this payment request? Cancelling payment request won't affect payments that you have already received.
        </span>
          </div>
          <div className={targetStyles.cancel_popup__bottom}>
            <Button
                title='Yes, cancel payment request'
                color={ButtonColor.Yellow}
                style={ButtonStyle.Rounded}
                onClick={onYesClick}
                spinner={isShowSpinner}
            />
            <Button
                title='No'
                color={ButtonColor.TransparentWithBorder}
                style={ButtonStyle.Rounded}
                onClick={onNoClick}
            />
          </div>
        </div>
      </Modal>
  )
}
