import { AnyAction } from "@reduxjs/toolkit";
import axios from "axios";
import { put, select, takeLatest } from "redux-saga/effects";
import {selectBankDetails, selectPersonaDID} from './../../bankAccount/logic/bankAccountSlice'
import { BankDetails } from "../../bankAccount/static/bankAccountTypes";
import {clearStore} from './../../../controllers/KeyController'
import { push } from 'connected-react-router'
import { RequestHeader } from "../../../static/objectTypes";
import { getRequestHeader } from "../../../helpers";
import config from "../../../config";
import { changeSubscriptionPlan } from "./subscriptionManagementSlice";
import { SubscriptionPlan } from "../static/subscriptionManagementTypes";
import { reset } from "../../../core/reducers/appSlice";
import { accountNotExistAction } from "../../../core/sagas/initSaga";
import { log } from "../../../static/Logger";

export const CLOSE_BOPP_ACCOUNT = "subscriptionManagement/close/bopp/account";
export const DELETE_SUBSCRIPTION = "subscriptionManagement/delete/subscription/plan";
export const ACTIVATE_SUBSCRIPTION = "subscriptionManagement/activate/subscription/plan";

export const activateSubscriptionAction = (): AnyAction => ({
  type: ACTIVATE_SUBSCRIPTION,
});

export const deleteSubscriptionAction = (): AnyAction => ({
  type: DELETE_SUBSCRIPTION,
});

export const closeBoppAccountAction = (): AnyAction => ({
  type: CLOSE_BOPP_ACCOUNT,
});

function* activateSubscription() {
    yield put(push('/bank-account/activate'))
}

function* deleteSubscription() {
  const bankDetails: BankDetails = yield select(selectBankDetails);
	const personaDID: string = yield select(selectPersonaDID);
  const accountDID = bankDetails.accountDID
  const header:RequestHeader = yield getRequestHeader()

	try {
    const {data} = yield axios.delete(config.dashboardServerUrl + '/persona/' + personaDID + '/account/' + accountDID, {headers: header});
    let subscriptionPlan = grabSubscriptionPlanDetails(data)
    yield put(changeSubscriptionPlan(subscriptionPlan))
	} 
  catch (e:any) 
  {
    if(e.response.data.error === 'PersonaDeactivated') {
      yield put(accountNotExistAction())
      return
    }
    log(e);
  }
}

export function grabSubscriptionPlanDetails(data:any):SubscriptionPlan {
  return {
    name: data.data.property.plan.name,
    timeStamp: data.data.property.plan.createdAt,
    isActive: !data.data.property.subscriptionDeactivated
  }
}

function* closeBoppAccount() {
	const personaDID: string = yield select(selectPersonaDID);
  const header:RequestHeader = yield getRequestHeader()

	try {
	  const {data}= yield axios.delete(config.dashboardServerUrl + '/persona/' + personaDID, {headers: header});
    yield put(reset())
    localStorage.clear();
    clearStore();
    yield put(push('/login'))
	} catch (e:any) {
    if(e.response.data.error === 'PersonaDeactivated') {
      yield put(accountNotExistAction())
      return
  }
    log(e);
  }
}

export default function* subscriptionManagementSaga() {
  yield takeLatest(ACTIVATE_SUBSCRIPTION, activateSubscription);
  yield takeLatest(DELETE_SUBSCRIPTION, deleteSubscription);
  yield takeLatest(CLOSE_BOPP_ACCOUNT, closeBoppAccount);
}
