import React from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';
import { ButtonColor, ButtonStyle } from "../../static/CommonDefinitions";
import { CircleSpinner } from '../circleSpinner/CircleSpinner';

export interface IButton extends Record<string, any> {
  title: string,
  disabled?: boolean,
  handleClick?: (e: React.MouseEvent<HTMLElement>) => void,
  style?: ButtonStyle,
  buttonHeight?: string,
  color?: ButtonColor,
  icon?: string,
  className?: string,
  spinner?: boolean,
}

export const Button = ({
  title,
  disabled = false,
  handleClick,
  style = ButtonStyle.Square,
  buttonHeight = '40px',
  color = ButtonColor.Black,
  icon,
  className,
  fontSize,
  spinner,
  ...props
}: IButton) => {
  const buttonStyle = classNames(styles.button, styles[style], styles[color], className);

  return (
    <button
      className={buttonStyle}
      disabled={disabled}
      onClick={handleClick}
      style={{height: buttonHeight, fontSize}}
      {...props}
    >
      {
        spinner
        ? <CircleSpinner className={styles.button__spinner} />
        : 
          <>
            {title}
            {icon && <img src={icon} alt=""/>}
          </>
      }
    </button>
  )
};
