import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';

import style from './UpgradeNow.module.scss';
import mobileStyle from './UpgradeNowMobile.module.scss';
import { Button } from '../../../components/button/Button';
import config from './../../../config';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import { StylizedWrapperWithRadius } from '../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import { StepsSubscriptionPlan } from '../../stepsSubscriptionPlan/ui/StepsSubscriptionPlan';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { MobileHeader } from '../../../components/mobileHeader/MobileHeader';
import { MobileHeaderPadding } from '../../../components/mobileHeader/static/mobileHeaderCommonDefinitions';
import { applyTabFocusSupport, isDesktopEnv } from '../../../helpers';
import { FreeSubscriptionPlanCard, MostPopularSubscriptionPlanCard, SubscriptionPlanCard, ValueSubscriptionPlanCard } from '../../chooseSubscriptionPlan/ui/ChooseSubscriptionPlan';
import { selectBOPPPAYG1Subscriptio, selectBOPPVBSubscription } from '../../chooseSubscriptionPlan/logic/ChooseSubscriptionPlanSlice';
import { parseSubscriptionPlansAction } from '../../chooseSubscriptionPlan/logic/ChooseSubscriptionPlanSaga';
import { Header } from '../../../components/header/ui/Header';
import {selectUserSubscriptionId} from '../../subscriptionManagement/logic/subscriptionManagementSlice';

export function UpgradeNow() {
  const dispatch = useAppDispatch();
  const subscriptionId = useAppSelector(selectUserSubscriptionId)

  useEffect(() => {
    dispatch(parseSubscriptionPlansAction());
  }, []);

  const hadleSubscribeButtonClick = (subscriptionName: string) => {
    window.open('mailto:bopp@agitate.com?subject=New Sign UP/Upgrade&body=' + subscriptionId + ' has signed up as ' + subscriptionName + '&cc=sunjay@agitate.com', '_blank')
  };

  const renderCards = () => {
    return (
      <>
        <MostPopularSubscriptionPlanCard
          isUpgrade={true}
          headerWidth={'111%'}
          onSubscribeButtonClick={()=>{
            hadleSubscribeButtonClick("PAY AS YOU GO")
          }}
        />
        <ValueSubscriptionPlanCard
          isUpgrade={true}
          headerWidth={''}
          onSubscribeButtonClick={()=>{
            hadleSubscribeButtonClick("VALUE BUNDLE")
          }}
        />
      </>
    )
  }

  if (isDesktopEnv()) {
    return (
      <BackgroundContainer isMenu={true} backButton={true}>
         <div className={style.upgradeNow}>
         <Header
          title='Upgrade Now'
        />
         
        <div className={style.choose_subscription_plan}>
          <StylizedWrapperWithRadius
            style="bRadius12"
            className={style.choose_subscription_plan__wrapper}>
            <div className={style.choose_subscription_plan__main}>
              <div className={style.choose_subscription_plan__cards}>
                {renderCards()}
              </div>
            </div>
          </StylizedWrapperWithRadius>
        </div>
        </div>
      </BackgroundContainer>
    );
  }

  return (
    <div className={mobileStyle.choose_subscription_plan}>
      <Header
          title='Upgrade Now'
        />
      <div className={mobileStyle.choose_subscription_plan__center}>
       {renderCards()}
      </div>
    </div>
  );
}