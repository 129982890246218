import { getNumberOfDigitsAfterDot, isEmptyString, isLastCharacterIsSpace } from "./index";

const organizationNameRegExp = new RegExp(/^[\w\s\&\'\-]+$/);
const consecutiveSpacesRegExp = new RegExp(/\s\s/);
const lettersAndNumbersRegExp = new RegExp(/^[\w]+$/);
const notesAndThankYouNoteRegExp = new RegExp(/^[\w\s\.\,\:\;\(\)\"\'\&\#\!\?\%]+$/);
const lettersNumbersAndSpacesRegExp =  new RegExp(/^[\w\s]+$/);

export const validateAmount = (value: string) => {
  if (isEmptyString(value)) {
    return true;
  }

  if (value[0] === '0' && value[1] && value[1] !== '.') {
      return false;
  }

  if (isLastCharacterIsSpace(value) || isNaN(+value) || +value > 99999.99 || getNumberOfDigitsAfterDot(value) > 2) {
      return false;
  }

  return true;
}

export const validateAutonumberPrefix = (value: string) => {
  return validateString(value, { maxLength: 8, regExpsToPass: [lettersAndNumbersRegExp] });
}

export const validateNotes = (value: string) => {
  return validateString(value, { maxLength: 200, regExpsToPass: [notesAndThankYouNoteRegExp] });
}

export const validateNotesCaption = (value: string) => {
  return validateString(value, { maxLength: 100, regExpsToPass: [lettersNumbersAndSpacesRegExp] });
}

export const validateNumberOfPayments = (value: string) => {
  if (isEmptyString(value)) {
    return true;
  } 

  return !value.includes('.') && !isLastCharacterIsSpace(value) && (+value > 0 && +value <= 100000);
}

export const validateOrganizationName = (value: string) => {
  return validateString(value, { maxLength: 70, regExpsToPass: [organizationNameRegExp] });
}

export const validateSetByMeReference = (value: string) => {
  if (value[0] === ' ') {
    return false;
  }

  return validateString(value, {
    maxLength: 18,
    regExpsToPass: [lettersNumbersAndSpacesRegExp],
    regExpsToNotPass: [consecutiveSpacesRegExp]
  });
}

export const validateThankYouNote = (value: string) => {
  return validateString(value, { maxLength: 80, regExpsToPass: [notesAndThankYouNoteRegExp] });
}

interface StringValidationOptions {
  maxLength: number;
  regExpsToPass?: RegExp[];
  regExpsToNotPass?: RegExp[];
}

const validateString = (value: string, { maxLength, regExpsToPass = [], regExpsToNotPass = [] }: StringValidationOptions) => {
  if (isEmptyString(value)) {
    return true;
  }

  if (value.length > maxLength) {
    return false;
  }

  for (const regExp of regExpsToPass) {
    if (!regExp.test(value)) {
      return false;
    }
  }

  for (const regExp of regExpsToNotPass) {
    if (regExp.test(value)) {
      return false;
    }
  }

  return true;
}
