import React, { useEffect, useRef, useState } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import styles from "./RequestReview.module.scss";
import mobileStyles from "./RequestReviewMobile.module.scss";
import { useAppSelector } from "../../../../app/hooks";
import { SettingsReviewTextItem } from "../../../paymentRequest/ui/additionalComponents/settingsReviewTextItem/SettingsReviewTextItem";
import {fetchPaylinkStatusForCancelRequest} from "../../../requestActivity/logic/requestActivitySaga";
import { ToastIcon } from "../../../../components/toast/static/toastCommonDefinitions";
import { PaymentRequest } from "../../../requestActivity/static/requestActivityTypes";
import {PopupList, PopupListItem} from "../../../../components/popupList/PopupList";
import { PaymentTrigger, ReferenceSettingType, TermType } from "../../static/requestSummaryConstants";
import { ButtonColor, ButtonStyle } from "../../../../static/CommonDefinitions";
import {CancelRequestPopup} from "../../../requestActivity/ui/RequestActivity";
import { formatAmountToString, isDesktopEnv, stringWithCommas } from "../../../../helpers";
import { formatPaylinkUrl } from "../../../../utils/paylinkUrl";
import { Button } from "../../../../components/button/Button";
import { copyToClipboard } from "../../../../utils/clipboard";
import { Toast } from "../../../../components/toast/Toast";

import {
    PaymentCancelRequestResults,
    PaymentRequestStatus,
    PaymentRequestStatusReason
} from "../../../requestActivity/static/requestActivityCommonDefinitions";
import {
    clearCancelRequests,
    selectCancelResult,
    selectIsCancelInProgress,
    selectIsShowModalForCancel,
    setCancelResult,
    setIsCancelInProgress,
    setIsShowModalForCancel,
    updateHistoryRequest
} from "../../../requestActivity/logic/requestActivitySlice";

import ThreeDotsIcon from "../../../../assets/images/three-dots-gray.svg";
import CancelIcon from "../../../../assets/images/cancel-rounded.svg";
import DownArrowIcon from "../../../../assets/images/down-black.svg";
import CopyIcon from "../../../../assets/images/copy-v2.svg";
import { useScrollPosition } from "../../../../hooks/useScrollPosition";
import { SettingsReviewBooleanItem } from "../../../paymentRequest/ui/additionalComponents/settingsReviewBooleanItem/SettingsReviewBooleanItem";

export interface RequestReviewProps {
  request: PaymentRequest;
}

let cancelTimeout: NodeJS.Timeout | null = null;

export const RequestReview = ({request}: RequestReviewProps) => {
    const dispatch = useDispatch();
    const isShowModalForCancel = useAppSelector(selectIsShowModalForCancel);
    const isCancelInProgress = useAppSelector(selectIsCancelInProgress);
    const cancelResult = useAppSelector(selectCancelResult);
    const [paylinkToCancel, setPaylinkToCancel] = useState<PaymentRequest | null>(null);
    const [isShowMoreDetails, setIsShowMoreDetails] = useState<boolean>(false);
    const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);
    const [showPopupList, setShowPopupList] = useState<boolean>(false);
    const [popupTimeout, setPopupTimeout] = useState<NodeJS.Timeout>();
    const itemsContainerRef = useRef(null);
    const isDesktop = isDesktopEnv();
    const targetStyles = isDesktop ? styles : mobileStyles;
    const delimeter = isDesktop ? '/' : '.';
    const dateFormat = `DD${delimeter}MM${delimeter}YYYY ${isDesktop ? 'HH:mm' : ''}`;
    const expirySettings = request.paymentSettings.expirySettings;
    const headerAmount = formatAmountToString(request.totalPaid);
    const dateValue =  moment(request.creationTime).calendar({
        sameDay: '[Today] HH:mm',
        nextDay: dateFormat,
        nextWeek: dateFormat,
        lastDay: dateFormat,
        lastWeek: dateFormat,
        sameElse: dateFormat,
    });

    const itemsContainerScroll = useScrollPosition(itemsContainerRef.current);

    useEffect(() => {
        if (isCancelInProgress) {
            cancelTimeout = setTimeout(() => {
                dispatch(setIsShowModalForCancel(false));
                dispatch(setIsCancelInProgress(false));
                dispatch(clearCancelRequests());
                dispatch(setCancelResult({
                    success: false,
                    message: PaymentCancelRequestResults.Default
                }));
                if (paylinkToCancel) {
                    const req = {
                        ...paylinkToCancel,
                        status: PaymentRequestStatus.Open,
                        statusReason: PaymentRequestStatusReason.NotCancelled
                    } as PaymentRequest;
                    dispatch(updateHistoryRequest(req));
                }
            }, 30000);
        } else if (cancelTimeout) {
            clearTimeout(cancelTimeout);
            cancelTimeout = null;
        }
    }, [isCancelInProgress]);

    useEffect(() => {
        dispatch(setIsShowModalForCancel(false));
        dispatch(setIsCancelInProgress(false));
        dispatch(clearCancelRequests());
    }, []);

    useEffect(() => {
        if (cancelResult) {
            setPopupTimeout(setTimeout(() => dispatch(setCancelResult(undefined)), 3000));
            setPaylinkToCancel(null);
        }
    }, [cancelResult]);

    const showCancelRequestStatusPopup = () => {
        if (!cancelResult) {
            return null;
        }

        const icon = cancelResult.success ? ToastIcon.Success : ToastIcon.Warning;

        return (
            <Toast
                icon={icon}
                title={cancelResult.message}
                onClose={() => dispatch(setCancelResult(undefined))}
            />
        )
    }

    const handlePaylinkCopied = () => {
        const paylink = formatPaylinkUrl(request.paylink);
        copyToClipboard(paylink);
        setShowInfoPopup(true);

        if (popupTimeout !== undefined) {
            clearTimeout(popupTimeout);
        }
        setPopupTimeout(setTimeout(() => setShowInfoPopup(false), 3000));
    }

    const hideModal = () => {
        dispatch(setIsShowModalForCancel(false));
        dispatch(setIsCancelInProgress(false));
    };

    const handleCancelRequestClick = () => {
        dispatch(setIsShowModalForCancel(true));
        setPaylinkToCancel(request);
    };

    const handleSendCancelRequest = () => {
        if (paylinkToCancel) {
            dispatch(fetchPaylinkStatusForCancelRequest(paylinkToCancel));
        }
    };

    const handleDotsClick = () => {
        setShowPopupList(true);
    };

    const redirectQR = () => {
        return dispatch(push(`/request-details`, {requestId: request.id}))
    };

    const getExpirySettings = () => {
        let result = '';

        switch (expirySettings) {
            case PaymentTrigger.OnInstruction:
                result = 'Single Use';
                break;
            case PaymentTrigger.EachInstruction:
                result = request.paymentSettings.instructionsCount
                    ? `After ${request.paymentSettings.instructionsCount || 'X'} ${+request.paymentSettings.instructionsCount === 1 ? 'payment' : 'payments'}`
                    : 'Multi use';
                    break;
            default:
            return 'Not defined';
        }

        if (request.paymentSettings.expiryDate) {
            result += ` until ${moment(request.paymentSettings.expiryDate).format('DD MMM.YYYY')}`;
        }
        
        return result;
    };

    const getAmountToDisplay = (): string => {
        const formattedAmount =
            request.paymentSettings.amountType === TermType.AnyAmount ? '' : stringWithCommas(Number(request.amount.value.value).toFixed(2));

        switch(request.paymentSettings.amountType) {
            case TermType.AnyAmount:
                return 'Set by payer';
            case TermType.FixedAmount:
                return `Fixed by me: £${formattedAmount}`;
            case TermType.MinAmount:
                return `£${formattedAmount} (min)`;
            case TermType.SuggestedAmount:
                return `£${formattedAmount} (suggested)`;
        }
        return 'Not defined'
    };

    const getReferenceToDisplay = () => {
        if (request.paymentSettings.referenceType === ReferenceSettingType.SetByPayer) {
            return 'Set By Payer';
        }

        return request.reference;
    }

    const renderPopupList = () => {
        if (showPopupList) {
            return (
                <PopupList className={targetStyles.settings_review_header_popup} onClose={() => setShowPopupList(false)}>
                    <PopupListItem icon={CopyIcon} title='Open Request Page' onClick={() => redirectQR()} />
                    {request.status === PaymentRequestStatus.Closed ? <></> : <PopupListItem icon={CancelIcon} title='Cancel Request' onClick={handleCancelRequestClick} /> }
                </PopupList>
            )
        }

        return <></>
    };

    const requestReviewContainerClassname = classNames([
        targetStyles.settings_review,
        {[targetStyles['settings_review_closed']]: request.status === PaymentRequestStatus.Closed},
    ]);

    const settingsContainerClassname = classNames([
        targetStyles.settings_review_scrollbar,
        {[targetStyles['settings_review_scrollbar_withButtons']]: request.status === PaymentRequestStatus.Open},
    ]);

    const headerAmountClassName = classNames([
        targetStyles.settings_review_header_amount,
    ]);

    const statusValueClassName = classNames([
        {[targetStyles.settings_review_statusOpen]: request.status === PaymentRequestStatus.Open},
        {[targetStyles.settings_review_statusClosed]: request.status === PaymentRequestStatus.Closed},
    ]);

    const arrowIconClassname = classNames([
        targetStyles.settings_review_scrollbar_button_img,
        {[targetStyles.settings_review_scrollbar_button_img_rotate]: isShowMoreDetails},
    ]);

    const showBottomShadow = itemsContainerScroll.maxScrollPositionY > 0 && itemsContainerScroll.y < itemsContainerScroll.maxScrollPositionY;

    if (isDesktop) {
        const buttonsClassName = classNames([
            targetStyles.settings_buttons,
            showBottomShadow && targetStyles['with-top-shadow'], 
        ]);

        const headerClassName = classNames([
            targetStyles.settings_review_header,
            itemsContainerScroll.y && targetStyles['with-bottom-shadow'],
        ]);

        return (
            <div className={targetStyles.settings}>
                <div className={requestReviewContainerClassname}>
                    <div className={headerClassName}>
                        <p className={statusValueClassName}>{request.status}</p>
                        <span className={headerAmountClassName}>{headerAmount}</span>
                        <span>Total Paid</span>
                        <a className={targetStyles.settings_review_info_link}>
                            {request.paylink}
                            { request.status === PaymentRequestStatus.Open && <img src={CopyIcon} alt="copy" onClick={() => handlePaylinkCopied()}/> }
                        </a>
                    </div>
                    <div className={settingsContainerClassname} ref={itemsContainerRef}>
                        <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Date/Time' value={dateValue}/>
                        <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Requested To' value={request.requestedTo}/>
                        <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Payment Amount' value={getAmountToDisplay()}/>
                        <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Reference' value={getReferenceToDisplay()}/>
                        <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Expiry Settings' value={getExpirySettings()}/>
                        <SettingsReviewBooleanItem className={targetStyles.settings_review_boolean_item} title='Request Email' value={request?.paymentSettings?.requestEmail?.enabled}/>
                        <SettingsReviewBooleanItem
                            className={targetStyles.settings_review_boolean_item}
                            title='Receive Marketing Info'
                            value={request.paymentSettings?.requestEmail?.displayMarketingOptIn}
                            customText={request.paymentSettings?.requestEmail?.displayMarketingOptIn ? 'opted in' : 'disabled'}
                        />
                        <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Notes' value={request.paymentSettings?.notesValue}/>
                        <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Thank you Note' value={request.paymentSettings?.thankYouNoteValue}/>
                        <SettingsReviewBooleanItem className={targetStyles.settings_review_boolean_item} title='Gift Aid' value={request.paymentSettings?.giftAid}/>
                    </div>
                    {
                        request.status === PaymentRequestStatus.Closed &&
                            <div className={classNames([
                                targetStyles.bottom_line,
                                showBottomShadow && targetStyles['with-top-shadow'],
                                ])}
                            />
                    }
                    {showInfoPopup &&
                        <Toast
                            icon={ToastIcon.Success}
                            title='Paylink Copied'
                            onClose={() => setShowInfoPopup(false)}
                        />}
                    {isShowModalForCancel &&
                    <CancelRequestPopup
                        isShowSpinner={isCancelInProgress}
                        onYesClick={handleSendCancelRequest}
                        onNoClick={hideModal}
                        onOutsideClick={hideModal}
                    />}
                    {showCancelRequestStatusPopup()}
                </div>
                {request.status === PaymentRequestStatus.Open &&
                <div className={buttonsClassName}>
                    <Button
                        spinner={false}
                        disabled={false}
                        title='OPEN REQUEST PAGE'
                        color={ButtonColor.Bordered}
                        style={ButtonStyle.Rounded}
                        className={targetStyles.settings_buttons_button}
                        onClick={() => redirectQR()}
                    />
                    <Button
                        spinner={false}
                        disabled={false}
                        title='CANCEL REQUEST'
                        color={ButtonColor.Black}
                        style={ButtonStyle.Rounded}
                        className={targetStyles.settings_buttons_button}
                        onClick={() => handleCancelRequestClick()}
                    />
                </div>}
            </div>
        )
    }

    return (
        <div className={targetStyles.settings}>
            <div className={targetStyles.settings_review}>
                <div className={targetStyles.settings_review_header}>
                    <div className={targetStyles.settings_review_header_threeDots}>
                        {request.status === 'Open' && <img src={ThreeDotsIcon} className={targetStyles.settings_review_header_threeDots_img} onClick={handleDotsClick} />}
                        {renderPopupList()}
                    </div>
                    <p className={statusValueClassName}>{request.status}</p>
                    <span className={headerAmountClassName}>{headerAmount}</span>
                    <span>Total Paid</span>
                    <a className={targetStyles.settings_review_info_link}>
                        {request.paylink}
                        { request.status === PaymentRequestStatus.Open && <img src={CopyIcon} alt="copy" onClick={() => handlePaylinkCopied()}/> }
                    </a>
                </div>
                <div className={settingsContainerClassname}>
                    <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Date/Time' value={dateValue}/>
                    <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Reference' value={getReferenceToDisplay()}/>
                    {isShowMoreDetails &&
                        <>
                            <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Payment Amount' value={getAmountToDisplay()}/>
                            <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Requested To' value={request.requestedTo}/>
                            <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Expiry Settings' value={getExpirySettings()}/>
                            <SettingsReviewBooleanItem className={targetStyles.settings_review_boolean_item} title='Request Email' value={request.paymentSettings?.requestEmail?.enabled}/>
                            <SettingsReviewBooleanItem
                                className={targetStyles.settings_review_boolean_item}
                                title='Receive Marketing Info'
                                value={request.paymentSettings?.requestEmail?.displayMarketingOptIn}
                                customText={request.paymentSettings?.requestEmail?.displayMarketingOptIn ? 'opted in' : 'disabled'}
                            />
                            <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Notes' value={request.paymentSettings?.notesValue}/>
                            <SettingsReviewTextItem className={targetStyles.settings_review_item} title='Thank you Note' value={request.paymentSettings?.thankYouNoteValue}/>
                            <SettingsReviewBooleanItem className={targetStyles.settings_review_boolean_item} title='Gift Aid' value={request.paymentSettings?.giftAid}/>
                        </>
                    }
                    <div className={targetStyles.settings_review_scrollbar_button} onClick={() => setIsShowMoreDetails(!isShowMoreDetails)}>
                        <span className={targetStyles.settings_review_scrollbar_button_title}>{isShowMoreDetails ? 'Hide details' : 'Show More Details'}</span>
                        <img className={arrowIconClassname} src={DownArrowIcon}/>
                    </div>
                </div>
                {showInfoPopup &&
                    <Toast
                        icon={ToastIcon.Success}
                        title='Paylink Copied'
                        onClose={() => setShowInfoPopup(false)}
                    />}
                {showCancelRequestStatusPopup()}
            </div>
            {showInfoPopup &&
                <Toast
                    icon={ToastIcon.Success}
                    title='Paylink Copied'
                    onClose={() => setShowInfoPopup(false)}
                />}
            {isShowModalForCancel &&
            <CancelRequestPopup
                isShowSpinner={isCancelInProgress}
                onYesClick={handleSendCancelRequest}
                onNoClick={hideModal}
                onOutsideClick={hideModal}
            />}
            {showCancelRequestStatusPopup()}
        </div>
    )
}
