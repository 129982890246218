import React from "react";
import { DefaultSettings} from "../../../logic/paymentRequestSlice";
import { EmailSettingsVariant } from "../emailSettingsVariant/EmailSettingsVariant";
import { NotesSettingsVariant } from "../notesSettingsVariant/NotesSettingsVariant";
import { ThankYouNoteSettingsVariant } from "../thankYouNoteSettingsVariant/ThankYouNoteSettingsVariant";

export interface ActiveSwitchSettingsVariantsProps {
  contentType: string;
  settings: DefaultSettings;
  onSettingChange: (changed: DefaultSettings) => void;
}

export interface SettingsVariantProps {
	settings: DefaultSettings;
  onSettingChange: (changed: DefaultSettings) => void;
}

export function ActiveSwitchSettingsVariants ({ contentType, settings, onSettingChange }: ActiveSwitchSettingsVariantsProps) {
	const settingsProps: SettingsVariantProps = { settings, onSettingChange };

	switch (contentType) {
		case 'RequestEmail':
			return <EmailSettingsVariant {...settingsProps} />
		case 'Notes':
			return <NotesSettingsVariant {...settingsProps} />
		case 'ThankYouNote':
			return <ThankYouNoteSettingsVariant {...settingsProps} />
		default:
			return <></>
	}
}
