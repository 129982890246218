import { defaultSettings } from "./paymentRequestSlice"

export const paymentMigration = (state:any, version:number) => {  
    switch(version) {
        case (0): {
            return {      
                ...state.payment,
                defaultAmount:''
            }  
        }
    }
} 

export const paymentRequestSettingsMigration = (state:any, version:number) => {  
    switch(version) {
        case (0): {
            return {      
                ...state.paymentRequestSettings,        
				paymentSettings: defaultSettings,
                defaultPaymentSettings: {          
                    ...state.paymentRequestSettings.defaultPaymentSettings,          
					amount: { 
                        ...state.paymentRequestSettings.defaultPaymentSettings.amount,
                        fixedInputValue: '',
                        suggestedInputValue: '',
                        minInputValue: ''
                    }
				},
                error: {
                    code: 0,
                    message: ''
                },
                inputsErrors: {
                    fixedAmount: false,
                    suggestedAmount: false,
                    minAmount: false,
                    reference: false,
                    xInstructions: false,
                    notes: false,
                    thankYouMessage: false,
                    expiryDate: false
                },
                isSettingsValid: true,
            }  
        }
    }
} 
