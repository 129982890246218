import { SubscriptionPlanCardInfo } from "./chooseSubscriptionTypes";

export const SubscriptionPlanCardDefaultInfo: SubscriptionPlanCardInfo = {
  title: "",
  symbolBefore: false,
  symbol: "",
  price: "",
  priceType: "",
  description: "",
  img: ""
}
