import { useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { Menu } from '../menu/Menu';
import style from './MobileHeader.module.scss';
import BackIcon from '../../assets/images/back.svg';
import CloseMenuIcon from '../../assets/images/menu/close-menu.svg';
import ManuBarsIcon from '../../assets/images/menu/bars-menu.svg';
import Logo from '../../assets/images/logo-v2.svg';
import logoYellow from '../../assets/images/logo-v2-yellow.svg';
import { useAppDispatch } from '../../app/hooks';
import { SelectionModal } from "../../pages/modals/SelectionModal/SelectionModal";

import {
  MenuIconPosition,
  MobileHeaderColor,
  MobileHeaderGradient,
  MobileHeaderLogoColor,
  MobileHeaderPadding,
} from './static/mobileHeaderCommonDefinitions';
import { setSignedIn } from '../../core/reducers/appSlice';
import { clearPayments } from '../../pages/activity/logic/activitySlice';
import { signOut } from '../../controllers/KeyController';

export interface MobileHeaderProps {
  isMenu?: boolean;
  logoColor?: MobileHeaderLogoColor,
  withLogo?: boolean;
  backButton?: boolean;
  menuIconPosition?: MenuIconPosition;
  title?: string;
  className?: string;
  leftIcon?: JSX.Element | null;
  rightIcon?: JSX.Element | null;
  leftIconContainerClassName?: string;
  padding?: MobileHeaderPadding;
  gradient?: MobileHeaderGradient;
  color?: MobileHeaderColor;
  onBackButtonPressed?:any;
  inlineStyles?: any;
}

export function MobileHeader({
  isMenu = true,
  logoColor,
  withLogo = false,
  backButton = false,
  menuIconPosition = MenuIconPosition.Right,
  onBackButtonPressed = null,
  title,
  className = '',
  leftIcon,
  rightIcon,
  leftIconContainerClassName,
  padding = MobileHeaderPadding.default,
  gradient = MobileHeaderGradient.fromTopRight,
  color = MobileHeaderColor.yellow,
  inlineStyles = {},
}: MobileHeaderProps) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);

  const headerStyle = classNames([
    style.mobile_header,
    className,
    { [style['mobile_header--with_menu']]: isMenu },
    style['mobile_header--with_' + padding],
    style['mobile_header--with_' + gradient],
    style['mobile_header--' + color],
  ]);

  const ellipseStyles = classNames(
    style.mobile_header__colorful_ellipse,
    style[`mobile_header__colorful_ellipse--yellow`],
  );

  const openSignOut = () => {
    setOpenModal(true);
  };

  const handleSignOut = () => {
    history.push('/login');
    signOut();
    //dispatch(clearPayments())
    dispatch(setSignedIn(false))
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const renderMenuIcon = () => {
    return <img src={ManuBarsIcon} onClick={() => setIsMenuOpened(true)} />
  }

  const renderLeftIcon = () => {
    if (isMenu && menuIconPosition === MenuIconPosition.Left) {
      return renderMenuIcon();
    }

    if (backButton) {
      return <img src={BackIcon} alt='' onClick={() => {
        if(onBackButtonPressed === null){
          history.goBack()
        } else {
          onBackButtonPressed()
        }
      }} />;
    }

    return leftIcon;
  }

  const leftIconContainerFinalStyle = classNames([
    style.mobile_header__left_icon_container,
    leftIconContainerClassName,
    {[style['mobile_header__left_icon_container--for-menu']]: isMenu && menuIconPosition === MenuIconPosition.Left}
  ]);

  const getLogo = () => {
    if (logoColor) {
      return logoColor === MobileHeaderLogoColor.White ? Logo : logoYellow;
    }

    return color === MobileHeaderColor.yellow ? Logo : logoYellow;
  }

  return (
    <header className={headerStyle} style={inlineStyles}>
      <div className={leftIconContainerFinalStyle}>
        {renderLeftIcon()}
      </div>
      {withLogo && (
        <img
          alt=''
          src={getLogo()}
          className={style.mobile_header__logo}
        />
      )}
      {!withLogo && title && (
        <div className={style.mobile_header__title_container}>
          <h2 className={style.mobile_header__title}>{title}</h2>
        </div>
      )}
      <div className={style.mobile_header__right_icon_container}>
        {isMenu && menuIconPosition === MenuIconPosition.Right && renderMenuIcon()}
        {(!isMenu || menuIconPosition === MenuIconPosition.Left) && rightIcon}
      </div>
      {isMenuOpened && (
        <>
        { openModal &&
        <SelectionModal
          selectOptionTop={handleSignOut}
          selectOptionBottom={handleCancel}
          text="Are you sure you want to sign out?"
          optionTop="Sign Out"
        />
        }
          <div
            className={style.mobile_header__menu_container_bg}
            onClick={() => setIsMenuOpened(false)}>
          </div>
          <div className={style.mobile_header__menu_container}>
            <Menu openSignOut={() => openSignOut()} onClose={() => setIsMenuOpened(false)} />
            <span className={ellipseStyles}></span>
          </div>
        </>
      )}
    </header>
  );
}
