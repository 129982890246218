import classNames from 'classnames';
import moment from 'moment';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { applyTabFocusSupport, getDateRangeByPeriodTime, isDesktopEnv, returnDate } from '../../helpers';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { ButtonColor, ButtonStyle } from '../../static/CommonDefinitions';
import { Button } from '../button/Button';
import { CustomizedCalendar } from '../CustomizedCalendar/CustomizedCalendar';
import { DateRange } from '../dateRangeInput/static/dateRangeInputTypes';
import { RangesList } from '../dateRangeInput/static/dateRangePickerCommonDefinitions';
import ThinCalendar from '../../assets/images/thin-calendar-black.svg';
import styles from './DatePicker.module.scss';
import mobileStyles from './DatePickerMobile.module.scss';

export interface DatePickerProps {
  startDate: string;
  endDate: string;
  handleRange: ({}: DateRange) => void;
  showOnlyRangesList?: boolean;
  setValue?: (value: any) => void;
}

export const DatePicker = ({
  startDate,
  endDate,
  handleRange,
  showOnlyRangesList = false,
  setValue,
}: DatePickerProps) => {
  const isDesktop = isDesktopEnv();
  const [tmpStartDate, setTmpStartDate] = useState(new Date(startDate));
  const [tmpEndDate, setTmpEndDate] = useState(new Date(endDate));
  const [showDayPickerStart, setShowDayPickerStart] = useState(false);
  const [showDayPickerEnd, setShowDayPickerEnd] = useState(false);

  const datepickerEndDate = useRef<HTMLDivElement>(null);
  const datepickerStartDate = useRef<HTMLDivElement>(null);

  const targetStyles = isDesktop ? styles : mobileStyles;

  const changeRange = () => {
    handleRange({
      start_date: moment(+tmpStartDate).toString(),
      end_date: moment(+tmpEndDate).toString(),
    });
  };

  const chooseRangeFromList = (e: SyntheticEvent<HTMLLIElement>) => {
    const { Range } = e.currentTarget.dataset;
    if (setValue && Range) {
      setValue(Range);
    }
    if (Range) {
      const { start_date, end_date } = getDateRangeByPeriodTime(Range);
      handleRange({ start_date, end_date });
    }
  };

  const handleShowPickerStart = () => {
    if (isDesktopEnv()) {
      setShowDayPickerStart(state => !state);
    }
  };

  const handleShowPickerEnd = () => {
    if (isDesktopEnv()) {
      setShowDayPickerEnd(state => !state);
    }
  };

  const handleOutsideClickStart = () => {
    setShowDayPickerStart(false);
  };

  const handleOutsideClickEnd = () => {
    setShowDayPickerEnd(false);
  };

  const handleDayClickStart = (day: Date) => {
    setShowDayPickerStart(false);
    const isPeriodValid = moment(day).diff(tmpEndDate) < 0;

    if (isPeriodValid) {
      setTmpStartDate(day);
    } else {
      setTmpStartDate(tmpEndDate);
    }
  };

  const handleDayClickEnd = (day: Date) => {
    setShowDayPickerEnd(false);
    const isPeriodValid = moment(day).diff(tmpStartDate) > 0;

    if (isPeriodValid) {
      const isFuture = moment(day).format('x') > moment().format('x');
      setTmpEndDate(isFuture ? moment().toDate() : day);
    } else {
      setTmpEndDate(tmpStartDate);
    }
  };

  const handleDayClickStartMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDayClickStart(new Date(e.target.value));
  }

  const handleDayClickEndMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDayClickEnd(new Date(e.target.value));
  }

  useOutsideClick(datepickerStartDate, handleOutsideClickStart);
  useOutsideClick(datepickerEndDate, handleOutsideClickEnd);

  const wrapperStyles = classNames(targetStyles.datePicker, {
    [targetStyles['datePicker--fullWidth']]: showOnlyRangesList,
  });

  return (
      <div className={wrapperStyles}>
        {!showOnlyRangesList && (
            <div className={targetStyles.datePicker__header}>
                <div
                  ref={datepickerStartDate}
                  className={targetStyles.datePicker__wrapper}
                >
                  <DatePreview
                    onClick={handleShowPickerStart}
                    onMobileCalendarValueChange={handleDayClickStartMobile}
                    value={returnDate(tmpStartDate)}
                  />
                  {showDayPickerStart && (
                    <div className={targetStyles.datePicker__daypickerStartWrap}>
                      <CustomizedCalendar
                        maxDate={tmpEndDate}
                        value={tmpStartDate}
                        onClickDay={handleDayClickStart}
                      />
                    </div>
                  )}
              </div>
              <span>-</span>
              <div
                ref={datepickerEndDate}
                className={targetStyles.datePicker__wrapper}
              >
                <DatePreview
                  onClick={handleShowPickerEnd}
                  onMobileCalendarValueChange={handleDayClickEndMobile}
                  value={returnDate(tmpEndDate)}
                />
                {showDayPickerEnd && (
                    <div className={targetStyles.datePicker__daypickerEndWrap}>
                      <CustomizedCalendar
                        minDate={tmpStartDate}
                        maxDate={new Date()}
                        value={tmpEndDate}
                        onClickDay={handleDayClickEnd}
                      />
                    </div>
                )}
              </div>

              <Button
                title='GO'
                handleClick={changeRange}
                color={ButtonColor.Yellow}
                style={ButtonStyle.Rounded}
                className={targetStyles.datePicker__button}
              />
            </div>
        )}

        <ul className={targetStyles.datePicker__list}>
          {Object.values(RangesList).map(rangeKey => (
              <li
                  key={rangeKey}
                  data--range={rangeKey}
                  onClick={chooseRangeFromList}
                  className={targetStyles.datePicker__listItem}>
                {rangeKey}
              </li>
          ))}
        </ul>
      </div>
  );
}

interface DatePreviewProps {
  value: string;
  onClick: () => void;
  onMobileCalendarValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const DatePreview = ({value, onClick, onMobileCalendarValueChange }: DatePreviewProps) => {
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;
  const [focused, setFocused] = useState(false);

  const finalClassName = classNames([
    targetStyles.datePicker__date_preview,
    {[targetStyles['datePicker__date_preview--active']]: focused},
  ]);

  return (
    <div className={finalClassName} onClick={onClick} { ...applyTabFocusSupport() }>
      <span className={targetStyles.datePicker__date_preview_value}>{value}</span>
      {
        isDesktopEnv()
          ? <img src={ThinCalendar} />
          : (
            <input
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              type='date'
              max={new Date().toString()}
              value={moment(value).format('YYYY-MM-DD')}
              onChange={onMobileCalendarValueChange}
            />
          )
      }
    </div>
  )
}
