export enum HeaderButton {
  Menu = 'Menu',
  Close = 'Close',
  Refresh = 'Refresh',
  None = 'None',
}

export enum HeaderButtonPosition {
  Left = 'Left',
  Right = 'Right',
}