import { put, takeLatest, select } from "redux-saga/effects";

import { AnyAction } from "@reduxjs/toolkit";
import config from "../../../config";
import { SubscriptionPlanCardInfo } from "../static/chooseSubscriptionTypes";
import { setBOPPVBSubscription, setBOPPPAYG1Subscriptio, setBOPPFREESubscription } from "./ChooseSubscriptionPlanSlice";

export const PARSE_SUBSCRIPTION_PLANS = "chooseSubscriptionPlan/parse";
export function parseSubscriptionPlansAction():AnyAction {return {type: PARSE_SUBSCRIPTION_PLANS}}

function parseSubscriptionFromConfig(data?: string): SubscriptionPlanCardInfo | null {
  const keyValues = data?.split(";;");
  const parsed: {[key: string]: string} = {};

  if (!keyValues) {
    return null;
  }

  for(const item of keyValues) {
    const [key, value] = item.split("==");
    parsed[key] = value;
  }

  return {
    title: parsed["title"],
    symbolBefore: parsed["symbolBefore"] === "true",
    symbol: parsed["symbol"],
    price: parsed["price"],
    priceType: parsed["priceType"],
    description: parsed["description"],
    img: parsed["img"],
  }
}

export function* parseSubscriptionPlansWatcher() {
  const BOPPVBSubscription = parseSubscriptionFromConfig(config.BOPPVBSubscription);
  const BOPPPAYG1Subscriptio = parseSubscriptionFromConfig(config.BOPPPAYG1Subscriptio);
  const BOPPFREESubscriptio = parseSubscriptionFromConfig(config.BOPPFREESubscriptionUrl);

  if (BOPPVBSubscription) {
    yield put(setBOPPVBSubscription(BOPPVBSubscription));
  }

  if (BOPPPAYG1Subscriptio) {
    yield put(setBOPPPAYG1Subscriptio(BOPPPAYG1Subscriptio));
  }

  if (BOPPFREESubscriptio) {
    yield put(setBOPPFREESubscription(BOPPFREESubscriptio));
  }
}

export default function* chooseSubscriptionPlanSaga() {
  yield takeLatest(PARSE_SUBSCRIPTION_PLANS, parseSubscriptionPlansWatcher);

}
