import React, { useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../../../app/hooks";
import { signOut } from "../../../../controllers/KeyController";
import { setSignedIn } from "../../../../core/reducers/appSlice";
import { SelectionModal } from "../../../../pages/modals/SelectionModal/SelectionModal";
import { Menu } from "../../../menu/Menu";
import mobileStyles from '../HeaderMobile.module.scss';

export interface HeaderMenuContainerProps {
  onClose: () => void;
}

export const HeaderMenuContainer = ({ onClose }: HeaderMenuContainerProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const openSignOut = () => {
    setOpenModal(true);
  };

  const handleSignOut = () => {
    history.push('/login');
    signOut();
    dispatch(setSignedIn(false))
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const ellipseStyles = classNames(
    mobileStyles.mobile_header__colorful_ellipse,
    mobileStyles[`mobile_header__colorful_ellipse--yellow`],
  );

  return (
    <>
      { openModal &&
        <SelectionModal
          selectOptionTop={handleSignOut}
          selectOptionBottom={handleCancel}
          text="Are you sure you want to sign out?"
          optionTop="Sign Out"
        />
      }
      <div
        className={mobileStyles.mobile_header__menu_container_bg}
        onClick={onClose}>
      </div>
      <div className={mobileStyles.mobile_header__menu_container}>
        <Menu openSignOut={() => openSignOut()} onClose={onClose} />
        <span className={ellipseStyles}></span>
      </div>
    </>
  )
}

