import { put, select, takeLatest } from "redux-saga/effects";

import { setBankList } from "./ChooseBankSlice";
import axios from "axios";
import config from "../../../config";
import { log } from "../../../static/Logger";

export const GET_BANK_LIST = "get/bank/list";

export const getBankList = () => ({
  type: GET_BANK_LIST,
});

function* getBankListSaga() {
  try {
    const { data } = yield axios.get(config.bankListUrl);
    if (config.appEnvironment === "DEV") {
      data.push({
        accountName: "Demo",
        api: "demo-aspsp",
        status: "healthy",
        update_time: "2020-10-30T15:24:26.786037524Z",
        friendly_name: "Demo",
        logo_uri: "",
        short_description: "",
      });
    }
    yield put(setBankList(data));
    yield put(setBankList([...data, ...config.noAISPBanks]));
  } catch ({ message }) {
    log(message);
  }
}

export default function* chooseBankSaga() {
  yield takeLatest(GET_BANK_LIST, getBankListSaga);
}
