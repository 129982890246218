export enum MobileHeaderPadding {
  default = 'default_padding',
  small = 'small_padding',
}

export enum MobileHeaderGradient {
  fromTopLeft = 'top_left_gradient',
  fromTopRight = 'top_right_gradient',
  none = 'none',
}

export enum MobileHeaderColor {
  yellow = 'yellow',
  green = 'green',
  red = 'red',
}

export enum MenuIconPosition {
  Left = 'left',
  Right= 'right'
}


export enum MobileHeaderLogoColor {
  Yellow = 'yellow',
  White = 'white',
}