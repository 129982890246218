import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../core/store/configureStore";

const initialState: PaymentState = {
    amount: '',
    defaultAmount: '',
    paylink: '',
}

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setAmount: (state, action: PayloadAction<string>) => {
            state.amount = action.payload;
        },
        setDefaultAmount: (state, action: PayloadAction<string>) => {
            state.defaultAmount = action.payload;
        },
        setPaylink: (state, action: PayloadAction<string>) => {
            state.paylink = action.payload;
        }
    }
})

export const {
    setAmount,
    setDefaultAmount,
    setPaylink
} = paymentSlice.actions;

export const selectAmount = (state: RootState) => state.payment.amount;
export const selectDefaultAmount = (state: RootState) => state.payment.defaultAmount;
export const selectPaylink = (state: RootState) => state.payment.paylink;

export default paymentSlice.reducer;

export interface PaymentState {
    amount: string;
    defaultAmount: string;
    paylink: string;
}

export interface PayeeSubscriberIds {
    payee: string,
    subscriber: string,
    giftAidPublicKey?: string
}
