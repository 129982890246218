import React from 'react';
import { formatAmountToString, isDesktopEnv } from '../../helpers';
import activityStyles from './TotalBlock.module.scss';
import activityMobileStyles from './TotalBlockMobile.module.scss';
import TotalAmount from '../../assets/images/total-amount.svg';

export interface TotalBlockProps {
    amount: number;
}

export const TotalBlock = ({ amount }: TotalBlockProps) => {
    const isDesktop = isDesktopEnv();
    const targetStyles = isDesktop ? activityStyles : activityMobileStyles;

    return (
        <div className={targetStyles.total__block}>
            <div className={targetStyles.total__wrapper}>
                <div>
                    <img src={TotalAmount} alt="Total amount" />
                </div>
                <div className={targetStyles.total__wrapper_text_block}>
                    <span className={targetStyles.total__wrapper_value}>
                        £{formatAmountToString(amount).slice(1)}
                    </span>
                    <span className={targetStyles.total__wrapper_title}>
                        Total Amount
                    </span>
                </div>
            </div>
        </div>
    );
};