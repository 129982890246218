import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAppDispatch } from '../../../app/hooks';
import { log } from '../../../static/Logger';
import { LoadingScreen } from '../../loadingScreen/ui/LoadingScreen';
import { handleBankConsent } from '../../retrieveAccountDetails/logic/retrieveAccountDetailsSaga';

interface LocationState {
  type: string;
  code: string;
  state: string;
  error: string;
}

export function SavingAccountDetails() {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { type } = location?.state || {};

    if (type === 'bankConsent') {
      const { code, state, error } = location.state;
      dispatch(handleBankConsent({ code, state, error, dispatch }));
      history.replace({ ...history.location, state: null });
    } else {
      history.replace('/');
    }
  }, []);

  return <LoadingScreen message="Saving account details" />;
}
