import React from "react";
import { CSVLink } from "react-csv";
import { isDesktopEnv } from "../../../../helpers";
import { ButtonColor, ButtonStyle } from "../../../../static/CommonDefinitions";
import { Button } from "../../../button/Button";
import styles from '../Header.module.scss';
import mobileStyles from '../HeaderMobile.module.scss';

export interface HeaderCTAButtonInfo {
  title: string,
  onClick: () => void,
  icon?: string;
}

export interface HeaderCTAButtonCSVInfo {
  CSVData: any;
  CSVHeaders: any;
  CSVFileName: string;
  onClick: () => void;
}

export interface HeaderCTAButtonProps {
  buttonInfo?: HeaderCTAButtonInfo;
  CSVButtonInfo?: HeaderCTAButtonCSVInfo;
}

export const HeaderCTAButton = ({ buttonInfo, CSVButtonInfo }: HeaderCTAButtonProps) => {
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  if (buttonInfo) {
    return (
      <Button
        title={buttonInfo.title}
        icon={buttonInfo.icon}
        handleClick={buttonInfo.onClick}
        style={buttonInfo.icon ? ButtonStyle.RoundedWithIco : ButtonStyle.LittleRounded}
        color={ButtonColor.Yellow}
        className={targetStyles.header__CTA_button}
      />
    )
  }

  if (CSVButtonInfo) {
    return (
      <CSVLink
        data={CSVButtonInfo.CSVData}
        headers={CSVButtonInfo.CSVHeaders}
        filename={CSVButtonInfo.CSVFileName}
        tabIndex={-1}
        onClick={CSVButtonInfo.onClick
      }>
        <Button
          title="Export to CSV"
          color={ButtonColor.Yellow}
          style={ButtonStyle.Rounded}
          className={targetStyles.header__CTA_button}
        />
      </CSVLink>
    )
  }

  return <></>
}

