import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { SubscriptionType } from '../../../static/CommonDefinitions';
import { selectSubscriptionType } from '../../subscriptionManagement/logic/subscriptionManagementSlice';
import { DefaultSettings, selectPaymentSettings, setPaymentSettings } from '../logic/paymentRequestSlice';
import { SettingsSwitchButton } from './additionalComponents/settingsSwitchButton/SettingsSwitchButton';
import { AmountSettings } from './settingsBlocks/AmountSettings/AmountSettings';
import { ExpirySettings } from './settingsBlocks/ExpirySettings/ExpirySettings';
import { ReferenceSettings } from './settingsBlocks/ReferenceSettings/ReferenceSettings';

export interface RenderSettingsByOptionTabProps {
  tab: number;
}

export function RenderSettingsByOptionTab({ tab }: RenderSettingsByOptionTabProps) {
  const dispatch = useAppDispatch();
  const subscriptionType = useAppSelector(selectSubscriptionType);
  const paymentSettings = useAppSelector(selectPaymentSettings);

  const switchButtonTitles = useMemo(() => [
      {
          title: 'Request email from payer',
          subtitle: 'Payer will be asked to supply their email.',
          contentType: 'RequestEmail',
          active: paymentSettings.requestEmail.enabled,
          visible: true,
      },
      {
          title: 'Enable notes',
          subtitle: 'Notes can be added for additional communication about the payment.',
          contentType: 'Notes',
          active: paymentSettings.notes.enabled,
          visible: true,
      },
      {
          title: 'Show Thank you note',
          subtitle: 'You can add a message that will be displayed to Payer after they complete payment.',
          contentType: 'ThankYouNote',
          active: paymentSettings.thankYouNote.enabled,
          visible: true,
      },
      {
          title: 'Request details for Gift Aid',
          subtitle: 'Turn on this setting to request payers’ details required for participation in Gift Aid.',
          contentType: 'GiftAid',
          active: paymentSettings.giftAid.enabled,
          visible: subscriptionType === SubscriptionType.Charity,
      }
  ], [paymentSettings]);

  const handleSettingsChange = (changed: DefaultSettings) => {
      dispatch(setPaymentSettings({...paymentSettings, ...changed}))
  }

  if (tab === 0) {
      return (
          <>
              <AmountSettings
                  settings={paymentSettings}
                  onSettingChange={handleSettingsChange}
              />
              <ReferenceSettings
                  settings={paymentSettings}
                  onSettingChange={handleSettingsChange}
              />
          </>
      )
  }

  if (tab === 1) {
      return (
          <ExpirySettings
              settings={paymentSettings}
              onSettingChange={handleSettingsChange}
          />
      );
  }

  if (tab === 2) {
      return (
          <>
              {switchButtonTitles.map(item => {
                  return item.visible && (
                      <SettingsSwitchButton
                          key={item.title}
                          title={item.title}
                          subtitle={item.subtitle}
                          contentType={item.contentType}
                          active={item.active}
                          settings={paymentSettings}
                          onSettingChange={handleSettingsChange}
                      />
                  )
              })}
          </>
      );
  }

  return null;
}
