import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import styles from './KeyManagement.module.scss';
import mobileStyles from './KeyManagementMobile.module.scss';
import { ApiKeyForm } from './apiKeyForm/ApiKeyForm';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CopyIco from '../../../assets/images/copy.svg';
import BackIcon from '../../../assets/images/back.svg';
import PlusIcon from "../../../assets/images/plus.svg";
import GearIcon from '../../../assets/images/gear.svg';
import { Toast } from '../../../components/toast/Toast';
import { copyToClipboard } from '../../../utils/clipboard';
import { Button } from '../../../components/button/Button';
import CloseIcon from '../../../assets/images/close-icon-16px.svg';
import {
  deleteApiKey,
  getExistingApiKeysAction,
  showToast,
} from '../logic/keyManagementSaga';
import { ButtonColor, ButtonStyle } from '../../../static/CommonDefinitions';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import {
  changeIsToastVisible,
  selectApiKeys,
  selectApiKeysLength,
  selectIsToastVisible,
  selectToastInfo,
} from '../logic/keyManagementSlice';
import { StylizedWrapperWithRadius } from '../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import { isDesktopEnv } from '../../../helpers';
import { Header } from '../../../components/header/ui/Header';
import { ToastIcon } from '../../../components/toast/static/toastCommonDefinitions';

export function KeyManagement() {
  const dispatch = useDispatch();

  const isToastVisible = useAppSelector(selectIsToastVisible);
  const toastInfo = useAppSelector(selectToastInfo);
  const [showApiKeyForm, setShowApiKeyForm] = useState(false);
  const [isClipboardMessageVisible, setIsClipboardMessageVisible] = useState(false);

  const handleGenerateButtonClick = () => {
    setShowApiKeyForm(true);
  };

  useEffect(() => {
    dispatch(getExistingApiKeysAction());
    dispatch(changeIsToastVisible(false));
  }, []);

  const handleApiKeyFormClose = () => setShowApiKeyForm(false);

  const handleCopyButtonClickMobile = () => {
    setIsClipboardMessageVisible(true);
    dispatch(showToast({title: 'Your API Key has been copied.', icon: ToastIcon.Success}));
    setTimeout(() => setIsClipboardMessageVisible(false), 3000);
  }

  const closeToast = () => dispatch(changeIsToastVisible(false));

  if (isDesktopEnv()) {
    return (
      <>
        <BackgroundContainer isMenu={true}>
          <div className={styles.keyManagement}>
            <Header
              title='Key Management'
              CTAButton={{
                buttonInfo: {
                  title: 'Generate API Key',
                  onClick: handleGenerateButtonClick,
                }
              }}
            />
            <div className={styles.keyManagement__main}>
              <StylizedWrapperWithRadius>
                <KeyManagementTable />
              </StylizedWrapperWithRadius>
            </div>

            {isToastVisible && <Toast {...toastInfo} onClose={closeToast} />}
          </div>
        </BackgroundContainer>
        {showApiKeyForm && (
          <ApiKeyFormContainer onClose={handleApiKeyFormClose}>
            <ApiKeyForm handleClose={handleApiKeyFormClose} />
          </ApiKeyFormContainer>
        )}
      </>
    );
  }

  return (
    <div className={mobileStyles.keyManagement}>
      <Header
        title='Key Management'
        CTAButton={{
          buttonInfo: {
            title: 'Generate API Key',
            icon: GearIcon,
            onClick: () => setShowApiKeyForm(true),
          }
        }}
      />
      <main className={mobileStyles.keyManagement__main}>
        <StylizedWrapperWithRadius>
          <KeyManagementTable onCopyButtonClick={handleCopyButtonClickMobile} />
        </StylizedWrapperWithRadius>
        {isClipboardMessageVisible && (
          <StylizedWrapperWithRadius>
          <div className={mobileStyles.keyManagement__clipboardMessage}>Key has been copied to clipboard</div>
         </StylizedWrapperWithRadius>
        )}
      </main>
				{isToastVisible && <Toast {...toastInfo} onClose={closeToast} />}
      {showApiKeyForm && (
        <ApiKeyFormContainer onClose={handleApiKeyFormClose}>
          <ApiKeyForm handleClose={handleApiKeyFormClose} submitButtonIcon={GearIcon} />
        </ApiKeyFormContainer>
      )}
    </div>
  )
}

export interface KeyManagementTableProps {
  onCopyButtonClick?: () => void,
}

export function KeyManagementTable({ onCopyButtonClick }: KeyManagementTableProps) {
  const dispatch = useAppDispatch();
  const apiKeys = useAppSelector(selectApiKeys);
  const apiKeysAmount = useAppSelector(selectApiKeysLength);
  const [apiKeyForIframe, setApiKeyForIframe] = useState<string>('');
  const [isCopyButtonActive, setIsCopyButtonActive] = useState(true);

  const handleCopyButtonClick = () => {
    if (!isCopyButtonActive) {
      return;
    }

    setIsCopyButtonActive(false);
    setTimeout(() => setIsCopyButtonActive(true), 3000);
    copyToClipboard(generateIframCode(apiKeyForIframe));
  };

  const generateIframCode = (apiKey: string) => {
    return `const boppButton = BoppButton({key: "${apiKey}", container: document.getElementById('bopp')});`;
  };

  const updateApiKeyForIframe = (newApiKey: string) => {
    if (newApiKey === apiKeyForIframe) {
      setApiKeyForIframe('');
    } else {
      setApiKeyForIframe(newApiKey);
    }
  };

  const handleMobileCopyButtonClick = (key: string) => {
    onCopyButtonClick && onCopyButtonClick();
    copyToClipboard(key);
  }

  if (isDesktopEnv()) {
    return (
      <div className={styles.tWrapper}>
        <h3 className={styles.tWrapper__name}>API Keys</h3>
        <table className={styles.tWrapper__table}>
          <thead>
            <tr className={styles.tWrapper__row}>
              <th className={styles.tWrapper__title}>Key name</th>
              <th className={styles.tWrapper__title}>Keys</th>
              <th className={styles.tWrapper__title}>Website URL</th>
              <th className={styles.tWrapper__title}>Issued</th>
              <th className={styles.tWrapper__title}></th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.map(keyDetails => (
              //Don't display deactivated keys
              !keyDetails.deactivated &&
              <TableRow
                key={keyDetails.apiKey}
                {...keyDetails}
                handleJsButtonClick={() =>
                  updateApiKeyForIframe(keyDetails.apiKey)
                }
                jsButtonIsInactive={
                  !!apiKeyForIframe && apiKeyForIframe !== keyDetails.apiKey
                }
                handleDeleteButtonClick={() => {
                  dispatch(deleteApiKey(keyDetails.apiKey));

                  if (apiKeyForIframe === keyDetails.apiKey) {
                    setApiKeyForIframe('');
                  }
                }}
              />
            ))}
          </tbody>
        </table>
        {apiKeyForIframe && (
          <>
            <div className={styles.tWrapper__tableFooter}>
              <span className={styles.tWrapper__codeText}>
                {generateIframCode(apiKeyForIframe)}
              </span>
              <Button
                title={isCopyButtonActive ? 'Copy' : 'Copied'}
                onClick={handleCopyButtonClick}
                color={ButtonColor.Transparent}
                className={classNames([styles['tWrapper__button--copy']])}
              />
            </div>
          </>
        )}

        <div className={styles.tWrapper__keysAmount}>{`${apiKeysAmount} API Key${
          apiKeysAmount > 1 || apiKeysAmount === 0 ? 's' : ''
        }`}</div>
      </div>
    );
  }

  return <div className={mobileStyles.tWrapper}>
    <table className={mobileStyles.tWrapper__table}>
      <thead>
        <tr className={mobileStyles.tWrapper__row}>
          <th className={mobileStyles.tWrapper__title}>Key name</th>
          <th className={mobileStyles.tWrapper__title}>Keys</th>
          <th className={mobileStyles.tWrapper__title}>Issued</th>
          <th className={mobileStyles.tWrapper__title}></th>
        </tr>
      </thead>
      <tbody>
        {apiKeys.map(keyDetails => (
          //Don't display deactivated keys
          !keyDetails.deactivated &&
          <TableRow
            key={keyDetails.apiKey}
            handleMobileCopyButtonClick={() => handleMobileCopyButtonClick(keyDetails.apiKey)}
            { ...keyDetails }
          />
        ))}
      </tbody>
    </table>
    <div className={mobileStyles.tWrapper__keysAmount}>{`${apiKeysAmount} API Key${apiKeysAmount > 1 ? "s" : ""}`}</div>
  </div>
}

export interface TableRowProps {
  name: string;
  apiKey: string;
  websiteURL: string;
  timeStamp: string;
  handleJsButtonClick?: () => void;
  handleDeleteButtonClick?: () => void;
  handleMobileCopyButtonClick?: () => void;
  jsButtonIsInactive?: boolean;
}

export function TableRow({
  name,
  apiKey,
  websiteURL,
  timeStamp,
  handleJsButtonClick,
  handleDeleteButtonClick,
  handleMobileCopyButtonClick,
  jsButtonIsInactive,
}: TableRowProps) {
  const dispatch = useDispatch();
  const formattedTimeStamp = moment(timeStamp).format('L');

  if (isDesktopEnv()) {

    const handleCopyButtonClick = () => {
      copyToClipboard(apiKey);
      dispatch(showToast({title: 'Your API Key has been copied.', icon: ToastIcon.Success}));
    }
    
    return (
      <tr className={styles.tableRow}>
        <td className={styles.tableRow__value}>
          <div className={styles['tableRow__value-wrap']}>
            <span>{name}</span>
          </div>
        </td>
        <td className={styles.tableRow__value}>
          <div className={styles['tableRow__value-wrap']}>
            <span>{apiKey}</span>
            <Button
              title=""
              icon={CopyIco}
              onClick={handleCopyButtonClick}
              color={ButtonColor.Transparent}
              className={classNames([
                styles['tWrapper__button'],
                styles['tWrapper__button--small'],
              ])}
            />
          </div>
        </td>
        <td className={styles.tableRow__value}>{websiteURL}</td>
        <td className={styles.tableRow__value}>{formattedTimeStamp}</td>
        <td className={styles.tableRow__value}>
          <Button
            title="JS Code"
            color={
              jsButtonIsInactive ? ButtonColor.YellowInactive : ButtonColor.Yellow
            }
            onClick={handleJsButtonClick}
            className={classNames([
              styles['tWrapper__button'],
              { [styles['tWrapper__button--disabled']]: jsButtonIsInactive },
            ])}
          />
          <Button
            title=""
            icon={CloseIcon}
            color={ButtonColor.Yellow}
            onClick={handleDeleteButtonClick}
            className={classNames([
              styles['tWrapper__button'],
              styles['tWrapper__button--small'],
              styles['tWrapper__button--close'],
            ])}
          />
        </td>
      </tr>
    );
  }

  return (
		<tr className={mobileStyles.tableRow}>
			<td className={mobileStyles.tableRow__value}>
				<div className={mobileStyles['tableRow__value-wrap']}>
					<span>{name}</span>
				</div>
			</td>
			<td className={mobileStyles.tableRow__value}>
				<div className={mobileStyles['tableRow__value-wrap']}>
					<span>{apiKey}</span>
          <img
            src={CopyIco}
            className={mobileStyles["tWrapper__copy-icon"]}
            onClick={handleMobileCopyButtonClick}
          />
				</div>
			</td>
			<td className={mobileStyles.tableRow__value}>{formattedTimeStamp}</td>
			<td className={mobileStyles.tableRow__value}>
				<Button
					title=""
					icon={CloseIcon}
					color={ButtonColor.Yellow}
					onClick={() => dispatch(deleteApiKey(apiKey))}
					className={classNames([
						mobileStyles["tWrapper__button--close"],
					])}
				/>
			</td>
		</tr>
	)
}

export interface ApiKeyFormContainerProps {
  children: JSX.Element;
  onClose: () => void;
}

export function ApiKeyFormContainer({
  children,
  onClose,
}: ApiKeyFormContainerProps) {
  if (isDesktopEnv()) {
    return (
      <>
        <div
          className={styles['api-key-form-container-bg']}
          onClick={onClose}></div>
        <div className={styles['api-key-form-container']}>
          <StylizedWrapperWithRadius shadow>
            <div className={styles['api-key-form-container__inner']}>
              {children}
            </div>
          </StylizedWrapperWithRadius>
        </div>
      </>
    );
  }

  return (
    <div className={mobileStyles.apiKeyFormContainer}>
      <div className={mobileStyles.apiKeyFormContainer__top}>
        <div className={mobileStyles.apiKeyFormContainer__back} onClick={onClose}>
          <img src={BackIcon} alt="back" className={mobileStyles.apiKeyFormContainer__backIcon} />
          <span className={mobileStyles.apiKeyFormContainer__backText}>Back</span>
        </div>
        <img src={PlusIcon} alt="plus" className={mobileStyles.apiKeyFormContainer__plusIcon} />
        <h4 className={mobileStyles.apiKeyFormContainer__title}>Key Management</h4>
      </div>
      <div className={mobileStyles.apiKeyFormContainer__main}>{ children }</div>
    </div>
  )
}
