import { useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { Button } from "../../../../components/button/Button";
import { FormInput } from "../../../../components/formInput/FormInput";
import { isDesktopEnv } from "../../../../helpers";
import { ButtonColor, ButtonStyle } from "../../../../static/CommonDefinitions";
import { createApiKeyAction } from "../../logic/keyManagementSaga";
import { ASCIIExpression, validationMessages } from "../../static/keyManagementConstants";
import styles from "./ApiKeyForm.module.scss";
import ApiKeyCheckBox from "./../../../../components/ApiKeyCheckBox";

export function removeProtocol(url:string): string {
	let result = url
	if(url.startsWith("www.")){
		const www = "www."
		result = url.slice(www.length)
	} else if(url.startsWith("https://www.")){
		const https = "https://www."
		result = url.slice(https.length)
	} else if(url.startsWith("http://www.")){
		const http = "http://www."
		result = url.slice(http.length)
	} else if(url.startsWith("https://")){
		const https = "https://"
		result = url.slice(https.length)
	} else if(url.startsWith('http://')){
		const http = "http://"
		result = url.slice(http.length)
	}

	let index = result.indexOf('/')
	if(index !== -1) {
		result = result.slice(0, index)
	}

	return result
}
export interface ApiKeyFormProps {
	handleClose: () => void,
	submitButtonIcon?: string,
};

export function ApiKeyForm({ handleClose, submitButtonIcon = "" }: ApiKeyFormProps) {
	const dispatch = useAppDispatch();

	const [keyName, setKeyName] = useState("");
	const [url, setUrl] = useState("");
	const [description, setDescription] = useState("");
	const [keyNameError, setKeyNameError] = useState("");
	const [isFieldsValid, setIsFieldsValid] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	const validateName = (name: string) => {
		if (!name) {
			setKeyNameError(validationMessages.keyNameIsRequired)
			setIsFieldsValid(false);
		}
		else if (!name.match(ASCIIExpression)) {
			setKeyNameError(validationMessages.invalidKeyName);
			setIsFieldsValid(false);
		}
		else {
			setKeyNameError("");
			setIsFieldsValid(true);
		}
	}

	const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setKeyName(e.target.value);
		validateName(e.target.value);
	}
	const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUrl(e.target.value);
	}

	const handleUrlBlur = () => {
		setIsFieldsValid(true)
		setUrl(removeProtocol(url));
	}
	
	const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);

	const generateKey = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		
		dispatch(createApiKeyAction({
		  name: keyName,
		  websiteURL: url,
		  description,
		}));
		handleClose();
	}

	return (
    <div className={styles[isDesktopEnv() ? "api-key-form" : "api-key-form-mobile"]}>
      <form >
        <FormInput
          name="apiKeyName"
          onChange={handleKeyChange}
          value={keyName}
          required={true}
          error={keyNameError}
          label="Key Name"
          info="Up to 28 characters"
          maxLength={28}
        />
        <FormInput
          name="apiWebsiteUrl"
          onChange={handleUrlChange}
					onBlur={handleUrlBlur}
          value={url}
          required={true}
          label="Website URL or application ID*"
          info="e.g.: yourdomain.com or  com.yourapp.id"
        />
        <FormInput
          name="apiKeyDescription"
          onChange={handleDescriptionChange}
          value={description}
          label="Description"
          info="Short description up to 80 characters"
          maxLength={80}
        />
		<ApiKeyCheckBox
			checked={isChecked}
			onChange={()=>{setIsChecked(!isChecked)}}
		/>
		{submitButtonIcon ?
			( <Button
				title="Generate API Key"
				buttonHeight="50px"
				style={ButtonStyle.RoundedWithIco}
				icon={submitButtonIcon}
				color={ButtonColor.DarkYellow}
				disabled={!(isFieldsValid && isChecked)}
				handleClick={generateKey}
			/> )
			: (
			<Button
				title="Generate API Key"
				buttonHeight="50px"
				style={ButtonStyle.Rounded}
				color={ButtonColor.DarkYellow}
				disabled={!(isFieldsValid && isChecked)}
				handleClick={generateKey}
			/>
			)
		}
      </form>
    </div>
	)
}
