import React from 'react';

import styles from './BankAccount.module.scss';
import mobileStyles from "./BankAccountMobile.module.scss";
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import { StylizedWrapperWithRadius } from '../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import { useAppSelector } from '../../../app/hooks';
import { selectBankDetails } from '../logic/bankAccountSlice';
import { insertSpaces, isDesktopEnv, sortCodeRemoveDash } from '../../../helpers';
import { Header } from '../../../components/header/ui/Header';

export function BankAccount() {
  const bank = useAppSelector(selectBankDetails);

  if (isDesktopEnv()) {
    return (
      <BackgroundContainer isMenu={true}>
        <div className={styles.bankAccount}>
          <Header title='Bank Account' />
          <StylizedWrapperWithRadius style="square" className={styles.bankAccount__body_wrapper}>
            <div className={styles.bankAccount__body}>
              <div className={styles.bankAccount__image}>
                <img src={bank.logo} alt="Bank logo" />
              </div>

              <div className={styles.bankAccount__details}>
                <RowWithBorder valueName="Bank" value={bank.bankName} />
                <RowWithBorder
                  valueName="Account Number"
                  value={insertSpaces(bank.accountNumber, 4)}
                />
                <RowWithBorder
                  valueName="Sort Code"
                  value={insertSpaces(sortCodeRemoveDash(bank.sortCode), 2)}
                />
              </div>
            </div>
          </StylizedWrapperWithRadius>
        </div>
      </BackgroundContainer>
    );
  }

  return (
    <div className={mobileStyles.bankAccount}>
      <Header title='Bank Account' />
      <main className={mobileStyles.bankAccount__main}>
        <StylizedWrapperWithRadius>
          <div className={mobileStyles.bankAccount__body}>
            <div className={mobileStyles.bankAccount__image}>
              <img src={bank.logo} alt="Bank logo" />
            </div>
            <div className={mobileStyles.bankAccount__details}>
              <RowWithBorder valueName="Bank" value={bank.bankName} />
              <RowWithBorder valueName="Account Number" value={insertSpaces(bank.accountNumber, 4)} />
              <RowWithBorder valueName="Sort Code" value={insertSpaces(sortCodeRemoveDash(bank.sortCode), 2)} />
            </div>
          </div>
        </StylizedWrapperWithRadius>
      </main>
    </div>
  )
}


export interface RowWithBorderProps {
  valueName: string;
  value: string;
}
export const RowWithBorder = ({ valueName, value }: RowWithBorderProps) => (
  <div className={styles.rowWithBorder}>
    <span className={styles.rowWithBorder__name}>{valueName}</span>
    <span className={styles.rowWithBorder__value}>{value}</span>
  </div>
);
