enum SortingType {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
  INACTIVE = 'inactive',
}

enum TableColumn {
  Amount = 'amount',
  DateTime = 'datetime',
  Reference = 'reference',
  Url = 'url',
  Fee = 'fee',
  Id = 'id',
  PayersEmail = 'payerEmail',
}

enum GiftAidTableColumn {
  Datetime= 'datetime',
  DonorName = 'donorName',
  PayerEmail = 'payersEmail',
  Address = 'address',
  Postcode = 'postcode',
  Reference = 'reference',
  Amount = 'amount',
  GiftAid = 'giftAid',
}

export { SortingType, TableColumn, GiftAidTableColumn };
