import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { ToastIcon } from '../../../../../components/toast/static/toastCommonDefinitions';
import { Toast } from '../../../../../components/toast/Toast';
import { isDesktopEnv, isEmptyString } from '../../../../../helpers';
import { validateNumberOfPayments } from '../../../../../helpers/dataFieldsValidation';
import { updateExpiryDateInputError } from '../../../logic/paymentRequestSaga';
import { selectInputsErrors, selectPaymentSettings, setInputsErrors } from '../../../logic/paymentRequestSlice';
import { PaymentTrigger, SettingsInputType } from '../../../static/paymentRequestConstants';
import { SettingsCheckboxButton } from '../../additionalComponents/settingsCheckboxButton/SettingsCheckboxButton';
import { SettingsRadioButton } from '../../additionalComponents/settingsRadioButton/SettingsRadioButton';
import { SettingsProps } from '../../PaymentRequest';

import styles from '../../PaymentRequest.module.scss';
import mobileStyles from '../../PaymentRequestMobile.module.scss';

export function ExpirySettings({settings, onSettingChange}: SettingsProps) {
  const dispatch = useAppDispatch();
  const paymentSettings = useAppSelector(selectPaymentSettings);
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;
  const inputsErrors = useAppSelector(selectInputsErrors);
  const [hideToast, setHideToast] = useState(false);

  const expiryTypes = useMemo(() => [
      {
          title: 'Single Use',
          subtitle: 'Your request is active until someone pays it. Once paid the Paylink (or QR code) cannot be used again.',
          paymentTriggers: PaymentTrigger.OnInstruction
      },
      {
          title: 'Multi Use',
          subtitle: 'Your pay request stays open unless cancelled or reaches its maximum pay limit. Multiple people can pay using the same Paylink or QR code.',
          paymentTriggers: PaymentTrigger.EachInstruction
      },
      {
          title: 'After X payments',
          subtitle: 'Payment request stays open until you receive X payments against this request or max pay limit is reached.',
          paymentTriggers: PaymentTrigger.XInstructions,
          placeholder: 'Set number or payments...',
          inputRequirement: 'Max 100 000, only numbers allowed.'
      }
  ], []);

  const handleXInstructionsValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if(validateNumberOfPayments(value)) {
          onSettingChange({
              ...paymentSettings,
              expire: {
                  ...paymentSettings.expire,
                  xInstructionsValue: value
              }
          })

          dispatch(setInputsErrors({
            ...inputsErrors,
            xInstructions: false,
          }));
      }
  }

  const handleXInstructionsInputBlur = () => {
      dispatch(setInputsErrors({
          ...inputsErrors,
          xInstructions: isEmptyString(paymentSettings.expire.xInstructionsValue)
      }));
  }

  const handleCertainDateChange = (value: string) => {
    onSettingChange({
        ...paymentSettings,
        expire: {
            ...paymentSettings.expire,
            certainDate: value,
      }})

      dispatch(updateExpiryDateInputError());
  }

  useEffect(() => {
    setHideToast(false);

    const scrollToBottom = inputsErrors.expiryDate && paymentSettings.expire.onCertainDate && !isDesktopEnv();

    if(scrollToBottom) {
        document.getElementById('expiry-settings-tab')?.scrollIntoView();
    }
  }, [inputsErrors.expiryDate, paymentSettings.expire.onCertainDate]);

const renderExpiryDateError = () => {
	if (inputsErrors.expiryDate && paymentSettings.expire.onCertainDate && !hideToast) {
		return (
			<Toast
				className={targetStyles.payment_request__toast}
				icon={ToastIcon.Warning}
				onClose={() => setHideToast(true)}
				title='Please set new expiry date in request expiry settings - you must select today’s or future date.'
			/>
		)
	}

	return <></>
}

  return(
      <div id='expiry-settings-tab' className={targetStyles.amount_settings}>
          <h5 className={targetStyles.amount_settings_header}>Expiry Settings</h5>
          <div className={targetStyles.amount_settings_container}>
              {expiryTypes.map((item) => {
                  const checked = settings.expire.type === item.paymentTriggers;
                  return(
                      <SettingsRadioButton
                          key={item.paymentTriggers}
                          title={item.title}
                          value={item.paymentTriggers}
                          checked={checked}
                          handleRadioChange={(e) => {
                              onSettingChange({
                                  ...paymentSettings,
                                  expire: {
                                      ...paymentSettings.expire,
                                      type: e.target.value
                                  }
                              })
                          }}
                          handleInputValueChange={handleXInstructionsValueChange}
                          handleInputBlur={handleXInstructionsInputBlur}
                          inputError={inputsErrors.xInstructions}
                          inputValue={paymentSettings.expire.xInstructionsValue}
                          style={targetStyles.amount_settings_expire_input}
                          subtitle={item.subtitle}
                          settingType={SettingsInputType.TextInput}
                          showInput={item.paymentTriggers === PaymentTrigger.XInstructions}
                          inputRequirement={item.inputRequirement}
                          placeholder={item.placeholder}
                          general={false}
                      />
                  )
              })}
              <div className={targetStyles.amount_settings_certain_date}>
                  <SettingsCheckboxButton
                      checked={settings.expire.onCertainDate}
                      handleCheckboxChange={(e) => {
                          onSettingChange({
                              ...paymentSettings,
                              expire: {
                                  ...paymentSettings.expire,
                                  onCertainDate: !settings.expire.onCertainDate
                              }})
                      }}
                      handleInputValueChange={(e) => {
                        handleCertainDateChange(e.target.value);
                      }}
                      handleDesktopDateInputChange={(date: Date) => {
                        handleCertainDateChange(moment(date).format("yyyy-MM-DD"),)
                      }}
                      inputValue={paymentSettings.expire.certainDate || ''}
                      title={"On a certain date"}
                      subtitle={"Request will expire on a certain date even if it hasn’t been paid yet."}
                      style={targetStyles.amount_settings_date_input}
                      settingType={SettingsInputType.ExpiryDate}
                  />
                  { renderExpiryDateError() }
              </div>
          </div>
      </div>
  )
}
