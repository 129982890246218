import React, { useCallback, useEffect, useRef } from 'react';

export function useOutsideClick(ref, action) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function useMultipleRefsOutsideClick(refs, action) {
  const handleClickOutside = useCallback((event) => {
    const shouldExecuteAction = !refs.some(ref => ref.current?.contains(event.target));

    if (shouldExecuteAction) {
      action();
    }
  }, [refs, action])
  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => document.removeEventListener("mouseup", handleClickOutside);
  }, [handleClickOutside]);
}
