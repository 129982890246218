import React, { useEffect, useState } from 'react';
import { useAppSelector } from "../../../app/hooks";
import { LoadingSpinner } from "../../../components/loadingSpinner/LoadingSpinner";
import { BackgroundContainer } from "../../../components/background/BackgroundContainer";
import { selectSelectedBank } from "../../retrieveAccountDetails/logic/retrieveAccountDetailsSlice";
import styles from './LoadingScreen.module.scss';

export interface ILoadingScreen {
    message: string,
    button?: boolean,
    clickCancelButton?: () => void
}

export function LoadingScreen ({message, button = false, clickCancelButton}: ILoadingScreen) {
    const selectedBank = useAppSelector(selectSelectedBank);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (message === `Securely transferring to ${selectedBank?.friendly_name}`) {
            setTimeout(() => setError(true), 30000)
        }
    }, []);

    const handleTryAgain = () => {
        window.location.replace('/bank-account');
    }

    return(
        <BackgroundContainer>
            <div className={styles.container}>
                <LoadingSpinner/>
                <p className={styles.container__text}>{message}</p>
                {button ? <p onClick={clickCancelButton} className={styles.container__button}>Cancel</p> : null}
                {error &&
                <p className={styles.container__text__error}>Slow internet connection or could not connect to server, please <span onClick={handleTryAgain}>try again</span>.</p>}
            </div>
        </BackgroundContainer>
    )
}
