import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BankDetails, GiftAidKeys } from '../static/bankAccountTypes';
import { RootState } from '../../../core/store/configureStore';
import { defaultBankDetails, defaultGiftAidConstants } from '../static/bankAccountConstants';

const initialState: BankAccountState = {
  bankDetails: defaultBankDetails,
  personaDID:'',
  giftAidKeys:defaultGiftAidConstants,
};

export const bankAccountSlice = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {
    setBankDetails: (state, action: PayloadAction<BankDetails>) => {
      state.bankDetails = action.payload;
    },
    setPersonaDID: (state, action: PayloadAction<string>) => {
      state.personaDID = action.payload;
    },
    clearBankAccountState: (state) => {
      state.bankDetails = defaultBankDetails;
      state.personaDID = "";
    },
    setGiftAidKeys: (state, action: PayloadAction<GiftAidKeys>) => {
      state.giftAidKeys = action.payload;
    },
  },
});

export const {
  setBankDetails,
  setPersonaDID,
  setGiftAidKeys,
  clearBankAccountState,
} = bankAccountSlice.actions;

export const selectBankDetails = (state: RootState) => state.bankAccount.bankDetails;
export const selectPersonaDID = (state: RootState) => state.bankAccount.personaDID;
export const selectGiftAidKeys = (state: RootState) => state.bankAccount.giftAidKeys;
export default bankAccountSlice.reducer;

export interface BankAccountState {
  bankDetails: BankDetails,
  personaDID:string,
  giftAidKeys:GiftAidKeys,
}
