import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { isDesktopEnv, isEmptyString } from '../../../../../helpers';
import { validateAmount } from '../../../../../helpers/dataFieldsValidation';
import { selectInputsErrors, selectPaymentSettings, setInputsErrors, setPaymentSettings } from '../../../logic/paymentRequestSlice';
import { setAmount } from '../../../logic/paymentSlice';
import { SettingsInputType, TermType } from '../../../static/paymentRequestConstants';
import { SettingsRadioButton } from '../../additionalComponents/settingsRadioButton/SettingsRadioButton';
import { SettingsProps } from '../../PaymentRequest';

import styles from '../../PaymentRequest.module.scss';
import mobileStyles from '../../PaymentRequestMobile.module.scss';

interface AmountInputInfo {
    value: string;
    error: boolean;
    valueKey: string;
    errorKey: string;
}

export function AmountSettings({ settings, onSettingChange }: SettingsProps) {
  const dispatch = useAppDispatch();
  const paymentSettings = useAppSelector(selectPaymentSettings);
  const inputsErrors = useAppSelector(selectInputsErrors);

  const amountTypes = useMemo(() => [
      {title: 'Fixed', termType: TermType.FixedAmount},
      {title: 'Set by Payer', termType: TermType.AnyAmount},
      {title: 'Suggested', termType: TermType.SuggestedAmount},
      {title: 'Min Amount', termType: TermType.MinAmount}
  ], []);

  const getAmountInputInfo = (type: string): AmountInputInfo => {
    switch (type) {
        case TermType.FixedAmount:
            return {
                value: paymentSettings.amount.fixedInputValue,
                error: inputsErrors.fixedAmount,
                valueKey: 'fixedInputValue',
                errorKey: 'fixedAmount',
            };
        case TermType.SuggestedAmount:
            return {
                value: paymentSettings.amount.suggestedInputValue,
                error: inputsErrors.suggestedAmount,
                valueKey: 'suggestedInputValue',
                errorKey: 'suggestedAmount',
            };
        case TermType.MinAmount:
            return {
                value: paymentSettings.amount.minInputValue,
                error: inputsErrors.minAmount,
                valueKey: 'minInputValue',
                errorKey: 'minAmount',
            };
        default:
            return {
                value: '',
                error: false,
                valueKey: '',
                errorKey: '',
            };
    }
  }

  const onAmountInputValueChange = (value: string) => {
      const currentInput = getAmountInputInfo(paymentSettings.amount.type);

      if (validateAmount(value)) {
          dispatch(setPaymentSettings({...paymentSettings, amount: {...paymentSettings.amount, [currentInput.valueKey]: value}}));
          dispatch(setAmount(value));
          dispatch(setInputsErrors({...inputsErrors, [currentInput.errorKey]: false}));
      }
  }

  const onAmountInputBlur = () => {
    const currentInput = getAmountInputInfo(paymentSettings.amount.type);
    const formattedValue = new Number(currentInput.value).toFixed(2);

    dispatch(setAmount(formattedValue));
    dispatch(setPaymentSettings({...paymentSettings, amount: {...paymentSettings.amount, [currentInput.valueKey]: formattedValue}}));

    dispatch(setInputsErrors({...inputsErrors, [currentInput.errorKey]: isEmptyString(currentInput.value) || +currentInput.value < 0.01}));
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSettingChange({...paymentSettings, amount: {...paymentSettings.amount, type: e.target.value}});

    if (e.target.value !== TermType.AnyAmount) {
      const currentInput = getAmountInputInfo(e.target.value);
      dispatch(setAmount(currentInput.value))
    }
  }

  const targetStyles = isDesktopEnv() ? styles : mobileStyles;
  const currentInput = getAmountInputInfo(paymentSettings.amount.type);

  return(
      <div className={targetStyles.amount_settings}>
          <h5 className={targetStyles.amount_settings_header}>Amount</h5>
          <div className={targetStyles.amount_settings_container}>
              {amountTypes.map((item) => {
                  const checked = settings.amount.type === item.termType;
                  return(
                      <SettingsRadioButton
                          key={item.termType}
                          title={item.title}
                          value={item.termType}
                          checked={checked}
                          handleRadioChange={handleRadioChange}
                          handleInputValueChange={(e) => {
                              onAmountInputValueChange(e.target.value);
                          }}
                          handleInputBlur={onAmountInputBlur}
                          inputValue={currentInput.value}
                          inputError={currentInput.error}
                          showInput={item.termType !== TermType.AnyAmount}
                          style={targetStyles.amount_settings_input}
                          placeholder={'0.00'}
                          settingType={SettingsInputType.Amount}
                          general={true}
                      />
                  )
              })}
          </div>
      </div>
  )
}
