import classNames from 'classnames';
import React from 'react';
import { Modal } from '../../../components/modal/Modal';
import { Button } from '../../../components/button/Button';
import { ButtonColor, ButtonStyle } from '../../../static/CommonDefinitions';
import styles from './TipModal.module.scss';
import { isDesktopEnv } from '../../../helpers';

export interface ITipModal {
  handleClick: () => void;
}

export function TipModal({ handleClick }: ITipModal) {
  const contentStyles = classNames([
    styles.content,
    { [styles['content--mobile']]: !isDesktopEnv() },
  ]);

  return (
    <Modal>
      <div className={styles.background} />
      <div className={contentStyles}>
        <h4 className={styles.content__title}>Why Do You Need This?</h4>
        <p className={styles.content__text}>
          To ensure that each payment request sent can be trusted as it contains account details provided directly by the bank.
        </p>
        <Button
          fontSize="18px"
          handleClick={handleClick}
          title="got it"
          style={ButtonStyle.Square}
          buttonHeight="44px"
          color={ButtonColor.DarkYellow}
        />
      </div>
    </Modal>
  );
}
