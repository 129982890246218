import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../core/store/configureStore';
import {
  StatusRequest,
  PaymentRequest,
  RequestFilters,
  RequestsTableSortState
} from '../static/requestActivityTypes';
import { getDateRangeByPeriodTime, isDesktopEnv } from '../../../helpers';
import config from '../../../config';
import { PaginationData } from '../../../components/pagination/static/paginationTypes';
import { defaultTableSortState } from '../static/requestActivityConstants';
import { RangesList } from '../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions';

const { start_date, end_date } = getDateRangeByPeriodTime(
  RangesList.YearFromToday,
);

const initialState: RequestActivityState = {
  requests: [],
  cancelRequests: [],
  isCancelInProgress: false,
  isShowModalForCancel: false,
  cancelResult: undefined,
  filteredRequests: [],
  requestsApiKeys: {},
  filters:{
    searchQuery: '',
    reference: '',
    queryByDate: {
      start_date,
      end_date,
    },
  },
  paginationData: {
    paginationRangeStart:0,
    paginationRangeEnd:isDesktopEnv() ? config.rowsCountPerTableDesktop : config.rowsCountPerTableMobile,
    currentPage: 1,
    totalPages: 1,
    pageLimit: isDesktopEnv() ? config.rowsCountPerTableDesktop : config.rowsCountPerTableMobile,
  },
  tableSorting: defaultTableSortState,
};

export const requestActivitySlice = createSlice({
  name: 'requestActivity',
  initialState,
  reducers: {
    addRequest: (state, action: PayloadAction<PaymentRequest>) => {
      state.requests = [...state.requests, action.payload];
    },
    updateRequest: (state, action: PayloadAction<PaymentRequest>) => {
      state.requests = state.requests.map(obj =>
        obj.id === action.payload.id ? action.payload : obj
      );
    },
    addCancelRequest: (state, action: PayloadAction<StatusRequest>) => {
      state.cancelRequests = [...state.cancelRequests, action.payload];
    },
    clearCancelRequests: (state) => {
      state.cancelRequests = [];
    },
    deleteCancelRequest: (state, action: PayloadAction<string>) => {
      state.cancelRequests = state.cancelRequests.filter(request => request.id !== action.payload);
    },
    updateHistoryRequest: (state, action: PayloadAction<PaymentRequest>) => {
      state.requests = (state.requests.length && state.requests || []).map((request) => {
        return request.id === action.payload.id ? {...request, ...action.payload} : request
      });
    },
    setCancelResult: (state, action: PayloadAction<CancelResult | undefined>) => {
      state.cancelResult = action.payload;
    },
    setIsCancelInProgress: (state, action: PayloadAction<boolean>) => {
      state.isCancelInProgress = action.payload;
    },
    setIsShowModalForCancel: (state, action: PayloadAction<boolean>) => {
      state.isShowModalForCancel = action.payload;
    },
    updateRequestsTableFilters:  (state, action: PayloadAction<RequestFilters>) => {
      state.filters = action.payload
    },
    setFilteredPaymentRequests: (state, action: PayloadAction<PaymentRequest[]>) => {
      state.filteredRequests = action.payload;
    },
    setRequestsApiKeys: (state, action: PayloadAction<RequestsApiKeys>) => {
      state.requestsApiKeys = {...action.payload};
    },
    updateRequestsTablePaginationData: (state, action: PayloadAction<Partial<PaginationData>>) => {
      state.paginationData = { ...state.paginationData, ...action.payload };
    },
    updateRequestsTableSorting: (
      state,
      action: PayloadAction<Partial<RequestsTableSortState>>,
    ) => {
      state.tableSorting = {
        ...state.tableSorting,
        ...action.payload,
      };
    },
  },
});

export const {
  addRequest,
  addCancelRequest,
  deleteCancelRequest,
  updateHistoryRequest,
  setIsCancelInProgress,
  setIsShowModalForCancel,
  setCancelResult,
  setRequestsApiKeys,
  updateRequestsTableFilters,
  updateRequestsTableSorting,
  setFilteredPaymentRequests,
  updateRequestsTablePaginationData,
  updateRequest,
  clearCancelRequests,
} = requestActivitySlice.actions;


export const selectPaymentRequests = (state: RootState) => state.requestActivity.requests;
export const selectRequestsTableFilters = (state: RootState) => state.requestActivity.filters;
export const selectRequestsApiKeys = (state: RootState) => state.requestActivity.requestsApiKeys;
export const selectCancelRequests = (state: RootState) => state.requestActivity.cancelRequests;
export const selectIsCancelInProgress = (state: RootState) => state.requestActivity.isCancelInProgress;
export const selectIsShowModalForCancel = (state: RootState) => state.requestActivity.isShowModalForCancel;
export const selectCancelResult = (state: RootState) => state.requestActivity.cancelResult;
export const selectRequestsTableSorting = (state: RootState) => state.requestActivity.tableSorting;
export const selectFilteredPaymentRequests = (state: RootState) => state.requestActivity.filteredRequests;
export const selectRequestsTablePaginationData = (state: RootState) => state.requestActivity.paginationData;

export default requestActivitySlice.reducer;

export interface RequestActivityState {
  requests: PaymentRequest[];
  cancelRequests: StatusRequest[];
  filteredRequests: PaymentRequest[];
  requestsApiKeys: RequestsApiKeys;
  filters: RequestFilters;
  paginationData: PaginationData;
  tableSorting: RequestsTableSortState;
  isCancelInProgress: boolean;
  isShowModalForCancel: boolean;
  cancelResult?: CancelResult;
}

export interface RequestsApiKeys {
  [requestId: string]: string;
}

interface CancelResult {
  success: boolean;
  message: string;
}