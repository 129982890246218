import { createId } from "../helpers";
import { StatusRequest as IStatusRequest } from "../pages/requestActivity/static/requestActivityTypes";

export class StatusRequest {
    paylink: string

    constructor(paylink: string) {
        this.paylink = paylink
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams(): IStatusRequest {
        return {
            "@type": "https://dvschema.io/activation#StatusRequest",
            "id": createId(true),
            "entitySelector": {
                "entityType": "https://miapago.io/paylink/request/PaylinkRequest",
                "propertiesSelector": {
                    "@type": "https://miapago.io/paylink/request/PropertiesSelector",
                    "paymentMethods": [{
                        "methodType": 'OpenBankingDomesticPayment',
                    }],
                },
                "stateSelector": {
                    "@type": "https://miapago.io/paylink/request/StateSelector",
                    "paylink": this.paylink,
                    "otp": "",
                }
            },
            "timestamp": new Date(),
            "possibleResend": false
        };
    }
}