import React, {
  useEffect, 
  useRef, 
  useState
} from 'react';
import { useAppDispatch } from '../../app/hooks';
import { Button } from '../button/Button';
import { Modal } from '../modal/Modal';
import { isDesktopEnv } from '../../helpers';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { 
  ButtonColor, 
  ButtonStyle 
} from '../../static/CommonDefinitions';
import styles from './CancelPopup.module.scss';
import mobileStyles from './CancelPopupMobile.module.scss';
import { RecurringPaymentRequest } from '../../pages/recurringRequestsActivity/static/recurringRequestActivityTypes';
import { PaymentRequest } from '../../pages/requestActivity/static/requestActivityTypes';
import { cancelRecurringRequest } from '../../pages/recurringRequestsActivity/logic/recurringRequestActivitySaga';
//import { cancelPaymentRequestAction } from '../../pages/requestActivity/logic/requestActivitySaga';
//import { updateRequestStatus } from '../../pages/requestActivity/logic/requestActivitySlice';
//import { PaymentRequestStatus } from '../../pages/requestActivity/static/requestActivityCommonDefinitions';

interface CancelPopupProps {
  onComplete: () => void,
  requestToCancel: RecurringPaymentRequest | PaymentRequest
}
  
export const CancelPopup = ({ onComplete, requestToCancel}: CancelPopupProps) => {
    const dispatch = useAppDispatch();
    const ref = useRef(null);
    const targetStyles = isDesktopEnv() ? styles : mobileStyles;
    const [isCancelInProcess, setCancelInProcess] = useState<boolean>(false);

  
    useOutsideClick(ref, onComplete);

    const cancelRequest = () => {
      setCancelInProcess(true)
      if(instanceOfRecurringPaymentRequest(requestToCancel)) {
        dispatch(cancelRecurringRequest(requestToCancel.requestId))
      } else {
        //dispatch(updateRequestStatus({request: requestToCancel as PaymentRequest, status:PaymentRequestStatus.Updating}))
        //dispatch(cancelPaymentRequestAction(requestToCancel.requestId))
      }
    }


    function instanceOfRecurringPaymentRequest(object: any): boolean {
      return 'firstPaymentAmount' in object;
    }

    useEffect(() => {
      if (requestToCancel && isCancelInProcess) {
        setCancelInProcess(false)
        onComplete()
      }
    }, [requestToCancel])
  
    return (
        <Modal>
          <div className={styles.background} onClick={onComplete}/>
          <div className={targetStyles.cancel_popup} ref={ref}>
            <div className={targetStyles.cancel_popup__top}>
              <span className={targetStyles.cancel_popup__title}>Cancel Request</span>
            </div>
            <div className={targetStyles.cancel_popup__center}>
          <span className={targetStyles.cancel_popup__text}>
            Do you want to cancel this payment request? Cancelling payment request won't affect payments that you have already received.
          </span>
            </div>
            <div className={targetStyles.cancel_popup__bottom}>
              <Button
                  title='Yes, cancel payment request'
                  color={ButtonColor.Yellow}
                  style={ButtonStyle.Rounded}
                  onClick={cancelRequest}
                  spinner={isCancelInProcess}
              />
              <Button
                  title='No'
                  color={ButtonColor.TransparentWithBorder}
                  style={ButtonStyle.Rounded}
                  onClick={onComplete}
              />
            </div>
          </div>
        </Modal>
    )
}