import React from "react";
import classNames from "classnames";
import { FilterTag, FilterTagProps } from "../filterTag/FilterTag";
import mobileStyles from './FilterTagsContainer.module.scss';
import { DateRange } from "../dateRangeInput/static/dateRangeInputTypes";
import { isTheSameDateRanges, getDateRangeByPeriodTime } from "../../helpers";
import { RangesList } from "../dateRangeInput/static/dateRangePickerCommonDefinitions";
import moment from "moment";

export interface FilterTagsContainerProps {
  referenceFilter: string,
  dateFilter: DateRange,
  onReferenceFilterClear: () => {},
  onDateFilterClear: () => {},
  className?: string,
}

export const FilterTagsContainer = ({ referenceFilter, dateFilter, onReferenceFilterClear, onDateFilterClear, className }: FilterTagsContainerProps) => {
  const finalClassName = classNames([
    className,
    mobileStyles.filter_tags_container,
  ]);

  const tags: FilterTagProps[] = [];

  if (!isTheSameDateRanges(dateFilter, getDateRangeByPeriodTime(RangesList.YearFromToday))) {
    tags.push({
      content: dateFilter,
      onClearButtonClick: onDateFilterClear,
    })
  }

  if (referenceFilter) {
    tags.push({
      content: referenceFilter,
      onClearButtonClick: onReferenceFilterClear,
    });
  }

  if (tags.length === 0) {
    return <></>
  }

  return (
    <div className={finalClassName}>
      {
        tags.map((tag, i) =>
          <FilterTag
            key={i}
            onClearButtonClick={tag.onClearButtonClick}
            content={typeof tag.content === 'string' ? tag.content : dateRangeToString(tag.content as DateRange)}
          />)
      }
    </div>
  )
}

const dateRangeToString = (dateRange: DateRange): string => {
  switch (true) {
    case isTheSameDateRanges(dateRange, getDateRangeByPeriodTime(RangesList.Today)):
      return 'Today';
    case isTheSameDateRanges(dateRange, getDateRangeByPeriodTime(RangesList.ThisWeek)):
      return 'This week';
    case isTheSameDateRanges(dateRange, getDateRangeByPeriodTime(RangesList.LastWeek)):
      return 'Last week';
    case isTheSameDateRanges(dateRange, getDateRangeByPeriodTime(RangesList.ThisMonth)):
      return 'This month';
    case isTheSameDateRanges(dateRange, getDateRangeByPeriodTime(RangesList.LastMonth)):
      return 'Last month';
    case isTheSameDateRanges(dateRange, getDateRangeByPeriodTime(RangesList.ThisYear)):
      return 'This year';
    default:
      return `${moment(dateRange.start_date).format('MMM DD, YYYY')} - ${moment(dateRange.end_date).format('MMM DD, YYYY')}`;
  }
}
