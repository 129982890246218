import React from "react";
import { applyTabFocusSupport, isDesktopEnv } from "../../../../../helpers";
import { SettingsInputType } from "../../../static/paymentRequestConstants";
import { SettingsInput } from "../settingsInput/SettingsInput";
import styles from './SettingsRadioButton.module.scss';
import mobileStyles from './SettingsRadioButtonMobile.module.scss';

export interface SettingsRadioButtonProps {
  title: string;
  checked: boolean;
  showInput: boolean;
  value: string;
  style: string;
  placeholder?: string;
  settingType?: SettingsInputType;
  subtitle?: string
  inputRequirement?: string;
  autofill?: boolean,
  general?: boolean;
  inputValue?: string;
  inputError?: boolean;
  handleInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleRadioChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SettingsRadioButton = ({
  title,
  style,
  placeholder,
  settingType,
  subtitle,
  inputRequirement,
  checked,
  handleRadioChange,
  value,
  showInput,
  general,
  autofill,
  inputValue,
  inputError = false,
  handleInputBlur,
  handleInputValueChange
}: SettingsRadioButtonProps) => {
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  return(
      <div className={targetStyles.radio_button__container}>
          <input
              type="radio"
              checked={checked}
              id={value}
              value={value}
              onChange={handleRadioChange}
              className={targetStyles.radio_button__input}
          />
          <label
              htmlFor={value}
              className={targetStyles.radio_button__label}
              { ...applyTabFocusSupport() }
          >
              {title}
              {!!subtitle && <div className={targetStyles.radio_button__subtitle}>{subtitle}</div>}
          </label>
          {(showInput && checked) &&
          <SettingsInput
              error={inputError}
              style={style}
              placeholder={placeholder}
              settingType={settingType}
              inputRequirement={inputRequirement}
              general={general}
              autofill={autofill}
              value={inputValue}
              handleInputBlur={handleInputBlur}
              handleInputValueChange={handleInputValueChange}
          />}
      </div>
  )
}
