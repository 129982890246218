import React from 'react';
import { Button } from '../../../components/button/Button';
import CheckIcon from '../../../assets/images/check-icon.svg';
import CloseIcon from '../../../assets/images/close-arrow-24px-rounded.svg';
import { ButtonStyle } from '../../../static/CommonDefinitions';
import {
  BackgroundColors,
  BackgroundContainer,
} from '../../../components/background/BackgroundContainer';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';

import styles from './ActivateAccountSuccess.module.scss';
import mobileStyles from './ActivateAccountSuccessMobile.module.scss';
import { selectIsSignedIn } from '../../../core/reducers/appSlice';
import { MobileHeader } from '../../../components/mobileHeader/MobileHeader';
import {
  MobileHeaderColor,
  MobileHeaderPadding,
} from '../../../components/mobileHeader/static/mobileHeaderCommonDefinitions';
import { isDesktopEnv } from '../../../helpers';

export function ActivateAccountSuccess() {
  const history = useHistory();
  const isSignedIn = useAppSelector(selectIsSignedIn);

  const handleButtonClick = () => {
    if(isSignedIn) {
      history.replace('/home');
    } else {
      history.push('/create-pin');
    }
  };

  if (isDesktopEnv()) {
    return (
      <BackgroundContainer color={BackgroundColors.Green}>
        <div className={styles.container}>
          <div>
            <img src={CheckIcon} alt="check" />
            <h4 className={styles.container__title}>Success</h4>
            <p className={styles.container__text}>
              You have successfully activated your BOPP Account on this device.
            </p>
          </div>
          <div className={styles.container__button}>
            <Button
              title="Got it"
              buttonHeight="50px"
              style={ButtonStyle.Rounded}
              handleClick={handleButtonClick}
            />
          </div>
        </div>
      </BackgroundContainer>
    );
  }

  return (
    <div className={mobileStyles.container}>
      <MobileHeader
        withLogo
        isMenu={false}
        rightIcon={<img src={CloseIcon} onClick={history.goBack} />}
        color={MobileHeaderColor.green}
        padding={MobileHeaderPadding.small}
      />
      <div className={mobileStyles.container__center}>
        <img
          src={CheckIcon}
          alt="check"
          className={mobileStyles.container__top_img}
        />
        <h4 className={mobileStyles.container__title}>Success</h4>
        <p className={mobileStyles.container__text}>
          You have successfully activated your BOPP Account on this device.
        </p>
      </div>
      <div className={mobileStyles.container__bottom}>
        <Button
          title="Got it"
          style={ButtonStyle.Rounded}
          handleClick={handleButtonClick}
        />
      </div>
    </div>
  );
}
