import style from './CreatePin.module.scss';
import { BackgroundContainer } from '../../../../components/background/BackgroundContainer';
import { StylizedWrapperWithRadius } from '../../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import PinInput from '../../../../components/pinInput/PinInput';
import { Button } from '../../../../components/button/Button';
import { ButtonColor, ButtonStyle } from '../../../../static/CommonDefinitions';
import { useState } from 'react';
import { PIN_LENGTH } from '../../static/createPinConstants';
import classNames from 'classnames';
import { setSignedIn } from '../../../../core/reducers/appSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { secureStoreKey } from '../../../../controllers/KeyController';

export function CreatePin() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleSubmit = async (value: string) => {
    dispatch(setSignedIn(true));
    await secureStoreKey(value);
    history.replace('/home');
  };

  return (
    <BackgroundContainer isMenu={false}>
      <div className={style.create_pin}>
        <header className={style.create_pin__header}>
          <h1 className={style.create_pin__header_title}>Almost there</h1>
          <span className={style.create_pin__header_subtitle}>
            You have successfully created a company. <br /> Add a PIN to finish
            the signup process
          </span>
        </header>
        <main className={style.create_pin__main}>
          <CreatePinForm onSubmit={handleSubmit} />
        </main>
      </div>
    </BackgroundContainer>
  );
}

export interface CreatePinFormProps {
  onSubmit: (value: string) => void;
}

export function CreatePinForm({ onSubmit }: CreatePinFormProps) {
  const [pinValue, setPinValue] = useState<string>('');
  const [confirmedPinValue, setConfirmedPinValue] = useState('');
  const [ableToDisplayError, setAbleToDisplayError] = useState(false);

  const shouldDisplayError =
    ableToDisplayError && pinValue !== confirmedPinValue;
  const mainStyle = classNames([
    style.create_pin_form__main,
    { [style['create_pin_form__main--with-error']]: shouldDisplayError },
  ]);

  return (
    <StylizedWrapperWithRadius className={style.create_pin_form_wrapper}>
      <div className={style.create_pin_form}>
        <div className={style.create_pin_form__header}>
          <h3 className={style.create_pin_form__title}>Create a PIN</h3>
          <span className={style.create_pin_form__subtitle}>
            You will need the PIN to authenticate
          </span>
        </div>
        <div className={mainStyle}>
          <div className={style.create_pin_form__input_wrap}>
            <PinInput
              secret
              length={PIN_LENGTH}
              label="Create a PIN"
              onChange={(value: string) => setPinValue(value)}
            />
          </div>
          <div className={style.create_pin_form__input_wrap}>
            <PinInput
              secret
              length={PIN_LENGTH}
              label="Confirm PIN"
              onChange={(value: string) => setConfirmedPinValue(value)}
              onFocus={() => setAbleToDisplayError(false)}
              onBlur={() => setAbleToDisplayError(true)}
            />
          </div>
          <span className={style.create_pin_form__error_message}>
            {shouldDisplayError && "PINs don't match"}
          </span>
          <Button
            disabled={
              pinValue.length !== PIN_LENGTH || pinValue !== confirmedPinValue
            }
            buttonHeight="50px"
            color={ButtonColor.Black}
            style={ButtonStyle.Rounded}
            title="Save & Continue"
            onClick={() => {
              onSubmit(pinValue);
            }}
          />
        </div>
      </div>
    </StylizedWrapperWithRadius>
  );
}
