import { push } from "connected-react-router";
import { put, takeLatest, select } from "redux-saga/effects";
import { addBankAccount } from "../../pages/bankAccount/logic/bankAccountSaga";
import { changeSelectedBankAuthURL, changeSelectedBankIsUnavailable, selectSelectedBankAuthURL } from "../../pages/retrieveAccountDetails/logic/retrieveAccountDetailsSlice";
import { log } from "../../static/Logger";
import { AispActivationState } from "./aispProcessorCommonDefinitions";

export const RESOLVE_AIPS_INCOMMING_MESSAGE = 'resolve/aisp/incomming/message';

export interface ResolveAISPIncommingMessagePayload {
  message: any,
}

export interface ResolveAISPIncommingMessage { type: typeof RESOLVE_AIPS_INCOMMING_MESSAGE; payload: ResolveAISPIncommingMessagePayload }

export const resolveAISPIncommingMessage = (payload: ResolveAISPIncommingMessagePayload): ResolveAISPIncommingMessage => ({
	type: RESOLVE_AIPS_INCOMMING_MESSAGE,
	payload,
})

function* resolveAISPIncommingMessageWatcher({ payload }: ResolveAISPIncommingMessage): any {
  const { message } = payload;

  try {
    const nonintegrated = message.nonintegrated;
    const response = nonintegrated ? message : message.params[0];
    const state = nonintegrated ? AispActivationState.AccountDetails : response.state.activationState;
    const authUrl = yield select(selectSelectedBankAuthURL);

    switch (state) {
      case AispActivationState.AuthURL:
        yield put(changeSelectedBankAuthURL(response.state.authorizationURL));
        break;
      case AispActivationState.AccountDetails:
        yield put(addBankAccount({response}));
        break;
      case AispActivationState.AccountRejected:
      if (!authUrl) {
        yield put(changeSelectedBankIsUnavailable(true));
      }
      else {
        yield put(push('/error-save-account-details'));
      }
      break;
      default:
        yield put(push('/error-save-account-details'));
    }
  } catch (error) {
    log('dispatchResolveAipsIncommingMessage ERROR: ' + error);
  }
}

export default function* aispProcessorSaga() {
	yield takeLatest(RESOLVE_AIPS_INCOMMING_MESSAGE, resolveAISPIncommingMessageWatcher);
}
