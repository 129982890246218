import React, { useRef } from "react";
import styles from "./DropdownWithSearch.module.scss";
import LeftIcon from "../../assets/images/left-gray.svg";
import classNames from "classnames";
import { applyTabFocusSupport, isDesktopEnv } from "../../helpers";

export enum DropdownLabelPosition {
  Left = 'Left',
  Top = 'Top',
}

export interface DropdownProps {
  label: string,
  labelPosition: DropdownLabelPosition,
  values: string[],
  placeholder: string,
  searchValue: string,
  onValueChange: (value: string) => void,
  onSearchValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export function DropdownWithSearch({ label, labelPosition, values, placeholder, searchValue, onValueChange, onSearchValueChange }: DropdownProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleListItemClick = (value: string) => {
    onValueChange(value);
  }

  const className = classNames([
    styles.dropdown,
    {[styles['dropdown--mobile']]: !isDesktopEnv()},
    {[styles['dropdown--with_left_label']]: labelPosition === DropdownLabelPosition.Left},
    {[styles['dropdown--with_top_label']]: labelPosition === DropdownLabelPosition.Top},
  ]);

  let filteredValues = searchValue ? values.filter(value => value.includes(searchValue)) : values;

  if (searchValue.length) {
    filteredValues = filteredValues.slice(0, 3);
  }

  return (
    <div className={className}>
      <span className={styles.dropdown__label}>{ label }</span>
      <div className={styles.dropdown__input_block}>
        <input
          ref={inputRef}
          className={styles.dropdown__input}
          value={searchValue}
          placeholder={placeholder}
          onChange={onSearchValueChange}
          { ...applyTabFocusSupport() }
        />
        <div className={styles.dropdown__list}>
          {
            filteredValues.map((value, i) => (
              <div
                key={i}
                className={styles.dropdown__list_item}
                onMouseDown={() => handleListItemClick(value)}
                onTouchStart={() => handleListItemClick(value)}
              >
                { value }
              </div>
              )
            )
          }
        </div>
        <img src={LeftIcon} className={styles.dropdown__input_icon} onClick={() => inputRef.current?.focus()} />
      </div>
    </div>
  )
}
