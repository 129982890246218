import React, { useMemo } from 'react';
import classNames from 'classnames';

import PlusIcon from '../../assets/images/plus-icon.svg';
import { applyTabFocusSupport, getCircleLogo, isDesktopEnv } from '../../helpers';

import styles from './ChooseBankPanel.module.scss';
import mobileStyles from './ChooseBankPanelMobile.module.scss';

import { CircleSpinner } from '../circleSpinner/CircleSpinner';

export interface ChooseBankPanelProps {
  handleChooseBank: () => void;
  accountName?: string;
  logoUri?: string;
  showSpinner?: boolean;
}

export function ChooseBankPanel({
  handleChooseBank,
  accountName,
  logoUri,
  showSpinner = false,
}: ChooseBankPanelProps) {
  const accountDetails = { state: '' };

  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  const renderChangeButton = () => {
    if (showSpinner) {
      return (
        <div className={targetStyles.panel__name_title}>
          <CircleSpinner />
        </div>
      );
    }

    if (!accountName) {
      return <div className={targetStyles.panel__name_title}>Choose Bank</div>;
    }
    return <div className={targetStyles.panel__name_title}>Change</div>;
  };

  const changeButtonIcon = useMemo(() => {
    return !logoUri ? (
      <img src={PlusIcon} alt="" />
    ) : (
      <img
        src={getCircleLogo(logoUri)}
        alt="bank-logo"
        className={targetStyles.panel__image_logo}
      />
    );
  }, [accountDetails.state, logoUri]);

  return (
    <div className={targetStyles.panel}>
      <div
        onClick={handleChooseBank}
        className={classNames(
          targetStyles.panel__image,
          !logoUri ? targetStyles.panel__image_border : '',
        )}>
        {changeButtonIcon}
      </div>
      <div {...applyTabFocusSupport()} className={targetStyles.panel__name} onClick={handleChooseBank}>
        {renderChangeButton()}
      </div>
    </div>
  );
}
