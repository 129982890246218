import { ToastDefaultInfo } from "../static/keyManagementConstants"

export const keyManagementMigration = (state:any, version:number) => {  
    switch(version) {
        case (0): {
            return {      
                ...state.keyManagement,        
				toastInfo: ToastDefaultInfo
            }  
        }
    }
} 
