import classNames from 'classnames';
import React from 'react';
import QRCode from 'qrcode.react';

import Logo from '../../assets/images/logo.png';

import { cssVhToPx, isDesktopEnv } from '../../helpers';
import'./QRCodeBlock.scss';

export interface QRCodeBlockProps {
  url: string;
}

export function QRCodeBlock({ url }: QRCodeBlockProps) {
  return(
    <div className='QR_code_block' style={isDesktopEnv() ? {} : inlineStyles.qr}>
        {<QRCode value={url} size={220}
            level={'H'}
            bgColor={'#fff'}
            fgColor={'#21242B'}
            imageSettings={
              {
                src: Logo,
                height: 40,
                width: 79,
                excavate: false,
              }
            }
        />}
    </div>
  )
}

const inlineStyles = {
  qr: {
    width: cssVhToPx(27),
    height: cssVhToPx(27),
  }
}