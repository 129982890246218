import React, {
  useEffect, 
  useState
} from 'react';
import classNames from 'classnames';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import {
  StylizedInputForFilters,
  StylizedInputStyles,
  StylizedInputStyleType,
  StylizedInputTypes
} from '../../../components/StylizedInput/StylizedInputForFilters';
import SearchIco from '../../../assets/images/loupe.svg';
import SettingsIcon from '../../../assets/images/filter-menu.svg';
import styles from './RecurringRequestActivity.module.scss';
import mobileStyles from './RecurringRequestActivityMobile.module.scss';
import { 
  formatAmountToString,
  getDateRangeByPeriodTime, 
  isDesktopEnv 
} from '../../../helpers';
import {
  DropdownLabelPosition, 
  DropdownWithSearch
} from '../../../components/dropdownWithSearch/DropdownWithSearch';
import {StylizedWrapperWithRadius} from '../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import {
  useAppDispatch, 
  useAppSelector
} from '../../../app/hooks';
import {
  selectFiltereRecurringPaymentRequests,
  selectRecurringPaymentRequests,
  selectRequestsTableFilters,
  selectRecurringRequestsTablePaginationData,
  updateRequestsTablePaginationData,
  updateRecurringRequestsTableSorting,
} from '../logic/recurringRequestActivitySlice';
import {defaultTableSortState} from '../static/recurringRequestActivityConstants';
import {
  RecurringRequestCSV,
  RequestFilters
} from '../static/recurringRequestActivityTypes';
import {
  filterRequestsAction,
  getRequests,
  refreshRequestsFilters,
  setRequestsTableFilters,
  sortRecurringRequestsTableAction,
} from '../logic/recurringRequestActivitySaga';
import {Pagination} from '../../../components/pagination/ui/Pagination';
import {PaginationData} from '../../../components/pagination/static/paginationTypes';
import config from '../../../config';
import CloseIcon from "../../../assets/images/close-arrow-24px-rounded.svg";
import {DateRangeInput} from '../../../components/dateRangeInput/ui/DateRangeInput';
import {RangesList} from '../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions';
import {FilterTagsContainer} from '../../../components/filterTagsContainer/FilterTagsContainer';
import {Header} from '../../../components/header/ui/Header';
import { Table } from './RecurringActivityTable';
import moment from 'moment';

const { start_date: start_date_default, end_date: end_date_default } = getDateRangeByPeriodTime(
  RangesList.YearFromToday,
);

export function RecurringRequestActivity() {
  const dispatch = useAppDispatch();
  const requests = useAppSelector(selectRecurringPaymentRequests);
  const filteredRequests = useAppSelector(selectFiltereRecurringPaymentRequests);
  const paginationData = useAppSelector(selectRecurringRequestsTablePaginationData);
  const { searchQuery, reference, queryByDate: {start_date, end_date} }: RequestFilters = useAppSelector(selectRequestsTableFilters);
  const [referenceInputValue, setReferenceInputValue] = useState('');
  const [filtersAreOpened, setFiltersAreOpened] = useState(false);
  const [csvData, setCsvData] = useState<RecurringRequestCSV[]>([]);

  const areFiltersClear = (searchQuery === '') && (reference === '')  && (start_date === start_date_default) && (end_date === end_date_default);
  const visibleRequests = filteredRequests.length || !areFiltersClear ? filteredRequests : requests;
  const isDesktop = isDesktopEnv();
    const targetRowsCountPerTable = isDesktop
    ? config.rowsCountPerTableDesktop
    : config.rowsCountPerTableMobile;
  const showPagination = visibleRequests.length > targetRowsCountPerTable;

  useEffect(() => {
    dispatch(getRequests());
    dispatch(refreshRequestsFilters());
  }, []);

  useEffect(() => {
    dispatch(filterRequestsAction());
  }, [requests]);

  useEffect(() => {
    dispatch(
      updateRequestsTablePaginationData({
          ...paginationData,
          pageLimit: targetRowsCountPerTable,
        }),
    );
  }, [isDesktop]);

  useEffect(() => {
    dispatch(updateRecurringRequestsTableSorting(defaultTableSortState));
    dispatch(sortRecurringRequestsTableAction());
  }, []);

  const exportToCSV = () => {
    const data = filteredRequests.map(request => ({
      creationTime: moment(request.creationTime).format('DD-MM-YYYY HH:mm'),
      status: request.status,
      reference: request.paymentReference,
      requestedTo: request.payeeName,
      amountValue: formatAmountToString(+request.firstPaymentAmount.value, 'GBP'),
      firstPaymentDue: request?.firstPaymentDue ? moment(request.firstPaymentDue).format('DD-MM-YYYY') : '',
      recurringAmount: request?.recurringPaymentAmount?.value ? formatAmountToString(+request.recurringPaymentAmount.value, 'GBP') : '',
      firstRecurringDue: request?.firstRecurringPaymentDue ? moment(request.firstRecurringPaymentDue).format('DD-MM-YYYY') : '',
      finalAmount: request?.finalPaymentAmount?.value ? formatAmountToString(+request.finalPaymentAmount.value, 'GBP') : '',
      finalDue: request?.finalPaymentDue ? moment(request.finalPaymentDue).format('DD-MM-YYYY') : '',
      frequency: request?.frequency ? request.frequency : '',
      id: request.id,
      websiteURL: request.websiteURL
    }));

    setCsvData(data);
  }

  const headersCSV = [
    { label: 'Creation Date/Time', key: 'creationTime' },
    { label: 'Status', key: 'status' },
    { label: 'Reference', key: 'reference' },
    { label: 'Requested To', key: 'requestedTo' },
    { label: 'Amount', key: 'amountValue' },
    { label: 'First Payment Due', key: 'firstPaymentDue' },
    { label: 'Recurring Amount', key: 'recurringAmount' },
    { label: 'First Recurring Due', key: 'firstRecurringDue' },
    { label: 'Final Payment Amount', key: 'finalAmount' },
    { label: 'Final Payment Due', key: 'finalDue' },
    { label: 'Frequency', key: 'frequency' },
    { label: 'Id', key: 'id' },
    { label: 'Website URL', key: 'websiteURL' },
  ];

  const handleReferenceValueChange = (value: string) => {
    setReferenceInputValue(value);
    dispatch(setRequestsTableFilters({ reference: value }));
  }

  const handleReferenceSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setReferenceInputValue(value);

    if (!value.length) {
      dispatch(setRequestsTableFilters({ reference: value }));
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRequestsTableFilters({searchQuery: e.target.value}));
  }

  const handleSearchClear = () => {
    dispatch(setRequestsTableFilters({searchQuery: ''}));
  }

  const requestsReferences: string[] = [];

  requests.forEach(request => {
    const targetReference = request.paymentReference;

    if (!requestsReferences.includes(targetReference)) {
      requestsReferences.push(targetReference);
    }
  });

  if (isDesktop) {
    return (
      <BackgroundContainer isMenu>
        <div className={styles.request_activity}>
          <Header
            title='Request Activity'
            CTAButton={{
              CSVButtonInfo: {
                CSVData: csvData,
                CSVHeaders: headersCSV,
                CSVFileName: 'BOPP-request-activity.csv',
                onClick: () => {exportToCSV()},
              }
            }}
          />
          <div className={styles.request_activity__filters}>
            <div className={styles.request_activity__search_filter_wrap}>
              <StylizedInputForFilters
                  useClearButton
                  handleClear={handleSearchClear}
                  name="searachQuery"
                  value={searchQuery}
                  icon={SearchIco}
                  placeholder="Search"
                  handleChange={handleSearchChange}
                  type={StylizedInputTypes.Text}
                  style={StylizedInputStyles.Search}
                  styleType={StylizedInputStyleType.Solid}
              />
            </div>
            <div className={styles.request_activity__filters_right}>
              <DateRangeInput startDate={start_date} endDate={end_date} onRangeByDate={(range) => dispatch(setRequestsTableFilters({queryByDate: range}))} />
              <DropdownWithSearch
                label='Reference'
                placeholder='All'
                values={requestsReferences}
                searchValue={referenceInputValue}
                onSearchValueChange={handleReferenceSearchChange}
                onValueChange={handleReferenceValueChange}
                labelPosition={DropdownLabelPosition.Left}
              />
            </div>
          </div>
          <StylizedWrapperWithRadius>
            <Table
                areFiltersClear={areFiltersClear}
            />
          </StylizedWrapperWithRadius>
          {
            showPagination && <div className={styles.request_activity__pagination}>
              <Pagination
                totalTableRecords={visibleRequests.length}
                currentPage={paginationData.currentPage}
                handlePaginationUpdate={(info: PaginationData) => {
                  dispatch(updateRequestsTablePaginationData(info));
                }}
              />
            </div>
          }
        </div>
      </BackgroundContainer>
    )
  }

  const filtersContainerClassName = classNames([
    mobileStyles.request_activity__filters,
    {[mobileStyles['request_activity__filters--opened']]: filtersAreOpened},
  ]);

  const handleFiltersToggleButtonClick = () => {
    setFiltersAreOpened(!filtersAreOpened);
  }

  return (
    <div className={mobileStyles.request_activity}>
      <Header
        title='Request Activity'
        CTAButton={{
          CSVButtonInfo: {
            CSVData: csvData,
            CSVHeaders: headersCSV,
            CSVFileName: 'BOPP-request-activity.csv',
            onClick: () => {exportToCSV()},
          }
        }}
      />
      <div className={filtersContainerClassName}>
        <div className={mobileStyles.request_activity__filters_top}>
          <div className={mobileStyles.request_activity__search_filter}>
            <StylizedInputForFilters
              useClearButton
              handleClear={handleSearchClear}
              name="searchQuery"
              value={searchQuery}
              icon={SearchIco}
              placeholder="Search"
              handleChange={handleSearchChange}
              type={StylizedInputTypes.Text}
              style={StylizedInputStyles.Search}
              styleType={StylizedInputStyleType.Solid}
            />
          </div>
          <img
            alt={''}
            src={filtersAreOpened ? CloseIcon : SettingsIcon}
            className={mobileStyles.request_activity__settings_icon}
            onClick={handleFiltersToggleButtonClick}
          />
        </div>
        <div className={mobileStyles.request_activity__filters_bottom}>
          <div className={mobileStyles.request_activity__date_filter}>
            <DateRangeInput startDate={start_date} endDate={end_date} onRangeByDate={(range) => dispatch(setRequestsTableFilters({queryByDate: range}))} />
          </div>
          <DropdownWithSearch
            label='Reference'
            placeholder='All'
            values={requestsReferences}
            searchValue={referenceInputValue}
            onSearchValueChange={handleReferenceSearchChange}
            onValueChange={handleReferenceValueChange}
            labelPosition={DropdownLabelPosition.Top}
          />
        </div>
      </div>
      {
        !filtersAreOpened &&
          <FilterTagsContainer
            referenceFilter={reference}
            dateFilter={{ start_date, end_date }}
            onReferenceFilterClear={() => dispatch(setRequestsTableFilters({reference: ''}))}
            onDateFilterClear={() => dispatch(setRequestsTableFilters({queryByDate: getDateRangeByPeriodTime(RangesList.YearFromToday)}))}
          />
      }
      <Table
          areFiltersClear={areFiltersClear}
      />
      {
        showPagination && <div className={mobileStyles.request_activity__pagination}>
          <Pagination
            totalTableRecords={visibleRequests.length}
            currentPage={paginationData.currentPage}
            handlePaginationUpdate={(info: PaginationData) => {
              dispatch(updateRequestsTablePaginationData(info));
            }}
          />
        </div>
      }
    </div>
  )
}