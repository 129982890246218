import { SortTableState } from './giftAidTypes.d';
import { GiftAidTableTitles } from "./giftAidTypes";
import { SortingType } from '../../activity/static/activityCommonDefinitions';

export const giftAidTableTitles: GiftAidTableTitles = {
  datetime: 'Date/Time',
  donorName: 'Donor Name',
  payersEmail: 'Payers Email',
  address: 'Address',
  postcode: 'Postcode',
  reference: 'Payment Reference',
  giftAid: 'Gift aid',
  amount: 'Amount',
};


export const giftAidTableTitlesMobile: Partial<GiftAidTableTitles> = {
  datetime: giftAidTableTitles.datetime,
  donorName: giftAidTableTitles.donorName,
  giftAid: giftAidTableTitles.giftAid,
  amount: giftAidTableTitles.amount,
};

export const defaultGiftAidTableSorting: SortTableState = {
  datetime: SortingType.DESCENDING,
  donorName: SortingType.INACTIVE,
  payersEmail: SortingType.INACTIVE,
  address: SortingType.INACTIVE,
  postcode: SortingType.INACTIVE,
  reference: SortingType.INACTIVE,
  giftAid: SortingType.INACTIVE,
  amount: SortingType.INACTIVE,
}