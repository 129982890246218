import classNames from "classnames";
import React from "react";
import styles from "./CircleSpinner.module.scss";

export interface CircleSpinnerProps {
  className?: string,
}

export function CircleSpinner({ className }: CircleSpinnerProps) {
  const style = classNames([styles.circleSpinner, className]);
  return <div className={style}>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
    <div className={styles.circleSpinner__item}></div>
  </div>
}
