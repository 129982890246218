import React from 'react';
import classNames from 'classnames';

import styles from './StylizedWrapperWithRadius.module.scss';

export interface IStylizedWrapper {
  children: JSX.Element | string,
  style?: 'main'| 'border' | 'square' | 'bRadius12',
  shadow?: boolean,
  overflowHide?: boolean,
  className?: string,
}

export const StylizedWrapperWithRadius = ({ children, style = 'main', shadow, overflowHide = false, className }: IStylizedWrapper) => {
  const wrapperStyle = classNames(
    styles.wrapper, styles[style], className,
    {[styles.overflow_h]: overflowHide},
    {[styles['with-shadow']]: shadow},
  );

  return (
    <div className={wrapperStyle}>
      {children}
    </div>
  )
};
