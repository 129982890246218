import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from '../button/Button';
import PinInput from '../pinInput/PinInput';
import styles from './PinInputForm.module.scss';
import DeleteleftIcon from '../../assets/images/delete-left.svg';
import { ButtonColor, ButtonStyle } from '../../static/CommonDefinitions';
import { PinInputErrorPosition } from './static/pinInputFormCommonDefinitions';

export interface PinInputFormProps {
  underInputComponent?: JSX.Element | null;
  underSubmitComponent?: JSX.Element | null;
  onSubmit: (value: string) => void;
  onChange: (value: string) => void;
  title: string;
  length: number;
  className?: string;
  errorMessage?: string;
  errorMessagePosition?: PinInputErrorPosition;
  submitButtonTitle: string;
  submitButtonDisabled?: boolean;
}

export function PinInputForm({
  underInputComponent,
  underSubmitComponent,
  onSubmit,
  onChange,
  title,
  length,
  className,
  errorMessage,
  errorMessagePosition = PinInputErrorPosition.Top,
  submitButtonTitle,
  submitButtonDisabled = false,
}: PinInputFormProps) {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value.length > length) {
      setValue(value.substring(0, length));
    } else {
      onChange(value);
    }
  }, [value, length]);

  const items = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    [0, -1],
  ];

  const handleItemClick = (item: number) => {
    if (item === -1) {
      setValue(value.slice(0, -1));
    } else if (value.length < length) {
      setValue(value + item);
    }
  };

  const pinInputStyle = classNames([
    styles.pin_input_form__pin_input,
    { [styles['pin_input_form__pin_input--error']]: errorMessage },
  ]);

  return (
    <div className={classNames([styles.pin_input_form, className])}>
      <div className={styles.pin_input_form__top}>
        <span className={styles.pin_input_form__title}>{title}</span>
        {errorMessage && errorMessagePosition === PinInputErrorPosition.Top && (
          <span className={styles.pin_input_form__error}>{errorMessage}</span>
        )}
        <PinInput
          secret
          mobile
          length={6}
          value={value}
          wrapperClassName={pinInputStyle}
        />
        {underInputComponent}
        {errorMessage &&
          errorMessagePosition === PinInputErrorPosition.Bottom && (
            <span className={styles.pin_input_form__error}>{errorMessage}</span>
          )}
      </div>
      <div className={styles.pin_input_form__center}>
        <div className={styles.pin_input_form__buttons}>
          {items.map((row, i) => (
            <div key={i} className={styles.pin_input_form__buttons_row}>
              {row.map(item =>
                item === -1 ? (
                  <img
                    key={item}
                    src={DeleteleftIcon}
                    onClick={() => handleItemClick(item)}
                  />
                ) : (
                  <span key={item} onClick={() => handleItemClick(item)}>
                    {item}
                  </span>
                ),
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.pin_input_form__bottom}>
        <Button
          title={submitButtonTitle}
          style={ButtonStyle.LittleRounded}
          color={ButtonColor.Black}
          handleClick={() => onSubmit(value)}
          disabled={submitButtonDisabled}
        />
        {underSubmitComponent}
      </div>
    </div>
  );
}
