import { ErrorScreenType } from "./errorScreenCommonDefinitions";
import { ErrorScreenData } from "./errorScreenTypes";

export const errorScreensData:  Record<ErrorScreenType, ErrorScreenData> = {
    [ErrorScreenType.PermissionDenied]: {
      title: 'Permission Denied',
      text: <span>We were unable to retrieve your<br/>bank account details, please try <br /> again.</span>,
    },
    [ErrorScreenType.SaveBankAccount]: {
      title: <span>failed to save<br/>bank accounts</span>,
      text: <span>Sorry, we’re unable to link more<br/>than one bank account.<br/>Please try again.</span>,
    },
    [ErrorScreenType.SaveAccountDetails]: {
      title: <span>failed to save<br/>account details</span>,
      text: <span>Sorry, we’re unable to link this bank<br/>account. Please try again with an<br/>alternative bank account.</span>,
    },
    [ErrorScreenType.EnvSetup]: {
      title: <span>Environment not setup properly</span>,
      text: <></>
    }
}
