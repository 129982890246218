import { AnyAction } from "@reduxjs/toolkit";
import axios from "axios";
import { push } from "connected-react-router";
import { put, select, takeLatest } from "redux-saga/effects";
import config from "../../config";
import { clearStore } from "../../controllers/KeyController";
import { getRequestHeader } from "../../helpers";
import { getGiftAidKeysAction } from "../../pages/activateAccount/logic/activateAccountSaga";
import { getPayments } from "../../pages/activity/logic/activitySaga";
import { setPaymentSubscriber } from "../../pages/activity/logic/activitySlice";
import { selectPersonaDID, setPersonaDID } from "../../pages/bankAccount/logic/bankAccountSlice";
import { grabApiKeysFromResponse } from "../../pages/keyManagement/logic/keyManagementSaga";
import { setApiKeys } from "../../pages/keyManagement/logic/keyManagementSlice";
import { grabSubscriptionPlanDetails } from "../../pages/subscriptionManagement/logic/subscriptionManagementSaga";
import { changeSubscriptionId, changeSubscriptionPlan, changeSubscriptionType, changeUserSubscriptionId } from "../../pages/subscriptionManagement/logic/subscriptionManagementSlice";
import { log } from "../../static/Logger";
import { RequestHeader } from "../../static/objectTypes";
import { reset, selectIsSignedIn } from "../reducers/appSlice";

export const REFRESH_APP = "refresh/app";
export const ACCOUNT_NOT_EXIST = "account/not/exist";
export function refreshAppAction():AnyAction {return {type: REFRESH_APP}}
export function accountNotExistAction():AnyAction {return {type: ACCOUNT_NOT_EXIST}}

function* refreshApp () {
    const isSignedIn:boolean = yield select(selectIsSignedIn);

    if(!isSignedIn) 
        return

    const personaDID: string = yield select(selectPersonaDID);
	const header:RequestHeader = yield getRequestHeader()

    try {
		const { data } = yield axios.get(config.dashboardServerUrl + '/persona/' + personaDID, {headers: header});

        yield handlePersonaResponse(data);

	} catch (e:any) {
        if(e.response.data.error === 'PersonaDeactivated') {
            yield put(accountNotExistAction())
            return
        }
        log(e);
    }
}

function* accountNotExist () {
    yield put(reset())
    localStorage.clear();
    clearStore();
    yield put(push('/login'))
}

function* handlePersonaResponse(data: any) {
    let personaDID = data.data.id;
    let subscriptionPlan = grabSubscriptionPlanDetails(data);
    let subscriptionId = data.data.property.id;
    let paymentSubscriber =
      data.data.property.parties[0].PartyRoles['payment:Payee'];
  
    let keys = grabApiKeysFromResponse(data);
    yield put(setApiKeys(keys));
  
    yield put(setPersonaDID(personaDID));
    yield put(changeSubscriptionPlan(subscriptionPlan));
    yield put(changeSubscriptionId(subscriptionId));
    yield put(changeUserSubscriptionId(data.data.property.subscriptionId));
    yield put(setPaymentSubscriber(paymentSubscriber));
    yield put(changeSubscriptionType(data.data.property.subscriptionType));
    yield put(getGiftAidKeysAction())
}

export default function* initSaga() {
    yield takeLatest(REFRESH_APP, refreshApp);
    yield takeLatest(ACCOUNT_NOT_EXIST, accountNotExist);
}

