import { SortingType } from "../../activity/static/activityCommonDefinitions";
import { RecurringRequestsTableTitles, RecurringRequestsTableSortState } from "./recurringRequestActivityTypes";

export const requestsTableTitles: RecurringRequestsTableTitles = {
  status: '',
  dateTime: 'Date/Time',
  requestId: 'Request ID',
  reference: 'Reference',
  websiteURL: 'Website URL',
  payerEmail: 'Payer’s Email',
  type: 'Type',
  amount: 'Amount'
};

export const requestTableTitlesMobile: Partial<RecurringRequestsTableTitles> = {
  status: '',
  dateTime: 'Date/Time',
  reference: 'Reference',
  amount: 'Amount',
}

export const defaultTableSortState: RecurringRequestsTableSortState = {
  dateTime: SortingType.DESCENDING,
  requestId: SortingType.INACTIVE,
  reference: SortingType.INACTIVE,
  websiteURL: SortingType.INACTIVE,
  payerEmail: SortingType.INACTIVE,
  type: SortingType.INACTIVE,
  amount: SortingType.INACTIVE,
}