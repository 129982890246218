import React, { forwardRef, useState } from "react";
import { cssVhToPx, isDesktopEnv } from "../../../helpers";
import { HeaderButton, HeaderButtonPosition } from "../static/headerCommonDefinitions";
import { HeaderBackButton } from "./additionalComponents/HeaderBackButton";
import { MobileHeaderButton } from "./additionalComponents/MobileHeaderButton";
import { HeaderCTAButton, HeaderCTAButtonProps } from "./additionalComponents/HeaderCTAButton";

import styles from './Header.module.scss';
import mobileStyles from './HeaderMobile.module.scss';
import { HeaderMenuContainer } from "./additionalComponents/HeaderMenuContainer";
import { useHistory } from "react-router";
import { Button } from "../../button/Button";
import { ButtonColor, ButtonStyle } from "../../../static/CommonDefinitions";
import { HeaderCloseButton } from "./additionalComponents/HeaderCloseButton";

export interface HeaderProps {
  title: string;
  withBackButton?: boolean;
  withCloseButton?: boolean;
  CTAButton?: HeaderCTAButtonProps,
  secondaryButton?: HeaderSecondaryButton,
  mobileRightButton?: HeaderButton,
  mobileLeftButton?: HeaderButton,
  onMobileLeftButtonClick?: () => void,
}

export interface HeaderSecondaryButton {
  title: string;
  onClick: () => void;
}

export const Header = forwardRef<HTMLDivElement, HeaderProps>(({
  title,
  withBackButton,
  withCloseButton,
  CTAButton,
  secondaryButton,
  mobileRightButton = HeaderButton.Menu,
  mobileLeftButton = HeaderButton.None,
  onMobileLeftButtonClick,
}, ref) => {
  const history = useHistory();
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const isDesktop = isDesktopEnv();
  const targetStyles = isDesktop ? styles : mobileStyles;

  if (isDesktop) {
    return (
      <div ref={ref} className={targetStyles.header}>
        <div className={targetStyles.header__inner}>
          <div className={targetStyles.header__left_side}>
            { withBackButton && <HeaderBackButton /> }
            <span className={targetStyles.header__title}>{ title }</span>
          </div>
          <div className={targetStyles.header__right_side}>
            {
              secondaryButton && 
                <Button
                  title={secondaryButton.title}
                  handleClick={secondaryButton.onClick}
                  style={ButtonStyle.Rounded}
                  color={ButtonColor.TransparentWithBorder}
                  className={targetStyles.header__secondary_button}
                />
            }
            { CTAButton && <HeaderCTAButton { ...CTAButton } /> }
          </div>
        </div>
      </div>
    )
  }

  const handleRightButtonClick = () => {
    switch (mobileRightButton) {
      case HeaderButton.Close:
        history.goBack();
        break;
      case HeaderButton.Menu:
        setIsMobileMenuOpened(true);
        break;
    }
  }

  const handleLeftButtonClick = () => {
    onMobileLeftButtonClick && onMobileLeftButtonClick();
  }

  return (
    <div ref={ref} className={targetStyles.header}>
      <div className={targetStyles.header__inner} style={inlineSlyles['inner']}>
        <div className={targetStyles.header__top} style={inlineSlyles['top']}>
          { 
            withBackButton
              ? <HeaderBackButton />
              : <MobileHeaderButton type={mobileLeftButton} onClick={handleLeftButtonClick} position={HeaderButtonPosition.Left} />
          }
          { withCloseButton && <HeaderCloseButton /> }
          <span className={targetStyles.header__title} style={inlineSlyles['title']}>{ title }</span>
          { <MobileHeaderButton type={mobileRightButton} onClick={handleRightButtonClick} position={HeaderButtonPosition.Right} /> }
        </div>
        {
          CTAButton && 
            <div className={targetStyles.header__bottom}>
              { <HeaderCTAButton {...CTAButton} /> }
            </div>
        }
      </div>
      { !isDesktop && isMobileMenuOpened && <HeaderMenuContainer onClose={() => setIsMobileMenuOpened(false)} /> }
    </div>
  )
})

const inlineSlyles = {
  ['inner']: {
    padding: `${cssVhToPx(0.6)} 0 0`,
  },
  ['top']: {
    padding: `${cssVhToPx(2.1)} 0`,
    marginBottom: cssVhToPx(4.5),
  },
  ['title']: {
    fontSize: cssVhToPx(2.5),
  }
}