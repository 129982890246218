import { getReferenceValueFromSettings } from './../../../helpers/index';
import { selectBankDetails, selectGiftAidKeys } from './../../bankAccount/logic/bankAccountSlice';
import moment from 'moment';
import { takeLatest, select, put } from 'redux-saga/effects';
import {
    setPaymentSettings,
    Reference,
    DefaultSettings,
    setIsSettingsValid,
    selectPaymentSettings,
    selectDefaultPaymentSettings,
    defaultInputsErrors,
    setInputsErrors,
    setDefaultPaymentSettings,
    selectInputsErrors,
    setPaylinkErrorStatus,
    InputsErrors,
    selectIsSettingsValid,
    Notes,
    defaultNotesSettings,
    RequestEmail,
    ThankYouNote,
    defaultEmailSettings,
    defaultThankyouNoteSettings,
} from "./paymentRequestSlice";
import {
    NotesSettingType,
    PaymentTrigger,
    ReferenceSettingType,
    AccountType,
    TermType
} from "../static/paymentRequestConstants";
import { PayloadAction } from "@reduxjs/toolkit";
import { RootSagaSockets } from "../../../core/sagas/rootSaga";
import { WrappedSocket } from "../../../sockets/types";
import {
    PayeeSubscriberIds,
    selectAmount,
    selectDefaultAmount,
    setAmount,
    setDefaultAmount,
    setPaylink
} from "./paymentSlice";
import {removeSpecialCharacters, createId, isEmptyString, wrap} from "../../../helpers";
import { store } from "../../../core/store/configureStore";
import { push } from 'connected-react-router';
import { BankDetails, GiftAidKeys } from '../../bankAccount/static/bankAccountTypes';
import { selectSubscriptionType } from '../../subscriptionManagement/logic/subscriptionManagementSlice';
import { selectPaymentSubscriber } from '../../activity/logic/activitySlice';
import { keyPairFromSecret } from '../../../controllers/KeyController';
import { encodeBase64 } from 'tweetnacl-util';
import { REQUEST_PAYMENT_CANCEL_SUCCESS } from "../../requestActivity/logic/requestActivitySaga";
import { log } from '../../../static/Logger';
import { EndToEndIdentification } from '../static/paymentRequestCommonDefinitions';
import { PaymentSettingsMetainfo } from '../static/paymentRequestTypes';
import { sendError } from '../../../resolver/errorProcessor/errorResolver';
import { ErrorFlow, ErrorObject } from '../../../entities/errorObject';

export const UPDATE_IS_SETTINGS_VALID = 'paymentRequestSettings/update/isSettingsValid'
export const CREATE_PAYLINK_REQUEST = 'CREATE_PAYLINK_REQUEST';
export const SEND_PAYLINK_REQUEST = 'SEND_PAYLINK_REQUEST';
export const ADD_LAST_PAYMENT = 'ADD_LAST_PAYMENT';
export const CREATE_PAYLINK_SUCCESS = 'CREATE_PAYLINK_SUCCESS';
export const RESET_PAYMENT_SETTINGS = 'paymentRequestSettings/reset/paymentSettings';
export const UPDATE_DEFAULT_SETTNGS = 'paymentRequestSettings/update/defaultSettings';
export const UPDATE_EXPIRY_DATE_INPUT_ERROR = 'paymentRequestSettings/update/expiryDateInputError';
export const TRY_TO_CREATE_PAYMENT_REQUEST = 'paymentRequestSettings/tryTo/createPaymentRequest';
export const UPDATE_INPUT_ERRORS = 'paymentRequestSettings/update/inputErrors';

export interface IExpiryDate {
    expiryDate: string;
}

export interface CreatePaylinkRequestPayload {
    amount: string | null,
    orderNumber: string;
    accountNumber: string;
    accountName: string;
    sortCode: string;
    currency?: string;
}

export interface TryToCreatePaymentRequestPayload {
    onSettingValidationFail: (errors: InputsErrors) => void;
}

export interface CreatePaylinkRequest {
    type: typeof CREATE_PAYLINK_REQUEST;
    payload: CreatePaylinkRequestPayload;
}

export interface TryToCreatePaymentRequest {
    type: typeof TRY_TO_CREATE_PAYMENT_REQUEST,
    payload: TryToCreatePaymentRequestPayload,
}

export const updateIsSettingsValid = () => ({
    type: UPDATE_IS_SETTINGS_VALID,
});

export const resetPaymentSettings = () => ({
    type: RESET_PAYMENT_SETTINGS,
});

export const updateDefaultSettings = () => ({
    type: UPDATE_DEFAULT_SETTNGS,
});

export const updateExpiryDateInputError = () => ({
    type: UPDATE_EXPIRY_DATE_INPUT_ERROR,
});

export const tryToCreatePaymentRequest = (payload: TryToCreatePaymentRequestPayload): TryToCreatePaymentRequest => ({
    type: TRY_TO_CREATE_PAYMENT_REQUEST,
    payload,
});

export const createPaylinkRequest = (
    payload: CreatePaylinkRequestPayload
): CreatePaylinkRequest => ({
    type: CREATE_PAYLINK_REQUEST,
    payload,
});

export const createPaylinkSuccess = (payload: string) => ({
    type: CREATE_PAYLINK_SUCCESS,
    payload
})

export const requestPaymentCancelSuccess = () => ({
    type: REQUEST_PAYMENT_CANCEL_SUCCESS
})

export const updateInputErrors = () => ({
    type: UPDATE_INPUT_ERRORS,
});

function* dispatchUpdateIsSettingsValid() {
    const { reference, expire, amount, notes, thankYouNote } = yield select(selectPaymentSettings);
    const requestAmount: string = yield select(selectAmount);
    const inputsErrors: InputsErrors = yield select(selectInputsErrors);

    let isValid = true;

    if (amount.type !== TermType.AnyAmount && +requestAmount === 0) {
        isValid = false;
    }

    if (reference.type === ReferenceSettingType.SetByMe && isEmptyString(reference.setByMeValue)) {
        isValid = false;
    }

    if (expire.type === PaymentTrigger.XInstructions && isEmptyString(expire.xInstructionsValue)) {
        isValid = false;
    }

    if (expire.onCertainDate && (!expire.certainDate || inputsErrors.expiryDate)) {
        isValid = false;
    }

    if (notes.enabled) {
        if (notes.type === NotesSettingType.SetByMe && isEmptyString(notes.notesValue)) {
            isValid = false;
        }
    }

    if (thankYouNote.enabled && isEmptyString(thankYouNote.message)) {
        isValid = false;
    }

    yield put(setIsSettingsValid(isValid));
}

export function getPaymentSettingsObject(settings: DefaultSettings, payeePK: string): PaymentSettingsMetainfo {
    let giftAid = settings.giftAid
    if(payeePK !== '') {
        let giftAidData = {
            payee:{publicKey: payeePK}
        }
        giftAid = {...settings.giftAid, ...giftAidData}
    }

    let res = {
        encryption:{
            serviceProvider: {
                pk:"bopp-public-key"
            },
            payer:{
                pk:"payer-public-key"
            },
            payee: {
                pk:"payee-public-key"
            }
        },
        reference: {
            type: settings.reference.type,
            value: getPaymentReferenceFromReferenceSettings(settings.reference),
        },
        notes: getNotesSettings(settings.notes),
        amount: settings.amount,
        requestEmail: getRequestEmailSettings(settings.requestEmail),
        thankYouNote: getYhankyouNoteSettings(settings.thankYouNote),
        giftAid
    }

    return res;
}

function getNotesSettings(notes:Notes): Notes {
    if(notes.enabled) {
        return notes
    } else {
        return defaultNotesSettings;
    }
}

function getRequestEmailSettings(requestEmail:RequestEmail): RequestEmail {
    if(requestEmail.enabled) {
        return requestEmail
    } else {
        return defaultEmailSettings;
    }
}

function getYhankyouNoteSettings(thankYouNote:ThankYouNote): ThankYouNote {
    if(thankYouNote.enabled) {
        return thankYouNote
    } else {
        return defaultThankyouNoteSettings;
    }
}

function getAmountTermProperties(settings: DefaultSettings, amount: string | null) {
    switch (settings.amount.type) {
        case TermType.AnyAmount:
            return {
                "termType": TermType.AnyAmount,
                "properties": {
                    "@type": "https://miapago.io/paymentterms/AnyAmountProperties",
                    "min": {
                        "value": "0.01",
                        "unit": "ISO4217a:GBP"
                    },
                    "max": {
                        "value": "99999.99",
                        "unit": "ISO4217a:GBP"
                    }
                }
            }
        case TermType.MinAmount:
            return {
                "termType": "SuggestedAmount",
                "properties": {
                    "@type": "https://miapago.io/paymentterms/SuggestedAmountProperties",
                    "min": {
                        "value": amount,
                        "unit": "ISO4217a:GBP"
                    },
                    "max": {
                        "value": "99999.99",
                        "unit": "ISO4217a:GBP"
                    },
                    "range": [{
                        "value": amount,
                        "unit": "ISO4217a:GBP"
                    }],
                    "allowOther": true
                }
            }
        case TermType.SuggestedAmount:
            return {
                "termType": "SuggestedAmount",
                "properties": {
                    "@type": "https://miapago.io/paymentterms/SuggestedAmountProperties",
                    "min": {
                        "value": "0.01",
                        "unit": "ISO4217a:GBP"
                    },
                    "max": {
                        "value": "99999.99",
                        "unit": "ISO4217a:GBP"
                    },
                    "range": [{
                        "value": amount,
                        "unit": "ISO4217a:GBP"
                    }],
                    "allowOther": true
                }
            }
        case TermType.FixedAmount:
            return {
                "termType": TermType.FixedAmount,
                "properties": {
                    "@type": "https://miapago.io/paymentterms/FixedAmountProperties"
                }
            }
    }
}

function getPaymentReferenceFromReferenceSettings(referenceSettings: Reference) {
    switch (referenceSettings.type) {
        case ReferenceSettingType.SetByMe:
            return removeSpecialCharacters(referenceSettings.setByMeValue) || ''
        case ReferenceSettingType.SetByPayer:
            return ''
        case ReferenceSettingType.Autonumber:
            const ref = 'autonumber:' + removeSpecialCharacters(referenceSettings.autonumberValue) || '';
            return ref;
        default: 
            return '';
    }
}

function* dispatchPaylinkRequest( socket: WrappedSocket, { payload }: PayloadAction<CreatePaylinkRequestPayload>) {
    const bank: BankDetails = yield select(selectBankDetails);
    const settings: DefaultSettings = yield select(selectPaymentSettings);
    const subscriptionType: string = yield select(selectSubscriptionType);

    const secretGiftAid: GiftAidKeys = yield select(selectGiftAidKeys);
    let keyPair:nacl.BoxKeyPair = yield keyPairFromSecret(secretGiftAid.secretKey)
    const paymentSettings = getPaymentSettingsObject(settings, encodeBase64(keyPair.publicKey) || '');

    const { expire: expireSetting } = settings;
    const amountTerm = getAmountTermProperties(settings, payload.amount);

    let expDate = null;

    if(settings.expire.onCertainDate && settings.expire.certainDate) {
        expDate = settings.expire.certainDate
    }

    let expireType = expireSetting.type
    if(expireSetting.type === PaymentTrigger.XInstructions){
        expireType = PaymentTrigger.EachInstruction
    }

    const paymentSubscriber: string = yield select(selectPaymentSubscriber);

    let did = ( bank.accountDID)
    did = did.replace(/did:mia:ob:account:/g,'') 
    did = 'did:mia:ob:account:' + did

    try {
        const request: any = {
            '@type': 'https://dvschema.io/activation#CreateRequest',
            id: createId(true),
            entitySelector: {
                entityType: 'https://miapago.io/paylink/request/PaylinkRequest',
            },
            properties: {
                "@type": "https://miapago.io/paylink/request/Properties",
                activationLifetime: {
                    activationDate: moment().format('YYYY-MM-DD'),
                    ...(expDate && {expiryDate: expDate}),
                },
                parties: {
                    "payment:Payee": paymentSubscriber,
                    "payment:Subscriber": paymentSubscriber,
                }, 
                requestType: "Original",
                amount: {
                    value: payload.amount,
                    unit: 'ISO4217a:' + (payload.currency || 'GBP'),
                },
                paymentTerms: {
                    termsType: "Instant",
                    paymentTrigger: expireType,
                    amountTerm: amountTerm,
                    paymentMethods: [
                        {
                            methodType: "OpenBankingDomesticPayment",
                            properties: {
                                "@type": "https://miapago.io/ob/domesticpayment/Properties",
                                endToEndIdentification: EndToEndIdentification.BOPPApp,
                                name: payload.accountName,
                                accountNumber: payload.accountNumber,
                                paymentReference: paymentSettings.reference.value,
                                sortCode: payload.sortCode,
                                accountDID: did,
                                payId: "",
                                subscriptionType: subscriptionType || AccountType.Personal,
                                metainfo: JSON.stringify(paymentSettings),
                            }
                        }
                    ]
                }
            },
            timestamp: new Date(),
            possibleResend: false,
        }

        if(settings.expire.xInstructionsValue !== '' && expireSetting.type === PaymentTrigger.XInstructions) {
            request.properties.paymentTerms.amountTerm.canBeExecutedXTimes = Number(settings.expire.xInstructionsValue)
        }

        yield socket.send(wrap('paylink-initiator', request), store.dispatch);
    } catch (error) {

        yield put(sendError({error:new ErrorObject('Error resolving history incomming message ' + JSON.stringify(error), payload, ErrorFlow.payments, null)}));    
        log('ERROR: ' + error);
    }
}

function* dispatchPaylinkSuccess(action: PayloadAction<string>) {
    yield put(setPaylink(action.payload));
    
    const id = action.payload.split('/').pop();
    yield put(push(`/request-details`, {requestId: id}));
}

function* sendPaylinkRequest() {
    const paymentSettings: DefaultSettings = yield select(selectPaymentSettings);
    const amount: string = yield select(selectAmount);
    const bank: BankDetails = yield select(selectBankDetails);

    const fixedAmount = paymentSettings.amount.type !== TermType.AnyAmount

    const requestAmount = fixedAmount ? amount : null;

    yield put(createPaylinkRequest({
        amount: requestAmount,
        orderNumber: bank.orderNumber,
        accountNumber: bank.accountNumber,
        accountName: bank.accountName,
        sortCode: bank.sortCode,
    }))

    setTimeout(() => {
        log(`Can't connect to the server`)
    }, 30000);
}

function* dispatchResetPaymentSettings() {
    const defaultSettings: DefaultSettings = yield select(selectDefaultPaymentSettings);
    const defaultAmount: string = yield select(selectDefaultAmount);

    let newPaymentSettings = defaultSettings;

    if (!newPaymentSettings.expire.certainDate) {
        newPaymentSettings = {
            ...newPaymentSettings,
            expire: {
                ...newPaymentSettings.expire,
                certainDate: moment().format("yyyy-MM-DD").toString(),
            }
        }
    }

    yield put(setInputsErrors(defaultInputsErrors));
    yield put(setPaymentSettings(newPaymentSettings));
    yield put(setAmount(defaultAmount));
    yield put(updateExpiryDateInputError());
}

function* dispatchUpdateDefaultSettings() {
    const paymentSettings: DefaultSettings = yield select(selectPaymentSettings);
    const amount: string = yield select(selectAmount);

    yield put(setDefaultPaymentSettings(paymentSettings));
    yield put(setDefaultAmount(amount));
}

function* dispatchUpdateExpiryDateInputError() {
    const { expire }: DefaultSettings = yield select(selectPaymentSettings);
    const inputsErrors: InputsErrors = yield select(selectInputsErrors);

    const today = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
    const expiryDate = expire.certainDate && moment(expire.certainDate).set({hour: 0, minute: 0, second: 0, millisecond: 0});

    if (!expiryDate) {
        yield put(setInputsErrors({...inputsErrors, expiryDate: true}));
        return;
    }

    if (today > expiryDate) {
        yield put(setInputsErrors({...inputsErrors, expiryDate: true}));
    }
    else {
        yield put(setInputsErrors({...inputsErrors, expiryDate: false}));
    }
}

function* dispatchTryToCreatePaymentRequest({ payload }: TryToCreatePaymentRequest) {
    const { onSettingValidationFail } = payload;

    yield put(setPaylinkErrorStatus({
        code: 0,
        message: '',
    }));
    yield put(updateInputErrors());
    yield put(updateIsSettingsValid());

    const isSettingsValid: boolean = yield select(selectIsSettingsValid);

    if (isSettingsValid) {
        yield sendPaylinkRequest();
        yield put(updateDefaultSettings());
    }
    else {
        const errors: InputsErrors = yield select(selectInputsErrors);
        onSettingValidationFail(errors);
    }
}

function* dispatchUpdateInputErrors() {
    const { expire, amount, reference, notes, thankYouNote }: DefaultSettings = yield select(selectPaymentSettings);

    const today = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
    const expiryDate = expire.certainDate && moment(expire.certainDate).set({hour: 0, minute: 0, second: 0, millisecond: 0});
    let expiryDateError = !expiryDate || today > expiryDate;

    const getAmountError = (value: string) => isEmptyString(value) || +value < 0.01;

    yield put(setInputsErrors({
        fixedAmount: amount.type === TermType.FixedAmount && getAmountError(amount.fixedInputValue),
        suggestedAmount: amount.type === TermType.SuggestedAmount && getAmountError(amount.suggestedInputValue),
        minAmount: amount.type === TermType.MinAmount && getAmountError(amount.minInputValue),
        reference: reference.type === ReferenceSettingType.SetByMe && isEmptyString(reference.setByMeValue),
        xInstructions: expire.type === PaymentTrigger.XInstructions && isEmptyString(expire.xInstructionsValue),
        notes: notes.enabled && notes.type === NotesSettingType.SetByMe && isEmptyString(notes.notesValue),
        thankYouMessage: thankYouNote.enabled && isEmptyString(thankYouNote.message),
        expiryDate: expiryDateError,
    }));
}

export function* paymentRequestSettingsSaga({ payLinkSocket }: RootSagaSockets) {
    yield takeLatest(UPDATE_IS_SETTINGS_VALID, dispatchUpdateIsSettingsValid);
    yield takeLatest(CREATE_PAYLINK_REQUEST, dispatchPaylinkRequest, payLinkSocket);
    yield takeLatest(CREATE_PAYLINK_SUCCESS, dispatchPaylinkSuccess);
    yield takeLatest(RESET_PAYMENT_SETTINGS, dispatchResetPaymentSettings);
    yield takeLatest(UPDATE_DEFAULT_SETTNGS, dispatchUpdateDefaultSettings);
    yield takeLatest(UPDATE_EXPIRY_DATE_INPUT_ERROR, dispatchUpdateExpiryDateInputError);
    yield takeLatest(TRY_TO_CREATE_PAYMENT_REQUEST, dispatchTryToCreatePaymentRequest);
    yield takeLatest(UPDATE_INPUT_ERRORS, dispatchUpdateInputErrors);
}
