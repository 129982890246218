import { AnyAction } from "@reduxjs/toolkit";
import { createBrowserHistory } from 'history';
import createSagaMiddleware from "redux-saga";
import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootSaga from "../sagas/rootSaga";
import rootReducer, { AppState } from "./../reducers/rootReducer";
import setupSocket from "../../sockets";
import config from "../../config";
import { routerMiddleware } from "connected-react-router";
import {createMigrate}from "redux-persist";
import { migrations } from "./migrations";
import { log } from "../../static/Logger";

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  storage,
  key: 'root',
  blacklist: [],
  stateReconciler: autoMergeLevel2,
  version:0,
  migrate: createMigrate(migrations, { debug: true }),
  transforms: [
    encryptTransform({
      secretKey: config.storeEncryptKey,
      onError: (error) => log(error)
    }),
  ],
};

export const history = createBrowserHistory();

const persistedReducer = persistReducer<AppState, AnyAction>(persistConfig, rootReducer(history));
export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware)));
export const persistor = persistStore(store);

const accountsSocket = setupSocket(store.dispatch, config.accountsServerURL);
const payLinkSocket = setupSocket(store.dispatch, config.payLinkServerURL);


export const configureStore = () => {
  sagaMiddleware.run(rootSaga, { sockets: { accountsSocket, payLinkSocket }});
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
