import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/configureStore';

export enum LoginStep {
  EnterPin = 'enterPin',
  AccountNotStored = 'accountNotStored',
  ForgotPin = 'forgotPin',
  AccountNotFound = 'accountNotFound',
  Login = 'login',
  HaveBOPPAccount = 'haveBOPPAccount',
}
export enum PinState {
  Correct = 'pinCorrect',
  Incorrect = 'pinIncorrect',
  None = 'none',
}

export interface LoginState {
  loginStep:
    | LoginStep.EnterPin
    | LoginStep.AccountNotStored
    | LoginStep.ForgotPin
    | LoginStep.AccountNotFound
    | LoginStep.Login
    | LoginStep.HaveBOPPAccount;
  isBackButton: boolean;
  enteredPin: string;
  pinState: PinState;
  isReconnectWithBank: boolean;
}

const initialState: LoginState = {
  loginStep: LoginStep.AccountNotStored,
  isBackButton: false,
  enteredPin: '',
  pinState: PinState.None,
  isReconnectWithBank: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    changeStep: (state, action: PayloadAction<LoginStep>) => {
      state.isBackButton =
        action.payload !== LoginStep.EnterPin &&
        action.payload !== LoginStep.AccountNotStored;
      state.loginStep = action.payload;
    },
    setEnteredPin: (state, action: PayloadAction<string>) => {
      state.enteredPin = action.payload;
    },
    setPinState: (state, action: PayloadAction<PinState>) => {
      state.pinState = action.payload;
    },
    setIsReconnectWithBank: (state, action: PayloadAction<boolean>) => {
      state.isReconnectWithBank = action.payload;
    },
  },
});

export const {
  changeStep,
  setEnteredPin,
  setPinState,
  setIsReconnectWithBank,
} = loginSlice.actions;

export const selectLoginStep = (state: RootState) => state.login.loginStep;
export const selectIsBackButton = (state: RootState) =>
  state.login.isBackButton;
export const selectEnteredPin = (state: RootState) => state.login.enteredPin;
export const selectPinState = (state: RootState) => state.login.pinState;
export const selectIsReconnectWithBank = (state: RootState) =>
  state.login.isReconnectWithBank;

export default loginSlice.reducer;
