import React from 'react';
import { useHistory } from 'react-router';
import { cssVhToPx, isDesktopEnv } from '../../../../helpers';
import styles from '../Header.module.scss';
import mobileStyles from '../HeaderMobile.module.scss';
import CloseIcon from './../../../../assets/images/close-arrow-24px-rounded.svg';

const imgStyle = isDesktopEnv() ? {} : {
  width: cssVhToPx(4.6),
  height: cssVhToPx(4.6),
}

export const HeaderCloseButton = () => {
  const history = useHistory();
  const isDesktop = isDesktopEnv();
  const targetStyles = isDesktop ? styles : mobileStyles;

  const handleClick = () => history.goBack();

  return (
    <>
      <img src={CloseIcon} className={targetStyles.header__back_button} style={imgStyle} onClick={handleClick} />
      { isDesktop && <div className={targetStyles.header__back_button_splitter} />}
    </>
  )
}
