import React from 'react';
import classNames from 'classnames';
import styles from '../settingsReview/SettingsReview.module.scss';

import YesIcon from '../../../../../assets/images/yes-icon.svg';
import NoIcon from '../../../../../assets/images/no-icon.svg';

export interface SettingsReviewBooleanItemProps {
  title: string;
  value: boolean;
  customText?: string;
  className?: string;
}

export function SettingsReviewBooleanItem({ title, value, customText, className }: SettingsReviewBooleanItemProps) {
  const finalClassName = classNames([
      styles.settings_review_item,
      styles['settings_review_item--boolean'],
      className,
  ]);

  return (
      <div className={finalClassName}>
          <span className={styles.settings_review_item_title}>{ title }</span>
          <div className={styles.settings_review_item_value}>
              {
                customText 
                  ? <span>{customText}</span>
                  : <span>{ value ? 'enabled' : 'disabled' }</span>
              }
              <img src={value ? YesIcon : NoIcon} />
          </div>
      </div>
  )
}
