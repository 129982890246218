import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { isDesktopEnv } from '../../helpers';

import styles from './ActivationCodeInput.module.scss';

export interface IActivationCodeInput {
  label: string;
  value: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
}

export function ActivationCodeInput({
  label,
  value,
  handleChange,
  error = false,
}: IActivationCodeInput) {

  const style = classNames([
    styles.input_field,
    { [styles['input_field--mobile']]: !isDesktopEnv() },
  ]);

  return (
    <label className={style}>
      {label}
      <input
        type="text"
        value={value}
        className={error ? styles['input_field__error'] : ''}
        onChange={handleChange}
      />
    </label>
  );
}
