import { History } from "history";
import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import appSlice, { appState } from "./appSlice";
import activitySlice, { ActivityState } from "../../pages/activity/logic/activitySlice";
import bankAccountSlice, { BankAccountState } from "../../pages/bankAccount/logic/bankAccountSlice";
import chooseBankSlice, { chooseBankState } from "../../pages/chooseBank/logic/ChooseBankSlice";
import keyManagementSlice, { KeyManagementState } from "../../pages/keyManagement/logic/keyManagementSlice";
import subscriptionManagementSlice, { SubscriptionManagementState } from "../../pages/subscriptionManagement/logic/subscriptionManagementSlice";
import retrieveAccountDetailsSlice, { retrieveAccountDetailsState } from "../../pages/retrieveAccountDetails/logic/retrieveAccountDetailsSlice";
import loginSlice, { LoginState } from "../../pages/login/logic/loginSlice";
import activateAccountSlice, { ActivateAccountState } from "../../pages/activateAccount/logic/activateAccountSlice";
import chooseSubscriptionPlanSlice, { ChooseSubscriptionPlanState } from "../../pages/chooseSubscriptionPlan/logic/ChooseSubscriptionPlanSlice";
import giftAidSlice, { GiftAidState } from "../../pages/GiftAid/logic/giftAidSlice";
import paymentRequestSlice, { PaymentRequestState } from "../../pages/paymentRequest/logic/paymentRequestSlice";
import paymentSlice, {PaymentState} from "../../pages/paymentRequest/logic/paymentSlice";
import requestActivitySlice, { RequestActivityState } from "../../pages/requestActivity/logic/requestActivitySlice";
import recurringRequestActivitySlice, { RecurringRequestActivityState } from "../../pages/recurringRequestsActivity/logic/recurringRequestActivitySlice";

const rootReducer = (history: History) => combineReducers<AppState>({
  router: connectRouter(history),
  app: appSlice,
  bankAccount: bankAccountSlice,
  activity: activitySlice,
  chooseBank: chooseBankSlice,
  keyManagement: keyManagementSlice,
  subscriptionManagement: subscriptionManagementSlice,
  retrieveAccountDetails: retrieveAccountDetailsSlice,
  login: loginSlice,
  activateAccount: activateAccountSlice,
  chooseSubscriptionPlan: chooseSubscriptionPlanSlice,
  giftAid: giftAidSlice,
  paymentRequestSettings: paymentRequestSlice,
  payment: paymentSlice,
  requestActivity: requestActivitySlice,
  recurringRequestActivity: recurringRequestActivitySlice,
});

export interface AppState {
  app: appState,
  router: RouterState,
  bankAccount: BankAccountState,
  activity: ActivityState,
  chooseBank: chooseBankState,
  keyManagement: KeyManagementState,
  subscriptionManagement: SubscriptionManagementState,
  retrieveAccountDetails: retrieveAccountDetailsState,
  login: LoginState,
  activateAccount: ActivateAccountState,
  chooseSubscriptionPlan: ChooseSubscriptionPlanState,
  giftAid: GiftAidState,
  paymentRequestSettings: PaymentRequestState,
  payment: PaymentState
  requestActivity: RequestActivityState,
  recurringRequestActivity: RecurringRequestActivityState,
}

export default rootReducer;
