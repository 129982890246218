import { activityMigration } from "../../pages/activity/logic/migration"
import { giftAidMigration } from "../../pages/GiftAid/logic/migration"
import { keyManagementMigration } from "../../pages/keyManagement/logic/migration"
import { paymentMigration, paymentRequestSettingsMigration } from "../../pages/paymentRequest/logic/migration"
import { requestActivityMigration } from "../../pages/requestActivity/logic/migration"
import { retrieveAccountDetailsMigration } from "../../pages/retrieveAccountDetails/logic/migration"

export const migrations = {  
    0 : (state:any) => {    
        return {      
            ...state,      
            activity: activityMigration(state, 0),
            giftAid: giftAidMigration(state, 0),
            keyManagement: keyManagementMigration(state, 0),
            payment: paymentMigration(state, 0),
			paymentRequestSettings: paymentRequestSettingsMigration(state, 0),
            requestActivity:requestActivityMigration(state, 0),
            retrieveAccountDetails:retrieveAccountDetailsMigration(state, 0),
		}  
    }
} 
