import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import LeftArrow from '../../assets/images/back-button.svg';

import './CustomizedCalendar.scss';

export interface CustomizedCalendarProps {
  value: Date,
  onClickDay: (date: Date) => void,
  minDate?: Date,
  maxDate?: Date,
  className?: string,
  calendarRef?: React.MutableRefObject<HTMLInputElement | null>,
}

export const CustomizedCalendar = ({value, minDate, maxDate, onClickDay, className, calendarRef}: CustomizedCalendarProps) => {
  const updateTilesHeight = () => {
    Array.from(document.getElementsByClassName('react-calendar__tile')).forEach(tile => {
      tile.setAttribute('style', `width: ${getComputedStyle(tile).height}`);
    });
  }

  useEffect(updateTilesHeight, []);

  return (
    <Calendar
      inputRef={calendarRef}
      className={className}
      onActiveStartDateChange={updateTilesHeight}
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      onClickDay={onClickDay}
      view='month'
      prevLabel={
        <>
          <img src={LeftArrow} />
          <span>Prev</span>
        </>
      }
      nextLabel={
        <>
          <span>Next</span>
          <img src={LeftArrow} />
        </>
      }
    />
  )
}
