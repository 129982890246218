import { AnyAction, Dispatch } from "redux";
import {  put, select, takeLatest } from "redux-saga/effects";
import { createId, wrap } from "../../../helpers";
import { getBoppKid, getPublickeyPem2, getWalletUUID } from "../../../controllers/KeyController";
import { changeBankAuthURLRequestId, changeSelectedBankAuthURL, selectBankAuthURLRequestId, selectSelectedBank } from "./retrieveAccountDetailsSlice";
import { RootSagaSockets } from "../../../core/sagas/rootSaga";
import { WrappedSocket } from "../../../sockets/types";
import { push } from "connected-react-router";
import { log } from "../../../static/Logger";
import { GetBankAuthUrl } from "../../../entities/getBankAuthUrl";
import { CancelBankAuthUrl } from "../../../entities/cancelBankAuthUrl";
import { SendBankConsent } from "../../../entities/sendBankConsent";

export const HANDLE_BANK_CONSENT = "accountDetails/handle/bankConsent";
export const CANCEL_BANK_AUTH_URL = "accountDetails/cancel/bankAuthURL";
export const GET_BANK_AUTH_URL = "accountDetails/get/bankAuthURL";

export interface HandleBankConsentPayload {
  code: string | null,
  state: string | null,
  error: string | null,
  dispatch: Dispatch<AnyAction>,
}

export interface CancelBankAuthURLPayload {
  dispatch: Dispatch<AnyAction>,
}

export interface GetbankAuthURLPayload {
  dispatch: Dispatch<AnyAction>,
}

export interface HandleBankConsent {type: typeof HANDLE_BANK_CONSENT; payload: HandleBankConsentPayload}
export interface CancelBankAuthURL {type: typeof CANCEL_BANK_AUTH_URL; payload: CancelBankAuthURLPayload}
export interface GetBankAuthURL {type: typeof GET_BANK_AUTH_URL; payload: GetbankAuthURLPayload}

export const handleBankConsent = (payload: HandleBankConsentPayload): HandleBankConsent => ({
  type: HANDLE_BANK_CONSENT,
  payload,
})

export const cancelBankAuthURL = (payload: CancelBankAuthURLPayload): CancelBankAuthURL => ({
  type: CANCEL_BANK_AUTH_URL,
  payload,
})

export const getBankAuthURL = (payload: GetbankAuthURLPayload): GetBankAuthURL => ({
  type: GET_BANK_AUTH_URL,
  payload,
})


function* sendBankConsent(socket: WrappedSocket, code: string): any {
  const authUrlRequestId = yield select(selectBankAuthURLRequestId);
  const requestId = createId(true);

  const sendBankConsentRequest = new SendBankConsent(requestId, authUrlRequestId, code);

  const request = sendBankConsentRequest.createRequest();

  yield socket.send(wrap('DomesticPaymentInitiator', request));
}

function* handleBankConsentWatcher(socket: WrappedSocket, { payload }: HandleBankConsent): any {
  const { code, error }: HandleBankConsentPayload = payload;

  if (error) {
    yield put(push('/permission-denied'))
  }
  else {
    yield sendBankConsent(socket, code as string);
  }
}

function* cancelBankAuthURLWatcher(socket: WrappedSocket, { payload }: CancelBankAuthURL): any {
  const { dispatch } = payload;

  try {
    const authUrlRequestId = yield select(selectBankAuthURLRequestId);
    const cancelRequestId = createId(true);

    const cancelBankAuthUrlRequest = new CancelBankAuthUrl(cancelRequestId, authUrlRequestId);

    const request = cancelBankAuthUrlRequest.createRequest();

    yield socket.send(wrap('DomesticPaymentInitiator', request));
  } catch (error) {
    log('ERROR: ' + error);
  }

  dispatch(changeSelectedBankAuthURL(""));
  dispatch(changeBankAuthURLRequestId(""));
}

function* getBankAuthURLWatcher(socket: WrappedSocket, { payload }: GetBankAuthURL): any {
  const { dispatch } = payload;
  const selectedBank = yield select(selectSelectedBank);

  if (!selectedBank) {
    return;
  }

  const publicKeyPem = yield getPublickeyPem2();
  const walletUuid = getWalletUUID();
  const requestId = createId(true);

  const kid = getBoppKid();

  try {
    const getBankAuthUrlRequest = new GetBankAuthUrl(requestId, selectedBank.api, kid, walletUuid, publicKeyPem);
    const data = getBankAuthUrlRequest.createRequest();

    const wrappedData = {
      jsonrpc: '2.0',
      method: "DomesticPaymentInitiator",
      params: [data],
    }

    dispatch(changeSelectedBankAuthURL(''));

    yield socket.send(JSON.stringify(wrappedData));

    dispatch(changeBankAuthURLRequestId(requestId));
  } catch (error) {
    log('ERROR: ' + error);
  }
}

export default function* activitySaga({ accountsSocket }: RootSagaSockets) {
  yield takeLatest(HANDLE_BANK_CONSENT, handleBankConsentWatcher, accountsSocket);
  yield takeLatest(CANCEL_BANK_AUTH_URL, cancelBankAuthURLWatcher, accountsSocket);
  yield takeLatest(GET_BANK_AUTH_URL, getBankAuthURLWatcher, accountsSocket);
}
