import config from "../config";
import { InitSocket, WrappedSocket } from "./types";
import { AppDispatch} from "../core/store/configureStore";

import { HISTORY_REPORT, HISTORY_RESPONSE, FAILED_PAYLINK_REQUEST_ERROR, REQUEST_HISTORY_REPORT } from "./constants";
import { handlePaymentsResponse } from "../pages/activity/logic/activitySaga";
import { resolveAISPIncommingMessage } from "../resolver/aispProcessor/aispProcessorSaga";
import { setPaylinkErrorStatus } from "../pages/paymentRequest/logic/paymentRequestSlice";
import { createPaylinkSuccess, requestPaymentCancelSuccess } from "../pages/paymentRequest/logic/paymentRequestSaga";
import { handleRequestsResponse } from "../pages/requestActivity/logic/requestActivitySaga";
import { log } from "../static/Logger";
import { handleRecurringRequestsCancelAction, handleRecurringRequestsResponse } from "../pages/recurringRequestsActivity/logic/recurringRequestActivitySaga";

const setupSocket = (dispatch: AppDispatch, websocketUrl: string) => {
	const initSocket: InitSocket = (dispatch, data) => {
		const socket = new WebSocket(websocketUrl);

		socket.onopen = () => {
			log('Connection opened' + websocketUrl);
			if (data) {
				log('Sending ' + data)
				socket.send(data)
			}
		};

		socket.onmessage = ({ data }) => {
			if (!data) {
				dispatch(setPaylinkErrorStatus({ message: FAILED_PAYLINK_REQUEST_ERROR}));
				return;
			}

			const message = JSON.parse(data.toString());
			if(socket.url === config.accountsServerURL) {
				dispatch(resolveAISPIncommingMessage({message}))
				return;
			}


			if (message.error) {
				dispatch(setPaylinkErrorStatus({ message: message.error }));
				return;
			}

			if (!message.params || !message.params[1]) {
				dispatch(setPaylinkErrorStatus({ message: FAILED_PAYLINK_REQUEST_ERROR}));
				return;
			}

			const response = message.params[1];
			const responseContext = response['@type'];

			if (responseContext === HISTORY_REPORT || responseContext === HISTORY_RESPONSE) {
				dispatch(handlePaymentsResponse({message}));
				return;
			}

			if (responseContext === REQUEST_HISTORY_REPORT) {
				if(response?.properties?.paymentTerms) {
					const requestType = response?.properties?.paymentTerms['@type']
					if(requestType === "https://miapago.io/paymentterms/v0.1.0#RecurringPayment") {
						dispatch(handleRecurringRequestsResponse({message}));
						return;
					} else {
						dispatch(handleRequestsResponse({message}));
						return;
					}
				}
			}

			if (response['@type'].substring(response['@type'].lastIndexOf('#') + 1) === 'StatusRejected') {
				dispatch(setPaylinkErrorStatus({ message: FAILED_PAYLINK_REQUEST_ERROR}));
				return;
			}

			if (!response.state) {
				dispatch(setPaylinkErrorStatus({ message: FAILED_PAYLINK_REQUEST_ERROR}));
				return;
			}

			const state = response.state.activationState;

			//handle create request response
			switch(state) {
				case 'Activated:Accepted:PendingChange':
				case 'Activated:Accepted:PendingActive':
					dispatch(createPaylinkSuccess(response.state.paylink));
					break;
				case 'Cancelled:Accepted:NonePending':
				case 'Cancelled:Processed:NonePending':
					if(response?.properties?.paymentTerms) {
						const requestType = response?.properties?.paymentTerms['@type']
						if(requestType === "https://miapago.io/paymentterms/v0.1.0#RecurringPayment") {
							dispatch(handleRecurringRequestsCancelAction({message}));
							break
						} else {
							dispatch(requestPaymentCancelSuccess());
							break
						}
					}
					break
				case 'Rejected':
				case 'Rejected:Initialised':
					if (response.state.statusReason) {
						dispatch(setPaylinkErrorStatus({ code: response.state.statusReason.code, message: response.state.statusReason.message }));
					} else {
						dispatch(setPaylinkErrorStatus({ message: FAILED_PAYLINK_REQUEST_ERROR}));
					}
			}
		}

		socket.onerror = () => {
			log('websocket error' + socket.url);

			if (socket.url === config.payLinkServerURL) {
				dispatch(setPaylinkErrorStatus({ message: FAILED_PAYLINK_REQUEST_ERROR}));
			}
		};

		socket.onclose = (event) => {
			log('log chanel close' + socket.url);
			// socket.isClosed = true;

			// if code !== 1000 then socket didn’t close normally
			//if (event.code !== 1000 && socket.url === config.payLinkServerURL) {
			//	dispatch(setPaylinkErrorStatus({ message: FAILED_PAYLINK_REQUEST_ERROR}));
			//}
		};

		return socket;
	}
	// @ts-ignore
	// let socket = initSocket(dispatch);
  let socket: WebSocket = null;
	let asyncSend = async (data: string) => {
		if (!socket || (socket && socket.CLOSED) || socket.readyState !== 1) {
			socket = initSocket(dispatch, data);
			return;
		} else {
			log('Sending ' + data)
			return socket.send(data);
		}
	};

	let reconnect = (url: string) => {
		if (url !== websocketUrl) {
			websocketUrl = url
			//socket = initSocket(dispatch);
		}
	};

	let closeSocket = () => {
		socket.close();
	};

	let wrappedSocket: WrappedSocket = {
		send: asyncSend,
		reconnect: reconnect,
		close: closeSocket,
	};
	return wrappedSocket;
}

export default setupSocket;
