import { createId, arrayBufferToBase64 } from './../helpers'
import nacl from 'tweetnacl';
import {
    decodeUTF8,
    encodeUTF8,
    encodeBase64,
    decodeBase64
} from 'tweetnacl-util';
import config from '../config';
import { log } from '../static/Logger';

// Is this supposed to be imported like this?
const CryptoJS = require("crypto-js");

const signature = config.signature;

export const getIsKeyStored = (): boolean => {
    const wuuid = localStorage.getItem("WUUID");
    return !!wuuid;
}

export const getBoppKid = (): string => {
    let walletId = getWalletUUID();
    let keyId = getKeyUUID();
    let boppKid = 'did:mia:wallet:' + walletId + '#' + keyId
    return boppKid;
}

export const getBoppSig2 = async (): Promise<string> => {
    let keyPair = await _getKeyPair2()
    let sig = await _signKey2(keyPair)
    let b64Sig = arrayBufferToBase64(sig)
    return b64Sig;
}


export const getPublickeyPem2 = async (): Promise<string> => {
    let keyPair = await _getKeyPair2()
    let kp = getPublicKey2(keyPair.publicKey)
    return kp
}

export const getWalletUUID = (): string => {
    let wuuid = localStorage.getItem("WUUID");
    if (wuuid) {
        return wuuid;
    } else {
        let wuuid = createId(false);
        localStorage.setItem("WUUID", wuuid);
        return wuuid;
    }
}

export const getKeyUUID = (): string => {
    let kuuid = localStorage.getItem("KUUID");
    if (kuuid) {
        return kuuid;
    } else {
        let kuuid = createId(false);
        localStorage.setItem("KUUID", kuuid);
        return kuuid;
    }
}

export const KeyStorageIndex = "key_index"
async function _loadKeyPair2(): Promise<nacl.SignKeyPair | undefined> {
    let secretKeyRaw = localStorage.getItem(KeyStorageIndex)
    if (!secretKeyRaw) {
        return undefined
    }
    let secretKey = decodeBase64(secretKeyRaw)
    let keyPair = nacl.sign.keyPair.fromSecretKey(secretKey)
    return keyPair
}

async function _saveKeyPair2(keyPair: nacl.SignKeyPair): Promise<void> {
    let secretKeyRaw = encodeBase64(keyPair.secretKey)
    localStorage.setItem(KeyStorageIndex, secretKeyRaw)
}

async function _getKeyPair2(): Promise<nacl.SignKeyPair> {
    let kp = await _loadKeyPair2();

    if (kp) {
        return kp;
    } else {
        let keyPair: nacl.SignKeyPair;
        keyPair = nacl.sign.keyPair();

        await _saveKeyPair2(keyPair);

        // Encode and save signed message in the local storage
        const secretKeyRaw = encodeBase64(keyPair.secretKey);
        const secretKey = decodeBase64(secretKeyRaw);
        const signatureJson = { signature };
        const messageUint8 = decodeUTF8(JSON.stringify(signatureJson));
        const signedMessage = nacl.sign(messageUint8, secretKey);
        localStorage.setItem('signedMessage', JSON.stringify(encodeBase64(signedMessage)));

        return keyPair;
    }
}

export function getPublicKey2(key: Uint8Array): string {

    let exportedAsBase64 = encodeBase64(key)
    const pemExported = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;
    return pemExported;
}

async function _signKey2(keyPair: nacl.SignKeyPair): Promise<ArrayBuffer> {
    const signatureJson = { signature }
    const messageUint8 = decodeUTF8(JSON.stringify(signatureJson));
    const signedMessage = nacl.sign(messageUint8, keyPair.secretKey)

    return signedMessage
}

export const secureStoreKey = async (pin: string) => {
    let keyPair = await _loadKeyPair2()
    if(keyPair) {
        let secretKeyRaw = encodeBase64(keyPair.secretKey)
        var encryptedAES = CryptoJS.AES.encrypt(secretKeyRaw, pin);
        localStorage.setItem(KeyStorageIndex + 'store', encryptedAES)
    } else {
        log('handle error')
    }
}

export const signOut = () => {
    localStorage.removeItem(KeyStorageIndex)
}


export const signIn = async (pin:string) => {
    try {
        const signatureJson = { signature }
        const secretKeyRaw = localStorage.getItem(KeyStorageIndex + 'store')
        let signedMessageRaw = localStorage.getItem('signedMessage')
        if (!secretKeyRaw || !signedMessageRaw) {
            log('handle error')
        }

        // Parse message from the local storage
        const parsedSignedMessage = JSON.parse(signedMessageRaw!);
        const decodedMessage = decodeBase64(parsedSignedMessage);

        let decryptedAES = await CryptoJS.AES.decrypt(secretKeyRaw, pin);

        decryptedAES = decryptedAES.toString(CryptoJS.enc.Utf8);

        // Get key pair from the encrypted secret key
        const secretKey = decodeBase64(decryptedAES)
        const keyPair = nacl.sign.keyPair.fromSecretKey(secretKey)
        const publicKey = encodeBase64(keyPair.publicKey)
        const decodedPublicKey = decodeBase64(publicKey)

        // Open signed message from local storage and check if it's valid
        const decodedSignedMessage = nacl.sign.open(decodedMessage, decodedPublicKey)

        const isEqual = JSON.stringify(signatureJson) === JSON.stringify(JSON.parse(encodeUTF8(decodedSignedMessage!)));

        if (isEqual) {
            await _saveKeyPair2(keyPair)
        }

        return isEqual
    } catch (e:any) {
        log(e.message);

        return false;
    }
}

export const signMessage = async (msg:string): Promise<string> => {
    let keyPair = await _getKeyPair2()
    let messageUint8 = decodeUTF8(msg);
    let signedMsg = nacl.sign(messageUint8, keyPair.secretKey)
    let b64Sig = arrayBufferToBase64(signedMsg)
    return b64Sig;
}

export function clearStore() {
    localStorage.removeItem("signedMessage")
    localStorage.removeItem("WUUID")
    localStorage.removeItem("KUUID")
    localStorage.removeItem(KeyStorageIndex)
    localStorage.removeItem(KeyStorageIndex + 'store')
}

export const keyPairFromSecret = async (secret:string): Promise<nacl.BoxKeyPair> => {
    let secretKey = decodeBase64(secret)
    let keyPair = nacl.box.keyPair.fromSecretKey(secretKey)
    return keyPair
}
