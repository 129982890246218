import React from "react";
import CloseIcon from '../../assets/images/cancel-rounded-gray.svg';
import { DateRange } from "../dateRangeInput/static/dateRangeInputTypes";
import mobileStyles from './FilterTagMobile.module.scss';

export interface FilterTagProps {
  content: string | DateRange;
  onClearButtonClick: () => void;
}

export const FilterTag = ({ content, onClearButtonClick }: FilterTagProps) => {
  return (
    <div className={mobileStyles.filter_tag}>
      <span className={mobileStyles.filter_tag__text}>{ content }</span>
      <img onClick={onClearButtonClick} src={CloseIcon} className={mobileStyles.filter_tag__close_button} />
    </div>
  )
}
