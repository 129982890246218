import React from "react";
import moment from "moment";
import classNames from "classnames";
import { StylizedWrapperWithRadius } from "../stylizedWrapper/StylizedWrapperWithRadius";
import IncommingTransactionIcon from "../../assets/images/incomming-transaction.svg";
import GiftAidIcon from '../../assets/images/gift-aid-payment.svg';
import CancelTransactionIcon from "../../assets/images/cancel-transaction.svg";
import PendingIncomeIco from '../../assets/images/pending-transaction.svg';
import YesIcon from "../../assets/images/yes-icon.svg";
import NoIcon from "../../assets/images/no-icon.svg";
import { Button } from "../button/Button";
import { ButtonColor, ButtonStyle, PaymentReportState } from "../../static/CommonDefinitions";
import styles from "./PaymentDetailsPopUp.module.scss";
import mobileStyles from "./PaymentDetailsPopUpMobile.module.scss";
import { IPayment } from "../../pages/activity/logic/activitySlice";
import { formatAmountToString, isDesktopEnv, stringWithCommas } from "../../helpers";
import { decodeExecutingEntityId } from "../../utils/paymentIdDecoder";


export interface PaymentDetailsPopUpProps {
  payment: IPayment,
  onClose: () => void,
  isGiftAid?: boolean,
}

export const PaymentDetailsPopUp = ({ payment, onClose, isGiftAid = false }: PaymentDetailsPopUpProps) => {
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;
  const isSuccessPayment =
    payment.reportState === PaymentReportState.Activated ||
    payment.reportState === PaymentReportState.Confirmed;

  const getTransactionIcon = () => {
    if(payment.reportState === PaymentReportState.Activated ||
    payment.reportState === PaymentReportState.Confirmed) {
        return IncommingTransactionIcon;
    } else if(payment.reportState === PaymentReportState.PendingAccept) {
        return PendingIncomeIco;
    } else {
        return CancelTransactionIcon;
    }
  }

  const renderBooleanValue = (value: boolean) => {
    return (
      <div className={targetStyles.popup__list_boolean_value}>
        <span>{ value ? 'enabled' : 'disabled' }</span>
        <img src={ value ? YesIcon : NoIcon } alt={''}/>
      </div>
    )
  }

  const renderTitle = () => <span className={ targetStyles.popup__title }>{ isGiftAid ? 'Gift Aid Details' : 'Payment Details' }</span>; 
  
  const renderIcon = () => {
    if (isGiftAid) {
      return <img src={GiftAidIcon} className={targetStyles.popup__transaction_icon} alt={''}/>
    }

    return <img src={getTransactionIcon()} className={targetStyles.popup__transaction_icon} alt={''} />
  }

  const renderGiftAidTopInfo = () => {
    if (!isGiftAid) {
      return <></>;
    }

    return (
      <div className={targetStyles.popup__gift_aid_top_info}>
        <span className={targetStyles.popup__gift_aid_top_info_title}>Gift Aid</span>
        <div className={targetStyles.popup__gift_aid_top_info_line} />
        <span className={targetStyles.popup__gift_aid_top_info_amount}>{ formatAmountToString(+(payment.giftAid?.amount || 0)) }</span>
      </div>
    ) 
  }

  const renderGiftAidTableValue = () => {
    if (!payment.giftAid) {
      return renderBooleanValue(false);
    }

    return (
      <div className={targetStyles.popup__list_gift_aid_value}>
        <span>{payment.giftAid.donorName}</span>
        <br />
        <span>{payment.giftAid.address}</span>
        <br />
        <span>{payment.giftAid.postcode}</span>
      </div>
    )
  }

  const renderPaymentItems = () => {
    return (
      <>
        <ListItem name='Date/Time' value={ moment(payment.activityTime).format('DD.MM.YYYY HH:mm') } />
        <ListItem name='ID' value={ payment.executingEntityId ? decodeExecutingEntityId(payment.executingEntityId) : payment.id } />
        <ListItem name='Website URL' value={ payment.siteUrl } />
        <ListItem name='Payer’s Email' value={payment.payerEmail || ''} />
        {/* <ListItem name='BOPP Fee' value={'£ ' + Number(payment.fee).toFixed(2).toString()} /> */}
        <ListItem name='Notes' value={payment.notes || ''} />
        <ListItem name='Gift Aid' value={renderGiftAidTableValue()} className={payment.giftAid ? targetStyles.popup__gift_aid_item : ''} />
        <ListItem name='Receive Marketing Info' value={renderBooleanValue(payment.receiveMarketingInfo)}/>
      </>
    )
  }

  const renderGiftAidPaymentItems = () => {
    const renderAddressValue = () => {
      return (
        <div className={targetStyles.popup__list_address_value}>
          <span>{ payment.giftAid?.address || '' }</span>
          <br />
          <span>{ payment.giftAid?.postcode || '' }</span>
        </div>
      )
    }

    return (
      <>
        <ListItem name='Date/Time' value={ moment(payment.activityTime).format('DD.MM.YYYY HH:mm') } />
        <ListItem name='Donor Name' value={ payment.giftAid?.donorName || '' } />
        <ListItem name='Payer’s Email' value={ payment.payerEmail } />
        <ListItem name='Address' value={ renderAddressValue() } />
      </>
    )
  }

  const referenceClassName = classNames([
    targetStyles.popup__reference,
    isGiftAid && targetStyles['popup__reference--gift-aid'],
  ])

  return (
    <div className={targetStyles.popup}>
      <StylizedWrapperWithRadius style='bRadius12' className={targetStyles.popup__content}>
        <>
          <div className={targetStyles.popup__top}>
            { renderTitle() }
            { renderIcon() }
            <div className={targetStyles.popup__amount_block}>
              <span className={targetStyles.popup__amount}>{ '£' + stringWithCommas(Number(payment.amount.value).toFixed(2).toString()) }</span>
            </div>
            { renderGiftAidTopInfo() }
            <span className={referenceClassName}>{ payment.reference }</span>
          </div>

          <div className={targetStyles.popup__list}>
           { isGiftAid ? renderGiftAidPaymentItems() : renderPaymentItems() }
          </div>
        <div className={targetStyles.popup__close_button_container}>
          <Button
            title='Close'
            style={ButtonStyle.Rounded}
            color={ButtonColor.Yellow}
            className={targetStyles.popup__close_button}
            onClick={onClose}
          />
        </div>
      </>
    </StylizedWrapperWithRadius>
    </div>
  )
}

interface ListItemProps {
  name: string,
  value: string | JSX.Element,
  className?: string,
}

const ListItem = ({ name, value, className = '' }: ListItemProps) =>{
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  const finalClassName = classNames([
    targetStyles.list_item,
    className,
    {[targetStyles['list_item--with-element-value']]: typeof value !== 'string'}
  ]) 

  return (
    <div className={finalClassName}>
      <span className={targetStyles.list_item__name}>{ name }</span>
      <div className={targetStyles.list_item__value}>{ value }</div>
    </div>
  )
}
