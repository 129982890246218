import config from "../config";
import { common } from "./index";

interface GetBankAuthUrlRequest extends common.Activation {
    originatorId: string;
    properties: {
        "@type": string;
        serviceId: string;
        callbackURI?: string;
        targetCallback?: string;
        publicKey: {
            id: string;
            type: string;
            controller: string;
            publicKeyPem: string;
        }
    }
}

export class GetBankAuthUrl implements common.RequestCreator<GetBankAuthUrlRequest> {
    requestId: string;
    selectedBankApi: string;
    kid: string;
    walletUuid: string;
    publicKeyPem: string;

    constructor(requestId: string, selectedBankApi: string, kid: string, walletUuid: string, publicKeyPem: string) {
        this.requestId = requestId;
        this.selectedBankApi = selectedBankApi;
        this.kid = kid;
        this.walletUuid = walletUuid;
        this.publicKeyPem = publicKeyPem;
    }

    createRequest() {
        return this.getRequestParams();
    }

    getRequestParams(): GetBankAuthUrlRequest {
        return {
            "@type": "https://dvschema.io/activation/CreateRequest",
            id: this.requestId,
            originatorId: "cri:0Fmx7QoPzEji9Qrx54LzndXL3MH5VP0nsX6",
            properties: {
                "@type": "https://miapago.io/ob/accountdetails/Properties",
                serviceId: this.selectedBankApi,
                callbackURI: config.redirectUrl,
                targetCallback: window.location.href.includes('localhost') ? 'http://localhost:3000' : config.targetCallbackUrl,
                publicKey: {
                    id: this.kid,
                    type: "PS256",
                    controller: `did:mia:wallet:${this.walletUuid}`,
                    publicKeyPem: this.publicKeyPem
                },
            },
            timestamp: new Date(),
            possibleResend: false,
        };
    }
}