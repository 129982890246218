import React, {useEffect, useState} from "react";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { isDesktopEnv } from "../../../helpers";
import { BackgroundContainer } from "../../../components/background/BackgroundContainer";
import { Button } from "../../../components/button/Button";
import { selectBankDetails } from "../../bankAccount/logic/bankAccountSlice";
import { ButtonColor, ButtonStyle } from "../../../static/CommonDefinitions";
import {
    DefaultSettings,
    selectPaymentSettings,
    selectIsSettingsValid,
    selectError,
    setPaylinkErrorStatus, selectInputsErrors, InputsErrors,

} from "../logic/paymentRequestSlice";
import { selectAmount } from "../logic/paymentSlice";
import {
    resetPaymentSettings,
    updateIsSettingsValid,
    tryToCreatePaymentRequest,
} from "../logic/paymentRequestSaga";

import InfoIcon from '../../../assets/images/info-grey.svg';

import styles from './PaymentRequest.module.scss';
import mobileStyles from './PaymentRequestMobile.module.scss';
import { SettingsReview } from "./additionalComponents/settingsReview/SettingsReview";
import { RenderSettingsByOptionTab } from "./SettingsTabContent";
import { Header } from "../../../components/header/ui/Header";
import ErrorHandler from "./errorHandlerComponent/errorHandler";
import { NotesSettingType, PaymentTrigger, ReferenceSettingType, TermType } from "../static/paymentRequestConstants";

const errorCodes = [25, 26, 150];
let showServerError: NodeJS.Timeout | null = null;

export function PaymentRequestScreen() {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const dispatch = useAppDispatch();

    const bank = useAppSelector(selectBankDetails);
    const paymentSettings = useAppSelector(selectPaymentSettings);
    const amount = useAppSelector(selectAmount);
    const isSettingsValid = useAppSelector(selectIsSettingsValid);
    const errorStatus = useAppSelector(selectError);
    const isError = !!(errorStatus.code || errorStatus.message);
    const inputsErrors = useAppSelector(selectInputsErrors);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (isError || !isSettingsValid) {
            setShowSpinner(false);
        }
    }, [errorStatus.code, isSettingsValid])


    useEffect(() => {
        dispatch(updateIsSettingsValid());
    }, [paymentSettings, amount]);

    useEffect(() => {
        if (inputsErrors.expiryDate && paymentSettings.expire.onCertainDate && activeTabIndex !== 1) {
            setActiveTabIndex(1);
        }
    }, [inputsErrors.expiryDate, paymentSettings.expire.onCertainDate]);

    useEffect(() => {
       dispatch(resetPaymentSettings());
       dispatch(setPaylinkErrorStatus({
           code: 0,
           message: '',
       }));
    }, []);

    useEffect(() => {
        return () => {
            if (!showServerError) {
                return;
            }
            if (!errorStatus!.code) {
                clearTimeout(showServerError);
                return;
            }
            if (errorCodes.includes(errorStatus!.code)) {
                clearTimeout(showServerError);
            }
        }
    }, [errorStatus]);

    const buttons = ['General', 'Expiry Settings', 'Other Settings'];

    const handleActiveButton = (index: number) => {
        setActiveTabIndex(index);
    };

    const renderButtons = () => {
        return buttons.map((item, i) => {
            return (
                <Button
                    key={item}
                    title={item}
                    color={i === activeTabIndex ? ButtonColor.BlackRounded : ButtonColor.WhiteRounded}
                    handleClick={() => handleActiveButton(i)}
                />
            )
        })
    }

    const handleCreateRequestClick = () => {
        setShowSpinner(true);
        
        dispatch(tryToCreatePaymentRequest({
            onSettingValidationFail: (errors: InputsErrors) => {
                if (errors.fixedAmount || errors.suggestedAmount || errors.minAmount || errors.reference) {
                    setActiveTabIndex(0);
                }  
                else if (errors.xInstructions || errors.expiryDate) {
                    setActiveTabIndex(1);
                }
                else {
                    setActiveTabIndex(2);
                }
                
                setShowSpinner(false);
            },
        }));
        
        showServerError = setTimeout(() => {
            dispatch(setPaylinkErrorStatus({
                code: 408,
                message: "Could not connect to server"
            }))
        }, 30000);
    };

    const isRequestButtonDisabled = () => {
        if (inputsErrors.reference && paymentSettings.reference.type === ReferenceSettingType.SetByMe) {
            return true;
        }
  
        if (inputsErrors.fixedAmount && paymentSettings.amount.type === TermType.FixedAmount) {
            return true;
        }
  
        if (inputsErrors.suggestedAmount && paymentSettings.amount.type === TermType.SuggestedAmount) {
          return true;
        }
  
        if (inputsErrors.minAmount && paymentSettings.amount.type === TermType.MinAmount) {
          return true;
        }
  
        if (inputsErrors.expiryDate && paymentSettings.expire.onCertainDate) {
            return true;
        }
  
        if (inputsErrors.xInstructions && paymentSettings.expire.type === PaymentTrigger.XInstructions) {
            return true;
        }
  
        if (inputsErrors.notes && paymentSettings.notes.enabled && paymentSettings.notes.type === NotesSettingType.SetByMe) {
            return true;
        }
  
        if (inputsErrors.thankYouMessage && paymentSettings.thankYouNote.enabled) {
            return true;
        }
  
        return false;
    }

    const settingsClassName = classNames([
        styles.payment_request_settings,
        {[styles['payment_request_settings--expiry']]: activeTabIndex === 1},
    ]);

    if(isDesktopEnv()) {
        return(
            <>
                <BackgroundContainer isMenu>
                    <div className={styles.payment_request}>
                        <Header title='Request Payment' />
                        {
                            isError && <ErrorHandler error={errorStatus}/>
                        }
                        <div className={styles.payment_request_container}>
                            <div className={settingsClassName}>
                                <div className={styles.payment_request_settings_requestTo}>
                                    <h5>Request to</h5>
                                    <div className={styles.payment_request_settings_requestTo_bankInfo}>
                                            <div className={styles.payment_request_settings_requestTo_bankInfo_inner}>
                                                <img src={bank.logo} alt="Bank Logo"/>
                                            </div>
                                            <div className={styles.payment_request_settings_requestTo_bankTitle}>
                                                <h3>{bank.accountName}</h3>
                                                <span>{bank.bankName}</span>
                                            </div>
                                    </div>
                                </div>
                                <div className={styles.payment_request_settings_buttons}>
                                    { renderButtons() }
                                </div>
                                <div className={styles.payment_request_settings_settingsFields}>
                                    <RenderSettingsByOptionTab
                                        tab={activeTabIndex}
                                    />
                                </div>
                            </div>
                        {   isDesktopEnv() &&
                                <SettingsReview
                                    showLoadingSpinner={showSpinner}
                                    saveChangeSettingsAndCreatePaylink={handleCreateRequestClick}
                                    createRequestButtonDisabled={isRequestButtonDisabled()}
                                />
                        }
                        </div>
                    </div>
                </BackgroundContainer>
            </>
        )
    }
    return (
        <div className={mobileStyles.payment_request}>
            <Header title='Request Payment' />
            {isError &&
                <div className={mobileStyles.payment_request__error}>
                    <div className={mobileStyles.payment_request__error_border} />
                    <ErrorHandler error={errorStatus} />
                </div>
            }
            <div className={mobileStyles.payment_request__bank}>
                {!errorStatus && <div className={mobileStyles.payment_request__bank_border} />}

                <span className={mobileStyles.payment_request__bank_label}>Request to</span>
                <div className={mobileStyles.payment_request__bank_info}>
                    <div className={mobileStyles.payment_request__bank_logo_container}>
                        <img src={bank.logo} alt="Bank Logo"/>
                    </div>
                    <div className={mobileStyles.payment_request__bank_names}>
                        <h3>{bank.accountName}</h3>
                        <span>{bank.bankName}</span>
                    </div>
                </div>
            </div>
            <div className={mobileStyles.payment_request__settings_buttons}>
                { renderButtons() }
            </div>
            <div className={mobileStyles.payment_request__settings_fields}>
                <RenderSettingsByOptionTab
                    tab={activeTabIndex}
                />
            </div>
            <div className={mobileStyles.payment_request__bottom}>
                <Button
                    spinner={showSpinner}
                    disabled={isRequestButtonDisabled()}
                    title='Create Request'
                    color={ButtonColor.Yellow}
                    style={ButtonStyle.Rounded}
                    className={mobileStyles.payment_request__bottom_button}
                    onClick={handleCreateRequestClick}
                />
                <div className={mobileStyles.payment_request__bottom_info}>
                    <img className={mobileStyles.payment_request__bottom_info_icon} src={InfoIcon} alt={'info'}/>
                    <span className={mobileStyles.payment_request__bottom_info_text}>
                        BOPP accounts are subject to account limits.
                        <br />
                        <a
                            target="_blank"
                            href="https://help.bopp.io/portal/en/kb/articles/do-you-have-limits-for-payment-requests"
                            className={mobileStyles.payment_request__bottom_info_link}
                        >
                            Learn more
                        </a>
                    </span>
                </div>
            </div>
        </div>
    )
}

export interface SettingsProps {
    settings: DefaultSettings;
    onSettingChange: (changed: DefaultSettings) => void;
}
