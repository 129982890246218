import React from 'react';
import { useHistory } from "react-router-dom";
import { BackgroundColors, BackgroundContainer } from "../../../components/background/BackgroundContainer";
import { MobileHeader } from '../../../components/mobileHeader/MobileHeader';
import { MobileHeaderColor, MobileHeaderGradient, MobileHeaderPadding } from '../../../components/mobileHeader/static/mobileHeaderCommonDefinitions';
import { isDesktopEnv } from '../../../helpers';
import CloseIcon from "../../../assets/images/close-arrow-24px-rounded.svg";
import Cross from "../../../assets/images/cross.svg";
import styles from './ErrorScreen.module.scss';
import mobileStyles from './ErrorScreenMobile.module.scss'
import { ErrorScreenType } from '../static/errorScreenCommonDefinitions';
import { errorScreensData } from '../static/errorScreenConstants';

export interface ErrorScreenProps {
  type: ErrorScreenType,
  onCloseButtonClick?: () => void,
}

export function ErrorScreen({ type, onCloseButtonClick }: ErrorScreenProps) {
  const history = useHistory();

  const backToRetrieve = () => {
    onCloseButtonClick ? onCloseButtonClick() : history.push('/bank-account/retrieve');
  };

  const { title, text } = errorScreensData[type];
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  if (isDesktopEnv()) {
    return(
      <BackgroundContainer color={BackgroundColors.Red}>
          <div className={targetStyles.container}>
              <div className={targetStyles.container__block}>
                  <img src={Cross} alt="cross"/>
                  <p className={targetStyles.container__title}>{title}</p>
                  <p className={targetStyles.container__text}>{text}</p>
              </div>
              {
                  type !== ErrorScreenType.EnvSetup &&
                  <p className={targetStyles.container__button} onClick={backToRetrieve}>Try Again</p>
              }
          </div>
      </BackgroundContainer>
    )
  }

  return (
    <div className={targetStyles.container}>
      <MobileHeader
        withLogo
        isMenu={false}
        color={MobileHeaderColor.red}
        padding={MobileHeaderPadding.small}
        gradient={MobileHeaderGradient.none}
        rightIcon={<img src={CloseIcon} alt="Close" onClick={backToRetrieve} />}
      />
      <div className={targetStyles.container__content}>
        <div className={targetStyles.container__content_top}>
          <img src={Cross} alt="corss" className={targetStyles.container__icon} />
          <p className={targetStyles.container__title}>{title}</p>
          <p className={targetStyles.container__text}>{text}</p>
        </div>
        <div className={targetStyles.container__content_bottom}>
          {
            type !== ErrorScreenType.EnvSetup &&
            <p className={targetStyles.container__link} onClick={backToRetrieve}>Try Again</p>
          }
        </div>
      </div>
    </div>
  )
}
