import classNames from "classnames";
import { isDesktopEnv } from "../../helpers";
import styles from "./FormInput.module.scss";

export interface FormInputProps extends Record<string, any> {
  value: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  filedClassName?: string
  label: string,
  info?: string,
  error?: string,
  required?: boolean,
  name?: string,
}

export function FormInput({ value, name, onChange, onBlur, filedClassName, label, info, error, required = false, ...props }: FormInputProps) {

  const filedStyle = classNames(styles.formInput__field, filedClassName, {
    [styles["formInput__field--error"]]: error,
    [styles["formInput__field--mobile"]]: !isDesktopEnv(),

  });
  const labelStyle = classNames(styles.formInput__label, {
    [styles["formInput__label--required"]]: required,
    [styles["formInput__label--mobile"]]: !isDesktopEnv(),
  });
  const infoStyle = classNames(styles.formInput__info, {
    [styles["formInput__info--mobile"]]: !isDesktopEnv(),
  });
  const errorStyle =  classNames(styles.formInput__error, {
    [styles["formInput__error--mobile"]]: !isDesktopEnv(),
  });

  return (
    <div className={styles.formInput}>
      <span className={labelStyle}>
        { label }
      </span>
      <input
        {...props}
        name={name}
        className={filedStyle}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {info && <span className={infoStyle}>
        { info }
      </span>}
      {error && <span className={errorStyle}>
        { error }
      </span>}
    </div>
  )

}
