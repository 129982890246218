import React, { useRef, useState } from 'react';
import {  isDesktopEnv, returnDate } from '../../../helpers';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { StylizedInputForFilters, StylizedInputStyles, StylizedInputTypes } from '../../StylizedInput/StylizedInputForFilters';
import { DateRange } from '../static/dateRangeInputTypes';
import CalendarIco from '../../../assets/images/calendar.svg';
import CloseIcon from '../../../assets/images/close-arrow-24px-rounded.svg';
import styles from './DateRangeInput.module.scss';
import mobileStyles from './DateRangeInputMobile.module.scss';
import { DatePicker } from '../../datePicker/DatePicker';

export interface DateRangeInputProps {
  startDate: string;
  endDate: string;
  onRangeByDate: (filter: DateRange) => void
}

export function DateRangeInput({startDate, endDate, onRangeByDate}: DateRangeInputProps) {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const dateRangeWrap = useRef<HTMLDivElement>(null);
  const targetStyle = isDesktopEnv() ? styles : mobileStyles;

  const handleClickByDate = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleRangeByDate = (range: DateRange) => {
    onRangeByDate(range);
    setShowDatePicker(false);
  };

  const handleDateRangeOutsideClick = () => {
    setShowDatePicker(false);
  };

  useOutsideClick(dateRangeWrap, handleDateRangeOutsideClick);

  return (
      <div
          ref={dateRangeWrap}
          className={targetStyle.date_range_input}>
        <p>Date range</p>
        <div className={targetStyle.date_range_input__input}>
          <StylizedInputForFilters
              name="date"
              disabled={true}
              icon={showDatePicker && !isDesktopEnv() ? CloseIcon : CalendarIco}
              handleIconClick={() => setShowDatePicker(false)}
              handleClick={handleClickByDate}
              type={StylizedInputTypes.Text}
              style={
                showDatePicker
                    ? StylizedInputStyles.DateActive
                    : StylizedInputStyles.Date
              }
              placeholder={`${returnDate(startDate)} - ${returnDate(endDate)}`}
          />
          {showDatePicker && <DatePicker handleRange={handleRangeByDate} startDate={startDate} endDate={endDate} />}
        </div>
      </div>
  );
}
