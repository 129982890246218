enum PaymentTrigger { OnInstruction = "OnInstruction", EachInstruction = "EachInstruction", XInstructions = "XInstructions" };
enum ReferenceSettingType { SetByMe = "SetByMe", SetByPayer = "SetByPayer", Autonumber = "Autonumber" };
enum TermType { FixedAmount = "FixedAmount", AnyAmount = "AnyAmount", SuggestedAmount = "SuggestedAmount", MinAmount = "MinAmount" };
enum NotesSettingType { SetByMe = "SetByMe", SetByPayer = "SetByPayer" };
enum AccountType {Personal = "Personal", Business = "Business", Charity = "Charity"};
enum SettingsInputType {Amount = "Amount", TextInput = "TextInput", ExpiryDate = "ExpiryDate"};

export {
    PaymentTrigger,
    ReferenceSettingType,
    TermType,
    NotesSettingType,
    AccountType,
    SettingsInputType
}
