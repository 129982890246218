import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/configureStore';
import { Bank } from '../../chooseBank/logic/ChooseBankSlice';

export interface retrieveAccountDetailsState {
  selectedBank: Bank | null;
  selectedBankSupportsAISPFlow: boolean;
  selectedBankAuthURL: string;
  bankAuthURLRequestId: string;
  personaId: string;
  selectedBankIsUnavailable: boolean;
}

const initialState: retrieveAccountDetailsState = {
  selectedBank: null,
  selectedBankSupportsAISPFlow: true,
  selectedBankAuthURL: '',
  bankAuthURLRequestId: '',
  personaId: '',
  selectedBankIsUnavailable: false,
};

export const retrieveAccountDetailsSlice = createSlice({
  name: 'retrieveAccountDetails',
  initialState,
  reducers: {
    changeSelectedBank: (state, action: PayloadAction<Bank | null>) => {
      state.selectedBank = action.payload;
    },
    changeSelectedBankSupportsAISPFlow: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.selectedBankSupportsAISPFlow = action.payload;
    },
    changeSelectedBankAuthURL: (state, action: PayloadAction<string>) => {
      state.selectedBankAuthURL = action.payload;
    },
    changeBankAuthURLRequestId: (state, action: PayloadAction<string>) => {
      state.bankAuthURLRequestId = action.payload;
    },
    changePersonaId: (state, action: PayloadAction<string>) => {
      state.personaId = action.payload;
    },
    changeSelectedBankIsUnavailable: (state, action: PayloadAction<boolean>) => {
      state.selectedBankIsUnavailable = action.payload;
    },
  },
});

export const {
  changePersonaId,
  changeSelectedBank,
  changeSelectedBankAuthURL,
  changeBankAuthURLRequestId,
  changeSelectedBankSupportsAISPFlow,
  changeSelectedBankIsUnavailable,
} = retrieveAccountDetailsSlice.actions;

export const selectPersonaId = (state: RootState) =>
  state.retrieveAccountDetails.personaId;
export const selectSelectedBank = (state: RootState) =>
  state.retrieveAccountDetails.selectedBank;
export const selectSelectedBankAuthURL = (state: RootState) =>
  state.retrieveAccountDetails.selectedBankAuthURL;
export const selectBankAuthURLRequestId = (state: RootState) =>
  state.retrieveAccountDetails.bankAuthURLRequestId;

export const selectSelectedBankSupportsAISPFlow = (state: RootState) =>
  state.retrieveAccountDetails.selectedBankSupportsAISPFlow;

export const selectSelectedBankIsUnavailable = (state: RootState) =>
  state.retrieveAccountDetails.selectedBankIsUnavailable;

export default retrieveAccountDetailsSlice.reducer;
