import classNames from "classnames";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { applyTabFocusSupport, isDesktopEnv } from "../../../../../helpers";
import { selectPaymentSettings } from "../../../logic/paymentRequestSlice";
import styles from './SettingsInput.module.scss';
import mobileStyles from './SettingsInputMobile.module.scss';
import ThinCalendar from '../../../../../assets/images/thin-calendar-black.svg';
import { CustomizedCalendar } from "../../../../../components/CustomizedCalendar/CustomizedCalendar";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import { SettingsInputType } from "../../../static/paymentRequestConstants";

export interface SettingsInputProps {
  general?: boolean;
  autofill?: boolean;
  style?: string;
  placeholder?: string;
  settingType?: SettingsInputType;
  inputRequirement?: string;
  value?: string;
  error?: boolean;
  handleInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleInputValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDesktopDateInputChange?: (date: Date) => void,
}

export const SettingsInput =({
  style,
  placeholder,
  settingType,
  inputRequirement,
  general,
  autofill,
  value = '',
  error = false,
  handleInputBlur,
  handleInputValueChange,
  handleDesktopDateInputChange,
}: SettingsInputProps) => {
  const paymentSettings = useAppSelector(selectPaymentSettings);
  const [showCustomCalendar, setShowCustomCalendar] = useState(false);
  const calendarRef = useRef<HTMLInputElement>(null);
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  const commonClassName = [error ? targetStyles['input--error'] : ''];

  const handleCalendarOutsideClick = () => setShowCustomCalendar(false);

  useOutsideClick(calendarRef, handleCalendarOutsideClick);

  if (settingType === SettingsInputType.Amount) {
      const className = classNames([targetStyles.input__amount_settings_with_currency, commonClassName]);

      return (
          <div className={className}>
              <span>£</span>
              <input
                  value={value}
                  type='text'
                  className={style}
                  placeholder={placeholder}
                  onChange={handleInputValueChange}
                  onBlur={handleInputBlur}
              />
          </div>
      )
  }

  if (settingType === SettingsInputType.TextInput) {
      return (
          <>
              <input
                  type='text'
                  className={classNames([style, commonClassName])}
                  placeholder={placeholder}
                  value={value}
                  onChange={handleInputValueChange}
                  onBlur={handleInputBlur}
              />
              <span className={general ? targetStyles.input__amount_settings_input_requirement : targetStyles.input__amount_settings_input_requirement_other_setting}>{inputRequirement}</span>
          </>
      )
  }

  if (settingType === SettingsInputType.ExpiryDate) {
      const handleClick = () => setShowCustomCalendar(true);

      const handleDayClick = (day: Date) => {
          setShowCustomCalendar(false);
          handleDesktopDateInputChange && handleDesktopDateInputChange(day);
      }

      const applyDateInputTabFocus = () => isDesktopEnv() ? applyTabFocusSupport() : {};

      return (
            <div className={classNames([style, commonClassName, targetStyles.input__expiry_date_input])}>
                <div onClick={handleClick} className={targetStyles.input__expiry_date_input_inner} { ...applyDateInputTabFocus() }>
                    <span className={targetStyles.input__expiry_date_input_value}>
                        { moment(paymentSettings.expire.certainDate).format("yyyy-MM-DD") }
                    </span>
                    <img src={ThinCalendar} className={targetStyles.input__expiry_date_input_icon} />
                    {
                        !isDesktopEnv() &&
                        <input
                            type='date'
                            min={moment().format("yyyy-MM-DD")}
                            value={moment(value).format("yyyy-MM-DD")}
                            onChange={handleInputValueChange}
                        />
                    }
                </div> 
                {
                    isDesktopEnv() && showCustomCalendar &&
                    <CustomizedCalendar
                        calendarRef ={calendarRef}
                        minDate={new Date()}
                        value={moment(value).toDate()}
                        onClickDay={handleDayClick}
                        className={targetStyles.input__expiry_date_input_custom_calendar}
                    />
                }
            </div>
      )
  }

  return null;
}
