import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  BackgroundColors,
  BackgroundContainer,
} from "../../../components/background/BackgroundContainer";
import { MobileHeader } from "../../../components/mobileHeader/MobileHeader";
import {
  MobileHeaderColor,
  MobileHeaderPadding,
} from "../../../components/mobileHeader/static/mobileHeaderCommonDefinitions";
import { StylizedWrapperWithRadius } from "../../../components/stylizedWrapper/StylizedWrapperWithRadius";
import { selectRestrictedTo } from "../../activateAccount/logic/activateAccountSlice";
import { linkAnotherAccount } from "../../bankAccount/logic/bankAccountSaga";
import { SelectionModal } from "../../modals/SelectionModal/SelectionModal";
import CrossIcon from "../../../assets/images/close-icon.svg";
import CloseIcon from "../../../assets/images/close-arrow-24px-rounded.svg";
import styles from "./TooManyActivationAttempts.module.scss";
import mobileStyles from "./TooManyActivationAttemptsMobile.module.scss";
import { initActivationTimers } from "../../activateAccount/logic/activateAccountSaga";
import { isDesktopEnv } from "../../../helpers";

export function TooManyActivatioAttempts() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const restrictedTo = useAppSelector(selectRestrictedTo);
  const [showModal, setShowModal] = useState(false);
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const currentTime = +moment().format("x");
  const timeLeft = moment.duration(restrictedTo - currentTime, "milliseconds");
  const minutes = timeLeft.minutes();
  const seconds = timeLeft.seconds();

  useEffect(() => {
    dispatch(initActivationTimers());
  }, []);

  useEffect(() => {
    const callback = () => {
      const id = setTimeout(callback, 1000);
      setTimeoutId(id);
    };
    callback();
  }, []);

  useEffect(() => {
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const renderModal = () => {
    if (showModal) {
      return (
        <SelectionModal
          text="If you leave this screen your account details will not be saved and you will need to restart the process in order to use BOPP dashboard in this browser."
          optionTop="leave anyway"
          optionBottom="cancel"
          selectOptionTop={() => {
            history.push("/bank-account/retrieve");
            dispatch(linkAnotherAccount());
          }}
          selectOptionBottom={() => setShowModal(false)}
        />
      );
    }
    return <></>;
  };

  let minutesToShow = "";
  let secondsToShow = "0 seconds";

  if (minutes > 0) {
    minutesToShow = `${minutes} ${minutes > 1 ? "minutes " : "minute "}`;
  }

  if (seconds > 0) {
    secondsToShow = `${seconds} ${seconds > 1 ? "seconds" : "second"}`;
  }

  if (isDesktopEnv()) {
    return (
      <BackgroundContainer 
        color={BackgroundColors.Red}
        backButton={true}
      >
        <>
          <StylizedWrapperWithRadius
            style="square"
            className={styles.too_many_attempts}
          >
            <div className={styles.too_many_attempts__inner}>
              <img src={CrossIcon} className={styles.too_many_attempts__icon} />
              <h4 className={styles.too_many_attempts__title}>Failure</h4>
              <p className={styles.too_many_attempts__text}>
                You have made too many attempts. Please wait and try again
                later.
              </p>
              <div className={styles.too_many_attempts__countdown}>
                <span>{`Try again in ${minutesToShow}${secondsToShow}`}</span>
              </div>
            </div>
          </StylizedWrapperWithRadius>
          {renderModal()}
        </>
      </BackgroundContainer>
    );
  }

  return (
    <div className={mobileStyles.too_many_attempts}>
      <MobileHeader
        withLogo
        isMenu={false}
        color={MobileHeaderColor.red}
        rightIcon={<img src={CloseIcon} onClick={() => setShowModal(true)} />}
        padding={MobileHeaderPadding.small}
      />
      <div className={mobileStyles.too_many_attempts__center}>
        <img
          src={CrossIcon}
          alt="cross"
          className={mobileStyles.too_many_attempts__top_icon}
        />
        <h4 className={mobileStyles.too_many_attempts__title}>Failure</h4>
        <p className={mobileStyles.too_many_attempts__text}>
          You have made too many attempts. Please wait and try again later.
        </p>
      </div>
      <div className={mobileStyles.too_many_attempts__bottom}>
        <span
          className={mobileStyles.too_many_attempts__countdown}
        >{`Try again in ${minutesToShow}${secondsToShow}`}</span>
      </div>
      {renderModal()}
    </div>
  );
}
