import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../../components/button/Button";
import { TipModal } from "../../modals/tipModal/TipModal";
import InfoIconPurple from "../../../assets/images/info-purple.svg";
import { ButtonColor, ButtonStyle } from "../../../static/CommonDefinitions";
import { ChooseBankPanel } from "../../../components/chooseBankPanel/СhooseBankPanel";
import { BackgroundContainer } from "../../../components/background/BackgroundContainer";

import styles from "./RetrieveAccountDetails.module.scss";
import mobileStyles from "./RetrieveAccountDetailsMobile.module.scss";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  changeSelectedBank,
  changeSelectedBankAuthURL,
  changeSelectedBankIsUnavailable,
  selectSelectedBank,
  selectSelectedBankAuthURL,
  selectSelectedBankIsUnavailable,
  selectSelectedBankSupportsAISPFlow,
} from "../logic/retrieveAccountDetailsSlice";
import {
  cancelBankAuthURL,
  getBankAuthURL,
} from "../logic/retrieveAccountDetailsSaga";
import { SelectionModal } from "../../modals/SelectionModal/SelectionModal";
import CloseIcon from "../../../assets/images/close-arrow-24px-rounded.svg";
import { applyTabFocusSupport, cssVhToPx, isDesktopEnv } from "../../../helpers";
import { clearStore } from "../../../controllers/KeyController";
import { reset } from "../../../core/reducers/appSlice";
import classNames from "classnames";

export interface AuthUrlTimestampInfo {
  url: string;
  timestamp: number;
}

let timerId: NodeJS.Timeout;

export function RetrieveAccountDetails() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [timeModal, showTimeModal] = useState(false);
  const selectedBank = useAppSelector(selectSelectedBank);
  const authURL = useAppSelector(selectSelectedBankAuthURL);
  const selectedBankSupportsAISP = useAppSelector(
    selectSelectedBankSupportsAISPFlow
  );
  const selectedBankIsUnavailable = useAppSelector(selectSelectedBankIsUnavailable);

  const targetStyles = isDesktopEnv() ? styles : mobileStyles;
  const isWaiting = !!(selectedBank && selectedBankSupportsAISP && !authURL);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickChooseBank = () => {
    clearInterval(timerId);
    showTimeModal(false);
    history.push("/bank-account/choose");
  };

  const handleConfirmButtonClick = () => {
    clearTimeout(timerId);
    if (selectedBankSupportsAISP) {
      history.replace("/bank-account/transferring");
      window.location.replace(authURL);
    } else {
      history.push("/bank-account/link");
    }
  };

  const refreshPage = () => {
    dispatch(reset())
    clearStore()
    dispatch(getBankAuthURL({ dispatch }));
    showTimeModal(false);
  }; 

  useEffect(() => {
    if (selectedBank) {
      if (selectedBankSupportsAISP) {
        dispatch(reset())
        clearStore()
        dispatch(getBankAuthURL({ dispatch }));
      }
    } else {
      dispatch(changeSelectedBankAuthURL(""));
    }
  }, [selectedBank]);

  useEffect(() => {
    clearInterval(timerId);

    if (authURL) {
      const timestampFromStorage = localStorage.getItem("authUrlTimestamp");
      const parsedTimestamp = !!timestampFromStorage
        ? (JSON.parse(timestampFromStorage) as AuthUrlTimestampInfo)
        : null;
      const newTimestamp: AuthUrlTimestampInfo = {
        url: authURL,
        timestamp:
          parsedTimestamp?.url === authURL
            ? parsedTimestamp.timestamp
            : new Date().getTime() + 480000,
      };
      localStorage.setItem("authUrlTimestamp", JSON.stringify(newTimestamp));
    }

    const timestamp = localStorage.getItem("authUrlTimestamp");
    const parsedTimestamp = !!timestamp
      ? (JSON.parse(timestamp) as AuthUrlTimestampInfo)
      : null;

    if (authURL && authURL === parsedTimestamp?.url) {
      timerId = setInterval(() => {
        if (parsedTimestamp.timestamp < new Date().getTime()) {
          dispatch(cancelBankAuthURL({ dispatch }));
          showTimeModal(true);
          clearInterval(timerId);
        }
      }, 5000);
    }
  }, [authURL]);

  const clearSelectedBank = () => {
    dispatch(changeSelectedBank(null));
    dispatch(changeSelectedBankIsUnavailable(false));
    clearInterval(timerId);
  }

  const renderModals = () => {
    return (
      <>
        {
          selectedBankIsUnavailable &&
            <SelectionModal
              text='Bank Unavailable'
              message='This bank is unavailable at the moment. Please, choose another bank or try again later.'
              optionTop={"choose another bank"}
              optionBottom={"CANCEL"}
              selectOptionTop={() => {
                clearSelectedBank();
                history.push('/bank-account/choose');
              }}
              selectOptionBottom={clearSelectedBank}
            />
        }
        {timeModal && (
          <SelectionModal
            text={"Sorry, this page has timed out."}
            optionTop={"REFRESH PAGE"}
            optionBottom={"CANCEL"}
            selectOptionTop={refreshPage}
            selectOptionBottom={() => {
              dispatch(changeSelectedBankAuthURL(""));
              history.push("/login");
            }}
          />
        )}
        {openModal && <TipModal handleClick={handleCloseModal} />}
      </>
    );
  };

  const confirmButtonDisabled = !selectedBank || isWaiting;

  if (isDesktopEnv()) {
    return (
      <>
        {renderModals()}
        <BackgroundContainer>
          <div className={targetStyles.container}>
            <h5 className={targetStyles.container__title}>
              Retrieve Account Details
            </h5>
            <div className={targetStyles.container__text_block}>
              <p>
                To securely process payments we need to contact your bank.
                <img
                  src={InfoIconPurple}
                  alt="info"
                  onClick={handleOpenModal}
                />
              </p>
              <p>We only access the following details:</p>
            </div>
            <div className={targetStyles.container__details_block}>
              <p>- Full Legal Name</p>
              <p>- Account name</p>
              <p>- Account number & sort code</p>
            </div>
            <div className={targetStyles.container__consent_expire}>
              <p>BOPP will have access to your bank account details for the next 10 mins.</p>
            </div>
            <p className={targetStyles.container__text_black}>
              Your details will be used in line with our
              <a href="https://bopp.io/legal" target="_blank" rel="noreferrer"  { ...applyTabFocusSupport() }>
                {" "}
                Privacy Policy
              </a>
            </p>
            <div className={targetStyles.container__panel}>
              <ChooseBankPanel
                handleChooseBank={handleClickChooseBank}
                logoUri={selectedBank?.logo_uri}
                accountName={selectedBank?.accountName}
                showSpinner={isWaiting}
              />
            </div>
            <p className={targetStyles.container__text_black}>
              You will be transferred to your bank to authenticate and confirm
            </p>
            <div className={targetStyles.container__button_details}>
              <Button
                onClick={handleConfirmButtonClick}
                disabled={confirmButtonDisabled}
                title="Confirm"
                color={ButtonColor.Black}
                style={ButtonStyle.Rounded}
                buttonHeight="50px"
              />
            </div>
          </div>
        </BackgroundContainer>
      </>
    );
  }

  return (
    <>
      {renderModals()}
      <div className={targetStyles.container} style={inlineStyles['container']}>
        <header className={targetStyles.container__header} style={inlineStyles['container__header']}>
          <div className={targetStyles.container__header_top} style={inlineStyles['container__header_top']}>
            <h5 className={targetStyles.container__title} style={inlineStyles['container__title']}>
              Retrieve Account Details
            </h5>
            <img
              src={CloseIcon}
              alt=""
              onClick={() => history.goBack()}
              style={inlineStyles['container__close_icon']}
            />
          </div>
          <div className={targetStyles.container__header_bottom}>
            <p style={inlineStyles['container__header_bottom_text']}>
              To securely process payments we need to contact your bank.
              <img src={InfoIconPurple} alt="info" onClick={handleOpenModal} style={inlineStyles['container__header_bottom_icon']} />
            </p>
            <p style={inlineStyles['container__header_bottom_text']}>We only access the following details:</p>
          </div>
          <div className={targetStyles.container__center} style={inlineStyles['container__center']}>
            <div className={targetStyles.container__details_block} style={inlineStyles['container__details_block']}>
              <p>- Full Legal Name</p>
              <p>- Account name</p>
              <p>- Account number & sort code</p>
            </div>
            <div className={targetStyles.container__consent_expire} style={inlineStyles['container__consent_expire']}>
              <p>BOPP will have access to your bank account details for the next 10 mins.</p>
            </div>
            <p className={targetStyles.container__text_black} style={inlineStyles['container__text_black']}>
              Your details will be used in line with our
              <a href="https://bopp.io/legal" target="_blank" rel="noreferrer">
                {" "}
                Privacy Policy
              </a>
            </p>
          </div>
        </header>
        <div className={targetStyles.container__bottom}>
          <div className={targetStyles.container__panel} style={inlineStyles['container__panel']}>
            <ChooseBankPanel
              handleChooseBank={handleClickChooseBank}
              logoUri={selectedBank?.logo_uri}
              accountName={selectedBank?.accountName}
              showSpinner={isWaiting}
            />
          </div>
          <p
              className={classNames(targetStyles.container__text_black, targetStyles.container__text_black_bottom)}
              style={inlineStyles['container__text_black']}
          >
            You will be transferred to your bank to authenticate and confirm
          </p>
          <div className={targetStyles.container__button_details} style={inlineStyles['container__button_details']}>
            <Button
              onClick={handleConfirmButtonClick}
              disabled={confirmButtonDisabled}
              title="Confirm"
              color={ButtonColor.Black}
              style={ButtonStyle.Rounded}
              buttonHeight={cssVhToPx(8.1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const inlineStyles = {
  ['container']: {
    paddingBottom: cssVhToPx(4.2),
  },
  ['container__header']: {
    padding: `${cssVhToPx(2.4)} 5.3% 0`,
  },
  ['container__header_top']: {
    marginBottom: `${cssVhToPx(5.25)}`,
  },
  ['container__title']: {
    fontSize: cssVhToPx(3.3),
  },
  ['container__close_icon']: {
    width: cssVhToPx(3.6),
    height: cssVhToPx(3.6),
  },
  ['container__header_bottom_text']: {
    fontSize: cssVhToPx(2.7),
  },
  ['container__header_bottom_icon']: {
    width: cssVhToPx(2.55),
    height: cssVhToPx(2.7),
  },
  ['container__center']: {
    marginTop: cssVhToPx(3),
  },
  ['container__details_block']: {
    fontSize: cssVhToPx(2.7),
    padding: cssVhToPx(1.5),
  },
  ['container__consent_expire']: {
    marginTop: cssVhToPx(2.55),
    marginBottom: cssVhToPx(1.8),
    padding: `${cssVhToPx(1.8)} 10%`,
    fontSize: cssVhToPx(2.1),
  },
  ['container__text_black']: {
    fontSize: cssVhToPx(2.1), 
  },
  ['container__panel']: {
    marginBottom: cssVhToPx(2.55),
  },
  ['container__button_details']: {
    marginTop: cssVhToPx(2.1),
  },
}