import { SortTableState, TableTitles } from './activityTypes';
import { SortingType } from './activityCommonDefinitions';

export const defaultSortTableState: SortTableState = {
  amount: SortingType.INACTIVE,
  id: SortingType.INACTIVE,
  datetime: SortingType.DESCENDING,
  reference: SortingType.INACTIVE,
  url: SortingType.INACTIVE,
  payerEmail: SortingType.INACTIVE,
};

export const tableTitles: TableTitles = {
  icon: 'icon',
  datetime: 'Date/Time',
  id: "ID",
  reference: 'Reference',
  url: 'Website URL',
  payerEmail: "Payer's Email",
  notes: "Notes",
  fee: 'BOPP Fee',
  amount: 'Amount',
};

export const tableTitlesPaymentSummaryMobile: Partial<TableTitles> = {
  datetime: 'Date',
  reference: tableTitles.reference,
  amount: tableTitles.amount
}
