import React, { useRef } from 'react';
import { applyTabFocusSupport, isDesktopEnv } from '../../helpers';

import desktopStyles from "./apiKeyCheckBoxStyle.module.scss";
import mobileStyles from "./apiKeyCheckBoxStyleMobile.module.scss";

const ApiKeyCheckBox = ({onChange, checked}) => {
  const styles = isDesktopEnv() ? desktopStyles : mobileStyles
  const inputRef = useRef();

  const forceClick = () => inputRef.current?.click();

  return (
    <div className={styles.policy_label} { ...applyTabFocusSupport(forceClick) }>
          <input
            tabIndex={-1}
            ref={inputRef}          
            id="policyAcception"
            type="checkbox"
            className={styles.policy_checkbox}
            checked={checked}
            onChange={onChange}
          />
          <span className={styles.checkmark}></span>

          <p>I confirm my consent to the BOPP Checkout</p><a href='https://bopp.io/checkout-agreement' target='_blank' className={styles.link}>Terms of Sale</a>
        </div>
  )
};

export default ApiKeyCheckBox;
