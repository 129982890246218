import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../core/store/configureStore';
import { ToastDefaultInfo } from '../static/keyManagementConstants';
import { ApiKey, KeyManagementToastInfo } from '../static/keyManagementTypes';

const initialState: KeyManagementState = {
  isToastVisible: false,
  toastInfo: ToastDefaultInfo,
  apiKeys: [
  ],
};

export const keyManagementSlice = createSlice({
  name: 'keyManagement',
  initialState,
  reducers: {
    changeIsToastVisible: (state, action: PayloadAction<boolean>) => {
      state.isToastVisible = action.payload;
    },
    changeToastInfo: (state, action: PayloadAction<KeyManagementToastInfo>) => {
      state.toastInfo = action.payload;
    },
    setApiKeys: (state, action: PayloadAction<ApiKey[]>) => {
      state.apiKeys = action.payload;
    },
    addNewApiKey: (state, action: PayloadAction<ApiKey>) => {
      state.apiKeys = state.apiKeys.concat([action.payload]);
    },
    removeApiKey: (state, action: PayloadAction<string>) => {
      state.apiKeys = state.apiKeys.filter((apiKeyDetails: ApiKey) => apiKeyDetails.apiKey !== action.payload);
    }
  },
});

export const {
  changeIsToastVisible,
  changeToastInfo,
  setApiKeys,
  addNewApiKey,
  removeApiKey,
} = keyManagementSlice.actions;

export const selectIsToastVisible = (state: RootState) => state.keyManagement.isToastVisible;
export const selectToastInfo = (state: RootState) => state.keyManagement.toastInfo;
export const selectApiKeys = (state: RootState) => state.keyManagement.apiKeys;
export const selectApiKeysLength = (state: RootState) => {
  let count = 0;
  state.keyManagement.apiKeys.forEach(key => {
    if(!key.deactivated)
      count++
  })

  return count
};

export default keyManagementSlice.reducer;

export interface KeyManagementState {
  isToastVisible: boolean,
  toastInfo: KeyManagementToastInfo,
  apiKeys: ApiKey[],
}
