import React, {
  ChangeEvent,
  DetailedHTMLProps,
  FocusEvent,
  InputHTMLAttributes,
} from 'react';
import classNames from 'classnames';

import styles from './StylizedInputForFilters.module.scss';
import mobileStyles from './StylizedInputForFiltersMobile.module.scss';
import { applyTabFocusSupport, isDesktopEnv } from '../../helpers';
import ClearIcon from '../../assets/images/cancel-rounded-gray.svg';

export enum StylizedInputStyles {
  Default = 'default',
  Search = 'search',
  Date = 'date',
  DateActive = 'date--active',
  Currency = 'currency',
}
export enum StylizedInputStyleType {
  Solid = 'solid',
  Transparent = 'transparent'
}
export enum StylizedInputTypes {
  Text = 'text',
  Number = 'number',
}
export interface IStylizedInput {
  name: string;
  icon?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  useClearButton?: boolean;
  value?: string | number;
  type: StylizedInputTypes;
  style?: StylizedInputStyles;
  styleType?: StylizedInputStyleType;
  blurOnEnterPress?: boolean;
  handleClick?: (
    e: DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
  ) => void;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  handleFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  handleClear?: () => void;
  handleIconClick?: () => void;
}

export const StylizedInputForFilters = ({
  label,
  type,
  value,
  name,
  icon,
  style = StylizedInputStyles.Default,
  styleType = !isDesktopEnv() ? StylizedInputStyleType.Solid : StylizedInputStyleType.Transparent,
  blurOnEnterPress = false,
  placeholder,
  useClearButton,
  disabled,
  handleClick,
  handleChange,
  handleBlur,
  handleFocus,
  handleClear,
  handleIconClick,
}: IStylizedInput) => {
  const targetStyle = isDesktopEnv() ? styles : mobileStyles;
  const renderClearButton = useClearButton && value;

  const inputStyle = classNames(
    targetStyle.inputField__input,
    targetStyle[style],
    {[targetStyle['inputField__input--with-clear-button']]: renderClearButton},
  );

  const className = classNames([
    targetStyle.inputField,
    targetStyle[style],
    {[targetStyle['inputField--solid']]: styleType === StylizedInputStyleType.Solid},
    {[targetStyle['inputField--transparent']]: styleType === StylizedInputStyleType.Transparent},
  ]);

  const onClearButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    handleClear && handleClear();
  }

  const isDateInput = style === StylizedInputStyles.Date || style === StylizedInputStyles.DateActive;

  const applyDateTabSupport = () => {
    return isDateInput ? applyTabFocusSupport() : {};
  }

  return (
    <label className={className} onClick={handleClick} { ...applyDateTabSupport() }>
      {label && <span className={targetStyle.inputField__label}>{label}</span>}
      <input
        tabIndex={isDateInput ? -1 : 0}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={inputStyle}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (!blurOnEnterPress) {
            return;
          }

          if (e.key === 'Enter') {
            (e.target as any).blur();
          }
        }}
      />
      {icon && (
        <img src={icon} alt="" className={targetStyle.inputField__icon} onClick={handleIconClick} />
      )}
      {
        renderClearButton &&
          <img
            onClick={onClearButtonClick}
            src={ClearIcon}
            className={targetStyle.inputField__clearButton}
          />

      }
    </label>
  );
};
