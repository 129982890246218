import { EndToEndIdentification } from './../../paymentRequest/static/paymentRequestCommonDefinitions';
import {selectApiKeys} from './../../keyManagement/logic/keyManagementSlice';
import {ApiKey} from './../../keyManagement/static/keyManagementTypes.d';
import {
  IPayment,
  selectPayments,
  selectPaymentSubscriber,
  selectPendingPayments,
  updatePayments,
  updatePendingPayments
} from './../../activity/logic/activitySlice';
import {
  PaymentCancelRequestResults,
  PaymentInstructionEntityState,
  PaymentRequestStatusReason
} from './../static/requestActivityCommonDefinitions';
import {PaymentInstruction} from './../static/requestActivityTypes.d';
import {
  calculatePages,
  createId,
  numbersSortingCallback,
  stringsSortingCallback,
  wrap,
} from './../../../helpers/index';
import {WrappedSocket} from './../../../sockets/types.d';
import {AnyAction, PayloadAction} from "@reduxjs/toolkit";
import moment from "moment";
import {put, select, takeLatest} from "redux-saga/effects";
import {DateRange} from "../../../components/dateRangeInput/static/dateRangeInputTypes";
import {RangesList} from "../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions";
import {RootSagaSockets} from "../../../core/sagas/rootSaga";
import {checkIsDateInFilterRange, getDateRangeByPeriodTime, isEmptyString} from "../../../helpers";
import {SortingType} from "../../activity/static/activityCommonDefinitions";
import {PaymentRequestStatus, RequestsTableColumn} from "../static/requestActivityCommonDefinitions";
import {PaymentRequest, RequestFilters, RequestsTableSortState} from "../static/requestActivityTypes";
import {
  addCancelRequest,
  addRequest,
  deleteCancelRequest,
  RequestsApiKeys,
  selectCancelRequests,
  selectFilteredPaymentRequests,
  selectPaymentRequests,
  selectRequestsApiKeys,
  selectRequestsTableFilters,
  selectRequestsTablePaginationData,
  selectRequestsTableSorting,
  setCancelResult,
  setFilteredPaymentRequests,
  setIsCancelInProgress,
  setIsShowModalForCancel,
  setRequestsApiKeys,
  updateHistoryRequest,
  updateRequest,
  updateRequestsTableFilters,
  updateRequestsTablePaginationData,
} from "./requestActivitySlice";
import {NotesSettingType, ReferenceSettingType, TermType} from '../../paymentRequest/static/paymentRequestConstants';
import {store} from "../../../core/store/configureStore";
import {isPaylinkValid, ValidationStatuses} from "../../../utils/paylinkValidator";
import {StatusRequest} from "../../../entities/statusRequest";
import {CancelPaymentRequest} from "../../../entities/cancelPaymentRequest";
import {REQUEST_REF_SET_BY_PAYER_VALUE} from "../static/requestActivityConstants";
import {formatPaylinkUrl} from "../../../utils/paylinkUrl";
import {requestPaymentCancelSuccess} from "../../paymentRequest/logic/paymentRequestSaga";
import { log } from '../../../static/Logger';
import { StatusRequest as IStatusRequest } from "./../../../pages/requestActivity/static/requestActivityTypes";

export const FILTER_REQUESTS = 'requestActivity/filter/requests';
export const SORT_REQUESTS_TABLE = 'requestActivity/sort/table';
export const SET_REQUESTS_TABLE_FILTERS = 'requestActivity/set/tableFilters';
export const REFRESH_REQUESTS_FILTERS = 'requestActivity/refresh/filters';
export const GET_REQUESTS = 'requestActivity/get/requests';
export const GET_ALL_REQUESTS = 'requestActivity/load/getRequests';
export const HANDLE_REQUESTS_RESPONSE = 'requestActivity/handle/requestsResponse';
export const CANCEL_REQUEST = 'CANCEL_REQUEST';
export const FETCH_PAYLINK_STATUS_FOR_CANCEL_REQUEST = 'FETCH_PAYLINK_STATUS_FOR_CANCEL_REQUEST';
export const REQUEST_PAYMENT_CANCEL_SUCCESS = 'REQUEST_PAYMENT_CANCEL_SUCCESS';

export interface SetFilters {
  type: typeof SET_REQUESTS_TABLE_FILTERS;
  payload: Partial<RequestFilters>;
}

export interface HandleRequestsResponsePayload {
  message: any;
}

export interface HandleRequestsResponse {
  type: typeof HANDLE_REQUESTS_RESPONSE;
  payload: HandleRequestsResponsePayload;
}

export function filterRequestsAction(): AnyAction {return { type: FILTER_REQUESTS }}
export function sortRequestsTableAction(): AnyAction {return { type: SORT_REQUESTS_TABLE }}
export function refreshRequestsFilters(): AnyAction {return { type: REFRESH_REQUESTS_FILTERS }}
export function getAllRequests(): AnyAction {return { type: GET_ALL_REQUESTS }}
export function getRequests(): AnyAction {return { type: GET_REQUESTS }}

export const handleRequestsResponse = (payload: HandleRequestsResponsePayload): HandleRequestsResponse => ({
  type: HANDLE_REQUESTS_RESPONSE,
  payload,
});

export const setRequestsTableFilters = (payload: Partial<RequestFilters>): SetFilters => ({
  type: SET_REQUESTS_TABLE_FILTERS,
  payload,
});

export const cancelRequest = (payload: HandleRequestsResponsePayload) => ({
  type: CANCEL_REQUEST,
  payload,
});

export const fetchPaylinkStatusForCancelRequest = (payload: PaymentRequest) => ({
  type: FETCH_PAYLINK_STATUS_FOR_CANCEL_REQUEST,
  payload,
});

export const sortRequestByField = (items: PaymentRequest[], sortingType: SortingType, fieldName: keyof PaymentRequest ) => {
  if (!items.length) {
    return;
  }

  const targetFieldType = typeof items[0][fieldName]; 

  switch(fieldName) {
    case 'receivedPayments':
      items.sort((a, b) => numbersSortingCallback(a.receivedPayments.length, b.receivedPayments.length, sortingType));
      return;
    case 'creationTime':
      items.sort((a, b) => numbersSortingCallback(moment(a.creationTime).unix(), moment(b.creationTime).unix(), sortingType));
      return;
    case 'amount':
      items.sort((a, b) => numbersSortingCallback(+a.amount.value.value, +b.amount.value.value, sortingType));
      return;
  }

  switch(targetFieldType) {
    case 'string':
      log(items[0][fieldName]);
      items.sort((a, b) => stringsSortingCallback(a[fieldName] as string, b[fieldName] as string, sortingType));
      break;
    case 'number':
      items.sort((a, b) => numbersSortingCallback(a[fieldName] as number, b[fieldName] as number, sortingType));
      break;
  }
}

function filterRequestsByReference(requests: PaymentRequest[], reference:string) {
  if(reference === '') {
    return requests
  }

  let result = requests.filter(request => {
    if (reference === REQUEST_REF_SET_BY_PAYER_VALUE && !request.reference) {
      return true;
    }

    return request.reference.includes(reference)
  });

  return result
}

function filterRequestsBySearchQuery(requests: PaymentRequest[], searchQuery:string) {
  if (isEmptyString(searchQuery)) {
    return requests;
  }

  const lowerCaseQuery = searchQuery.toLowerCase();

  const result = requests.filter(request => {
    return request.reference.toLowerCase().includes(lowerCaseQuery) ||
      request.amount.value.value.includes(lowerCaseQuery) ||
      formatPaylinkUrl(request.paylink).toLowerCase().includes(lowerCaseQuery);
  });

  return result;
}

function filterRequestsByDate(requests:PaymentRequest[], date:DateRange) {
  let result = requests.filter(request => {
    return checkIsDateInFilterRange(request.creationTime, date);
  });

  return result
}


function* filterPayments(): any {
  const requests = yield select(selectPaymentRequests);

  if(requests.length) {
    const { searchQuery, reference, queryByDate }: RequestFilters = yield select(selectRequestsTableFilters);
    let filtered = filterRequestsByReference(requests, reference);
    const paginationData = yield select(selectRequestsTablePaginationData);
    filtered = filterRequestsBySearchQuery(filtered, searchQuery);
    filtered = filterRequestsByDate(filtered, queryByDate);

    const pcount = calculatePages(filtered.length, paginationData.pageLimit)
    yield put(updateRequestsTablePaginationData({totalPages:pcount, currentPage:1, paginationRangeStart:0, paginationRangeEnd:paginationData.pageLimit}));

    yield put(setFilteredPaymentRequests(filtered));
    yield put(sortRequestsTableAction());
  }
}

function* sortRequestsTableWatcher(): any {
  const filteredRequests: PaymentRequest[] = yield select(selectFilteredPaymentRequests);
  const sorting: RequestsTableSortState = yield select(selectRequestsTableSorting);
  const sortType = Object.keys(sorting).filter(
    el => sorting[el] !== SortingType.INACTIVE,
  )[0];

  const requestsCopy = filteredRequests.slice();
  let targetFieldName: keyof PaymentRequest | null = null;
  
  switch (sortType) {
    case RequestsTableColumn.CreationTime:
      targetFieldName = 'creationTime';
      break;
    case RequestsTableColumn.Status:
      targetFieldName = 'status';
      break;
    case RequestsTableColumn.Reference:
      targetFieldName = 'reference';
      break;
    case RequestsTableColumn.RequestedTo:
      targetFieldName = 'requestedTo';
      break;
    case RequestsTableColumn.AssociatedPayments:
      targetFieldName = 'receivedPayments';
      break;
    case RequestsTableColumn.Amount:
      targetFieldName = 'amount';
      break;
    case RequestsTableColumn.TotalPaid:
      targetFieldName = 'totalPaid';
      break;
  }

  if (targetFieldName) {
    sortRequestByField(requestsCopy, sorting[sortType], targetFieldName);
  }

  yield put(setFilteredPaymentRequests(requestsCopy));
}

function* setRequestsTableFiltersWatcher({ payload }: SetFilters): any {
  const filters: RequestFilters = yield select(selectRequestsTableFilters);
  yield put(updateRequestsTableFilters({...filters, ...payload}));
  yield filterPayments();
}

function* refreshRequestsFiltersWatcher() {
  yield put(setRequestsTableFilters({
    searchQuery: '',
    reference: '',
    queryByDate: getDateRangeByPeriodTime(RangesList.YearFromToday),
  }))
}

function* filterRequestsWatcher() {
  yield filterPayments();
}

function getLatestPaymentRequestDate(paymentRequests: PaymentRequest[]) : Date {
  if(paymentRequests.length > 0) {
    let pp = paymentRequests.reduce((a, b) => {
      return new Date(a.creationTime) > new Date(b.creationTime) ? a : b;
    })
    return new Date(pp.creationTime)
  } else {
    return new Date(+0)
  }
}

function* sendRequestsHistoryRequest(socket: WrappedSocket, startDate: Date) {
  const paymentSubscriber: string = yield select(selectPaymentSubscriber);
  const createdId: string = createId(true);

  if (!createdId || !paymentSubscriber) {
    return;
  }

  try {
    const request = {
      "@type": "https://dvschema.io/activation#StatusRequest",
      "id": createdId,
      "entitySelector": {
        "entityType": "https://miapago.io/paylink/request/PaylinkRequest",
        "propertiesSelector": {
          "@type": "https://miapago.io/paylink/request/PropertiesSelector",
          "parties": {
            "payment:Payee": paymentSubscriber
          },
          startDate,
        },
      },
      "timestamp": new Date().toISOString(),
      "possibleResend": false,
      'visit': true,
    }

    yield socket.send(wrap('paylink-initiator', request));
  } catch (error) {
    log('ERROR: ' + error);
  }
}

function* getRequestsWatcher(socket: WrappedSocket) {
  const requests: PaymentRequest[] = yield select(selectPaymentRequests);
  const startDate = getLatestPaymentRequestDate(requests);
  yield sendRequestsHistoryRequest(socket, startDate);
}

function* getAllRequestsWatcher(socket: WrappedSocket) {
  yield sendRequestsHistoryRequest(socket, new Date(+0));
}

function* handleRequestsResponseWatcher({ payload }: HandleRequestsResponse) {
  // check if it's for cancel request
  try {
    const cancelRequests:IStatusRequest[] = yield select(selectCancelRequests);
    const previousRequestId = payload.message.params[1].previousRequestId;
    const isResponseForCancel = cancelRequests.some((request: { id: string; }) => request.id === previousRequestId);

    if (isResponseForCancel) {
      yield put(deleteCancelRequest(previousRequestId))
      yield put(cancelRequest(payload))
      return;
    }
  } catch (e) {
    yield put(setIsShowModalForCancel(false));
    yield put(setIsCancelInProgress(false));
    yield put(setCancelResult({
      success: false,
      message: PaymentCancelRequestResults.Default
    }));
    log('ERROR: ' + e);
    return;
  }

  try {
    const request: PaymentRequest = getRequestFromResponse(payload);

    const requests: PaymentRequest[] = yield select(selectPaymentRequests);
    const isRequestExist = checkIsRequestWithIdExistsInHistory(request.id, requests);

    const requestsApiKeys: RequestsApiKeys = yield select(selectRequestsApiKeys);
    const apiKeys: ApiKey[] = yield select(selectApiKeys);
    const websiteUrl = getWebsiteUrlForApiKeyId(payload.message.params[1].properties.paymentTerms.paymentMethods[0].properties.apikey, apiKeys); 

    yield put(setRequestsApiKeys({
      ...requestsApiKeys,
      [request.id]: websiteUrl
    }));

    if (request.endToEndIdentification !== EndToEndIdentification.BOPPButton) {
      if(!isRequestExist) {
        yield put(addRequest(request));
      } else {
        yield put(updateRequest(request));
      }
    }

    yield updatePendingPaymentsInfo(request, websiteUrl);
  }
  catch(e) {
    // log(e);
  }
}

function* updatePendingPaymentsInfo(newRequest: PaymentRequest, websiteUrl: string) {
  const pendingPayments: IPayment[] = yield select(selectPendingPayments);
  const payments: IPayment[] = yield select(selectPayments);

  const paymentsWithSiteUrl: IPayment[] = [];
  const paymentsWithoutSiteUrl: IPayment[] = [];

  pendingPayments.forEach(payment => {
    if (payment.requestId === newRequest.id) {
      const updatedPayment = {
        ...payment,
        siteUrl: websiteUrl,
      };

      paymentsWithSiteUrl.push(updatedPayment);
    }
    else {
      paymentsWithoutSiteUrl.push(payment);
    }
  });

  yield put(updatePendingPayments(paymentsWithoutSiteUrl));
  yield put(updatePayments([...payments, ...paymentsWithSiteUrl]));
}

export function getWebsiteUrlForApiKeyId(apiKeyIdentifier : string, apiKeys: ApiKey[]): string  {
  let key = apiKeys.find(obj => {
    return obj.apiKey === apiKeyIdentifier;
  });

  if(!key) {
    return 'BOPP APP'
  }

  if(key.websiteURL) {
    return key.websiteURL
  }

  return 'BOPP APP'
}

export function getRequestFromResponse(resp: HandleRequestsResponsePayload): PaymentRequest {
  const response = resp.message.params[1];
  let amountType = getAmountTypeFromResponse(response);
  const state = response.state;
  const payments: PaymentInstruction[] = Object.values(state.instructions || {}).map((e: any) => ({
    id: e.id,
    entityState: e.entityState,
  }));
  //const amountTerm = response.properties.paymentTerms.amountTerm;

  const reference = state.reference || '';

  let amount = response.properties.amount;
  if(!amount) {
    amount = {value: '',unit: ''};
  }

  /*amountType = amountTerm.termType;

  const minValue = amountTerm.properties.min?.value;
  const rangeValue = amountTerm.properties.range ? amountTerm.properties.range[0].value : null;
  const isMinAmount = minValue && rangeValue;

  if (isMinAmount && (amountTerm.properties.min.value === amountTerm.properties.range[0]?.value)) {
    amountType = TermType.MinAmount;
  }*/

  const metainfo = response?.properties?.paymentTerms?.paymentMethods[0]?.properties?.metainfo;
  const defaultMetainfo = {
    notes: { notesValue: 'test', type: NotesSettingType.SetByMe },
    requestEmail: { enabled: true, displayMarketingOptIn: true },
    thankYouNote: { message: 'test' },
    giftAid: { enabled: true },
  }

  const { notes, requestEmail, thankYouNote, giftAid } = metainfo ? JSON.parse(metainfo) : defaultMetainfo;

  const request: PaymentRequest = {
    id: state.id,
    creationTime: state.initiationTime,
    requestedTo: response.properties.paymentTerms.paymentMethods[0].properties.name,
    status: isPaylinkValid(response) === ValidationStatuses.Valid ? PaymentRequestStatus.Open : PaymentRequestStatus.Closed,
    paylink: state.paylink,
    reference,
    endToEndIdentification: response.properties.paymentTerms.paymentMethods[0].properties.endToEndIdentification,
    receivedPayments: payments.filter(e => e.entityState === PaymentInstructionEntityState.Complete),
    amount: {
      type: amountType,
      value: amount
    },
    totalPaid: +state.received.value,
    paymentSettings: {
      referenceType: getReferenceType(response), 
      amountType: amountType,
      expirySettings: response.properties.paymentTerms.paymentTrigger,
      expiryDate: response.properties.activationLifetime.expiryDate,
      instructionsCount: response.properties.paymentTerms.amountTerm.canBeExecutedXTimes || '',
      notesValue: notes.type === NotesSettingType.SetByMe ? notes.notesValue : "Set by payer",
      requestEmail: {
        enabled: requestEmail.enabled,
        displayMarketingOptIn: requestEmail.displayMarketingOptIn,
      },
      thankYouNoteValue: thankYouNote.message,
      giftAid: giftAid.enabled,
    },
    requestId:''
  }
  return request;
}

function getAmountTypeFromResponse(response : any) : TermType {
  let amountTerm = response?.properties?.paymentTerms?.amountTerm?.termType;

  if(amountTerm === TermType.SuggestedAmount) {
    if(response?.properties?.paymentTerms?.paymentMethods[0]?.properties?.metainfo) {
      let metainfo = JSON.parse(response?.properties?.paymentTerms?.paymentMethods[0]?.properties?.metainfo)
      if(metainfo) {
        if(metainfo?.amount?.type === TermType.MinAmount) {
          amountTerm = TermType.MinAmount
        }
      }
    }
  } 

  return amountTerm
}

function getReferenceType(response: any): ReferenceSettingType {
  const reference = response.properties.paymentTerms.paymentMethods[0].properties.paymentReference;
  
  if (isEmptyString(reference)) {
    return ReferenceSettingType.SetByPayer;
  }

  if (reference.includes('autonumber:')) {
    return ReferenceSettingType.Autonumber;
  }
  return ReferenceSettingType.SetByMe;
}

export function checkIsRequestWithIdExistsInHistory(id: string, requests: PaymentRequest[]): boolean {
  const index = requests.findIndex(request => request.id === id);
  if (index === -1) {
    return false
  } else {
    return true
  }
}

function* dispatchCancelRequest( socket: WrappedSocket, { payload }: HandleRequestsResponse) {
  try {
    const response = payload.message.params[1];
    const requestId = response.requestId;
    const { requests } = yield select(state => ({ requests: state.requestActivity.requests }));
    const request = requests.find((element: { id: string; }) => element.id === response.state.id);

    const validationStatus = isPaylinkValid(response);

    if (validationStatus === ValidationStatuses.AlreadyPaid) {
      throw new Error(PaymentCancelRequestResults.AlreadyPaid)
    }

    if (request.status === PaymentRequestStatus.Closed ||
        request.statusReason === PaymentRequestStatusReason.Cancelled ||
        validationStatus === ValidationStatuses.Expired
    ) {
      throw new Error(PaymentCancelRequestResults.Default)
    }

    const req = {...request, status: PaymentRequestStatus.Closed, statusReason: PaymentRequestStatusReason.Cancelled};
    yield put(updateHistoryRequest(req));

    const cancelRequest = new CancelPaymentRequest(requestId, response.state.paylink).createRequest();

    yield socket.send(wrap('paylink-initiator', cancelRequest), store.dispatch);
  } catch (error:any) {
    yield put(setIsShowModalForCancel(false));
    yield put(setIsCancelInProgress(false));
    yield put(setCancelResult({
      success: false,
      message: error.message
    }));
    log('ERROR: ' + error);
  }
}

function* fetchPaylinkStatusForCancelRequestWatcher(socket: WrappedSocket, { payload }: PayloadAction<PaymentRequest>) {
  try {
    const statusRequest = new StatusRequest(payload.paylink).createRequest()

    yield put(setIsCancelInProgress(true));
    yield put(addCancelRequest(statusRequest));

    yield socket.send(wrap('paylink-initiator', statusRequest), store.dispatch);
  } catch (error) {
    yield put(setIsShowModalForCancel(false));
    yield put(setIsCancelInProgress(false));
    yield put(setCancelResult({
      success: false,
      message: PaymentCancelRequestResults.Default
    }));
    log('ERROR: ' + error);
  }
}

function* requestPaymentCancelSuccessWatcher() {
  yield put(setIsShowModalForCancel(false));
  yield put(setIsCancelInProgress(false));
  yield put(setCancelResult({
    success: true,
    message: PaymentCancelRequestResults.Cancelled
  }));
}

export default function* requestActivitySaga({ payLinkSocket }: RootSagaSockets) {
  yield takeLatest(FILTER_REQUESTS, filterRequestsWatcher);
  yield takeLatest(SORT_REQUESTS_TABLE, sortRequestsTableWatcher);
  yield takeLatest(SET_REQUESTS_TABLE_FILTERS, setRequestsTableFiltersWatcher);
  yield takeLatest(REFRESH_REQUESTS_FILTERS, refreshRequestsFiltersWatcher);
  yield takeLatest(GET_REQUESTS, getRequestsWatcher, payLinkSocket);
  yield takeLatest(GET_ALL_REQUESTS, getAllRequestsWatcher, payLinkSocket);
  yield takeLatest(HANDLE_REQUESTS_RESPONSE, handleRequestsResponseWatcher);
  yield takeLatest(CANCEL_REQUEST, dispatchCancelRequest, payLinkSocket);
  yield takeLatest(FETCH_PAYLINK_STATUS_FOR_CANCEL_REQUEST, fetchPaylinkStatusForCancelRequestWatcher, payLinkSocket);
  yield takeLatest(REQUEST_PAYMENT_CANCEL_SUCCESS, requestPaymentCancelSuccessWatcher);
}


