import React from "react";
import { applyTabFocusSupport, isDesktopEnv } from "../../../../../helpers";
import { SettingsInputType } from "../../../static/paymentRequestConstants";
import { SettingsInput } from "../settingsInput/SettingsInput";
import styles from './SettingsCheckboxButton.module.scss';
import mobileStyles from './SettingsCheckboxButtonMobile.module.scss';

export interface SettingsCheckboxButtonProps {
  checked: boolean;
  title?: string;
  subtitle?: string;
  style?: string;
  settingType?: SettingsInputType;
  inputRequirement?: string;
  placeholder?: string;
  inputValue?: string;
  inputError?: boolean;
  isOtherTab?: boolean;
  handleCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDesktopDateInputChange?: (date: Date) => void,
}

export const SettingsCheckboxButton = ({
  title,
  subtitle,
  style,
  settingType,
  checked,
  inputRequirement,
  placeholder,
  isOtherTab,
  inputValue = '',
  inputError = false,
  handleCheckboxChange,
  handleInputValueChange,
  handleDesktopDateInputChange,
}: SettingsCheckboxButtonProps) => {
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  return(
      <div className={targetStyles.settings_checkbox}>
          <input
              type="checkbox"
              checked={checked}
              id={title}
              value={title}
              onChange={handleCheckboxChange}
              className={targetStyles.settings_checkbox__input}
          />
          <label
              htmlFor={title}
              className={targetStyles.settings_checkbox__label}
              { ...applyTabFocusSupport() }
          >
          {title}
          {!!subtitle &&
          <div className={isOtherTab ? targetStyles.settings_checkbox__subtitle_others : targetStyles.settings_checkbox__subtitle}>{subtitle}</div>}
          </label>
          {(checked && !!settingType) &&
          <SettingsInput
              style={style}
              settingType={settingType}
              inputRequirement={inputRequirement}
              placeholder={placeholder}
              value={inputValue}
              error={inputError}
              handleInputValueChange={handleInputValueChange}
              handleDesktopDateInputChange={handleDesktopDateInputChange}
          />}
      </div>
  )
}
