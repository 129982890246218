import { all, fork } from "redux-saga/effects";

import initSaga from "./initSaga";
import activitySaga from "../../pages/activity/logic/activitySaga";
import bankAccountSaga from "../../pages/bankAccount/logic/bankAccountSaga";
import chooseBankSaga from "../../pages/chooseBank/logic/ChooseBankSaga";
import keyManagementSaga from "../../pages/keyManagement/logic/keyManagementSaga";
import subscriptionManagementSaga from "../../pages/subscriptionManagement/logic/subscriptionManagementSaga";
import retrieveAccountDetailsSaga from "../../pages/retrieveAccountDetails/logic/retrieveAccountDetailsSaga";
import loginSaga from "../../pages/login/logic/loginSaga";
import { WrappedSocket } from "../../sockets/types";
import aispProcessorSaga from "../../resolver/aispProcessor/aispProcessorSaga";
import activateAccountSaga from './../../pages/activateAccount/logic/activateAccountSaga'
import chooseSubscriptionPlanSaga from "../../pages/chooseSubscriptionPlan/logic/ChooseSubscriptionPlanSaga";
import giftAidSagaSaga from "../../pages/GiftAid/logic/giftAidSaga";
import {paymentRequestSettingsSaga} from "../../pages/paymentRequest/logic/paymentRequestSaga";
import requestActivitySaga from "../../pages/requestActivity/logic/requestActivitySaga";
import {errorResolver} from "../../resolver/errorProcessor/errorResolver";
import recurringRequestActivitySaga from "../../pages/recurringRequestsActivity/logic/recurringRequestActivitySaga";

export interface RootSagaParams {
  sockets: RootSagaSockets,
}
export interface RootSagaSockets {
  accountsSocket: WrappedSocket,
  payLinkSocket: WrappedSocket,
}

export default function* rootSaga({ sockets }: RootSagaParams) {
  yield all([
    fork(initSaga),
    fork(activitySaga, sockets),
    fork(chooseBankSaga),
    fork(bankAccountSaga),
    fork(aispProcessorSaga),
    fork(keyManagementSaga),
    fork(subscriptionManagementSaga),
    fork(loginSaga),
    fork(retrieveAccountDetailsSaga, sockets),
    fork(activateAccountSaga),
    fork(chooseSubscriptionPlanSaga),
    fork(giftAidSagaSaga),
    fork(requestActivitySaga, sockets),
    fork(paymentRequestSettingsSaga, sockets),
    fork(errorResolver, sockets),
    fork(recurringRequestActivitySaga, sockets),
  ]);
}
