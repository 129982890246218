export enum PaymentRequestStatus {
  Open = 'Open',
  Closed = 'Closed'
}

export enum PaymentInstructionEntityState {
  Complete = 'Complete',
  Activated = 'Activated',
  Cancelled = 'Cancelled',
}

export enum PaymentInstructionActivityState {
  Initialised = 'Initialised',
  Processing = 'Processing',
  Processed = 'Processed',
}

export enum PaymentRequestStatusReason {
    Cancelled = "Cancelled",
    ClosedAutomatically = "Closed Automatically",
    NotCancelled = "Not Cancelled"
}

export enum RequestsTableColumn {
  CreationTime = 'creationTime',
  Status = 'status',
  Reference = 'reference',
  RequestedTo = 'requestedTo',
  AssociatedPayments = 'associatedPayments',
  Amount = 'amount',
  TotalPaid = 'totalPaid',
}

export enum PaymentCancelRequestResults {
  Cancelled = 'Request Cancelled.',
  AlreadyPaid = 'Cancel failed as request has already been paid.',
  Default = 'Cancel failed. Please try again later.'
}
