import classNames from 'classnames';
import React, { forwardRef, useEffect } from 'react';
import { applyTabFocusSupport, isDesktopEnv } from '../../helpers';

import styles from './PopupList.module.scss';

export interface PopupListProps {
  className?: string;
  children?: JSX.Element[] | JSX.Element;
  onClose?: () => void;
}

export const PopupList = forwardRef<HTMLDivElement, PopupListProps>(({
  className,
  children,
  onClose
}, ref) => {
  const finalClassName = classNames([
    {[styles['popup_list--mobile']]: !isDesktopEnv()},
    styles.popup_list,
    className
  ]);

  useEffect(() => {
    const callback = () => {
      if (onClose) {
        onClose();
      }
    };

    document.addEventListener('click', callback);
    return () => document.removeEventListener('click', callback);
  }, [onClose]);

  return (
    <div ref={ref} className={finalClassName} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  )
})

export interface PopupListItemProps {
  icon: string;
  title: string;
  onClick: () => void,
}

export function PopupListItem({icon, title, onClick}: PopupListItemProps) {
  return (
    <div className={styles.popup_list__item} onClick={onClick} { ...applyTabFocusSupport() }>
      <img src={icon} className={styles.popup_list__item_icon} />
      <span className={styles.popup_list__item_title}>{title}</span>
    </div>
  )
}
