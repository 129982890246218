import { AnyAction } from "@reduxjs/toolkit";
import { put, takeLatest, select } from "redux-saga/effects";
import { ApiKey, ShowToastPayload, NewApiKeyInfo } from "../static/keyManagementTypes";
import { removeApiKey, changeIsToastVisible, changeToastInfo, setApiKeys } from "./keyManagementSlice";
import axios from 'axios';
import {selectBankDetails, selectPersonaDID} from './../../bankAccount/logic/bankAccountSlice'
import { BankDetails } from "../../bankAccount/static/bankAccountTypes";
import { getRequestHeader } from "../../../helpers";
import { RequestHeader } from "../../../static/objectTypes";
import config from "../../../config";
import { accountNotExistAction } from "../../../core/sagas/initSaga";
import { ToastIcon } from "../../../components/toast/static/toastCommonDefinitions";
import { log } from "../../../static/Logger";

export const SHOW_TOAST = "keyManagement/set/showToast";
export const CREATE_API_KEY = "keyManagement/create/apiKey";
export const DELETE_API_KEY = "keyManagement/delete/apiKey";
export const GET_EXISTING_API_KEYS = "keyManagement/get/existing/apiKeys";

export interface ShowToast { type: typeof SHOW_TOAST; payload: ShowToastPayload }
export interface AddApiKey { type: typeof CREATE_API_KEY; payload: NewApiKeyInfo }
export interface DeleteApiKey { type: typeof DELETE_API_KEY; payload: string }

export const showToast = (payload: ShowToastPayload): ShowToast => {
	return {
		type: SHOW_TOAST,
		payload,
	};
}

export const createApiKeyAction = (payload: NewApiKeyInfo): AddApiKey => {
	return {
		type: CREATE_API_KEY,
		payload,
	};
}

export const deleteApiKey = (payload: string): DeleteApiKey => {
	return {
		type: DELETE_API_KEY,
		payload,
	};
}

export const getExistingApiKeysAction = (): AnyAction => {return {type: GET_EXISTING_API_KEYS}}

function* showToastWatcher({ payload }: ShowToast) {
	const { title, icon } = payload;

	yield put(changeToastInfo({ title, icon }));
	yield put(changeIsToastVisible(true));
}

function* deleteApiKeyWatcher({ payload }: DeleteApiKey) {
	const personaDID: string = yield select(selectPersonaDID);
	const header:RequestHeader = yield getRequestHeader()

	try {
		yield axios.delete(config.dashboardServerUrl + '/persona/' + personaDID + '/key/' + payload, {headers: header});

		yield put(removeApiKey(payload));
	} catch (e:any) {
		if(e.response.data.error === 'PersonaDeactivated') {
            yield put(accountNotExistAction())
            return
        }
		log(e);
	}
}

function* createApiKey({ payload }: AddApiKey) {
	const bankDetails: BankDetails = yield select(selectBankDetails);
	const personaDID: string = yield select(selectPersonaDID);
    const accountDID = bankDetails.accountDID
	const header:RequestHeader = yield getRequestHeader()

	let env = 'master'
	if(config.appEnvironment === 'SANDBOX')
		env = 'dev'
	else if(config.appEnvironment === 'UAT') {
		env = 'uat'
	} else if(config.appEnvironment === 'PROD') {
		env = 'prod'
	}

	try {
		const {data}= yield axios.post(config.dashboardServerUrl + '/persona/' + personaDID + '/key',
		{
			"@type": "https://miapago.io/merchant#APIKey",
			"env": env,
			"name": payload.name,
			"description": payload.description,
			"metainfo": {
				"@type": "https://miapago.io/merchant#IFrameKey",
				"accountDID": accountDID,
				"origin": payload.websiteURL
			}
		}, {headers: header});

		let keys = grabApiKeysFromResponse(data)
		yield put(setApiKeys(keys))

		yield put(showToast({
			title: "Your API Key has been created. You can now embed it to your website.",
			icon: ToastIcon.Success,
		}));
	} catch (e:any) {
		if(e.response.data.error === 'PersonaDeactivated') {
            yield put(accountNotExistAction())
            return
        }

		yield put(showToast({
			title: "Server is not responding. Please try again.",
			icon: ToastIcon.Warning,
		}));
    	log(e);
    }
}

function* getExistingApiKeys() {
	const personaDID: string = yield select(selectPersonaDID);
	const header:RequestHeader = yield getRequestHeader()

	try {
		const {data} = yield axios.get(config.dashboardServerUrl + '/persona/' + personaDID, {headers: header});

		let keys = grabApiKeysFromResponse(data)
		yield put(setApiKeys(keys))
	} catch (e:any) {
		if(e.response.data.error === 'PersonaDeactivated') {
            yield put(accountNotExistAction())
            return
        }
		log(e);
	}
}

export function grabApiKeysFromResponse(response: any): ApiKey[] {
	let keys:ApiKey[] = []

	if(response.data.property.apiKeys != null) {
		if(response.data.property.apiKeys.length > 0){
			for (let element of response.data.property.apiKeys) {
				let key:ApiKey = {
					env: element.env,
					name: element.name,
					apiKey: element.apiKey,
					websiteURL: element.metainfo.origin,
					timeStamp: element.created,
					description: element.description,
					deactivated: element.deactivated,
				}
				keys.push(key)
			}
		}
	}

	return keys
}

export default function* keyManagementSaga() {
	yield takeLatest(CREATE_API_KEY, createApiKey);
	yield takeLatest(SHOW_TOAST, showToastWatcher);
	yield takeLatest(DELETE_API_KEY, deleteApiKeyWatcher);
	yield takeLatest(GET_EXISTING_API_KEYS, getExistingApiKeys);
}
