import { select, takeLatest, put } from "redux-saga/effects";
import {
  clearBankAccountState,
  selectBankDetails,
  selectPersonaDID,
  setBankDetails,
  setPersonaDID,
} from "./bankAccountSlice";
import { BankDetails } from "../static/bankAccountTypes";
import {
  changeSelectedBankAuthURL,
  selectSelectedBank,
} from "../../retrieveAccountDetails/logic/retrieveAccountDetailsSlice";
import axios from "axios";
import config from "../../../config";
import { push } from "connected-react-router";
import {
  changeStep,
  LoginStep,
  selectIsReconnectWithBank,
  setIsReconnectWithBank,
} from "./../../login/logic/loginSlice";
import { setAuthCodeActivationStatus } from "../../activateAccount/logic/activateAccountSlice";
import { AuthCodeActivationStatus } from "../../activateAccount/static/activateAccountCommonDefinitions";
import {
  changeSubscriptionId,
  changeSubscriptionPlan,
  changeSubscriptionType,
  changeUserSubscriptionId,
} from "../../subscriptionManagement/logic/subscriptionManagementSlice";
import { setPaymentSubscriber } from "../../activity/logic/activitySlice";
import { setApiKeys } from "../../keyManagement/logic/keyManagementSlice";
import { grabApiKeysFromResponse } from "../../keyManagement/logic/keyManagementSaga";
import { getRequestHeader, sortCodeRemoveDash } from "../../../helpers";
import { RequestHeader } from "../../../static/objectTypes";
import { grabSubscriptionPlanDetails } from "../../subscriptionManagement/logic/subscriptionManagementSaga";
import { Bank } from "../../chooseBank/logic/ChooseBankSlice";
import { accountNotExistAction } from "../../../core/sagas/initSaga";
import { getGiftAidKeysAction } from "../../activateAccount/logic/activateAccountSaga";
import { log } from "../../../static/Logger";

export const ADD_BANK_ACCOUNT = "bankAccount/add/bankAccount";
export const ADD_ACCOUNT = "bankAccount/add/account";
export const LINK_ANOTHER_ACCOUNT = "bankAccount/link/anotherAccount";
export const QUERY_ACCOUNT_DETAILS = "bankAccount/query/details";

export interface AddBankAccountPayload {
  response: any;
}

export interface QueryAccountDetailsPayload {
  accountDID: string;
}

export interface AddBankAccount {
  type: typeof ADD_BANK_ACCOUNT;
  payload: AddBankAccountPayload;
}
export interface LinkAnotherAccount {
  type: typeof LINK_ANOTHER_ACCOUNT;
}
export interface QueryAccountDetails {
  type: typeof QUERY_ACCOUNT_DETAILS;
  payload: QueryAccountDetailsPayload;
}

export const addBankAccount = (
  payload: AddBankAccountPayload
): AddBankAccount => ({
  type: ADD_BANK_ACCOUNT,
  payload,
});

export const linkAnotherAccount = (): LinkAnotherAccount => ({
  type: LINK_ANOTHER_ACCOUNT,
});

export const queryAccountDetails = (
  payload: QueryAccountDetailsPayload
): QueryAccountDetails => ({
  type: QUERY_ACCOUNT_DETAILS,
  payload,
});

function* getBankDetails(response: any): any {
  const selectedBank = yield select(selectSelectedBank);
  const { state } = response;
  const account = state.accounts[0];
  const innerAccount = account.Account[0];
  const details = parseAccountDetails(innerAccount);

  return {
    accountId: account.AccountId,
    currency: account.Currency,
    accountType: account.AccountType,
    accountSubType: account.AccountSubType,
    accountNickname: details.accountNickName,
    accountName: details.accountName,
    bankName: selectedBank?.friendly_name as string,
    schemeName: account.Account[0].SchemeName,
    identification: account.Account[0].Identification,
    status: account.Status,
    accountDID: state.accountDID,
    payeeUniqueIdentifier: state.payeeUniqueIdentifier,
    sortCode: details.sortCode,
    accountNumber: details.accountNumber,
    logo: selectedBank?.logo_uri as string,
  } as BankDetails;
}

function* addBankAccountWatcher({ payload }: AddBankAccount): any {
  const { response } = payload;
  const { state } = response;

  if (!response.nonintegrated && state.accounts.length !== 1) {
    yield put(push('/error-save-bank-account'))
    return;
  }

  const isReconnectWithBank = yield select(selectIsReconnectWithBank);
  const bankAccountDetails: BankDetails = yield getBankDetails(response);

  yield put(setBankDetails(bankAccountDetails));

  const bankDetails: BankDetails = yield select(selectBankDetails);
  const accountDID = bankDetails.accountDID;
  const header: RequestHeader = yield getRequestHeader();

  try {
    const { data } = yield axios.post(
      config.dashboardServerUrl + "/persona/recover",
      { accountDID: accountDID },
      { headers: header }
    );

    let personaDID = data.data.id;
    let subscriptionPlan = grabSubscriptionPlanDetails(data);
    const subscriptionType = data.data.property.subscriptionType;
    let subscriptionId = data.data.property.id;
    let paymentSubscriber =
      data.data.property.parties[0].PartyRoles["payment:Payee"];

    let keys = grabApiKeysFromResponse(data);
    yield put(setApiKeys(keys));

    yield put(setPersonaDID(personaDID));
    yield put(changeSubscriptionPlan(subscriptionPlan));
    yield put(changeSubscriptionId(subscriptionId));
    yield put(changeUserSubscriptionId(data.data.property.subscriptionId));
    yield put(setPaymentSubscriber(paymentSubscriber));
    yield put(changeSubscriptionType(subscriptionType));
    
    if(!isReconnectWithBank) {
      yield put(setIsReconnectWithBank(true))
    }

    yield put(getGiftAidKeysAction())
    yield put(push("/bank-account/added"));
  } catch (e:any) {
    if(e.response.data.error === 'PersonaDeactivated') {
      yield put(accountNotExistAction())
      return
    }
    log(e);
    if(isReconnectWithBank) {
      yield put(changeStep(LoginStep.AccountNotFound));
      yield put(push("/login"));
    } else {
      yield put(getGiftAidKeysAction())
      yield put(push("/bank-account/added"));
    }
  }
}

function* linkAnotherAccountWatcher() {
  yield put(setAuthCodeActivationStatus(AuthCodeActivationStatus.pending));
  yield put(changeSelectedBankAuthURL(""));
  yield put(clearBankAccountState());
}

function* queryAccountDetailsWatcher({ payload }: QueryAccountDetails): any {
  const { accountDID } = payload;
  const selectedBank: Bank = yield select(selectSelectedBank);
  const personaDID = yield select(selectPersonaDID);
  const headers = yield getRequestHeader();

  const { data } = yield axios.get(
    config.dashboardServerUrl +
      "/persona/" +
      personaDID +
      "/account/" +
      accountDID,
    { headers }
  );

  const details = data.data;
  const account = details.account[0];
  const parsedAccountDetails = parseAccountDetails(account);

  const scode = sortCodeRemoveDash(parsedAccountDetails.sortCode)

  if(parsedAccountDetails.accountNumber.length!==8 || parsedAccountDetails.accountName.trim().length===0 || scode.length!==6){
    yield put(push('/error-save-account-details'))
  }

  yield put(
    setBankDetails({
      accountId: details.accountId,
      currency: details.currency,
      accountType: details.accountType,
      accountSubType: details.accountSubType,
      accountName: account.name,
      accountNickname: details.nickname,
      bankName: selectedBank.friendly_name,
      schemeName: account.schemeName,
      identification: account.identification,
      status: details.status,
      accountDID: details.id,
      payeeUniqueIdentifier: account.id,
      accountNumber: parsedAccountDetails.accountNumber,
      sortCode: parsedAccountDetails.sortCode,
      logo: selectedBank.logo_uri,
      orderNumber: '',
    })
  );

  yield put(getGiftAidKeysAction())
}

function parseAccountDetails(account: any) {
  const identification = account.Identification || account.identification;
  const accountNumber = identification.slice(6)

  let sortCode = (
    String(identification.slice(0, 6)).match(/.{1,2}/g) || []
  ).join("-");
  let accountName = account.Name || account.name;
  let accountNickName = account.Name || account.name;
  // if Coutts, why it needed for Couts, is there any better way to do it ?
  if (sortCode === "18-00-02") {
    let tmpAccountName = accountName.split("-");
    if (tmpAccountName.length > 1) {
      accountName = tmpAccountName[tmpAccountName.length - 1];
    }
    let tmpAccountNickName = accountNickName.split("-");
    if (tmpAccountNickName.length > 1) {
      accountNickName = tmpAccountNickName[tmpAccountNickName.length - 1];
    }
  }

  return { sortCode, accountName, accountNickName, accountNumber };
}

export default function* bankAccountSaga() {
  yield takeLatest(ADD_BANK_ACCOUNT, addBankAccountWatcher);
  yield takeLatest(LINK_ANOTHER_ACCOUNT, linkAnotherAccountWatcher);
  yield takeLatest(QUERY_ACCOUNT_DETAILS, queryAccountDetailsWatcher);
}
