import React from "react";
import SwitchEnable from '../../../../../assets/images/switcher-enable.svg';
import SwitchDisable from '../../../../../assets/images/switcher-disable.svg';
import { useAppSelector } from "../../../../../app/hooks";
import { applyTabFocusSupport, isDesktopEnv } from "../../../../../helpers";
import { DefaultSettings, selectPaymentSettings } from "../../../logic/paymentRequestSlice";
import styles from './SettingsSwitchButton.module.scss';
import mobileStyles from './SettingsSwitchButtonMobile.module.scss';
import { ActiveSwitchSettingsVariants } from "../../settingsVariants/activeSwitchSettingsVariants/ActiveSwitchSettingsVariants";

export type settingsKey = 'RequestEmail' | 'Notes' | 'ThankYouNote' | 'GiftAid';

export interface SettingsSwitchButtonProps {
    title: string;
    subtitle: string;
    contentType: string;
    active: boolean;
    settings: DefaultSettings;
    onSettingChange: (changed: DefaultSettings) => void;
}

export const SettingsSwitchButton = ({ title, subtitle, contentType, onSettingChange, settings, active }: SettingsSwitchButtonProps) => {
    const paymentSettings = useAppSelector(selectPaymentSettings);
    const targetStyles = isDesktopEnv() ? styles : mobileStyles;

    const handleEnableSetting = () => {
        const settingsKeys = {
            RequestEmail: 'requestEmail',
            Notes: 'notes',
            ThankYouNote: 'thankYouNote',
            GiftAid: 'giftAid',
        }

        const key = settingsKeys[(contentType as settingsKey)];

        return onSettingChange({
            ...paymentSettings,
            [key]: {
                ...paymentSettings[key as Uncapitalize<settingsKey>],
                enabled: !active
            }
        })
    }

    return(
        <>
            <div className={targetStyles.switch_button}>
                <img
                    src={active ? SwitchEnable : SwitchDisable}
                    onClick={handleEnableSetting}
                    { ...applyTabFocusSupport() }
                />
                <div className={targetStyles.switch_button_text_container}>
                    <span className={targetStyles.switch_button_title}>{title}</span>
                    <span className={targetStyles.switch_button_subtitle}>{subtitle}</span>
                </div>
            </div>
            { active && contentType !== 'GiftAid' &&
            <div className={contentType === 'ThankYouNote' ? targetStyles.switch_button_tyn : targetStyles.switch_button_is_active}>
                <ActiveSwitchSettingsVariants
                    contentType={contentType}
                    settings={settings}
                    onSettingChange={onSettingChange}
                />
            </div> }
        </>
    )
}
