enum ButtonStyle {Link = "link", Square = "square", Rounded = "rounded", Login = "login", SquareWithIco = 'squareWithIco', RoundedWithIco = 'roundedWithIco', LittleRounded = "littleRounded"}
enum ButtonColor {Bordered = "bordered", Link = "link", Black = "black", Yellow = "yellow", DarkYellow = "darkYellow", YellowInactive = "yellowInactive", Transparent = "transparent", ModalYellow = "modalYellow", White='white', WhiteRounded='whiteRounded', BlackRounded='blackRounded', TransparentWithBorder='transparentWithBorder'}
enum PaymentReportState {Activated  = "Activated", Received = "Received", PendingAccept = "PendingAccept", Created = "Created", Confirmed = "Confirmed", Cancelled = "Cancelled", CancelledOnBank = "CancelledOnBank"}
enum StatusBank {Unhealthy="unhealthy", Healthy="healthy"}
enum SubscriptionType {Charity = "charity", Business = "business"}
enum PaymentRequestType {Standard = "Standard", Recurring = "Recurring"}

export {
    ButtonStyle,
    ButtonColor,
    PaymentReportState,
    StatusBank,
    SubscriptionType,
    PaymentRequestType
}
