import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { isDesktopEnv, isEmptyString, isLastCharacterIsSpace } from '../../../../../helpers';
import { validateAutonumberPrefix, validateSetByMeReference } from '../../../../../helpers/dataFieldsValidation';
import { selectInputsErrors, selectPaymentSettings, setInputsErrors } from '../../../logic/paymentRequestSlice';
import { ReferenceSettingType, SettingsInputType } from '../../../static/paymentRequestConstants';
import { SettingsRadioButton } from '../../additionalComponents/settingsRadioButton/SettingsRadioButton';
import { SettingsProps } from '../../PaymentRequest';

import styles from '../../PaymentRequest.module.scss';
import mobileStyles from '../../PaymentRequestMobile.module.scss';

export function ReferenceSettings({ settings, onSettingChange }: SettingsProps) {
  const dispatch = useAppDispatch();
  const paymentSettings = useAppSelector(selectPaymentSettings);
  const inputsErrors = useAppSelector(selectInputsErrors);

  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  const referenceTypes = useMemo(() => [
      {
          title: 'Set by me',
          referenceType: ReferenceSettingType.SetByMe,
          inputRequirement: 'Max 18 chars - letters, numbers and spaces only',
          placeholder: 'Add a payment reference..'
      },
      {
          title: 'Set by payer',
          referenceType: ReferenceSettingType.SetByPayer
      },
      {
          title: 'Autonumber',
          referenceType: ReferenceSettingType.Autonumber,
          inputRequirement: 'Max 8 chars - letters and numbers only',
          subtitle: 'Each request will be assigned a unique no. You can add a prefix below.',
          placeholder: 'Add request prefix...'
      }
  ], []);

  const onInputValueChange = (value: string, itemType: string) => {
      if (itemType === ReferenceSettingType.SetByMe) {
          if (!validateSetByMeReference(value)) {
              return;
          }

          onSettingChange({
              ...paymentSettings,
              reference: {
                  ...paymentSettings.reference,
                  setByMeValue: value
              }
          })

          dispatch(setInputsErrors({...inputsErrors, reference: false}));
      } else {
          if (!validateAutonumberPrefix(value)) {
              return;
          }

          onSettingChange({
              ...paymentSettings,
              reference: {
                  ...paymentSettings.reference,
                  autonumberValue: value
              }
          })
      }
  }

  const onInputBlur = (referenceType: ReferenceSettingType) => {
      if (referenceType === ReferenceSettingType.SetByMe) {
          const setByMeValue = paymentSettings.reference.setByMeValue;

          dispatch(setInputsErrors({...inputsErrors, reference: isEmptyString(setByMeValue)}));

          if (isLastCharacterIsSpace(setByMeValue)) {
              const formattedValue = setByMeValue.substring(0, setByMeValue.length - 1);
              onInputValueChange(formattedValue, ReferenceSettingType.SetByMe);
          }
      }
  }

  const getValueForInput = (referenceType: ReferenceSettingType) => {
      return referenceType === ReferenceSettingType.SetByMe ?
          paymentSettings.reference.setByMeValue :
          paymentSettings.reference.autonumberValue;
  }

  return(
      <div className={targetStyles.amount_settings}>
          <h5 className={targetStyles.amount_settings_header}>Payment Reference</h5>
          <div className={targetStyles.amount_settings_container}>
              {referenceTypes.map((item) => {
                  const checked = settings.reference.type === item.referenceType;
                  return(
                      <SettingsRadioButton
                          key={item.referenceType}
                          title={item.title}
                          checked={checked}
                          value={item.referenceType}
                          handleRadioChange={(e) => {
                              onSettingChange({
                                  ...paymentSettings,
                                  reference: {
                                      ...paymentSettings.reference,
                                      type: e.target.value as ReferenceSettingType
                                  }})
                          }}
                          handleInputValueChange={(e) => {
                              onInputValueChange(e.target.value, item.referenceType)
                          }}
                          handleInputBlur={() => onInputBlur(item.referenceType)}
                          inputValue={getValueForInput(item.referenceType)}
                          inputError={item.referenceType === ReferenceSettingType.SetByMe && inputsErrors.reference}
                          showInput={item.referenceType !== ReferenceSettingType.SetByPayer}
                          style={targetStyles.amount_settings_reference_input}
                          placeholder={item.placeholder}
                          settingType={SettingsInputType.TextInput}
                          subtitle={item.subtitle}
                          inputRequirement={item.inputRequirement}
                          general={true}
                          autofill={item.referenceType === ReferenceSettingType.SetByMe}
                      />
                  )
              })}
          </div>
      </div>
  )
}
