import React, { useMemo } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { isDesktopEnv } from "../../../../../helpers";
import { validateOrganizationName } from "../../../../../helpers/dataFieldsValidation";
import { selectPaymentSettings } from "../../../logic/paymentRequestSlice";
import { SettingsInputType } from "../../../static/paymentRequestConstants";
import { SettingsCheckboxButton } from "../../additionalComponents/settingsCheckboxButton/SettingsCheckboxButton";
import { SettingsVariantProps } from "../activeSwitchSettingsVariants/ActiveSwitchSettingsVariants";
import styles from '../CommonSettingsStyles.module.scss';
import mobileStyles from '../CommonSettingsStylesMobile.module.scss';

export const EmailSettingsVariant = ({ settings, onSettingChange }: SettingsVariantProps) => {
  const paymentSettings = useAppSelector(selectPaymentSettings);
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  const items = useMemo(() => [
		{
				title: 'Mandatory',
				subtitle: 'User won’t be able to pay if they don’t supply email.',
				checked: settings.requestEmail.mandatory,
		},
		{
				title: 'Display marketing opt-in',
				subtitle: 'Payers will be asked to opt-in to your marketing communications. You can add your organisation name to our default consent message, otherwise we will use you bank account name.',
				settingsType: SettingsInputType.TextInput,
				placeholder: 'Your organisation name..',
				inputRequirement: 'Max 70 chars',
				checked: settings.requestEmail.displayMarketingOptIn,
		}
  ], [settings]);

  const handleRequestEmailChecked = (checked: boolean, key: string) => {
    onSettingChange({
      ...paymentSettings,
      requestEmail: {
        ...paymentSettings.requestEmail,
        [key]: !checked
      }});
  }

  const handleOrganizationNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validateOrganizationName(e.target.value)) {
			onSettingChange({
				...paymentSettings,
				requestEmail: {
					...paymentSettings.requestEmail,
					organizationName: e.target.value
				}
			})
    }
  }

  return (
    <>
			{items.map((item) => {
				return (
					<SettingsCheckboxButton
						key={item.title}
						checked={item.checked}
						handleCheckboxChange={(e) => {
								handleRequestEmailChecked(item.checked, item.title === 'Mandatory' ? 'mandatory' : 'displayMarketingOptIn');
						}}
						inputValue={paymentSettings.requestEmail.organizationName}
						handleInputValueChange={handleOrganizationNameChange}
						title={item.title}
						subtitle={item.subtitle}
						style={targetStyles.amount_settings_expire_input}
						settingType={item.settingsType}
						placeholder={item.placeholder}
						isOtherTab={true}
						inputRequirement={item.inputRequirement}
					/>
				)
			})}
    </>
	)
}
