import classNames from 'classnames';
import { useState } from "react";
import styles from './BackgroundContainer.module.scss';
import { Menu } from '../menu/Menu';
import { useHistory } from "react-router-dom";
import Logo from '../../assets/images/logo.svg';
import BackButton from '../../assets/images/back-button.svg';
import { SelectionModal } from "../../pages/modals/SelectionModal/SelectionModal";
import {selectIsSignedIn, setSignedIn} from './../../core/reducers/appSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearPayments } from '../../pages/activity/logic/activitySlice';
import { signOut } from '../../controllers/KeyController';
import { applyTabFocusSupport } from '../../helpers';

export enum BackgroundColors {Yellow = 'yellow', Red = 'red', Green = 'green'};
export interface IBackgroundContainer {
  children: JSX.Element | string,
  isMenu?: boolean,
  color?: BackgroundColors,
  backButton?: boolean,
  onBackButtonPressed?:() => void,
  backButtonText?: string
}

export const BackgroundContainer = ({ children, isMenu = false, color = BackgroundColors.Yellow, backButton = false, onBackButtonPressed, backButtonText = 'Back' }: IBackgroundContainer) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const isSignedIn = useAppSelector(selectIsSignedIn)

  const [openModal, setOpenModal] = useState(false);

  const ellipseStyles = classNames(
    styles.background__colorfulEllipse,
    styles[`background__colorfulEllipse--${color}`],
  );

  const clickBackButton = () => {
    if(onBackButtonPressed) {
      onBackButtonPressed()
    } else {
        history.goBack()
    }
  };

  const openSignOut = () => {
    setOpenModal(true);
  };

  const handleSignOut = () => {
    signOut();
    history.push('/login');
    //dispatch(clearPayments())
    dispatch(setSignedIn(false))
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const openNewTab = (url: string) => {
    window.open(url);
  };

  return (
      <div className={styles.background}>
        { openModal &&
        <SelectionModal
          selectOptionTop={handleSignOut}
          selectOptionBottom={handleCancel}
          text="Are you sure you want to sign out?"
          optionTop="Sign Out"
        />
        }
        <span className={ellipseStyles} />
        {isMenu ? <Menu openSignOut={openSignOut} /> : null}
        {isMenu
        ?
          null
        :
          <img src={Logo} alt="BOPP Logo" className={styles.logo} onClick={() => history.push("/home")}/>
        }
        {backButton && <div {...applyTabFocusSupport()} className={styles.back} onClick={()=>{
          clickBackButton()
        }}><img src={BackButton} alt="back"/>{backButtonText}</div>}
        {children}
    </div>
    )
};
