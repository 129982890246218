import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import { useAppSelector } from "../../../app/hooks";
import styles from "./SubscriptionManagement.module.scss";
import { Button } from "../../../components/button/Button";
import CloseIcon from "../../../assets/images/close-icon-gray.svg"
import mobileStyles from "./SubscriptionManagementMobile.module.scss";
import { SubscriptionPlan } from "../static/subscriptionManagementTypes";
import { SelectionModal } from "../../modals/SelectionModal/SelectionModal";
import { ButtonColor, ButtonStyle } from "../../../static/CommonDefinitions";
import { selectSubscriptionPlan } from "../logic/subscriptionManagementSlice";
import { BackgroundContainer } from "../../../components/background/BackgroundContainer";
import { closeBoppAccountAction, deleteSubscriptionAction, activateSubscriptionAction } from "../logic/subscriptionManagementSaga";
import moment from "moment";
import { refreshAppAction } from '../../../core/sagas/initSaga';
import { applyTabFocusSupport, isDesktopEnv } from '../../../helpers';
import { Header } from '../../../components/header/ui/Header';
import { selectPaymentSubscriber } from '../../activity/logic/activitySlice';
import { getAllPayments } from '../../activity/logic/activitySaga';
import { Toast } from '../../../components/toast/Toast';
import { ToastIcon } from '../../../components/toast/static/toastCommonDefinitions';


export function SubscriptionManagement() {
  const dispatch = useDispatch();

  const subscription = useAppSelector(selectSubscriptionPlan);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);
  const [showNewPlanModal, setShowNewPlanModal] = useState(false);

  const closeDeleteModal = () => setShowDeleteModal(false);
  const closeAccountModal = () => setShowCloseAccountModal(false);
  const closeNewPlanModal = () => setShowNewPlanModal(false);
  const paymentSubscriber = useAppSelector(selectPaymentSubscriber);

  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [popupTimeout, setPopupTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    dispatch(refreshAppAction())
  }, [])

  const handleCloseBoppAccountButtonClick = () => {
    dispatch(closeBoppAccountAction())
    closeAccountModal();
  };

  const handleDeletePlanButtonClick = () => {
    dispatch(deleteSubscriptionAction());
    closeDeleteModal();
  };

  const handleNewPlanButtonClick = () => {
    dispatch(activateSubscriptionAction());
    closeNewPlanModal();
  }

  const deleteModalProps = {
    selectOptionTop: handleDeletePlanButtonClick,
    handleOutsideClick: closeDeleteModal,
    selectOptionBottom: closeDeleteModal,
    text: 'Deleting your plan will not just cancel your subscription but it will also disable any API keys you have in place on websites',
    optionTop: 'Delete my plan',
    optionBottom: 'CANCEL',
  };

  const closeAccountModalProps = {
    handleOutsideClick: closeAccountModal,
    selectOptionTop: handleCloseBoppAccountButtonClick,
    selectOptionBottom: closeAccountModal,
    text: 'By closing your BOPP account any API Keys you have in use will stop working and you will no longer be able to access the BOPP dashboard.',
    optionTop: 'CLOSE MY BOPP ACCOUNT',
    optionBottom: 'CANCEL',
  };

  const newPlanModalProps = {
    handleOutsideClick: closeNewPlanModal,
    selectOptionTop: handleNewPlanButtonClick,
    selectOptionBottom: closeNewPlanModal,
    text: 'Activating a new plan will disable your existing subscription, do not continue unless you have already signed up for a new one.',
    optionTop: 'CONTINUE',
    optionBottom: 'CANCEL',
  }

  const handleRefreshCopied = () => {
    setShowInfoPopup(true);
    if (popupTimeout !== undefined) {
      clearTimeout(popupTimeout);
    }

    setPopupTimeout(setTimeout(() => setShowInfoPopup(false), 3000));
  }

  const viewProps: SubscriptionManagementProps = {
    subscription,
    deleteModal: showDeleteModal ? (
      <SelectionModal {...deleteModalProps} />
    ) : null,
    closeAccountModal: showCloseAccountModal ? (
      <SelectionModal {...closeAccountModalProps} />
    ) : null,
    newPlanModal: showNewPlanModal ? (
      <SelectionModal {...newPlanModalProps} />
    ) : null,
    onNewPlanClick: () => setShowNewPlanModal(true),
    onDeletePlanClick: () => setShowDeleteModal(true),
    onCloseAccountClick: () => setShowCloseAccountModal(true),
    onRefreshAllPayments: () => {
      dispatch(getAllPayments({ id: paymentSubscriber }))
      handleRefreshCopied()
    },
  };

  return isDesktopEnv() ? (
    <>
    <SubscriptionManagementDesktop {...viewProps}/>
          {showInfoPopup && 
          <Toast
          title="Payment Activity data refreshing"
          icon={ToastIcon.Success}
          onClose={()=>{}}
        />}
    </>
  ) : (
    <>
    <SubscriptionManagementMobile {...viewProps} />
    {showInfoPopup && 
          <Toast
          title="Payment Activity data refreshing"
          icon={ToastIcon.Success}
          onClose={()=>{}}
        />}
    </>
  );
}

export interface SubscriptionManagementProps {
  subscription: SubscriptionPlan;
  deleteModal: JSX.Element | null;
  closeAccountModal: JSX.Element | null;
  newPlanModal: JSX.Element | null,
  onNewPlanClick: () => void;
  onDeletePlanClick: () => void;
  onCloseAccountClick: () => void;
  onRefreshAllPayments: () => void;
}

function SubscriptionManagementDesktop({subscription, deleteModal, closeAccountModal, newPlanModal, onNewPlanClick, onDeletePlanClick, onCloseAccountClick, onRefreshAllPayments}: SubscriptionManagementProps) {
  const formattedTimeStamp = moment(subscription.timeStamp).format('DD/MM/YYYY');

  return (
    <>
      <BackgroundContainer isMenu>
        <div className={styles.subscriptionManagement}>
          <Header title='BOPP Account' />
          <div className={styles.subscriptionManagement__buttons}>
            <Button
              className={styles['subscriptionManagement__new-plan-button']}
              handleClick={onNewPlanClick}
              buttonHeight="40px"
              title="Activate new plan"
              style={ButtonStyle.Rounded}
              color={ButtonColor.Yellow}
            />
            <Button
              buttonHeight="40px"
              title="CLOSE BOPP ACCOUNT"
              style={ButtonStyle.Rounded}
              color={ButtonColor.Yellow}
              handleClick={onCloseAccountClick}
            />
          </div>
          <div className={styles.subscriptionManagement__main}>
            {subscription.isActive && (
                <div className={styles.subscriptionManagement__subscription}>
                <h3 className={styles.subscriptionManagement__subscriptionName}>{subscription.name}</h3>
                <span className={styles.subscriptionManagement__subscriptionTimeStamp}>
                  Subscription created: { formattedTimeStamp }
                </span>
                <Button
                  buttonHeight="16px"
                  icon={CloseIcon}
                  title=""
                  color={ButtonColor.Transparent}
                  onClick={onDeletePlanClick}
                />
              </div>
            )}
            <a
              { ...applyTabFocusSupport() }
              href="https://subscriptions.agitate.io/portal/agitate" target="_blank"
              className={styles.subscriptionManagement__billingDataLink} rel="noreferrer">Access my billing data
            </a>
            <p { ...applyTabFocusSupport() } className={styles.subscriptionManagement__refreshButton} onClick={onRefreshAllPayments}>Refresh Payment Activity data</p>
          </div>
        </div>
      </BackgroundContainer>
      {deleteModal}
      {closeAccountModal}
      {newPlanModal}
    </>
  );
}

function SubscriptionManagementMobile({subscription, deleteModal, closeAccountModal, newPlanModal, onNewPlanClick, onDeletePlanClick, onCloseAccountClick, onRefreshAllPayments}: SubscriptionManagementProps) {
  const formattedTimeStamp = moment(subscription.timeStamp).format('DD/MM/YYYY');
  
  return (
    <>
      <div className={mobileStyles.subscriptionManagement}>
        <Header title='BOPP Account' />
        <main className={mobileStyles.subscriptionManagement__main}>
          {subscription.isActive && (
            <div className={mobileStyles.subscriptionManagement__subscription}>
              <h3
                className={
                  mobileStyles.subscriptionManagement__subscriptionName
                }>
                {subscription.name}
              </h3>
              <span
                className={
                  mobileStyles.subscriptionManagement__subscriptionTimeStamp
                }>
                Monthly subscription
              </span>
              <span
                className={
                  mobileStyles.subscriptionManagement__subscriptionTimeStampDate
                }>
                {formattedTimeStamp}
              </span>
              <Button
                icon={CloseIcon}
                title=""
                color={ButtonColor.Transparent}
                onClick={onDeletePlanClick}
                className={
                  mobileStyles.subscriptionManagement__subscriptionCloseIcon
                }
              />
            </div>
          )}
          <div className={mobileStyles.subscriptionManagement__billingDataLinkContainer}>
            <a
              href="https://subscriptions.agitate.io/portal/agitate" target="_blank"
              className={mobileStyles.subscriptionManagement__billingDataLink} rel="noreferrer">Access my billing data
            </a>
            <p { ...applyTabFocusSupport() } className={styles.subscriptionManagement__refreshButton} onClick={onRefreshAllPayments}>Refresh Payment Activity data</p>
          </div>
        </main>
        <footer className={mobileStyles.subscriptionManagement__footer}>
          <Button
            title="Activate new plan"
            color={ButtonColor.DarkYellow}
            style={ButtonStyle.Rounded}
            onClick={onNewPlanClick}
            className={mobileStyles.subscriptionManagement__activateButton}
          />
          <a
            href="#"
            onClick={onCloseAccountClick}
            className={mobileStyles.subscriptionManagement__closeAccountLink}>
            Close BOPP account
          </a>
        </footer>
      </div>
      {deleteModal}
      {closeAccountModal}
      {newPlanModal}
    </>
  );
}
