import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../core/store/configureStore';
import { SubscriptionPlan } from '../static/subscriptionManagementTypes';

const initialState: SubscriptionManagementState = {
  subscriptionPlan: {
    name: '',
    timeStamp: '',
    isActive: false
  },
  subscriptionId: '',
  subscriptionType: '',
  userSubscriptionId: '',
};

export const keyManagementSlice = createSlice({
  name: 'subscriptionManagement',
  initialState,
  reducers: {
    changeSubscriptionPlan: (
      state,
      action: PayloadAction<SubscriptionPlan>,
    ) => {
      state.subscriptionPlan = action.payload;
    },
    changeSubscriptionId: (state, action: PayloadAction<string>) => {
      state.subscriptionId = action.payload;
    },
    changeSubscriptionType: (state, action: PayloadAction<string>) => {
      state.subscriptionType = action.payload;
    },
    changeUserSubscriptionId: (state, action: PayloadAction<string>) => {
      state.userSubscriptionId = action.payload;
    },
  },
});

export const {
  changeSubscriptionType,
  changeSubscriptionPlan,
  changeSubscriptionId,
  changeUserSubscriptionId
} = keyManagementSlice.actions;

export const selectSubscriptionPlan = (state: RootState) =>
  state.subscriptionManagement.subscriptionPlan;
export const selectSubscriptionId = (state: RootState) =>
  state.subscriptionManagement.subscriptionId;
export const selectSubscriptionType = (state: RootState) =>
  state.subscriptionManagement.subscriptionType;
export const selectUserSubscriptionId = (state: RootState) =>
  state.subscriptionManagement.userSubscriptionId;

export default keyManagementSlice.reducer;

export interface SubscriptionManagementState {
  subscriptionPlan: SubscriptionPlan;
  subscriptionId: string;
  subscriptionType: string;
  userSubscriptionId: string;
}
