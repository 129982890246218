import moment from 'moment';
import React, {
  useRef, 
  useState
} from 'react';
import { 
  PopupList, 
  PopupListItem 
} from '../../../components/popupList/PopupList';
import { 
  applyTabFocusSupport, 
  isDesktopEnv 
} from '../../../helpers';
import { PaymentRequestStatus } from '../static/recurringRequestActivityCommonDefinitions';
import { RecurringPaymentRequest } from '../static/recurringRequestActivityTypes';
import styles from './RecurringRequestActivity.module.scss';
import mobileStyles from './RecurringRequestActivityMobile.module.scss';
import CopyIcon from '../../../assets/images/copy-v2.svg';
import ArrowIncomeIco from '../../../assets/images/incomming-transaction.svg';
import ThreeDotsIcon from "../../../assets/images/three-dots-gray.svg";
import CrossIco from '../../../assets/images/cancel-transaction.svg';
import CancelIcon from '../../../assets/images/cancel-rounded.svg';
import { RecurringRequestDetailsPopup } from '../../../components/RecurringRequestDetailsPopup/RecurringRequestDetailsPopup';
import { ToastIcon } from '../../../components/toast/static/toastCommonDefinitions';
import { Toast } from '../../../components/toast/Toast';
import { copyToClipboard } from '../../../utils/clipboard';
import { formatPaylinkUrl } from '../../../utils/paylinkUrl';
import { CancelPopup } from '../../../components/CancelPopup/CancelPopup';

interface TableRowProps {
  request: RecurringPaymentRequest
}

export const TableRow = ({
  request

}: TableRowProps) => {
  const isDesktop = isDesktopEnv();
  const threeDotsRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const popupRef = useRef() as React.MutableRefObject<HTMLTableRowElement>;
  const targetStyles = isDesktop ? styles : mobileStyles;
  const [isShowModalForCancel, setShowModalForCancel] = useState<boolean>(false);
  const [isShowDetailsPopup, setShowDetailsPopup] = useState<boolean>(false);
  const [isShowCancelResult, setShowCancelResult] = useState<boolean>(false);
  const [popupTimeout, setPopupTimeout] = useState<NodeJS.Timeout>();
  const [isShowPaylinkCopiedPoupup, setShowPaylinkCopiedPoupup] = useState<boolean>(false);
  const [isShowPopupList, setShowPopupList] = useState<boolean>(false);
  const delimeter = isDesktop ? '/' : '.';
  const dateFormat = `DD${delimeter}MM${delimeter}YYYY ${isDesktop ? 'HH:mm' : ''}`;
  const dateValue =  moment(request.creationTime).calendar({
    sameDay: '[Today] HH:mm',
    nextDay: dateFormat,
    nextWeek: dateFormat,
    lastDay: dateFormat,
    lastWeek: dateFormat,
    sameElse: dateFormat,
  })

  const handlePaylinkCopied = () => {
    const paylink = formatPaylinkUrl(request.paylink);
    copyToClipboard(paylink);
    setShowPaylinkCopiedPoupup(true);
    setShowPopupList(false)

    if (popupTimeout !== undefined) {
      clearTimeout(popupTimeout);
    }
    setPopupTimeout(setTimeout(() => setShowPaylinkCopiedPoupup(false), 3000));
  }

  const handleDotsClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowPopupList(true)
  }

  const handleCancelItemClick = () => {
    setShowModalForCancel(true);
  }

  const handleCancelComplete = () => {
      setShowModalForCancel(false)
      setShowDetailsPopup(false)
      //setShowCancelResult(true)
  }

  const handleDetailsClose = () => {
    setShowDetailsPopup(false)
  }

  const handleOnRequestsItemClick = () => {
    if(!isShowModalForCancel && !isShowDetailsPopup) {
      setShowDetailsPopup(true)
    }
  };

  const renderPopupList = () => {
    if (isShowPopupList) {
      return (
        <PopupList className={targetStyles.table_row__popup}>
          <PopupListItem icon={CopyIcon} title='Copy Paylink' onClick={handlePaylinkCopied} />
          {/*request.status === PaymentRequestStatus.Closed ? <></> : <PopupListItem icon={CancelIcon} title='Cancel Request' onClick={handleCancelItemClick} /> */}
        </PopupList>
      )
    }

    return <></>
  }

  const renderMobile = () => {
    return (
      <tr  ref={popupRef} className={targetStyles.table_row} onClick={handleOnRequestsItemClick}>
        {renderIcon()}
        {renderDate()}
        {renderReference()}
        {renderAmount()}
        { isShowDetailsPopup &&
            <RecurringRequestDetailsPopup
              onClose = {handleDetailsClose}
              request = {request}
          />
        }
      </tr>
    )
  }

  const renderDesktop = () => {
    return (
      <tr  ref={popupRef} className={targetStyles.table_row} onClick={handleOnRequestsItemClick}>
          {renderIcon()}
          {renderDate()}
          {renderId()}
          {renderReference()}
          {renderWebsiteURL()}
          {renderPayerEmail()}
          {renderType()}
          {renderAmount()}
          {/*renderThreeDots()*/}
          {renderPopupList()}
          { isShowModalForCancel &&
              <CancelPopup
                  onComplete = {handleCancelComplete}
                  requestToCancel = {request}
              />
          }
          { isShowDetailsPopup &&
              <RecurringRequestDetailsPopup
                onClose = {handleDetailsClose}
                request = {request}
            />
          }
          { isShowCancelResult &&
            <Toast
              icon={ToastIcon.Success}
              title={'Success'}
              onClose={()=>{setShowCancelResult(false)}}
            />
          }
          {
            isShowPaylinkCopiedPoupup &&
              <Toast
                icon={ToastIcon.Success}
                title='Paylink Copied'
                onClose={() => setShowPaylinkCopiedPoupup(false)}
              />
          }
      </tr>
    )
  }

  const renderIcon = () => {
      let iconImg = ArrowIncomeIco
      if(request.status === PaymentRequestStatus.Closed) {
          iconImg = CrossIco
      }

      return (
          <td className={targetStyles.table_row__value}>
              <img
                  src={iconImg}
                  alt=""
                  className={targetStyles.tableRow__icon}
              />
          </td>
      )
  }

  const renderDate = () => {
    return (
      <td className={targetStyles.table_row__value}>{dateValue}</td>
    )
  }

  const renderId = () => {
    return (
      <td className={targetStyles.table_row__value}>{request.id}</td>
    )
  }

  const renderReference = () => {
    return (
      <td className={targetStyles.table_row__value}>{request.paymentReference}</td>
    )
  }

  const renderWebsiteURL = () => {
    return (
      <td className={targetStyles.table_row__value}>{request.websiteURL}</td>
    )
  }

  const renderPayerEmail = () => {
    return (
      <td className={targetStyles.table_row__value}></td>
    )
  }

  const renderType = () => {
    return (
      <td className={targetStyles.table_row__value}>Standing Order</td>
    )
  }

  const renderAmount = () => {
    return (
      <td className={targetStyles.table_row__value}>{request.firstPaymentAmount.value}</td>
    )
  }

  const renderThreeDots = () => {
      return (
          <td className={targetStyles.table_row__value}>
          <img { ...applyTabFocusSupport() } 
              ref={threeDotsRef} 
              src={ThreeDotsIcon} 
              className={targetStyles.table_row__three_dots} 
              onClick={handleDotsClick} 
              alt=''
          />
          </td>
      )
    }

  return (
    <>
      { isDesktop ? renderDesktop() : renderMobile() }
    </>
  );
}