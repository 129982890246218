import { common } from "./index";

interface CancelBankAuthUrlRequest extends common.Activation {
    previousId: string;
    entityType: string;
}

export class CancelBankAuthUrl implements common.RequestCreator<CancelBankAuthUrlRequest> {
    cancelRequestId: string;
    authUrlRequestId: string;

    constructor(cancelRequestId: string, authUrlRequestId: string) {
        this.cancelRequestId = cancelRequestId;
        this.authUrlRequestId = authUrlRequestId;
    }

    createRequest() {
        return this.getRequestParams();
    }

    getRequestParams(): CancelBankAuthUrlRequest {
        return {
            "@type": "https://dvschema.io/activation/CancelRequest",
            id: this.cancelRequestId,
            previousId: this.authUrlRequestId,
            entityType: "https://miapago.io/ob/accountdetails/AccountDetails",
            timestamp: new Date(),
            possibleResend: false,
        }
    }
}