import { put, takeLatest, select } from "redux-saga/effects";
import {
  LoginStep,
  selectLoginStep,
  changeStep,
  selectEnteredPin,
  setPinState,
  PinState,
  setIsReconnectWithBank,
  selectIsReconnectWithBank,
  setEnteredPin
} from './loginSlice';

import { push, replace } from 'connected-react-router'
import { selectIsSignedIn, setSignedIn } from './../../../core/reducers/appSlice'
import { clearStore, getIsKeyStored, KeyStorageIndex } from './../../../controllers/KeyController'
import { AnyAction } from "@reduxjs/toolkit";

export const CANCEL_ACCOUNT_NOT_FOUND = "login/cancel/account/not/found";
export function cancelAccountNotFoundAction():AnyAction {return {type: CANCEL_ACCOUNT_NOT_FOUND}}

export const FORGOT_PIN = "login/forgot/pin";
export function forgotPinAction():AnyAction {return {type: FORGOT_PIN}}

export const DETECT_STATE = "login/detect/state";
export function detectStateAction():AnyAction {return {type: DETECT_STATE}}

export const GO_BACK_ACTION = "login/go/back";
export function goBackAction():AnyAction {return {type: GO_BACK_ACTION}}

export const HANDLE_PIN_ACTION = "login/handle/pin";
export function handlePinAction():AnyAction {return {type: HANDLE_PIN_ACTION}}

export const ALREADY_HAVE_ACCOUNT_ACTION = "login/already/have/account";
export function alreadyHaveAccountAction():AnyAction {return {type: ALREADY_HAVE_ACCOUNT_ACTION}}

export const CONNECT_WITH_BANK = "login/connect/with/bank";
export function connectWithBankAction():AnyAction {return {type: CONNECT_WITH_BANK}}

export const CONNECT_WITH_EXISTING_BANK = "login/connect/with/existing/bank";
export function connectWithExistingBankAction():AnyAction {return {type: CONNECT_WITH_EXISTING_BANK}}

export const CREATE_NEW_ACCOUNT = "login/create/new/account";
export function createNewAccountAction():AnyAction {return {type: CREATE_NEW_ACCOUNT}}

export const DO_YOU_HAVE_BOPP_ACCOUNT_ACTION = "login/do/youo/have/bopp/account";
export function doYouHaveBoppAccountAction():AnyAction {return {type: DO_YOU_HAVE_BOPP_ACCOUNT_ACTION}}

function* goToLogin() {
  yield put(changeStep(LoginStep.Login))
}

function* doYouHaveBoppAccount() {
  yield put(changeStep(LoginStep.HaveBOPPAccount))
}

function* goBack() {
  const step: LoginStep = yield select(selectLoginStep);

  if(step === LoginStep.Login)
    yield put(changeStep(LoginStep.AccountNotStored))

  if(step === LoginStep.ForgotPin)
    yield put(changeStep(LoginStep.EnterPin))

  if(step === LoginStep.AccountNotFound)
    yield put(changeStep(LoginStep.Login))

  if(step === LoginStep.HaveBOPPAccount)
    yield put(changeStep(LoginStep.AccountNotStored))
}

function* goToForgotPin() {
  yield put(setPinState(PinState.None))
  yield put(changeStep(LoginStep.ForgotPin))
}

function* detectState() {
  if(getIsKeyStored()) {
    const isSignedIn: Boolean = yield select(selectIsSignedIn);
    if(isSignedIn) {
      yield put(replace('/home'))
    } else {
      const isReconnectWithBank:Boolean = yield select(selectIsReconnectWithBank);
      const secretKeyRaw = localStorage.getItem(KeyStorageIndex + 'store')
      if(!isReconnectWithBank) {
        //TODO: Generate and use temporarykeys on keys when restoring account not found in system
        if (!secretKeyRaw) {
          localStorage.clear()
          clearStore()
          yield put(changeStep(LoginStep.AccountNotStored))
        } else {
          yield put(changeStep(LoginStep.EnterPin))
        }
      }
    }
  } else {
    yield put(changeStep(LoginStep.AccountNotStored))
  }
}

function* handlePin() {
    yield put(setPinState(PinState.None))
    yield put(setSignedIn(true))
    yield put(setEnteredPin(''))
    yield put(replace('/home'))
}

function* createNewAccount() {
  yield put(setIsReconnectWithBank(false))
  yield put(push('/choose-subscription-plan'))
}

function* connectWithBank() {
  yield put(setIsReconnectWithBank(true))
  yield put(push('/bank-account'))
}

function* connectWithExistingBank() {
  yield put(setIsReconnectWithBank(false))
  yield put(push('/bank-account'))
}

function* cancelAccountNotFound() {
  if(getIsKeyStored()) {
    //TODO: Generate and use temporarykeys on keys when restoring account not found in system
    const secretKey = localStorage.getItem(KeyStorageIndex + 'store');
    if (!secretKey) {
      localStorage.clear()
      clearStore()
      yield put(changeStep(LoginStep.AccountNotStored))
    } else {
      yield put(changeStep(LoginStep.EnterPin))
    }
  } else {
    yield put(changeStep(LoginStep.AccountNotStored))
  }
}

export default function* loginSaga() {
  yield takeLatest(GO_BACK_ACTION, goBack);
  yield takeLatest(ALREADY_HAVE_ACCOUNT_ACTION, goToLogin);
  yield takeLatest(CONNECT_WITH_BANK, connectWithBank);
  yield takeLatest(DETECT_STATE, detectState);
  yield takeLatest(FORGOT_PIN, goToForgotPin);
  yield takeLatest(HANDLE_PIN_ACTION, handlePin);
  yield takeLatest(CREATE_NEW_ACCOUNT, createNewAccount);
  yield takeLatest(CANCEL_ACCOUNT_NOT_FOUND, cancelAccountNotFound);
  yield takeLatest(DO_YOU_HAVE_BOPP_ACCOUNT_ACTION, doYouHaveBoppAccount);
  yield takeLatest(CONNECT_WITH_EXISTING_BANK, connectWithExistingBank);
}
