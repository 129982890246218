import React, { ChangeEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ActivationCodeInput } from '../../../components/activationCodeInput/ActivationCodeInput';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import { Button } from '../../../components/button/Button';
import { StylizedWrapperWithRadius } from '../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import { ButtonColor, ButtonStyle } from '../../../static/CommonDefinitions';
import {
  selectAuthCode,
  selectSecurityCode,
  selectTooManyAttempts,
  setAuthCode,
  setSecurityCode,
} from '../../activateAccount/logic/activateAccountSlice';
import CrossIcon from '../../../assets/images/close-icon.svg';
import CloseIcon from '../../../assets/images/close-arrow-24px-rounded.svg';
import style from './LinkAccountError.module.scss';
import mobileStyle from './LinkAccountErrorMobile.module.scss';
import { MobileHeader } from '../../../components/mobileHeader/MobileHeader';
import {
  MobileHeaderColor,
  MobileHeaderPadding,
} from '../../../components/mobileHeader/static/mobileHeaderCommonDefinitions';
import {
  initActivationTimers,
  linkWithSecurityCode,
  updateRoute,
} from '../../activateAccount/logic/activateAccountSaga';
import { applyTabFocusSupport, isDesktopEnv } from '../../../helpers';

export function LinkAccountError() {
  const dispatch = useAppDispatch();
  const activationCode = useAppSelector(selectAuthCode);
  const securityCode = useAppSelector(selectSecurityCode);
  const tooManyAttempts = useAppSelector(selectTooManyAttempts);

  useEffect(() => {
    dispatch(updateRoute());
    dispatch(initActivationTimers());
  }, []);

  if (tooManyAttempts) {
    return <></>;
  }

  const handleActivationCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setAuthCode(e.target.value));
  };

  const handleSecurityCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSecurityCode(e.target.value));
  };

  const handleButtonClick = () => {
    dispatch(linkWithSecurityCode());
  };

  const buttonDisabled =
    activationCode.length !== 8 || securityCode.length !== 8;

  if (isDesktopEnv()) {
    return (
      <BackgroundContainer backButton>
        <StylizedWrapperWithRadius
          style="square"
          className={style.link_account_error}>
          <div className={style.link_account_error__inner}>
            <img
              src={CrossIcon}
              alt="cross"
              className={style.link_account_error__cross_icon}
            />
            <h4 className={style.link_account_error__title}>
              Please try again
            </h4>
            <p className={style.link_account_error__error_text}>
              We do not recognise that combination of activation and security
              codes. Please re-enter your codes.
            </p>
            <div className={style.link_account_error__inputs}>
              <ActivationCodeInput
                label="Activation Code"
                value={activationCode}
                handleChange={handleActivationCodeChange}
                error
              />
              <ActivationCodeInput
                label="Security Code"
                value={securityCode}
                handleChange={handleSecurityCodeChange}
                error
              />
            </div>
            <p className={style.link_account_error__support_team_text}>
              If you do not have a security code please contact our{' '}
              <a
                target="_blank" rel="noopener noreferrer" 
                href="https://help.bopp.io"
                className={style.link_account_error__support_team_link}>
                Support team
              </a>
              .
            </p>
            <p className={style.link_account_error__privacy_policy_text}>
              Your details will be used in line with our <br />
              <a target="_blank" rel="noopener noreferrer" 
                {  ...applyTabFocusSupport() }
                href="https://bopp.io/legal"
                className={style.link_account_error__privacy_policy_link}>
                Privacy Policy
              </a>
            </p>
            <Button
              color={ButtonColor.Black}
              style={ButtonStyle.Rounded}
              title="Try Again"
              className={style.link_account_error__button}
              onClick={handleButtonClick}
              disabled={buttonDisabled}
            />
          </div>
        </StylizedWrapperWithRadius>
      </BackgroundContainer>
    );
  }

  return (
    <div className={mobileStyle.link_account}>
      <MobileHeader
        withLogo
        isMenu={false}
        color={MobileHeaderColor.red}
        padding={MobileHeaderPadding.small}
        rightIcon={<img src={CloseIcon} alt="close" />}
      />
      <div className={mobileStyle.link_account__content}>
        <img
          src={CrossIcon}
          alt="cross"
          className={mobileStyle.link_account__cross_icon}
        />
        <h4 className={mobileStyle.link_account__title}>Please try again</h4>
        <p className={mobileStyle.link_account__error_text}>
          We do not recognise that combination of activation and security
          codes. Please re-enter your codes.
        </p>
        <div className={mobileStyle.link_account__inputs}>
          <ActivationCodeInput
            label="Activation Code"
            value={activationCode}
            handleChange={handleActivationCodeChange}
          />
          <ActivationCodeInput
            label="Security Code"
            value={securityCode}
            handleChange={handleSecurityCodeChange}
          />
        </div>
        <p className={mobileStyle.link_account__support_team_text}>
          If you do not have a security code please contact our{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://help.bopp.io" className={mobileStyle.link_account__support_team_link}>
            Support team
          </a>
          .
        </p>
        <p className={mobileStyle.link_account__privacy_policy_text}>
          Your details will be used in line with our <br />
          <a
            target="_blank" rel="noopener noreferrer" href="https://bopp.io/legal"
            className={mobileStyle.link_account__privacy_policy_link}>
            Privacy Policy
          </a>
        </p>
        <div className={mobileStyle.link_account__button_container}>
          <Button
            color={ButtonColor.Black}
            style={ButtonStyle.Rounded}
            title="Try Again"
            className={mobileStyle.link_account__button}
            onClick={handleButtonClick}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </div>
  );
}
