import React from "react";
import { useHistory } from "react-router";
import { isDesktopEnv } from "../../../../helpers";
import { Error } from "../../logic/paymentRequestSlice";
import ErrorIcon from "../../../../assets/images/error-icon.svg";
import RedArrow from "../../../../assets/images/red-arrow.svg";
import desktopStyles from "./errorHandler.module.scss";
import mobileStyles from "./errorHandlerMobile.module.scss";

interface ErrorHandlerProps {
    error: Error;
}

const ErrorHandler = ({error}: ErrorHandlerProps) => {
    const isDesktop = isDesktopEnv();
    const history = useHistory();

    const handleLinkWrapperClick = () => {
        history.push('/bank-account/activate');
    }

    const getErrorData = () => {
        const getStringWithLink = () => {
            return(
                <>
                    The BOPP account on this device has been deactivated. Please enter your new activation code <span
                        className={targetStyles.error_description_link}
                        onClick={handleLinkWrapperClick}
                    >
                        here
                    </span> or visit our help page.
                </>
            )
        }

        switch (error.code) {
            case(26):
                return {
                    title: 'Pay request limit reached',
                    description: 'You are requesting an amount that exceeds the limit set for your account. To learn more about pay request limits visit our FAQ page.',
                    link: 'https://help.bopp.io/portal/en/kb/articles/do-you-have-limits-for-payment-requests'
                };
            case(25):
            case(150):
                return {
                    title: 'Account Deactivated',
                    description: getStringWithLink(),
                    link: 'https://help.bopp.io/portal/en/kb/articles/why-is-my-account-showing-as-deactivated'
                };
            default:
                return {
                    title: 'Something went wrong',
                    description: error.message,
                    link: 'https://help.bopp.io/portal/en/kb/bopp'
                };
        }
    }

    const targetStyles = isDesktop ? desktopStyles : mobileStyles;

    return(
        <div className={targetStyles.error_wrapper}>
            <div className={targetStyles.error_container}>
                <img src={ErrorIcon} alt="Warning" className={targetStyles.error_container_icon}/>
                <div className={targetStyles.error_text_container}>
                    <div className={targetStyles.error_title}>
                        {getErrorData().title}
                    </div>
                    <div className={targetStyles.error_description}>
                        {getErrorData().description}
                    </div>
                </div>
            </div>
            <div className={targetStyles.error_button}>
                    <a
                        className={targetStyles.error_button_link}
                        target="_blank"
                        href={getErrorData().link}
                    >
                        <div className={targetStyles.error_button_text}>Learn more</div>
                        <img src={RedArrow} alt="about error" className={targetStyles.error_button_icon}/>
                    </a>
            </div>
        </div>
    )
}

export default ErrorHandler;
