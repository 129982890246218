import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

export const useScrollPosition = (element: Element | null) => {
  const [scroll, setScroll] = useState({x: 0, y: 0});
  const [maxScrollPositionY, setMaxScrollPositionY] = useState(0);

  const updateMaxScrollPosition = () => {
    const offsetHeight = (element as HTMLElement)?.offsetHeight || 0;
    const maxScroll = (element?.scrollHeight || 0) - offsetHeight;
    setMaxScrollPositionY(maxScroll);
  }

  useEffect(() => {
    const callback = () => {
      setScroll({x: element?.scrollLeft || 0, y: element?.scrollTop || 0});
      updateMaxScrollPosition();
    }

    element?.addEventListener('scroll', callback);

    if (maxScrollPositionY == 0) {
      updateMaxScrollPosition();
    }

    return () => element?.removeEventListener('scroll', callback);

  }, [element]);
  
  return {
    ...scroll,
    maxScrollPositionY,
  }
}