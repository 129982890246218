import React, { ChangeEvent, useEffect } from 'react';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import { StylizedWrapperWithRadius } from '../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import InfoIcon from '../../../assets/images/info-grey.svg';
import CloseIcon from '../../../assets/images/close-arrow-24px-rounded.svg';
import { ActivationCodeInput } from '../../../components/activationCodeInput/ActivationCodeInput';
import { Button } from '../../../components/button/Button';
import { ButtonColor, ButtonStyle } from '../../../static/CommonDefinitions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import style from './LinkAccount.module.scss';
import mobileStyle from './LinkAccountMobile.module.scss';
import {
  selectAccountLinkingStatus,
  selectAuthCode,
  selectSecurityCode,
  selectTooManyAttempts,
  setAuthCode,
  setSecurityCode,
} from '../../activateAccount/logic/activateAccountSlice';
import { AccountLinkingStatus } from '../../activateAccount/static/activateAccountCommonDefinitions';
import {
  linkWithSecurityCode,
  updateRoute,
} from '../../activateAccount/logic/activateAccountSaga';
import { applyTabFocusSupport, isDesktopEnv } from '../../../helpers';

export function LinkAccount() {
  const dispatch = useAppDispatch();
  const activationCode = useAppSelector(selectAuthCode);
  const securityCode = useAppSelector(selectSecurityCode);
  const accountLinkingStatus = useAppSelector(selectAccountLinkingStatus);
  const tooManyAttempts = useAppSelector(selectTooManyAttempts);

  const hasError =
    accountLinkingStatus === AccountLinkingStatus.error || tooManyAttempts;

  useEffect(() => {
    dispatch(updateRoute());
  }, []);

  if (hasError) {
    return <></>;
  }

  const handleActivationCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setAuthCode(e.target.value));
  };

  const handleSecurityCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSecurityCode(e.target.value));
  };

  const handleButtonClick = () => {
    dispatch(linkWithSecurityCode());
  };

  const confirmButtonDisabled =
    activationCode.length !== 8 || securityCode.length !== 8;

  if (isDesktopEnv()) {
    return (
      <BackgroundContainer backButton>
        <StylizedWrapperWithRadius
          style="square"
          className={style.link_account}>
          <div className={style.link_account__inner}>
            <h4 className={style.link_account__title}>Link Account Details</h4>
            <div className={style.link_account__info_block}>
              <img
                src={InfoIcon}
                alt="info"
                className={style.link_account__info_block_icon}
              />
              <span className={style.link_account__info_block_text}>
                Onboarding for this bank is currently restricted to organisation
                accounts only. To link your bank account please enter your BOPP
                activation and security codes below.
              </span>
              <a target="_blank" rel="noopener noreferrer" href="https://help.bopp.io/" className={style.link_account__info_block_link}>
                Learn More
              </a>
            </div>
            <div className={style.link_account__inputs}>
              <ActivationCodeInput
                label="Activation Code"
                value={activationCode}
                handleChange={handleActivationCodeChange}
              />
              <ActivationCodeInput
                label="Security Code"
                value={securityCode}
                handleChange={handleSecurityCodeChange}
              />
            </div>
            <p className={style.link_account__support_team_text}>
              If you do not have a security code please contact our{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://help.bopp.io" className={style.link_account__support_team_link}>
                Support team
              </a>
              .
            </p>
            <p className={style.link_account__privacy_policy_text}>
              Your details will be used in line with our <br />
              <a { ...applyTabFocusSupport() } target="_blank" rel="noopener noreferrer" href="https://bopp.io/legal" className={style.link_account__privacy_policy_link}>
                Privacy Policy
              </a>
            </p>
            <Button
              color={ButtonColor.Black}
              style={ButtonStyle.Rounded}
              title="Confirm"
              className={style.link_account__button}
              onClick={handleButtonClick}
              disabled={confirmButtonDisabled}
            />
          </div>
        </StylizedWrapperWithRadius>
      </BackgroundContainer>
    );
  }

  return (
    <div className={mobileStyle.link_account}>
      <div className={mobileStyle.link_account__top}>
        <h2 className={mobileStyle.link_account__title}>
          Link Account Details
        </h2>
        <img
          src={CloseIcon}
          alt="close"
          className={mobileStyle.link_account__close_icon}
        />
      </div>
      <div className={mobileStyle.link_account__content}>
        <div className={mobileStyle.link_account__info_block}>
          <img
            src={InfoIcon}
            alt="info"
            className={mobileStyle.link_account__info_block_icon}
          />
          <span className={mobileStyle.link_account__info_block_text}>
            Onboarding for this bank is currently restricted to organisation
            accounts only. To link your bank account please enter your BOPP
            activation and security codes below.
          </span>
          <a target="_blank" rel="noopener noreferrer" href="https://help.bopp.io" className={mobileStyle.link_account__info_block_link}>
            Learn More
          </a>
        </div>
        <div className={mobileStyle.link_account__inputs}>
          <ActivationCodeInput
            label="Activation Code"
            value={activationCode}
            handleChange={handleActivationCodeChange}
          />
          <ActivationCodeInput
            label="Security Code"
            value={securityCode}
            handleChange={handleSecurityCodeChange}
          />
        </div>
        <p className={mobileStyle.link_account__support_team_text}>
          If you do not have a security code please contact our{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://help.bopp.io" className={mobileStyle.link_account__support_team_link}>
            Support team
          </a>
          .
        </p>
        <p className={mobileStyle.link_account__privacy_policy_text}>
          Your details will be used in line with our <br />
          <a target="_blank" rel="noopener noreferrer" href="https://bopp.io/legal" className={mobileStyle.link_account__privacy_policy_link}>
            Privacy Policy
          </a>
        </p>
        <div className={mobileStyle.link_account__button_container}>
          <Button
            color={ButtonColor.Black}
            style={ButtonStyle.Rounded}
            title="Confirm"
            className={mobileStyle.link_account__button}
            onClick={handleButtonClick}
            disabled={confirmButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
}
