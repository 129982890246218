import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import config from '../../../config';
import { applyTabFocusSupport, fetchPageNumbersWithSplits, isDesktopEnv, SPLIT } from '../../../helpers';
import { PaginationData } from '../static/paginationTypes';
import ArrowShortDisabledIco from '../../../assets/images/backspace-arrow-disabled.svg';
import ArrowShortIco from '../../../assets/images/backspace-arrow-16px.svg';

import styles from './Pagination.module.scss';
import mobileStyles from './PaginationMobile.module.scss';

export interface PaginationProps {
  totalTableRecords: number;
  pageLimit?: number;
  pageNeighbors?: number;
  currentPage: number;
  handlePaginationUpdate: (info: PaginationData) => void
}

export function Pagination({
  totalTableRecords = 0,
  pageLimit = config.rowsCountPerTableDesktop,
  pageNeighbors = 1,
  currentPage,
  handlePaginationUpdate,
}: PaginationProps) {
  const dispatch = useAppDispatch();
  const isDesktop = isDesktopEnv();

  const pageButtonNeighbors = Math.max(0, Math.min(pageNeighbors, 2));
  const totalPagesCount = Math.ceil(totalTableRecords / pageLimit);
  const isRightArrowDisabled = currentPage === totalPagesCount;
  const isLeftArrowDisabled = currentPage === 1;
  const pagesButtons = fetchPageNumbersWithSplits(
    currentPage,
    totalPagesCount,
    pageButtonNeighbors,
    );

  useEffect(() => {
    gotoPage(currentPage);
  }, []);

  const gotoPage = (page: number) => {
    const currentPage = Math.max(0, Math.min(page, totalPagesCount));

    let paginationRangeStart = (currentPage -1) * pageLimit
    let paginationRangeEnd = paginationRangeStart + pageLimit

    handlePaginationUpdate({currentPage, pageLimit, paginationRangeStart, paginationRangeEnd});
  };

  const changePage = (page: number) => () => {
    gotoPage(page);
  };

  const handleMoveLeft = () => {
    gotoPage(currentPage - 1);
  };

  const handleMoveRight = () => {
    gotoPage(currentPage + 1);
  };

  const blockStyles = (page: number) =>
    classNames(targetStyle.pagination__numBtm, {
      [targetStyle['pagination__numBtm--active']]: currentPage === page,
    });

  const targetStyle = isDesktop ? styles : mobileStyles;

  return (
    <>
      {totalTableRecords && totalPagesCount > 1 && (
        <div className={targetStyle.pagination}>
          <button
            className={targetStyle.pagination__stepBtn}
            onClick={handleMoveLeft}
            disabled={isLeftArrowDisabled}>
              <img
                src={isLeftArrowDisabled ? ArrowShortDisabledIco : ArrowShortIco}
                alt=""
              />
              <span>Prev</span>
          </button>
          <ul className={targetStyle.pagination__pages}>
            {pagesButtons.map((page, index) =>
              page === SPLIT ? (
                <li
                  className={classNames(
                    targetStyle.pagination__numBtm,
                    targetStyle['pagination__numBtm--split'],
                  )}>
                  ..
                </li>
              ) : (
                <li
                  key={index}
                  { ...applyTabFocusSupport() }
                  className={blockStyles(page)}
                  onClick={changePage(page)}>
                  {page}
                </li>
              ),
            )}
          </ul>
          <button
            className={targetStyle.pagination__stepBtn}
            onClick={handleMoveRight}
            disabled={isRightArrowDisabled}>
              <span>Next</span>
              <img
                src={isRightArrowDisabled ? ArrowShortDisabledIco : ArrowShortIco}
                alt=""
              />
          </button>
        </div>
      )}
    </>
);
}
