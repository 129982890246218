import React from 'react';
import classNames from 'classnames';

import { StatusBank } from '../../static/CommonDefinitions';
import styles from './BankItem.module.scss';
import mobileStyles from './BankItemMobile.module.scss';
import { isDesktopEnv } from '../../helpers';

export interface BankItemProps {
  bankStatus: string;
  bankLogo?: string;
  defaultLogo?: string;
  bankName: string;
  handleChooseBank: () => void;
}

export function BankItem({
  bankStatus,
  bankLogo,
  defaultLogo,
  bankName,
  handleChooseBank,
}: BankItemProps) {
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  return (
    <div
      className={
        bankStatus === StatusBank.Healthy
          ? targetStyles.bank_item
          : classNames(targetStyles.bank_item__grey, targetStyles.bank_item)
      }
      onClick={handleChooseBank}>
      <div
        className={classNames(
          bankLogo && targetStyles.bank_item__background_white,
          targetStyles.bank_item__icon,
        )}>
        <img src={bankLogo || defaultLogo} alt="bank-logo" />
      </div>
      <div className={targetStyles.bank_item__account}>
        <div className={targetStyles.bank_item__account__name}>
          <span>{bankName}</span>
          {bankStatus === StatusBank.Unhealthy ? (
            <span className={targetStyles.bank_item__account__red_text}>
              Currently unavailable
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}
