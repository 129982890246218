import { EndToEndIdentification } from './../../paymentRequest/static/paymentRequestCommonDefinitions';
import {
  PaymentRequestStatus
} from '../static/recurringRequestActivityCommonDefinitions';
import {
  calculatePages,
  createId,
  numbersSortingCallback,
  stringsSortingCallback,
  wrap,
} from './../../../helpers/index';
import {WrappedSocket} from './../../../sockets/types.d';
import {AnyAction} from "@reduxjs/toolkit";
import moment from "moment";
import {
  put, 
  select, 
  takeLatest
} from "redux-saga/effects";
import {DateRange} from "../../../components/dateRangeInput/static/dateRangeInputTypes";
import {RangesList} from "../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions";
import {RootSagaSockets} from "../../../core/sagas/rootSaga";
import {
  checkIsDateInFilterRange, 
  getDateRangeByPeriodTime, 
  isEmptyString
} from "../../../helpers";
import {SortingType} from "../../activity/static/activityCommonDefinitions";
import {
  RecurringPaymentRequest,
  RequestFilters, 
  RecurringRequestsTableSortState
} from "../static/recurringRequestActivityTypes";
import {
  addRequest,
  selectFiltereRecurringPaymentRequests,
  selectRecurringPaymentRequests,
  selectRequestsTableFilters,
  selectRecurringRequestsTablePaginationData,
  selectRecurringRequestsTableSorting,
  setFilteredPaymentRequests,
  updateRequest,
  updateRequestsTableFilters,
  updateRequestsTablePaginationData,
  updateRecurringRequestStatus,
} from "./recurringRequestActivitySlice";
import {store} from "../../../core/store/configureStore";
import {formatPaylinkUrl} from "../../../utils/paylinkUrl";
import { log } from '../../../static/Logger';
import { getPropertyFrom } from '../../../helpers/objectHelper';
import { selectApiKeys } from '../../keyManagement/logic/keyManagementSlice';
import { ApiKey } from '../../keyManagement/static/keyManagementTypes';
import { PaymentInstruction } from '../../requestActivity/static/requestActivityTypes';
import { PaymentInstructionActivityState, PaymentInstructionEntityState } from '../../requestActivity/static/requestActivityCommonDefinitions';

export const FILTER_REQUESTS = 'requestActivity/filter/requests';
export const SORT_REQUESTS_TABLE = 'recurringRequestActivity/sort/table';
export const SET_REQUESTS_TABLE_FILTERS = 'requestActivity/set/tableFilters';
export const REFRESH_REQUESTS_FILTERS = 'requestActivity/refresh/filters';
export const GET_REQUESTS = 'requestActivity/get/requests';
export const GET_ALL_REQUESTS = 'requestActivity/load/getRequests';
export const HANDLE_RECURRING_REQUESTS_RESPONSE = 'recurringRequestActivity/handle/requestsResponse';
export const CANCEL_RECURRING_REQUEST = 'CANCEL_RECURRING_REQUEST';
export const HANDLE_RECURRING_REQUEST_CANCEL_RESPONSE = 'handle/recurringRequest/cancle/response';

export interface SetFilters {
  type: typeof SET_REQUESTS_TABLE_FILTERS;
  payload: Partial<RequestFilters>;
}

export interface HandleRequestsResponsePayload {
  message: any;
}

export interface HandleRequestsResponse {
  type: typeof HANDLE_RECURRING_REQUESTS_RESPONSE;
  payload: HandleRequestsResponsePayload;
}

export interface CancelRequestPayload {
  type: typeof HANDLE_RECURRING_REQUESTS_RESPONSE;
  id: string;
}

export interface CancelRecurringRequestResponse {
  type: typeof HANDLE_RECURRING_REQUEST_CANCEL_RESPONSE;
  payload: HandleRequestsResponsePayload;
}

export function filterRequestsAction(): AnyAction {return { type: FILTER_REQUESTS }}
export function sortRecurringRequestsTableAction(): AnyAction {return { type: SORT_REQUESTS_TABLE }}
export function refreshRequestsFilters(): AnyAction {return { type: REFRESH_REQUESTS_FILTERS }}
export function getAllRequests(): AnyAction {return { type: GET_ALL_REQUESTS }}
export function getRequests(): AnyAction {return { type: GET_REQUESTS }}

export const handleRecurringRequestsCancelAction = (payload: HandleRequestsResponsePayload): CancelRecurringRequestResponse => ({
  type: HANDLE_RECURRING_REQUEST_CANCEL_RESPONSE,
  payload,
});

export const handleRecurringRequestsResponse = (payload: HandleRequestsResponsePayload): HandleRequestsResponse => ({
  type: HANDLE_RECURRING_REQUESTS_RESPONSE,
  payload,
});

export const setRequestsTableFilters = (payload: Partial<RequestFilters>): SetFilters => ({
  type: SET_REQUESTS_TABLE_FILTERS,
  payload,
});

export const cancelRecurringRequest = (payload: string) => ({
  type: CANCEL_RECURRING_REQUEST,
  payload,
});

export const sortRequestByField = (items: RecurringPaymentRequest[], sortingType: SortingType, fieldName: keyof RecurringPaymentRequest ) => {
  if (!items.length) {
    return;
  }

  const targetFieldType = typeof items[0][fieldName]; 

  switch(fieldName) {
    case 'creationTime':
      items.sort((a, b) => numbersSortingCallback(moment(a.creationTime).unix(), moment(b.creationTime).unix(), sortingType));
      return;
    case 'firstPaymentAmount':
      items.sort((a, b) => numbersSortingCallback(+a.firstPaymentAmount.value, +b.firstPaymentAmount.value, sortingType));
      return;
  }

  switch(targetFieldType) {
    case 'string':
      log(items[0][fieldName]);
      items.sort((a, b) => stringsSortingCallback(a[fieldName] as string, b[fieldName] as string, sortingType));
      break;
    case 'number':
      items.sort((a, b) => numbersSortingCallback(a[fieldName] as number, b[fieldName] as number, sortingType));
      break;
  }
}

function filterRequestsByReference(requests: RecurringPaymentRequest[], reference:string) {
  if(reference === '') {
    return requests
  }

  let result = requests.filter(request => {
    if (!request.paymentReference) {
      return true;
    }

    return request.paymentReference.includes(reference)
  });

  return result
}

function filterRequestsBySearchQuery(requests: RecurringPaymentRequest[], searchQuery:string) {
  if (isEmptyString(searchQuery)) {
    return requests;
  }

  const lowerCaseQuery = searchQuery.toLowerCase();

  const result = requests.filter(request => {
    return request.paymentReference.toLowerCase().includes(lowerCaseQuery) ||
      request.firstPaymentAmount.value.includes(lowerCaseQuery) ||
      formatPaylinkUrl(request.paylink).toLowerCase().includes(lowerCaseQuery);
  });

  return result;
}

function filterRequestsByDate(requests:RecurringPaymentRequest[], date:DateRange) {
  let result = requests.filter(request => {
    return checkIsDateInFilterRange(request.creationTime, date);
  });

  return result
}


function* filterPayments(): any {
  const requests = yield select(selectRecurringPaymentRequests);

  if(requests.length) {
    const { searchQuery, reference, queryByDate }: RequestFilters = yield select(selectRequestsTableFilters);
    let filtered = filterRequestsByReference(requests, reference);
    const paginationData = yield select(selectRecurringRequestsTablePaginationData);
    filtered = filterRequestsBySearchQuery(filtered, searchQuery);
    filtered = filterRequestsByDate(filtered, queryByDate);

    const pcount = calculatePages(filtered.length, paginationData.pageLimit)
    yield put(updateRequestsTablePaginationData({totalPages:pcount, currentPage:1, paginationRangeStart:0, paginationRangeEnd:paginationData.pageLimit}));

    yield put(setFilteredPaymentRequests(filtered));
    yield put(sortRecurringRequestsTableAction());
  }
}

function* sortRequestsTableWatcher(): any {
  const filteredRequests: RecurringPaymentRequest[] = yield select(selectFiltereRecurringPaymentRequests);
  const sorting: RecurringRequestsTableSortState = yield select(selectRecurringRequestsTableSorting);
  const sortType = Object.keys(sorting).filter(
    el => sorting[el] !== SortingType.INACTIVE,
  )[0];

  const requestsCopy = filteredRequests.slice();
  let targetFieldName: keyof RecurringPaymentRequest | null = null;
  
  switch (sortType) {
    case 'requestId':
      targetFieldName = 'id';
      break;
    case 'dateTime':
      targetFieldName = 'creationTime';
      break;
    case 'reference':
      targetFieldName = 'paymentReference';
      break;
    case 'websiteURL':
      targetFieldName = 'websiteURL';
      break;
    case 'amount':
      targetFieldName = 'firstPaymentAmount';
      break;
  }

  if (targetFieldName) {
    sortRequestByField(requestsCopy, sorting[sortType], targetFieldName);
  }

  yield put(setFilteredPaymentRequests(requestsCopy));
}

function* setRequestsTableFiltersWatcher({ payload }: SetFilters): any {
  const filters: RequestFilters = yield select(selectRequestsTableFilters);
  yield put(updateRequestsTableFilters({...filters, ...payload}));
  yield filterPayments();
}

function* refreshRequestsFiltersWatcher() {
  yield put(setRequestsTableFilters({
    searchQuery: '',
    reference: '',
    queryByDate: getDateRangeByPeriodTime(RangesList.YearFromToday),
  }))
}

function* filterRequestsWatcher() {
  yield filterPayments();
}

function* handleRequestsResponseWatcher({ payload }: HandleRequestsResponse) {
  try {
    const apiKeys: ApiKey[] = yield select(selectApiKeys);
    const request: RecurringPaymentRequest | null = getRequestFromResponse(payload, apiKeys);
    if(request !== null) {
      const requests: RecurringPaymentRequest[] = yield select(selectRecurringPaymentRequests);
      const isRequestExist = checkIsRequestWithIdExistsInHistory(request.id, requests);
  
      if(!isRequestExist) {
        yield put(addRequest(request));
      } else {
        yield put(updateRequest(request));
      }
    }
  }
  catch(e) {
    // log(e);
  }
}

function getFirstPaymentDueDate(firstPaymentDue: string): Date | never {
  let firstDate = parseDate(firstPaymentDue)
  if(!firstDate) {
      throw new ReferenceError('Can not resolve recurrying payment date ' + firstPaymentDue + ', invalid date');
  } 

  return firstDate;
}

function parseDate(datestring: string): Date | never {
  let miliseconds = Date.parse(datestring)
  if (isNaN(miliseconds) === false) {
      return new Date(miliseconds)   
  } else {
      throw new ReferenceError('Can not resolve recurrying payment date ' + datestring + ', invalid date');
  }
}

function getRequestFromResponse(resp: HandleRequestsResponsePayload, apiKeys:ApiKey[]): RecurringPaymentRequest | null {
  const response = resp.message.params[1];
  const state = response.state;
  console.log(state)
  if(!isRequestPaid(resp)) {
    return null;
  }

  //const response = resp.message.params[1];
  // /const state = response.state;

  console.log(state)
  let recurringPaymentAmount = getPropertyFrom(response, 'properties.paymentTerms', 'recurringPaymentAmount');
  let firstPaymentDue = getPropertyFrom(response, 'properties.paymentTerms', 'firstPaymentDue', true);
  let frequency = getPropertyFrom(response, 'properties.paymentTerms', 'frequency', true);
  let paymentReference = getPropertyFrom(response, 'properties.paymentTerms.paymentMethods[0]', 'paymentReference', true);
  let payeeName = getPropertyFrom(response, 'state', 'requesterName', true);
  let firstPaymentDueDate = getFirstPaymentDueDate(firstPaymentDue);
  firstPaymentDue = moment(firstPaymentDueDate).set({hour: 23, minute: 59, second: 59,});
  let finalPaymentDue = getPropertyFrom(response, 'properties.paymentTerms', 'finalPaymentDue');
  let firstPaymentAmount = getPropertyFrom(response, 'properties.paymentTerms', 'firstPaymentAmount', true)
  let finalPaymentAmount = getPropertyFrom(response, 'properties.paymentTerms', 'finalPaymentAmount');
  let numberOfPayments = getPropertyFrom(response, 'properties.paymentTerms', 'numberOfPayments');
  let firstRecurringPaymentDue = getPropertyFrom(response, 'properties.paymentTerms', 'firstRecurringPaymentDue');
  let paylink = getPropertyFrom(response, 'state', 'paylink');
  let requestId = getPropertyFrom(response, '', 'requestId');

  let apiKey  = getPropertyFrom(response, 'properties.paymentTerms.paymentMethods[0]', 'apikey');
  let apik = apiKeys.find(element => element.apiKey === apiKey);
  let website_url = apik?.websiteURL || ''

  const request: RecurringPaymentRequest = {
    id: state.id,
    creationTime: state.initiationTime,
    status: getRequestStatus(state.activationState),
    paylink: paylink,
    endToEndIdentification: EndToEndIdentification.BOPPButton,
    payeeName:payeeName,
    paymentReference:paymentReference,
    finalPaymentDue:finalPaymentDue,
    firstPaymentAmount:firstPaymentAmount,
    recurringPaymentAmount:recurringPaymentAmount,
    finalPaymentAmount:finalPaymentAmount,
    frequency:frequency,
    numberOfPayments:numberOfPayments,
    firstPaymentDue:firstPaymentDue,
    firstRecurringPaymentDue:firstRecurringPaymentDue,
    websiteURL:website_url,
    requestId:requestId
  }
  return request;
}

function isRequestPaid(resp: HandleRequestsResponsePayload): boolean {
  let isPaid = false
  const response = resp.message.params[1];
  const state = getPropertyFrom(response, '', 'state', true);
  const instructions: PaymentInstruction[] = Object.values(state?.instructions || {}).map((e: any) => ({
    id: e.id,
    entityState: e.entityState,
    activityState: e.activityState
  }));

  instructions.forEach(instruction => {
    if(instruction.entityState === PaymentInstructionEntityState.Activated && instruction.activityState === PaymentInstructionActivityState.Processing) {
      isPaid = true;
    }
  });

  return isPaid;
}

export function checkIsRequestWithIdExistsInHistory(id: string, requests: RecurringPaymentRequest[]): boolean {
  const index = requests.findIndex(request => request.id === id);
  if (index === -1) {
    return false
  } else {
    return true
  }
}

function* dispatchCancelRequest( socket: WrappedSocket, { payload }: any) {
  let cancelRequest = {
    "@type": "https://dvschema.io/activation#CancelRequest",
    id: createId(true),
    previousId: payload,
    entitySelector: {
      entityType: "https://miapago.io/paylink/request/v0.1.0#PaylinkRequest"
    },
    timestamp: new Date(),
    possibleResend: false
  }
  
  yield socket.send(wrap('paylink-initiator', cancelRequest), store.dispatch);
}

function getRequestStatus(activationState: string): PaymentRequestStatus {
  const status = activationState.substring(0, activationState.indexOf(':'));
  if(status === 'Activated') {
    return PaymentRequestStatus.Open
  } else {
    return PaymentRequestStatus.Closed
  }
}

function* handleRecurringRequestsCancel({ payload }: HandleRequestsResponse) {
  const requests:RecurringPaymentRequest[] = yield select(selectRecurringPaymentRequests);
  let request = requests.find((element: { id: string; }) => element.id === payload.message.params[1].state.id);
  if(request) {
    yield put(updateRecurringRequestStatus({request: request, status: PaymentRequestStatus.Closed}));
  } else {
    //drop error
  }
}

export default function* recurringRequestActivitySaga({ payLinkSocket }: RootSagaSockets) {
  yield takeLatest(FILTER_REQUESTS, filterRequestsWatcher);
  yield takeLatest(SORT_REQUESTS_TABLE, sortRequestsTableWatcher);
  yield takeLatest(SET_REQUESTS_TABLE_FILTERS, setRequestsTableFiltersWatcher);
  yield takeLatest(REFRESH_REQUESTS_FILTERS, refreshRequestsFiltersWatcher);
  yield takeLatest(HANDLE_RECURRING_REQUESTS_RESPONSE, handleRequestsResponseWatcher);
  yield takeLatest(CANCEL_RECURRING_REQUEST, dispatchCancelRequest, payLinkSocket);
  yield takeLatest(HANDLE_RECURRING_REQUEST_CANCEL_RESPONSE, handleRecurringRequestsCancel);
}