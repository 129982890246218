import React from 'react';
import classNames from 'classnames';
import {ButtonColor} from "../../static/CommonDefinitions";

import styles from './LoadingSpinner.module.scss';

export interface ILoadingSpinner {
    size?: number,
    color?: ButtonColor
}

export function LoadingSpinner ({size = 20, color = ButtonColor.Black}: ILoadingSpinner) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper__block}>
                <div className={classNames(styles.wrapper__block_item, styles.wrapper__block_item_first)} style={{width:size, height:size, backgroundColor:color}}/>
                <div className={classNames(styles.wrapper__block_item, styles.wrapper__block_item_second)} style={{width:size, height:size, backgroundColor:color}}/>
            </div>
            <div className={styles.wrapper__block}>
                <div className={classNames(styles.wrapper__block_item, styles.wrapper__block_item_fourth)} style={{width:size, height:size, backgroundColor:color}} />
                <div className={classNames(styles.wrapper__block_item, styles.wrapper__block_item_third)} style={{width:size, height:size, backgroundColor:color}} />
            </div>
        </div>
    )
}
