import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ActivationCodeInput } from '../../../components/activationCodeInput/ActivationCodeInput';
import { Button } from '../../../components/button/Button';
import CrossIcon from '../../../assets/images/close-icon.svg';
import CloseIcon from '../../../assets/images/close-arrow-24px-rounded.svg';
import { ButtonStyle } from '../../../static/CommonDefinitions';
import {
  BackgroundColors,
  BackgroundContainer,
} from '../../../components/background/BackgroundContainer';

import styles from './ActivateAccountError.module.scss';
import mobileStyles from './ActivateAccountErrorMobile.module.scss';
import { AuthCodeActivationStatus } from '../../activateAccount/static/activateAccountCommonDefinitions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectAuthCode,
  selectAuthCodeActivationStatus,
  selectTooManyAttempts,
  setAuthCode,
  selectAuthCodeUnknownError,
  setAuthCodeActivationStatus,
} from '../../activateAccount/logic/activateAccountSlice';
import {
  initActivationTimers,
  registerAuthCode,
  updateRoute,
} from '../../activateAccount/logic/activateAccountSaga';
import { linkAnotherAccount } from '../../bankAccount/logic/bankAccountSaga';
import { SelectionModal } from '../../modals/SelectionModal/SelectionModal';
import { MobileHeader } from '../../../components/mobileHeader/MobileHeader';
import {
  MobileHeaderColor,
  MobileHeaderPadding,
} from '../../../components/mobileHeader/static/mobileHeaderCommonDefinitions';
import config from '../../../config';
import { isDesktopEnv } from '../../../helpers';
import { selectIsSignedIn } from '../../../core/reducers/appSlice';

type PageTitles = {
  [key in AuthCodeActivationStatus]: string;
};

const pageTitles: PageTitles = {
  [AuthCodeActivationStatus.notExist]:
    'Unfortunately, we can’t find this Activation code in our system. Please try again or visit our help page.',
  [AuthCodeActivationStatus.codeLinkedWithAnother]:
    'This Activation code is associated with another bank account. You can link another bank account or try again with another Activation code.',
  [AuthCodeActivationStatus.bankLinkedWithAnother]:
    'This bank account is already associated with another Activation code. Please use the Activation code already associated with this bank account.',
  [AuthCodeActivationStatus.deactivated]:
    'Subscription deactivated, please activate you subscription and try again',
  [AuthCodeActivationStatus.unknownError]: 'Unknown error',
  [AuthCodeActivationStatus.success]: '',
  [AuthCodeActivationStatus.pending]: '',
};

export function ActivateAccountError() {
  const dispatch = useAppDispatch();
  const activationStatus = useAppSelector(selectAuthCodeActivationStatus);
  const history = useHistory();
  const authCodeFromInput = useAppSelector(selectAuthCode);
  const tooManyAttempts = useAppSelector(selectTooManyAttempts);
  const [showModal, setShowModal] = useState(false);
  const unknownError = useAppSelector(selectAuthCodeUnknownError);
  const isSignedIn = useAppSelector(selectIsSignedIn)

  const title =
    config.appEnvironment !== 'PROD' &&
    activationStatus === AuthCodeActivationStatus.unknownError
      ? unknownError
      : pageTitles[activationStatus];

  useEffect(() => {
    dispatch(updateRoute());
    dispatch(initActivationTimers());

    return () => {
      dispatch(setAuthCodeActivationStatus(AuthCodeActivationStatus.pending))
    }
  }, []);

  if (tooManyAttempts) {
    return <></>;
  }

  const handleCode = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setAuthCode(e.target.value));
  };

  const goToLinkAnotherAccount = () => {
    history.push('/bank-account/retrieve');
    dispatch(linkAnotherAccount());
  };

  const showLinkAnotherAccount =
    activationStatus === AuthCodeActivationStatus.bankLinkedWithAnother ||
    activationStatus === AuthCodeActivationStatus.codeLinkedWithAnother;

  const buttonDisabled = authCodeFromInput.length !== 8;
  const handleButtonClick = () => dispatch(registerAuthCode());

  const renderModal = () => {
    return (
      showModal && (
        <SelectionModal
          text="If you leave this screen your account details will not be saved and you will need to restart the process in order to use BOPP dashboard in this browser."
          optionTop="leave anyway"
          optionBottom="cancel"
          selectOptionTop={goToLinkAnotherAccount}
          selectOptionBottom={() => setShowModal(false)}
        />
      )
    );
  };

  const goBack = () => {
    if(isSignedIn){
      history.replace("/account-management")
    } else {
      setShowModal(true)
    }
  }

  if (isDesktopEnv()) {
    return (
      <BackgroundContainer
        onBackButtonPressed={goBack}
        backButton={true}
        color={BackgroundColors.Red}>
        <div className={styles.container}>
          <div>
            <img src={CrossIcon} alt="cross" />
            <h4 className={styles.container__title}>Failure</h4>
            <p className={styles.container__text}>{title}</p>
            <a target="_blank" rel="noopener noreferrer" href="https://help.bopp.io/" className={styles.container__link}>
              Learn More
            </a>
            <ActivationCodeInput
              label="Activation Code"
              value={authCodeFromInput}
              handleChange={handleCode}
              error={true}
            />
          </div>
          <div className={styles.container__button}>
            <Button
              disabled={buttonDisabled}
              onClick={handleButtonClick}
              title="ACTIVATE NOW"
              buttonHeight="50px"
              style={ButtonStyle.Rounded}
            />
            {showLinkAnotherAccount && (
              <a href="#" onClick={goToLinkAnotherAccount}>
                Link another account
              </a>
            )}
          </div>
          {renderModal()}
        </div>
      </BackgroundContainer>
    );
  }

  return (
    <div className={mobileStyles.container}>
      <MobileHeader
        withLogo
        isMenu={false}
        rightIcon={<img src={CloseIcon} alt="" onClick={goBack} />}
        color={MobileHeaderColor.red}
        padding={MobileHeaderPadding.small}
        onBackButtonPressed={goBack}
      />
      <div className={mobileStyles.container__center}>
        <img
          src={CrossIcon}
          alt="cross"
          className={mobileStyles.container__top_icon}
        />
        <h4 className={mobileStyles.container__title}>Failure</h4>
        <p className={mobileStyles.container__text}>{title}</p>
        <a target="_blank" rel="noopener noreferrer" href="https://help.bopp.io/" className={mobileStyles.container__link}>
          Learn More
        </a>
        <ActivationCodeInput
          label="Activation Code"
          value={authCodeFromInput}
          handleChange={handleCode}
          error={true}
        />
      </div>
      <div className={mobileStyles.container__bottom}>
        <Button
          disabled={buttonDisabled}
          onClick={handleButtonClick}
          title="ACTIVATE NOW"
          style={ButtonStyle.Rounded}
        />
        {showLinkAnotherAccount && (
          <a href="#" onClick={goToLinkAnotherAccount}>
            Link another account
          </a>
        )}
      </div>
      {renderModal()}
    </div>
  );
}
