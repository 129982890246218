import React, { useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/button/Button';
import { ButtonColor, ButtonStyle } from '../../../static/CommonDefinitions';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import styles from './HomePage.module.scss';
import mobileStyles from './HomePageMobile.module.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBankDetails } from '../../bankAccount/logic/bankAccountSlice';
import {
  selectPayments,
  selectPaymentSubscriber,
} from '../../activity/logic/activitySlice';
import { getPayments } from '../../activity/logic/activitySaga';
import config from '../../../config';
import { isDesktopEnv } from '../../../helpers';
import LinkIcon from '../../../assets/images/link.svg';
import { Header } from '../../../components/header/ui/Header';

export function HomePage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const bank = useAppSelector(selectBankDetails);
  const payments = useAppSelector(selectPayments);
  const paymentSubscriber = useAppSelector(selectPaymentSubscriber);

  useEffect(() => {
    dispatch(getPayments({ id: paymentSubscriber }));
  }, []);

  const goToPaymentSummary = () => {
    history.push('/payment-summary');
  };

  const handleClickManage = () => {
    history.push('/bankAccount');
  };

  if (isDesktopEnv()) {
    return (
      <BackgroundContainer isMenu={true}>
        <div className={styles.cc}>
        <div className={styles.container}>
          <div className={styles.container__block}>
            <iframe src={config.dynamicMessage} />
          </div>
          <div className={styles.container__block}>
            <h3 className={styles.container__block__title}>Recent activity</h3>
            {payments.slice().reverse().map((payment, index) => {
              if (index < 5) {
                return (
                  <div className={styles.container__block__wrapper} key={index}>
                    <div className={styles.container__block__wrapper_time}>
                      {moment(payment.activityTime).format('DD MMM YYYY, HH:mm')}
                    </div>
                    <div className={styles.container__block__wrapper_name}>
                      {payment.reference}
                    </div>
                    <div className={styles.container__block__wrapper_url}>
                      {payment.siteUrl}
                    </div>
                  </div>
                );
              }
            })}
            {payments.length > 5 && (
              <p
                className={styles.container__block__link}
                onClick={goToPaymentSummary}>
                More...
              </p>
            )}
          </div>
          <div className={styles.container__block}>
            <h3 className={styles.container__block__title}>
              Bank account details
            </h3>
            <div className={styles.container__block__account}>
              <div className={styles.container__block__account__wrapper}>
                <img
                  className={styles.container__block__account__logo}
                  src={bank.logo}
                  alt="logo"
                />
                <p className={styles.container__block__title}>{bank.bankName}</p>
              </div>
              <div className={styles.container__block__account__button}>
                <Button
                  title="manage bank account"
                  style={ButtonStyle.Rounded}
                  color={ButtonColor.White}
                  handleClick={handleClickManage}
                />
              </div>
            </div>
          </div>
        </div>
        </div>
      </BackgroundContainer>
    );
  }

  return (
    <div className={mobileStyles.homePage}>
      <Header title="Dashboard" />
      <main className={mobileStyles.homePage__main}>
        <div className={mobileStyles.homePage__block}>
          <iframe src={config.dynamicMessage} />
          <div className={mobileStyles.homePage__transparent_layer} />
        </div>
        <div className={mobileStyles.homePage__block}>
          <div className={mobileStyles.homePage__activity_header}>
            <h3 className={mobileStyles.homePage__block__title}>Recent activity</h3>
            <img src={LinkIcon} alt="link" onClick={goToPaymentSummary} />
          </div>
          {payments.slice().reverse().map((payment, index) => {
            if (index < 5) {
              return (
                <div className={mobileStyles.homePage__block__wrapper} key={index}>
                  <div className={mobileStyles.homePage__block__wrapper_time}>
                    {moment(payment.activityTime).format('DD MMM YYYY, HH:mm')}
                  </div>
                  <div className={mobileStyles.homePage__block__wrapper_name}>
                    {payment.reference}
                  </div>
                  <div className={mobileStyles.homePage__block__wrapper_url}>
                    {payment.siteUrl}
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className={mobileStyles.homePage__block}>
          <h3 className={mobileStyles.homePage__block__title}>
            Bank account details
          </h3>
          <div className={mobileStyles.homePage__block__account}>
            <div className={mobileStyles.homePage__block__account__wrapper}>
              <img
                className={mobileStyles.homePage__block__account__logo}
                src={bank.logo}
                alt="logo"
              />
              <p className={mobileStyles.homePage__block__title}>{bank.bankName}</p>
            </div>
            <div className={mobileStyles.homePage__block__account__button}>
              <Button
                title="manage bank account"
                style={ButtonStyle.Rounded}
                color={ButtonColor.White}
                handleClick={handleClickManage}
                className={mobileStyles.homePage__manageAccountButton}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
