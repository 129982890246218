import classNames from "classnames";
import React from "react";
import styles from '../settingsReview/SettingsReview.module.scss';

export interface SettingsReviewTextItemProps {
  title: string;
  value: string;
  className?: string;
}

export function SettingsReviewTextItem({ title, value, className }: SettingsReviewTextItemProps) {
  const finalClassName = classNames([
    styles.settings_review_item,
    className,
  ]);

  return (
      <div className={finalClassName}>
          <span className={styles.settings_review_item_title}>{ title }</span>
          <span className={styles.settings_review_item_value}>{ value }</span>
      </div>
  )
}
