export function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    const input = document.createElement("input");

    input.value = text;
    input.style.position="fixed";
    document.body.appendChild(input);
    input.select();

    document.execCommand('copy');
    document.body.removeChild(input)            
    return;
  }

  navigator.clipboard.writeText(text);
}
