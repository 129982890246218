import React from "react";
import classNames from "classnames";
import moment from "moment";
import { useAppSelector } from "../../../../../app/hooks";
import { applyTabFocusSupport, stringWithCommas } from "../../../../../helpers";
import { selectPaymentSettings } from "../../../logic/paymentRequestSlice";
import { selectAmount } from "../../../logic/paymentSlice";
import { PaymentTrigger, ReferenceSettingType, TermType } from "../../../static/paymentRequestConstants";
import { selectSubscriptionType } from "../../../../subscriptionManagement/logic/subscriptionManagementSlice";
import { ButtonColor, ButtonStyle, SubscriptionType } from "../../../../../static/CommonDefinitions";
import { Button } from "../../../../../components/button/Button";

import RequestGrayIcon from '../../../../../assets/images/request-gray.svg';
import InfoIcon from '../../../../../assets/images/info-grey.svg';
import styles from './SettingsReview.module.scss';
import { SettingsReviewTextItem } from "../settingsReviewTextItem/SettingsReviewTextItem";
import { SettingsReviewBooleanItem } from "../settingsReviewBooleanItem/SettingsReviewBooleanItem";

export interface SettingsReviewProps {
  saveChangeSettingsAndCreatePaylink: () => void;
  showLoadingSpinner: boolean;
  createRequestButtonDisabled: boolean;
}

export const SettingsReview = ({saveChangeSettingsAndCreatePaylink, showLoadingSpinner, createRequestButtonDisabled} : SettingsReviewProps) => {
  const subscriptionType = useAppSelector(selectSubscriptionType);
  const { reference, expire, amount, requestEmail, notes, thankYouNote, giftAid } = useAppSelector(selectPaymentSettings);
  const requestAmount = useAppSelector(selectAmount);
  const formattedRequestAmount = Number(requestAmount).toFixed(2).toString();

  const headerAmount = amount.type === TermType.AnyAmount ? 'Payment amount set by payer' : '£' + stringWithCommas(formattedRequestAmount);
  let headerRef = '';
  let refItemValue = '';
  let amountItemValue = '';
  let expireItemValue = '';

  switch(reference.type) {
      case ReferenceSettingType.SetByPayer:
          refItemValue = 'Set by payer';
          break;
      case ReferenceSettingType.SetByMe:
          headerRef = reference.setByMeValue;
          refItemValue = 'Set by me';
          break;
      case ReferenceSettingType.Autonumber:
          headerRef = reference.autonumberValue;
          refItemValue = 'Auto-number'
          break;
  }

  switch(amount.type) {
      case TermType.FixedAmount:
          amountItemValue = 'Fixed';
          break;
      case TermType.AnyAmount:
          amountItemValue = 'Set by payer';
          break;
      case TermType.MinAmount:
          amountItemValue = 'Min Amount';
          break;
      case TermType.SuggestedAmount:
          amountItemValue = 'Suggested';
          break;
  }

  switch(expire.type) {
      case PaymentTrigger.OnInstruction:
          expireItemValue = 'Single Use';
          break;
      case PaymentTrigger.EachInstruction:
          expireItemValue = 'Multi use';
          break;
      case PaymentTrigger.XInstructions:
          expireItemValue = `After ${expire.xInstructionsValue || 'X'} ${+expire.xInstructionsValue === 1 ? 'payment' : 'payments'}`;
          break;
  }

  if(expire.onCertainDate && expire.certainDate) {
      expireItemValue += ` until ${moment(expire.certainDate).format('DD MMM. YYYY')}`;
  }

  const headerAmountClassName = classNames([
      styles.settings_review_header_amount,
      {[styles['settings_review_header_amount--empty']]: !requestAmount.length && amount.type !== TermType.AnyAmount},
      {[styles['settings_review_header_amount--set_by_payer']]: amount.type === TermType.AnyAmount},
  ]);

  return (
      <div className={styles.settings_review}>
          <div className={styles.settings_review_header}>
              <span className={styles.settings_review_header_title}>Request Preview</span>
              <div className={styles.settings_review_header_icon}>
                  <img src={RequestGrayIcon} alt={''} />
              </div>
              <span className={headerAmountClassName}>{ headerAmount }</span>
              <span className={styles.settings_review_header_reference}>{ headerRef }</span>
          </div>
          <div>
              <SettingsReviewTextItem title='Payment Amount' value={ amountItemValue } />
              <SettingsReviewTextItem title='Payment Reference' value={ refItemValue } />
              <SettingsReviewTextItem title='Expiry Settings' value={expireItemValue} />
              <SettingsReviewBooleanItem title='Request emails' value={requestEmail.enabled} />
              <SettingsReviewBooleanItem title='Notes' value={notes.enabled} />
              <SettingsReviewBooleanItem title='Thank You Note' value={thankYouNote.enabled} />
              {
                  subscriptionType === SubscriptionType.Charity &&
                  <SettingsReviewBooleanItem title='Gift Aid' value={giftAid.enabled} />
              }
              <SettingsReviewBooleanItem title='Receive Marketing Info' value={requestEmail.enabled && requestEmail.displayMarketingOptIn} />
          </div>
          <div className={styles.settings_review_bottom}>
              <Button
                  spinner={showLoadingSpinner}
                  disabled={createRequestButtonDisabled}
                  title='Create Request'
                  color={ButtonColor.Yellow}
                  style={ButtonStyle.Rounded}
                  className={styles.settings_review_bottom_button}
                  onClick={saveChangeSettingsAndCreatePaylink}
              />
              <div className={styles.settings_review_info}>
                  <img src={InfoIcon} alt={''} className={styles.settings_review_info_icon} />
                  <span>
                      BOPP accounts are subject to account limits.
                      <br />
                      <a
                        { ...applyTabFocusSupport() }
                        className={styles.settings_review_info_link}
                        target="_blank"
                        href='https://help.bopp.io/portal/en/kb/articles/do-you-have-limits-for-payment-requests'
                      >
                        Learn more
                    </a>
                  </span>
              </div>
          </div>
      </div>
  )
}
