import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useMemo, ChangeEvent, useRef } from 'react';
import { getBankList } from '../logic/ChooseBankSaga';
import Search from '../../../assets/images/search.svg';
import DefaultIcon from '../../../assets/images/bank-icon.svg';
import CloseIcon from '../../../assets/images/close-icon-thin.svg';
import { BankItem } from '../../../components/bankItem/BunkItem';
import {
  Bank,
  selectBankList,
  selectByAccountName,
} from '../logic/ChooseBankSlice';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import { StatusBank } from '../../../static/CommonDefinitions';

import styles from './ChooseBank.module.scss';
import mobileStyles from './ChooseBankMobile.module.scss';
import {
  changeSelectedBank,
  changeSelectedBankSupportsAISPFlow,
} from '../../retrieveAccountDetails/logic/retrieveAccountDetailsSlice';
import { useScrollPosition } from '../../../hooks/useScrollPosition';
import classNames from 'classnames';
import { isDesktopEnv } from '../../../helpers';

export function ChooseBank() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [input, setInput] = useState('');
  const bankList = useSelector(selectBankList);
  const filteredData = useSelector(selectByAccountName(input.toLowerCase()));
  const bankItemsRef = useRef<HTMLDivElement>(null);
  const bankItemsScroll = useScrollPosition(bankItemsRef.current);

  const otherBank:Bank = {
    accountName: 'Other',
    api: 'did:mia:ob:ss:general#other',
    status: 'healthy',
    update_time: '2021-04-12T15:24:26.786037524Z',
    friendly_name: 'Other',
    logo_uri: 'https://boppapp-dev.netlify.app/banklogos/defoult-icon-black.png',
    short_description: '',
  }

  const list = useMemo(
    () => (input ? filteredData : bankList),
    [bankList, input, filteredData],
  );

  useEffect(() => {
    dispatch(getBankList());
  }, [dispatch]);

  const filterList = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const sortList = (list: Bank[]) => {
    return list
      .slice()
      .sort((bank1, bank2) =>
        bank1.friendly_name.localeCompare(bank2.friendly_name),
      );
  };

  const chooseBank = (selectBank: Bank) => {
    if (selectBank.status === StatusBank.Unhealthy) {
      return;
    }
    const supportsAISPFlow = !selectBank.api.includes('general');
    dispatch(changeSelectedBankSupportsAISPFlow(supportsAISPFlow));
    dispatch(changeSelectedBank(selectBank as Bank));
    history.goBack();
  };

  const showTopShadow = bankItemsScroll.y > 0;

  if (isDesktopEnv()) {
    const topClassName = classNames([
      styles.container__top,
      showTopShadow && styles['container__top--with_shadow'],
    ]);

    return (
      <BackgroundContainer backButton={true}>
        <div className={styles.container}>
          <div className={topClassName}>
            <div className={styles.container__title}>Choose Bank</div>
            <div className={styles.container__search_wrapper}>
              <div className={styles.container__search_wrapper__search_request}>
                <img src={Search} alt="" />
                <input
                  type="text "
                  value={input}
                  onChange={filterList}
                  placeholder="Search.."
                />
              </div>
            </div>
          </div>
          <div className={styles.container__bank_items} ref={bankItemsRef}>
            {Boolean(list.length) &&
              sortList(list).map((bank, index) => (
                <BankItem
                  key={index}
                  bankStatus={bank.status}
                  bankLogo={bank.logo_uri}
                  defaultLogo={DefaultIcon}
                  bankName={bank.friendly_name}
                  handleChooseBank={() => chooseBank(bank)}
                />
              ))}
              <BankItem
                  key={-1}
                  bankStatus={otherBank.status}
                  bankLogo={otherBank.logo_uri}
                  defaultLogo={DefaultIcon}
                  bankName={otherBank.friendly_name}
                  handleChooseBank={() => chooseBank(otherBank)}
                />
          </div>
        </div>
      </BackgroundContainer>
    );
  }

  const topClassName = classNames([
    mobileStyles.container__top,
    showTopShadow && mobileStyles['container__top--with_shadow'],
  ]);

  return (
    <div className={mobileStyles.container}>
      <div className={topClassName}>
        <div className={mobileStyles.container__header}>
          <div className={mobileStyles.container__title}>Choose Bank</div>
          <img
            src={CloseIcon}
            alt="Close"
            className={mobileStyles.container__close_icon}
            onClick={() => history.goBack()}
          />
        </div>
        <div className={mobileStyles.container__search_wrapper}>
          <div
            className={mobileStyles.container__search_wrapper__search_request}>
            <img src={Search} alt="" />
            <input
              type="text "
              value={input}
              onChange={filterList}
              placeholder="Search.."
            />
          </div>
        </div>
      </div>
      <div ref={bankItemsRef} className={mobileStyles.container__bank_items}>
        {Boolean(list.length) &&
          sortList(list).map((bank, index) => (
            <BankItem
              key={index}
              bankStatus={bank.status}
              bankLogo={bank.logo_uri}
              defaultLogo={DefaultIcon}
              bankName={bank.friendly_name}
              handleChooseBank={() => chooseBank(bank)}
            />
          ))}
          <BankItem
                  key={-1}
                  bankStatus={otherBank.status}
                  bankLogo={otherBank.logo_uri}
                  defaultLogo={DefaultIcon}
                  bankName={otherBank.friendly_name}
                  handleChooseBank={() => chooseBank(otherBank)}
                />
      </div>
    </div>
  );
}
