import { useEffect } from 'react';
import { useHistory } from 'react-router';

export function Cb() {
  const history = useHistory();

  useEffect(() => {
    const url = new URL(window.location.href);
    let params =
      url.hash && url.hash.length > 1
        ? new URLSearchParams(url.hash.substr(1))
        : url.searchParams;
    const code: string | null = params.get('code');
    const state: string | null = params.get('state');
    const error: string | null = params.get('error');
    history.replace('/bank-account/saving', {
      code,
      state,
      error,
      type: 'bankConsent',
    });
  }, []);
  return <></>;
}
