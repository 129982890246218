import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import classNames from 'classnames';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import { cssVhToPx, getReferenceValueFromSettings, isDesktopEnv, applyTabFocusSupport } from '../../../helpers';

import CloseIcon from "../../../assets/images/close-arrow-24px-rounded.svg";

import styles from './RequestDetails.module.scss';
import mobileStyles from './RequestDetailsMobile.module.scss';
import { StylizedWrapperWithRadius } from '../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import { QRCodeBlock } from '../../../components/QRCodeBlock/QrCodeBlock';
import { Button } from '../../../components/button/Button';
import { ButtonColor, ButtonStyle } from '../../../static/CommonDefinitions';
import { useAppSelector } from '../../../app/hooks';
import { selectPaymentRequests } from '../../requestActivity/logic/requestActivitySlice';
import { PaymentRequest } from '../../requestActivity/static/requestActivityTypes';
import { DefaultSettings, selectPaymentSettings } from '../../paymentRequest/logic/paymentRequestSlice';
import { ReferenceSettingType, TermType } from '../../paymentRequest/static/paymentRequestConstants';
import { selectAmount } from '../../paymentRequest/logic/paymentSlice';
import { copyToClipboard } from '../../../utils/clipboard';
import { Header } from '../../../components/header/ui/Header';
import { HeaderButton } from '../../../components/header/static/headerCommonDefinitions';
import { formatPaylinkUrl } from '../../../utils/paylinkUrl';
import { Toast } from '../../../components/toast/Toast';
import { ToastIcon } from '../../../components/toast/static/toastCommonDefinitions';

export interface RequestDetailsocationState {
  requestId: string;
}

export function RequestDetails() {
  const history = useHistory();
  const location = useLocation();
  const { requestId } = location.state as RequestDetailsocationState;
  const requests = useAppSelector(selectPaymentRequests);
  const paymentSettings: DefaultSettings = useAppSelector(selectPaymentSettings);
  const amount = useAppSelector(selectAmount);
  const [fixedAmount, setFixedAmount] = useState(true);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [popupTimeout, setPopupTimeout] = useState<NodeJS.Timeout>();
  const [paylink, setPaylink] = useState('');
  const [isInHistory, setIsInHistory] = useState(requestId.startsWith('cri:') ? true : false);

  const targetRequest: PaymentRequest | null = isInHistory ? (requests.find(request => request.id === requestId) || null) : null;
  const QRUrl = formatPaylinkUrl(paylink);

  useEffect(() => {
    setPaylink((isInHistory && targetRequest) ? targetRequest.paylink : `paylink://bopp.link/${requestId}`);
  }, [requestId]);

 useEffect(() => {
    if(isInHistory) {
      if(targetRequest?.paymentSettings?.amountType === TermType.AnyAmount){
          setFixedAmount(false);
      }
  } else if(paymentSettings.amount.type === TermType.AnyAmount) {
      setFixedAmount(false);
  }
  }, [paymentSettings]);

  const isDesktop = isDesktopEnv();

  const copyPaylink = () => {
    copyToClipboard(QRUrl);
    setShowInfoPopup(true);

    if (popupTimeout !== undefined) {
      clearTimeout(popupTimeout);
    }
  }

  const closeButtopPressed = () => {
    history.replace('/request-activity')
  }

  const saveQRCode = () => {
    let qr = document.querySelector('.QR_code_block')?.children[0];
    
    if(qr) {
      const MIME_TYPE = "image/png";
      const canvas = qr as HTMLCanvasElement;
      const imgURL = canvas?.toDataURL(MIME_TYPE);
      
      const dlLink = document.createElement('a');
      dlLink.download = 'QR';
      dlLink.href = imgURL;
      dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':');

      document.body.appendChild(dlLink);
      dlLink.click();
      document.body.removeChild(dlLink);
    }
  }

  const renderInfoPopup = () => {
    const targetStyles = isDesktop ? styles : mobileStyles;

    const handleClose = () => setShowInfoPopup(false);

    return (
      <Toast
        title='Paylink copied'
        icon={ToastIcon.Success}
        onClose={handleClose}
        className={targetStyles.request_details__popup}
      />
    )
  }

  const renderAmount = () => {
    const formattedAmount = new Number(targetRequest?.amount.value.value).toFixed(2);
    if (fixedAmount) {
      return '£' + (targetRequest?.amount.value.value ? formattedAmount : amount);
    }
    else {
      return 'Payment amount set by payer';
    }
  }

  if (isDesktop) {
    const amountClassName = classNames([
      styles.request_details__card_amount,
      {[styles['request_details__card_amount--any']]: !fixedAmount},
    ])

    return (
      <BackgroundContainer isMenu>
        <div className={styles.request_details}>
          <Header withBackButton title='Share Request' />
          <main>
            <StylizedWrapperWithRadius style='bRadius12' className={styles.request_details__card}>
              <>
                <img className={styles.request_details__card_close_icon} alt={''} src={CloseIcon} onClick={closeButtopPressed} { ...applyTabFocusSupport() } />
                <span className={styles.request_details__card_title}>Your Payment Request</span>
                <div className={styles.request_details__card_info_container}>
                  <div className={styles.request_details__card_QR_container}>
                    <QRCodeBlock url={QRUrl} />
                  </div>
                  <span className={amountClassName}>{ renderAmount() }</span>
                <span className={styles.request_details__card_reference}>{isInHistory ? targetRequest?.reference : getReferenceValueFromSettings(paymentSettings.reference) }</span>
                </div>
                <span className={styles.request_details__card_hint}>Copy and Share a paylink for this request or save a QR code to send it to a payer</span>
                <div className={styles.request_details__card_bottom}>
                  { showInfoPopup &&  renderInfoPopup()}
                  <Button
                    title='Copy Paylink'
                    style={ButtonStyle.Rounded}
                    color={ButtonColor.Yellow}
                    className={styles.request_details__card_copy_button}
                    onClick={copyPaylink}
                  />
                  <span { ...applyTabFocusSupport() } className={styles.request_details__card_save_button} onClick={saveQRCode}>Save QR Code</span>
                </div>
              </>
            </StylizedWrapperWithRadius>
          </main>
        </div>
      </BackgroundContainer>
    )
  }

  const amountStyles = inlineStyles[fixedAmount ? 'request_details__card_amount' : 'request_details__card_amount--any']; 

  return (
    <div className={mobileStyles.request_details}>
      <Header withBackButton title='Share Request' mobileRightButton={HeaderButton.Close} />
      <div className={mobileStyles.request_details__card} style={inlineStyles['request_details__card']}>
        <div className={mobileStyles.request_details__card_top} style={inlineStyles['request_details__card_top']}>
          <span className={mobileStyles.request_details__card_title} style={inlineStyles['request_details__card_title']}>Your Payment Request</span>
        </div>
        <div className={mobileStyles.request_details__card_center}>
          <div className={mobileStyles.request_details__card_center_inner} style={inlineStyles['request_details__card_center_inner']}>
            <div className={mobileStyles.request_details__card_QR_container} style={inlineStyles['request_details__card_QR_container']}>
              <QRCodeBlock url={QRUrl} />
            </div>
            <span className={mobileStyles.request_details__card_amount} style={amountStyles}>{ renderAmount() }</span>
          <span className={mobileStyles.request_details__card_reference}>{isInHistory ? targetRequest?.reference : getReferenceValueFromSettings(paymentSettings.reference) }</span>
          </div>
        </div>
        <span className={mobileStyles.request_details__card_hint} style={inlineStyles['request_details__card_hint']}>Copy and Share a paylink for this request or save a QR code to send it to a payer</span>
        <div className={mobileStyles.request_details__card_bottom}>
          { showInfoPopup &&  renderInfoPopup()}
          <Button
            title='Copy Paylink'
            color={ButtonColor.Yellow}
            style={ButtonStyle.Rounded}
            className={mobileStyles.request_details__card_copy_button}
            onClick={copyPaylink}
            buttonHeight={cssVhToPx(8.4)}
          />
          <span
            className={mobileStyles.request_details__card_save_QR_link}
            style={inlineStyles['request_details__card_save_QR_link']}
            onClick={saveQRCode}
          >
            Save QR Code
          </span>
        </div>
      </div>
    </div>
  )
}


const inlineStyles = {
  ['request_details__card']: {
    padding: `${cssVhToPx(4.5)} 5.334% ${cssVhToPx(3)}`,
  },
  ['request_details__card_top']: {
    marginBottom: cssVhToPx(3.75),
  },
  ['request_details__card_title']: {
    fontSize: cssVhToPx(3),
  },
  ['request_details__card_center_inner']: {
    paddingBottom: cssVhToPx(3),
  },
  ['request_details__card_QR_container']: {
    marginBottom: cssVhToPx(3.6),
  },
  ['request_details__card_amount']: {
    fontSize: cssVhToPx(5.4),
  },
  ['request_details__card_amount--any']: {
    fontSize: cssVhToPx(3),
    marginBottom: cssVhToPx(1.5),
  },
  ['request_details__card_hint']: {
    fontSize: cssVhToPx(1.8),
    paddingTop: cssVhToPx(3),
    marginBottom: cssVhToPx(4.5),
  },
  ['request_details__card_save_QR_link']: {
    marginTop: cssVhToPx(3),
    fontSize: cssVhToPx(2.25),
  },
}