import { SortingType } from "../static/activityCommonDefinitions"

export const activityMigration = (state:any, version:number) => {  
    switch(version) {
        case (0): {
            return {
                ...state.activity,        
                filters: {          
                    ...state.activity.filters,
                    searchQuery: ''
                },
                pendingPayments:[],
                tableSorting: {
                    ...state.activity.tableSorting,
                    id: SortingType.INACTIVE,
                    payerEmail: SortingType.INACTIVE,
                },
                areFiltersClear: true,
            }  
        }
    }
}

export const getMigrationValues = (version:number) : Map<String, any> => {  
    let map = new Map<String, any>()
    map.set('filters.searchQuery', '')
    map.set('pendingPayments', [])
    map.set('tableSorting.id', SortingType.INACTIVE)
    map.set('tableSorting.payerEmail', SortingType.INACTIVE)
    map.set('areFiltersClear', true)

    return map
} 
