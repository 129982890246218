import React, { useRef } from 'react';
import { isDesktopEnv } from "../../helpers";
import CloseIcon from '../../assets/images/close-icon-white.svg';
import desktopStyles from './HintPopup.module.scss';
import  mobileStyles from './HintPopupMobile.module.scss';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import classNames from 'classnames';

interface FeeCalculationPopUpProps {
    title?: string;
    text?: string;
    content?: JSX.Element,
    className?: string,
    handleCloseModal: () => void;
}

const HintPopup = ({ title, text, content, className, handleCloseModal }: FeeCalculationPopUpProps) => {
    const isDesktop = isDesktopEnv();
    const targetStyles = isDesktop ? desktopStyles : mobileStyles;
    const modalRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = () => {
        isDesktop && handleCloseModal()
    }

    useOutsideClick(modalRef, handleOutsideClick);

    return(
        <div ref={modalRef} className={classNames([targetStyles.modalWrapper, className])}>
            {
                content
                ? content
                : (
                    <>
                        <div className={targetStyles.titleWrapper}>
                            <span>{ title }</span>
                            <img
                                src={CloseIcon}
                                alt='close'
                                onClick={handleCloseModal}
                            />
                        </div>
                        <div className={targetStyles.textWrapper}>
                            { text }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default HintPopup;
