export const requestActivityMigration = (state:any, version:number) => {  
    switch(version) {
        case (0): {
            return {      
                ...state.requestActivity,  
                cancelRequests:[],
                filters:{
                    ...state.requestActivity.filters,
                    searchQuery:''
                },
                isCancelInProgress:false,
                isShowModalForCancel:false,
                requestsApiKeys:{}
            }  
        }
    }
} 