import classNames from "classnames";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { isDesktopEnv, isEmptyString } from "../../../../../helpers";
import { validateThankYouNote } from "../../../../../helpers/dataFieldsValidation";
import { selectInputsErrors, selectPaymentSettings, setInputsErrors } from "../../../logic/paymentRequestSlice";
import { SettingsInputType } from "../../../static/paymentRequestConstants";
import { SettingsInput } from "../../additionalComponents/settingsInput/SettingsInput";
import { SettingsVariantProps } from "../activeSwitchSettingsVariants/ActiveSwitchSettingsVariants";
import styles from '../CommonSettingsStyles.module.scss';
import mobileStyles from '../CommonSettingsStylesMobile.module.scss';

export const ThankYouNoteSettingsVariant = ({ settings, onSettingChange }: SettingsVariantProps) => {
  const dispatch = useAppDispatch();
  const paymentSettings = useAppSelector(selectPaymentSettings);
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;
  const inputsErrors = useAppSelector(selectInputsErrors);

  const handleThankYouNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (validateThankYouNote(value)) {
      onSettingChange({
        ...paymentSettings,
        thankYouNote: {
          ...paymentSettings.thankYouNote,
          message: value
        }
      })

      dispatch(setInputsErrors({
        ...inputsErrors,
        thankYouMessage: false,
      }));
    }
  }


  const handleThankYouNoteInputBlur = () => {
    dispatch(setInputsErrors({
      ...inputsErrors,
      thankYouMessage: isEmptyString(paymentSettings.thankYouNote.message),
    }));
  }

  const finalClassName = classNames([
    targetStyles.amount_settings_reference_input,
    targetStyles.amount_settings_thank_you_note_input,
  ])

  return(
    <SettingsInput
      style={finalClassName}
      settingType={SettingsInputType.TextInput}
      inputRequirement={'Max 80 chars'}
      placeholder={'Add thank you message...'}
      value={paymentSettings.thankYouNote.message}
      error={inputsErrors.thankYouMessage}
      handleInputValueChange={handleThankYouNoteChange}
      handleInputBlur={handleThankYouNoteInputBlur}
    />
  )
}
