import React, { ChangeEvent, useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { BackgroundContainer } from "../../../components/background/BackgroundContainer";
import { Button } from "../../../components/button/Button";
import { StylizedInputForFilters, StylizedInputStyles, StylizedInputStyleType, StylizedInputTypes } from "../../../components/StylizedInput/StylizedInputForFilters";
import { ButtonColor, ButtonStyle } from "../../../static/CommonDefinitions";
import SearchIco from '../../../assets/images/loupe.svg';
import BlueGiftIcon from "../../../assets/images/blue-gift.svg";
import HeartIcon from "../../../assets/images/heart-inside-circle.svg";
import FilterMenuIcon from "../../../assets/images/filter-menu.svg";
import CloseIcon from "../../../assets/images/close-arrow-24px-rounded.svg";
import HourglassIcon from "../../../assets/images/hourglass-inside-circle.svg";
import RepeatIcon from '../../../assets/images/repeat.svg';
import { DropdownLabelPosition, DropdownWithSearch } from "../../../components/dropdownWithSearch/DropdownWithSearch";

import styles from "./GiftAid.module.scss";
import mobileStyles from "./GiftAidMobile.module.scss";
import { applyTabFocusSupport, formatAmountToString, getDateRangeByPeriodTime, isDesktopEnv, stringWithCommas } from "../../../helpers";
import { StylizedWrapperWithRadius } from "../../../components/stylizedWrapper/StylizedWrapperWithRadius";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { defaultGiftAidTableSorting, giftAidTableTitles, giftAidTableTitlesMobile } from "../static/giftAidConstants";
import {IPayment, selectPayments, selectPaymentSubscriber} from "../../activity/logic/activitySlice";
import {
  resetGiftAidFilters,
  selectGiftAidFilteredPayments,
  selectGiftAidFilters,
  selectGiftAidPayments,
  selectReferenceSearchValue,
  selectTableSorting,
  setReferenceSearchValue
} from "../logic/giftAidSlice";
import {refreshGiftAidFiltersAction, retrieveGiftAidPayments, setGiftAidFilters, sortGiftAidTableAction} from "../logic/giftAidSaga";
import { GiftAidCSVPayment } from "../static/giftAidTypes";
import { DateRange } from "../../../components/dateRangeInput/static/dateRangeInputTypes";
import { DateRangeInput } from "../../../components/dateRangeInput/ui/DateRangeInput";
import {SortingType} from "../../activity/static/activityCommonDefinitions";
import {setTableSortingAction} from "../logic/giftAidSaga";
import {getAllPayments, getPayments} from "../../activity/logic/activitySaga";
import { FilterTagsContainer } from "../../../components/filterTagsContainer/FilterTagsContainer";
import { RangesList } from "../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions";
import { Header } from "../../../components/header/ui/Header";
import { HeaderButton } from "../../../components/header/static/headerCommonDefinitions";
import { log } from "../../../static/Logger";
import { PaymentDetailsPopUp } from "../../../components/paymentDetailsPopUp/PaymentDetailsPopUp";

export function GiftAid() {
  const dispatch = useAppDispatch();
  const payments = useAppSelector(selectPayments);
  const paymentSubscriber = useAppSelector(selectPaymentSubscriber);
  const giftAidPayments = useAppSelector(selectGiftAidPayments);
  const filteredPayments = useAppSelector(selectGiftAidFilteredPayments);
  const { searchQuery, date, reference }  = useAppSelector(selectGiftAidFilters);
  const referenceSearchValue = useAppSelector(selectReferenceSearchValue);
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [csvData, setCsvData] = useState<GiftAidCSVPayment[]>([]);
  const { start_date, end_date } = date;

  const totalGiftAid = filteredPayments.reduce((acc, payment) => {
    if (payment.giftAid) {
      return acc += +payment.giftAid.amount;
    }
    return acc;
  }, 0);

  const totalNetAmount = filteredPayments.reduce((acc, payment) => {
    return acc += +payment.amount.value;
  }, 0);

  const totalAmount = totalNetAmount + totalGiftAid;

  useEffect(() => {
    dispatch(refreshGiftAidFiltersAction());

    return () => {
      dispatch(resetGiftAidFilters());
    };
}, []);

  useEffect(() => {
    dispatch(setTableSortingAction(defaultGiftAidTableSorting));
  }, []);

  useEffect(() => {
    dispatch(sortGiftAidTableAction());
  }, [searchQuery, date, reference]);

  useEffect(() => {
    dispatch(retrieveGiftAidPayments(payments));
  }, [payments]);

  useEffect(() => {
    dispatch(getPayments({ id: paymentSubscriber }));
  }, []);

  const renderCards = () => {
    return (
      <>
        <AmountCard icon={BlueGiftIcon} value={totalGiftAid} description='Total Gift Aid' />
        <AmountCard icon={HeartIcon} value={totalNetAmount} description='Net Amount' />
        <AmountCard icon={HourglassIcon} value={totalAmount} description='Total Amount ' />
      </>
    )
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    dispatch(setGiftAidFilters({ searchQuery: query }));
  };

  const handleSearchClear = () => {
    dispatch(setGiftAidFilters({ searchQuery: '' }));
  }

  const giftAidPaymentReferences: string[] = [];

  giftAidPayments.forEach(payment => {
    if (!giftAidPaymentReferences.includes(payment.reference)) {
      giftAidPaymentReferences.push(payment.reference);
    }
  });

  const handleReferenceSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    dispatch(setReferenceSearchValue(value));

    if (!value.length) {
      dispatch(setGiftAidFilters({ reference: value }));
    }
  }

  const handleReferenceValueChange = (value: string) => {
    dispatch(setReferenceSearchValue(value));
    dispatch(setGiftAidFilters({ reference: value }));
  }

  const exportToCSV = () => {
    const data = filteredPayments.map(payment => ({
      activityTime: moment(payment.activityTime).format('DD-MM-YYYY HH:mm'),
      donorName: payment.giftAid?.donorName || '',
      payersEmail: payment.payerEmail,
      address: payment.giftAid?.address || '',
      postcode: payment.giftAid?.postcode || '',
      reference: payment.reference,
      giftAid: formatAmountToString(
        payment.giftAid?.amount ? +payment.giftAid.amount : 0,
        'GBP',
        3
      ),
      amount: formatAmountToString(
        +payment.amount.value,
        'GBP',
      ),
    }));
    setCsvData(data);
  }

  const headersCSV = [
    { label: 'Date/Time', key: 'activityTime' },
    { label: 'Donor Name', key: 'donorName' },
    { label: 'Payers Email', key: 'payersEmail' },
    { label: 'Address', key: 'address' },
    { label: 'Postcode', key: 'postcode' },
    { label: 'Payment Reference', key: 'reference' },
    { label: 'Gift Aid', key: 'giftAid' },
    { label: 'Amount', key: 'amount' },
  ];

  if(isDesktopEnv()) {
    return (
      <BackgroundContainer isMenu>
        <div className={styles.gift_aid}>
          <Header
            title='Gift Aid'
            CTAButton={{
              CSVButtonInfo: {
                CSVData: csvData,
                CSVHeaders: headersCSV,
                CSVFileName: 'BOPP-gift-aid.csv',
                onClick: exportToCSV,
              }
            }}
          />
          <div className={styles.gift_aid__filters}>
            <div className={styles.gift_aid__search_container}>
              <StylizedInputForFilters
                useClearButton
                handleClear={handleSearchClear}
                name="search"
                value={searchQuery}
                label=""
                icon={SearchIco}
                placeholder="Search"
                handleChange={handleSearchChange}
                type={StylizedInputTypes.Text}
                style={StylizedInputStyles.Search}
              />
            </div>
            <DateRangeInput startDate={start_date} endDate={end_date} onRangeByDate={(date: DateRange) => dispatch(setGiftAidFilters({ date }))} />
            <DropdownWithSearch
              label='Reference'
              placeholder='All'
              values={giftAidPaymentReferences}
              searchValue={referenceSearchValue}
              onSearchValueChange={handleReferenceSearchChange}
              onValueChange={handleReferenceValueChange}
              labelPosition={DropdownLabelPosition.Left}
            />
          </div>
          <div className={styles.gift_aid__cards}>
            { renderCards() }
          </div>
          <Table />
        </div>
      </BackgroundContainer>
    )
  }

  const filtersClassName = classNames([
    mobileStyles.gift_aid__filters,
    {[mobileStyles['gift_aid__filters--opened']]: filtersOpened}
  ])

  return (
    <div className={mobileStyles.gift_aid}>
      <Header
          title='Gift Aid'
          CTAButton={{
            CSVButtonInfo: {
              CSVData: csvData,
              CSVHeaders: headersCSV,
              CSVFileName: 'BOPP-gift-aid.csv',
              onClick: exportToCSV,
            }
          }}
        />
      <div className={filtersClassName}>
        <div className={mobileStyles.gift_aid__filters_top}>
          <div className={mobileStyles.gift_aid__search_container}>
            <StylizedInputForFilters
              useClearButton
              handleClear={handleSearchClear}
              name="search"
              value={searchQuery}
              label=""
              icon={SearchIco}
              placeholder="Search"
              handleChange={handleSearchChange}
              type={StylizedInputTypes.Text}
              style={StylizedInputStyles.Search}
              styleType={StylizedInputStyleType.Solid}
            />
          </div>
          <img
            src={filtersOpened ? CloseIcon : FilterMenuIcon}
            alt=""
            className={mobileStyles.gift_aid__toggle_settings_icon}
            onClick={() => setFiltersOpened(!filtersOpened)}
          />
        </div>
        <div className={mobileStyles.gift_aid__filters_bottom}>
          <div className={mobileStyles.gift_aid__date_container}>
            <DateRangeInput startDate={start_date} endDate={end_date} onRangeByDate={(date: DateRange) => dispatch(setGiftAidFilters({ date }))} />
          </div>
          <div className={mobileStyles.gift_aid__reference_filter_container}>
            <DropdownWithSearch
              label='Reference'
              placeholder='All'
              values={giftAidPaymentReferences}
              searchValue={referenceSearchValue}
              onSearchValueChange={handleReferenceSearchChange}
              onValueChange={handleReferenceValueChange}
              labelPosition={DropdownLabelPosition.Top}
            />
          </div>
        </div>

      </div>
      {
        !filtersOpened &&
          <FilterTagsContainer
            referenceFilter={reference}
            dateFilter={date}
            onDateFilterClear={() => dispatch(setGiftAidFilters({date: getDateRangeByPeriodTime(RangesList.YearFromToday)}))}
            onReferenceFilterClear={() => dispatch(setGiftAidFilters({reference: ''}))}
          />
      }
      <div className={mobileStyles.gift_aid__cards}>
        { renderCards() }
      </div>
      <Table />
    </div>
  )
}

interface AmountCardProps {
  icon: string,
  value: number,
  description: string,
}

const AmountCard = ({icon, value, description}: AmountCardProps) => {
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  return (
    <div className={targetStyles.amount_card}>
      <img src={icon} className={targetStyles.amount_card__icon} />
      <div className={targetStyles.amount_card__right_side}>
        <span className={targetStyles.amount_card__value}>£{stringWithCommas(Number(value).toFixed(2))}</span>
        <span className={targetStyles.amount_card__description}>{description}</span>
      </div>
    </div>

  )
}

const Table = () => {
  const dispatch = useAppDispatch();
  const isDesktop = isDesktopEnv();
  const filteredPayments = useAppSelector(selectGiftAidFilteredPayments);
  const targetTitles = isDesktopEnv() ? giftAidTableTitles : giftAidTableTitlesMobile;
  const sorting = useAppSelector(selectTableSorting);
  const targetStyle = isDesktopEnv() ? styles : mobileStyles;
  const [clickedPayment, setClickedPayment] = useState<IPayment | null>(null);

  const handleSortOrder = (name: string) => {
    const value = sorting[name];
    Object.keys(sorting).forEach(key => {
      if (sorting[key] !== SortingType.INACTIVE) {
        dispatch(setTableSortingAction({ [key]: SortingType.INACTIVE }));
      }
    });

    switch (value) {
      case SortingType.DESCENDING:
        dispatch(setTableSortingAction({ [name]: SortingType.ASCENDING }));
        break;
      case SortingType.INACTIVE:
        dispatch(setTableSortingAction({ [name]: SortingType.ASCENDING }));
        break;
      case SortingType.ASCENDING:
        dispatch(setTableSortingAction({ [name]: SortingType.DESCENDING }));
        break;
      default:
        return;
    }

    dispatch(sortGiftAidTableAction())
  };

  const targetStyles = isDesktop ? styles : mobileStyles;

  const Titles = () => {
    return Object.keys(targetTitles).map((key: string) => {
      const titleStyles = classNames([
        targetStyles.tWrapper__title,
        {
          [targetStyles['tWrapper__title--asc']]:
          sorting[key] === SortingType.ASCENDING,
          [targetStyles['tWrapper__title--desc']]:
          sorting[key] === SortingType.DESCENDING,
          [targetStyles['tWrapper__title--inactive']]:
          sorting[key] === SortingType.INACTIVE,
        },
      ]);
      return (
        <th
          { ...applyTabFocusSupport() }
          className={titleStyles}
          key={key}
          style={{paddingLeft: 0}}
          onClick={() => handleSortOrder(key)}
        >
          {targetTitles[key]}
        </th>
      )
    })
  }

  const handleRowClick = (payment: IPayment) => setClickedPayment(payment);

  return (
    <div className={targetStyle.table_container}>
      <StylizedWrapperWithRadius>
        <table className={targetStyle.table_container__table}>
            <thead>
              <tr className={targetStyle.table_container__head}>{Titles()}</tr>
            </thead>
            <tbody>
              {filteredPayments.slice().map((payment: IPayment, i: number) => (
                  <TableRow key={i.toString()} payment={payment} onClick={handleRowClick.bind(this, payment)}  />
                  ))}
            </tbody>
          </table>
      </StylizedWrapperWithRadius>
      { clickedPayment && <PaymentDetailsPopUp isGiftAid payment={clickedPayment} onClose={() => setClickedPayment(null)} /> }
    </div>
  )
}

interface TableRowProps {
  key: string,
  payment: IPayment,
  onClick: () => void,
}

const TableRow = ({ payment, onClick }: TableRowProps) => {
  const giftAid = payment.giftAid;
  const activityTime = moment(payment.activityTime).format('DD.MM.YYYY HH:mm');
  const amount = formatAmountToString(+payment.amount.value);
  
  if (!giftAid) {
    log("ERROR: payment has no gift aid");
    return <></>
  }
  
  const giftAidAmount = formatAmountToString(+giftAid.amount);

  if (isDesktopEnv()) {
    return (
      <tr className={styles.table_container__row} onClick={onClick}>
        <td className={styles.table_container__row_value}>{activityTime}</td>
        <td className={styles.table_container__row_value}>{giftAid.donorName}</td>
        <td className={styles.table_container__row_value}>{payment.payerEmail}</td>
        <td className={styles.table_container__row_value}>{giftAid.address}</td>
        <td className={styles.table_container__row_value}>{giftAid.postcode}</td>
        <td className={styles.table_container__row_value}>{payment.reference}</td>
        <td className={styles.table_container__row_value}>{giftAidAmount}</td>
        <td className={styles.table_container__row_value}>{amount}</td>
      </tr>
    )
  }

  return (
    <tr className={mobileStyles.table_container__row} onClick={onClick}>
      <td className={mobileStyles.table_container__row_value}>{activityTime}</td>
      <td className={mobileStyles.table_container__row_value}>{giftAid.donorName}</td>
      <td className={mobileStyles.table_container__row_value}>{giftAidAmount}</td>
      <td className={mobileStyles.table_container__row_value}>{amount}</td>
    </tr>
  )
}