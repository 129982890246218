import { createId } from "../helpers";
import {CancelPaymentRequest as ICancelRequest } from "../pages/requestActivity/static/requestActivityTypes";

export class CancelPaymentRequest {
    previousId: string
    paylink: string

    constructor(previousId: string, paylink: string) {
        this.previousId = previousId
        this.paylink = paylink
    }

    createRequest() {
        return this.getRequestParams()
    }

    getRequestParams(): ICancelRequest {
        return {
            "@type": "https://dvschema.io/activation#CancelRequest",
            id: createId(true),
            previousId: this.previousId,
            entitySelector: {
                entityType: 'https://miapago.io/paylink/request/PaylinkRequest',
            },
            timestamp: new Date(),
            possibleResend: false
        };
    }
}