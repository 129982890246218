import { SortingType } from "../../activity/static/activityCommonDefinitions";
import { RequestsTableSortState, RequestsTableTitles } from "./requestActivityTypes";

export const requestsTableTitles: RequestsTableTitles = {
  creationTime: 'Creation Date/Time',
  status: 'Status',
  reference: 'Reference',
  requestedTo: 'Requested To',
  associatedPayments: 'No. associate payments',
  amount: 'Payment Amount',
  totalPaid: 'Total Paid',
};

export const requestTableTitlesMobile: Partial<RequestsTableTitles> = {
  creationTime: 'Date',
  reference: 'Reference',
  amount: 'Payment Amount',
  totalPaid: 'Total Paid',
}

export const defaultTableSortState: RequestsTableSortState = {
    creationTime: SortingType.DESCENDING,
    status: SortingType.INACTIVE,
    reference: SortingType.INACTIVE,
    requestedTo: SortingType.INACTIVE,
    associatedPayments: SortingType.INACTIVE,
    amount: SortingType.INACTIVE,
    totalPaid: SortingType.INACTIVE,
}

export const REQUEST_REF_SET_BY_PAYER_VALUE: string = 'Set by payer';