import {
  put,
  takeEvery,
  select,
  delay,
  race,
  call,
  take,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import axios from 'axios';
import {
  selectAuthCode,
  setAuthCodeActivationStatus,
  selectAttemptsTime,
  setRestrictedTo,
  setAttemptsTime,
  selectAuthCodeActivationStatus,
  selectRestrictedTo,
  setNextAttemptsUpdateTime,
  selectNextAttemptsUpdateTime,
  selectSecurityCode,
  setAccountLinkingStatus,
  setTooManyAttempts,
  selectAccountLinkingStatus,
  selectTooManyAttempts,
  setAuthCodeUnknownError,
  selectHasAttemptsTimer,
  setHasAttemptsTimer,
  clearActivateAccountState,
} from './activateAccountSlice';
import {
  selectBankDetails,
  selectPersonaDID,
  setGiftAidKeys,
  setPersonaDID,
} from './../../bankAccount/logic/bankAccountSlice';
import { BankDetails } from '../../bankAccount/static/bankAccountTypes';
import { selectIsSignedIn } from './../../../core/reducers/appSlice';
import {
  AccountLinkingStatus,
  AuthCodeActivationStatus,
} from '../static/activateAccountCommonDefinitions';
import {
  changeSubscriptionId,
  changeSubscriptionPlan,
  changeSubscriptionType,
  changeUserSubscriptionId,
} from '../../subscriptionManagement/logic/subscriptionManagementSlice';
import { setPaymentSubscriber } from '../../activity/logic/activitySlice';
import { setApiKeys } from '../../keyManagement/logic/keyManagementSlice';
import { grabApiKeysFromResponse } from '../../keyManagement/logic/keyManagementSaga';
import { getRequestHeader } from '../../../helpers';
import { RequestHeader } from '../../../static/objectTypes';
import config from '../../../config';
import { grabSubscriptionPlanDetails } from '../../subscriptionManagement/logic/subscriptionManagementSaga';
import moment from 'moment';
import { AnyAction } from '@reduxjs/toolkit';
import {
  ATTEMPTS_UPDATE_INTERVAL_MS,
  MAX_ATTEMPTS,
  RESTRICTION_TIME_MS,
} from '../static/activateAccountConstants';
import {
  selectSelectedBank,
  selectSelectedBankSupportsAISPFlow,
} from '../../retrieveAccountDetails/logic/retrieveAccountDetailsSlice';
import { getPublickeyPem2 } from '../../../controllers/KeyController';
import { queryAccountDetails } from '../../bankAccount/logic/bankAccountSaga';
import { accountNotExistAction } from '../../../core/sagas/initSaga';
import { log } from '../../../static/Logger';

export const RGISTER_AUTH_CODE = 'activate/register/auth/code';
export const LINK_WITH_SECURITY_CODE = 'activate/link/securityCode';
export const CHECK_AUTH_CODE = 'activate/check/auth/code';
export const UPDATE_ATTEMPTS_TIME = 'activate/update/attemptsTime';
export const REMOVE_ACTIVATION_RESTRICTION = 'activate/remove/restriction';
export const INIT_ACTIVATION_TIMERS = 'activate/init/timers';
export const ACTIVATE_NEW_SUBSCRIPTION = 'activate/new/subscription';
export const PROCCESS_AUTH_CODE = 'activate/process/auth/code';
export const HANDLE_UNKNOWN_ERROR = 'activate/handle/unknownError';
export const CLEAR_RESTRICTION_TIMER = 'activate/clearTimer/restriction';
export const CLEAR_ATTEMPRS_TIMER = 'activate/clearTimer/attempts';
export const UPDATE_ROUTE = 'activate/update/route';
export const GET_GIFT_AID_KEYS = 'activate/get/giftAid/keys';

export function getGiftAidKeysAction(): AnyAction {return {type: GET_GIFT_AID_KEYS}}
export interface HandleUnknownErrorPayload {
  message: string;
}

export interface HandleUnknownError {
  type: typeof HANDLE_UNKNOWN_ERROR;
  payload: HandleUnknownErrorPayload;
}

export function registerAuthCode(): AnyAction {
  return {
    type: RGISTER_AUTH_CODE,
  };
}
export function linkWithSecurityCode(): AnyAction {
  return {
    type: LINK_WITH_SECURITY_CODE,
  };
}

export function checkAuthCode(): AnyAction {
  return {
    type: CHECK_AUTH_CODE,
  };
}

export function updateAttemptsTime(): AnyAction {
  return {
    type: UPDATE_ATTEMPTS_TIME,
  };
}

export function removeActivationRestriction(): AnyAction {
  return {
    type: REMOVE_ACTIVATION_RESTRICTION,
  };
}

export function initActivationTimers(): AnyAction {
  return {
    type: INIT_ACTIVATION_TIMERS,
  };
}

export function handleUnknownError(
  payload: HandleUnknownErrorPayload,
): HandleUnknownError {
  return { type: HANDLE_UNKNOWN_ERROR, payload };
}

export function updateRoute(): AnyAction {
  return { type: UPDATE_ROUTE };
}

export function* createAttemptsUpdateTimer(ms: number) {
  yield put(clearAttemptsTimerAction());

  yield put(setNextAttemptsUpdateTime(+moment().format('x') + ms));
  yield put(setHasAttemptsTimer(true));

  function* callback() {
    yield delay(ms);
    yield put(updateAttemptsTime());
  }

  yield race({
    callback: call(callback),
    cancel: take(CLEAR_ATTEMPRS_TIMER),
  });
}

export function activateNewSubscriptionAction(): AnyAction {
  return { type: ACTIVATE_NEW_SUBSCRIPTION };
}

export function proccessAuthCodeAction(): AnyAction {
  return { type: PROCCESS_AUTH_CODE };
}

export function clearRestrictionTimerAction(): AnyAction {
  return { type: CLEAR_RESTRICTION_TIMER };
}

export function clearAttemptsTimerAction(): AnyAction {
  return { type: CLEAR_ATTEMPRS_TIMER };
}

export function* createRestrictionTimer(): any {
  const restrictedTo = yield select(selectRestrictedTo);
  const currentTime = +moment().format('x');

  yield put(clearRestrictionTimerAction());

  function* callback() {
    yield delay(restrictedTo - currentTime);
    yield put(removeActivationRestriction());
  }

  yield race({
    callback: call(callback),
    cancel: take(CLEAR_RESTRICTION_TIMER),
  });
}

export function* getGiftAidKeys(): any {
  const bankDetails: BankDetails = yield select(selectBankDetails);
  const accountDID = bankDetails.accountDID;
  const header: RequestHeader = yield getRequestHeader();
  const personaDID: string = yield select(selectPersonaDID);

  if(personaDID) {
    try {
      const { data } = yield axios.get(
        config.dashboardServerUrl + '/persona/' + personaDID + '/account/' + accountDID,
        { headers: header },
      );
  
      yield put(setGiftAidKeys({publicKey:data.data.publicKey, secretKey:data.data.secretKey}))
  
    } catch (e) {
      log(e)
    }
  }
}

export function* applyRestriction(): any {
  yield put(setTooManyAttempts(true));
  yield put(setRestrictedTo(+moment().format('x') + RESTRICTION_TIME_MS));

  yield put(replace('/bank-account/too-many-attempts'));

  yield put(clearAttemptsTimerAction());
  yield createRestrictionTimer();
}

export function* addAttempt(): any {
  const attemptsTime = yield select(selectAttemptsTime);
  const hasAttemptsTimer = yield select(selectHasAttemptsTimer);

  if (attemptsTime.length !== MAX_ATTEMPTS) {
    yield put(setAttemptsTime([...attemptsTime, +moment().format('x')]));

    if (!hasAttemptsTimer) {
      yield createAttemptsUpdateTimer(ATTEMPTS_UPDATE_INTERVAL_MS);
    }
  }
}

function* handlePersonaResponse(data: any) {
  let personaDID = data.data.id;
  let subscriptionPlan = grabSubscriptionPlanDetails(data);
  let subscriptionId = data.data.property.id;
  let paymentSubscriber =
    data.data.property.parties[0].PartyRoles['payment:Payee'];

  let keys = grabApiKeysFromResponse(data);
  yield put(setApiKeys(keys));

  yield put(changeSubscriptionType(data.data.property.subscriptionType));
  yield put(setPersonaDID(personaDID));
  yield put(changeSubscriptionPlan(subscriptionPlan));
  yield put(changeSubscriptionId(subscriptionId));
  yield put(changeUserSubscriptionId(data.data.property.subscriptionId));
  yield put(setPaymentSubscriber(paymentSubscriber));
  yield put(getGiftAidKeysAction())
}

function* handlePersonaError(e: any): any {
  const attemptsTime = yield select(selectAttemptsTime);

  if (attemptsTime.length >= MAX_ATTEMPTS - 1) {
    yield applyRestriction();
    return;
  }

  const message = e.response.data.error;

  switch (message) {
    case 'subscription is not found':
      yield put(
        setAuthCodeActivationStatus(AuthCodeActivationStatus.notExist),
      );
      break;
    case 'Subscription is deactivated':
      yield put(
        setAuthCodeActivationStatus(AuthCodeActivationStatus.deactivated),
      );
      break;
    default:
      yield put(
        handleUnknownError({ message: message || e.response.data.message }),
      );
  }

  yield put(push('/bank-account/activate/error'));
  yield addAttempt();
}

function* registerAuthCodeWatcher(): any {
  const authCode: string = yield select(selectAuthCode);
  const bankDetails: BankDetails = yield select(selectBankDetails);
  const accountDID = bankDetails.accountDID;
  const header: RequestHeader = yield getRequestHeader();
  const publicKey = yield getPublickeyPem2();

  let personaDID;

  try {
    const { data } = yield axios.post(
      config.dashboardServerUrl + '/persona',
      {
        '@type': 'https://miapago.io/merchant#Merchant',
        personaName: 'some-org-name',
        authCode: authCode,
        "accounts": [
          accountDID
        ],
      },
      {
        headers: {
          ...header,
          'x-bopp-public-key': btoa(publicKey),
        },
      },
      
    );

    personaDID = data.data.id;
    yield handlePersonaResponse(data);
  } catch (e:any) {
    if(e.response.data.error === 'PersonaDeactivated') {
      yield put(accountNotExistAction())
      return
    }
    yield handlePersonaError(e);
    return;
  }

  try {
    yield axios.post(
      config.dashboardServerUrl + '/persona/' + personaDID + '/account',
      { accountDID: accountDID },
      { headers: header },
    );

    yield put(getGiftAidKeysAction())
  } catch (e:any) {
    if(e.response.data.error === 'PersonaDeactivated') {
      yield put(accountNotExistAction())
      return
    }

    const message = e.response.data.error;

    switch (message) {
      case 'Auth Code is associated with another bank account':
        yield put(
          setAuthCodeActivationStatus(
            AuthCodeActivationStatus.codeLinkedWithAnother,
          ),
        );
        break;
      case 'Payee is already assigned to subscription':
        yield put(
          setAuthCodeActivationStatus(
            AuthCodeActivationStatus.bankLinkedWithAnother,
          ),
        );
        break;
      default:
        yield put(
          handleUnknownError({ message: message || e.response.data.message }),
        );
    }

    yield put(push('/bank-account/activate/error'));
    return;
  }

  yield put(clearActivateAccountState());
  yield put(push('/bank-account/activate/success'));
}

function* activateNewSubscription(): any {
  const authCode: string = yield select(selectAuthCode);
  const bankDetails: BankDetails = yield select(selectBankDetails);
  const accountDID = bankDetails.accountDID;
  const header: RequestHeader = yield getRequestHeader();
  const personaDID: string = yield select(selectPersonaDID);

  try {
    //Check is auth code exist and correct
    try {
      yield axios.get(config.dashboardServerUrl + '/persona/parties/' + authCode);
    }
    catch(e: any) {
      if (e.response.data.error !== 'KYCIsNotCompletedPUIDIsEmpty') {
        throw e;
      }
    }

    //Delete subscription
    yield axios.delete(
      config.dashboardServerUrl +
        '/persona/' +
        personaDID +
        '/account/' +
        accountDID,
      { headers: header },
    );

    //Link with new subscription
    const { data } = yield axios.put(
      config.dashboardServerUrl + '/persona/' + personaDID,
      {
        '@type': 'https://miapago.io/merchant#Merchant',
        personaName: 'some-org-name',
        authCode: authCode,
      },
      { headers: header },
    );

    let subscriptionPlan = grabSubscriptionPlanDetails(data);
    let subscriptionId = data.data.property.id;

    let paymentSubscriber =
      data.data.property.parties[0].PartyRoles['payment:Payee'];
    yield put(setPaymentSubscriber(paymentSubscriber));
    yield put(changeSubscriptionPlan(subscriptionPlan));
    yield put(changeSubscriptionId(subscriptionId));
    yield put(changeUserSubscriptionId(data.data.property.subscriptionId));
    yield put(clearActivateAccountState());
    yield put(push('/bank-account/activate/success'));
  } catch (e:any) {
    if(e.response.data.error === 'PersonaDeactivated') {
      yield put(accountNotExistAction())
      return
    }
    yield handlePersonaError(e);
  }
}

function* linkWithSecurityCodeWatcher(): any {
  const attemptsTime = yield select(selectAttemptsTime);
  const securityCode = yield select(selectSecurityCode);
  const authCode = yield select(selectAuthCode);
  const selectedBank = yield select(selectSelectedBank);
  const headers = yield getRequestHeader();
  const publicKey = yield getPublickeyPem2();
  let personaResponseData: any;

  try {
    const { data } = yield axios.post(
      config.dashboardServerUrl + '/persona',
      {
        '@type': 'https://miapago.io/merchant#Merchant',
        personaName: 'some-org-name',
        authCode,
        securityCode,
        serviceId: selectedBank.api,
      },
      {
        headers: {
          ...headers,
          'x-bopp-public-key': btoa(publicKey),
        },
      },
    );

    personaResponseData = data;
  } catch (error:any) {
    if(error.response.data.error === 'PersonaDeactivated') {
      yield put(accountNotExistAction())
      return
    }
    if (attemptsTime.length >= MAX_ATTEMPTS - 1) {
      yield applyRestriction();
      return;
    }

    yield put(setAccountLinkingStatus(AccountLinkingStatus.error));
    yield put(push('/bank-account/link/error'));

    yield addAttempt();

    return;
  }

  yield handlePersonaResponse(personaResponseData);
  const accountDID = personaResponseData.data.property.accounts[0];
  yield put(queryAccountDetails({ accountDID }));

  yield put(clearActivateAccountState());
  yield put(push('/bank-account/added'));
}

function* proccessAuthCode(): any {
  const isSignedIn: boolean = yield select(selectIsSignedIn);
  if (isSignedIn) {
    yield put(activateNewSubscriptionAction());
  } else {
    yield put(registerAuthCode());
  }
}

export function* updateAttemptsTimeWatcher(): any {
  const currentTime = +moment().format('x');
  const attemptsTime = yield select(selectAttemptsTime);
  const attemptsTimeCopy = attemptsTime.slice();

  attemptsTimeCopy.splice(0, 1);
  yield put(setAttemptsTime(attemptsTimeCopy));

  if (attemptsTimeCopy[0]) {
    yield createAttemptsUpdateTimer(
      ATTEMPTS_UPDATE_INTERVAL_MS - (currentTime - attemptsTimeCopy[0]),
    );
  } else {
    yield put(clearAttemptsTimerAction());
  }
}

export function* removeActivationRestrictionWatcher(): any {
  yield put(setTooManyAttempts(false));
  const supportAISPFlow = yield select(selectSelectedBankSupportsAISPFlow);
  const isSignedIn = yield select(selectIsSignedIn);

  if (supportAISPFlow || isSignedIn) {
    yield put(setAuthCodeActivationStatus(AuthCodeActivationStatus.pending));
    yield put(push('/bank-account/activate'));
  } else {
    yield put(setAccountLinkingStatus(AccountLinkingStatus.pending));
    yield put(push('/bank-account/link'));
  }

  yield put(setAttemptsTime([]));
  yield put(clearAttemptsTimerAction());
}

export function* initActivationTimersWatcher(): any {
  const nextAttemptsUpdateTime = yield select(selectNextAttemptsUpdateTime);
  const hasAttemptsTimer = yield select(selectHasAttemptsTimer);
  const tooManyAttempts = yield select(selectTooManyAttempts);
  const currentTime = +moment().format('x');

  const updateAttemptsAfter = Math.max(0, nextAttemptsUpdateTime - currentTime);

  if (tooManyAttempts) {
    yield createRestrictionTimer();
  } else if (hasAttemptsTimer) {
    yield createAttemptsUpdateTimer(updateAttemptsAfter);
  }
}

export function* clearAttemptsTimerWatcher() {
  yield put(setHasAttemptsTimer(false));
}

function* handleUnknownErrorWatcher({ payload }: HandleUnknownError) {
  const { message } = payload;
  log(payload);
  yield put(setAuthCodeUnknownError(message));
  yield put(setAuthCodeActivationStatus(AuthCodeActivationStatus.unknownError));
}

function* updateRouteWatcher(): any {
  const supportAISPFlow = yield select(selectSelectedBankSupportsAISPFlow);
  const tooManyAttempts = yield select(selectTooManyAttempts);
  const activationStatus = yield select(selectAuthCodeActivationStatus);
  const linkingStatus = yield select(selectAccountLinkingStatus);
  const isSignedIn = yield select(selectIsSignedIn);

  if (tooManyAttempts) {
    yield put(replace('/bank-account/too-many-attempts'));
    return;
  }

  if (supportAISPFlow || isSignedIn) {
    if (
      activationStatus !== AuthCodeActivationStatus.pending &&
      activationStatus !== AuthCodeActivationStatus.success
    ) {
      yield put(replace('/bank-account/activate/error'));
    }
  } else if (linkingStatus === AccountLinkingStatus.error) {
    yield put(replace('/bank-account/link/error'));
  }
}

export default function* activateAccountSaga() {
  yield takeEvery(RGISTER_AUTH_CODE, registerAuthCodeWatcher);
  yield takeEvery(ACTIVATE_NEW_SUBSCRIPTION, activateNewSubscription);
  yield takeEvery(PROCCESS_AUTH_CODE, proccessAuthCode);
  yield takeEvery(HANDLE_UNKNOWN_ERROR, handleUnknownErrorWatcher);
  yield takeEvery(LINK_WITH_SECURITY_CODE, linkWithSecurityCodeWatcher);
  yield takeEvery(UPDATE_ATTEMPTS_TIME, updateAttemptsTimeWatcher);
  yield takeEvery(
    REMOVE_ACTIVATION_RESTRICTION,
    removeActivationRestrictionWatcher,
  );
  yield takeEvery(INIT_ACTIVATION_TIMERS, initActivationTimersWatcher);
  yield takeEvery(CLEAR_ATTEMPRS_TIMER, clearAttemptsTimerWatcher);
  yield takeEvery(UPDATE_ROUTE, updateRouteWatcher);
  yield takeEvery(GET_GIFT_AID_KEYS, getGiftAidKeys);
}
