import { defaultSortTableState } from './../static/activityConstants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getDateRangeByPeriodTime, isDesktopEnv,
} from '../../../helpers';
import { RootState } from '../../../core/store/configureStore';
import {
  PaymentAmount,
  SortTableState,
  AccountState,
} from '../static/activityTypes';
import config from '../../../config';
import { PaginationData } from '../../../components/pagination/static/paginationTypes';
import { DateRange } from '../../../components/dateRangeInput/static/dateRangeInputTypes';
import { RangesList } from '../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions';

export enum QueryByAmountKey {
  Min = 'min',
  Max = 'max',
}

export const activitySliceInitialState: ActivityState = {
  filters:{
    reference: '',
    searchQuery: '',
    date: getDateRangeByPeriodTime(
        RangesList.YearFromToday,
    ),
    amount: {
      min: '',
      max: '',
    }
  },
  payments: [],
  pendingPayments: [],
  filteredPayments: [],
  paginationData: {
    paginationRangeStart:0,
    paginationRangeEnd:isDesktopEnv() ? config.rowsCountPerTableDesktop : config.rowsCountPerTableMobile,
    currentPage: 1,
    totalPages: 1,
    pageLimit: isDesktopEnv() ? config.rowsCountPerTableDesktop : config.rowsCountPerTableMobile,
  },
  tableSorting: defaultSortTableState,
  accountState: {
    amount: 0,
    fee: 0,
  },
  paymentSubscriber: '',
  rangeDateFilter: getDateRangeByPeriodTime(RangesList.ThisMonth),
  referenceSearchValue: '',
  areFiltersClear: true,
  paymentsRefreshTime: new Date(0),
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState: activitySliceInitialState,
  reducers: {
    addPayment: (state, action: PayloadAction<IPayment>) => {
      state.payments = [...state.payments, action.payload];
    },
    addPendingPayment: (state, action: PayloadAction<IPayment>) => {
      state.pendingPayments = [...state.pendingPayments, action.payload];
    },
    updatePayments: (state, action: PayloadAction<IPayment[]>) => {
      state.payments = [...action.payload];
    },
    updatePendingPayments: (state, action: PayloadAction<IPayment[]>) => {
      state.pendingPayments = [...action.payload];
    },
    setRangeDateFilter: (state, action: PayloadAction<IRangeDateFilter>) => {
      state.rangeDateFilter = action.payload;
    },
    clearPayments: state => {
      state.payments = [];
      state.pendingPayments = [];
      state.filteredPayments = [];
    },
    setFilteredPayments: (state, action: PayloadAction<IPayment[]>) => {
      state.filteredPayments = action.payload;
    },
    updateFilters:  (state, action: PayloadAction<IActivityFilters>) => {
      state.filters = action.payload
    },
    resetFilters: state => {
      state.filters = {
        reference: '',
        searchQuery: '',
        date: getDateRangeByPeriodTime(
            RangesList.YearFromToday,
        ),
        amount: {
          min: '',
          max: '',
        }
      };
      state.areFiltersClear = true;
      state.referenceSearchValue = '';
    },
    setAmountFilter: (state, action: PayloadAction<IAmountFilter>) => {
      state.filters = {
        ...state.filters,
        amount: {
          ...action.payload
        },
      };
    },
    updatePaginationData: (state, action: PayloadAction<Partial<PaginationData>>) => {
      state.paginationData = { ...state.paginationData, ...action.payload };
    },
    updateTableSorting: (
        state,
        action: PayloadAction<Partial<SortTableState>>,
    ) => {
      state.tableSorting = {
        ...state.tableSorting,
        ...action.payload,
      };
    },
    setAccountState: state => {
      let amount = 0;
      let fee = 0;

      state.filteredPayments.forEach(payment => {
          amount += Number(payment.amount.value);
          fee += Number(payment.fee);
        });
        
      state.accountState = {
        amount,
        fee,
      };
    },
    clearAccountState: state => {
      state.accountState = {
        amount: 0,
        fee: 0,
      };
    },
    setPaymentSubscriber: (state, action: PayloadAction<string>) => {
      state.paymentSubscriber = action.payload;
    },
    setReferenceSearchValue: (state, action: PayloadAction<string>) => {
      state.referenceSearchValue = action.payload;
    },
    setAreFiltersClear: (state, action: PayloadAction<boolean>) => {
      state.areFiltersClear = action.payload;
    },
    setPaymentsRefreshTime: (state, action: PayloadAction<Date>) => {
      state.paymentsRefreshTime = action.payload;
    },
  },
});

export const {
  updatePayments,
  updatePendingPayments,
  setAccountState,
  clearAccountState,
  updateTableSorting,
  setFilteredPayments,
  updatePaginationData,
  clearPayments,
  setPaymentSubscriber,
  addPayment,
  addPendingPayment,
  setRangeDateFilter,
  updateFilters,
  setReferenceSearchValue,
  resetFilters,
  setAreFiltersClear,
  setPaymentsRefreshTime,
} = activitySlice.actions;

export const selectFilters = (state: RootState) => state.activity.filters;
export const selectRangeDateFilter = (state: RootState) => state.activity.rangeDateFilter;
export const selectPayments = (state: RootState) => state.activity.payments;
export const selectPendingPayments = (state: RootState) => state.activity.pendingPayments;
export const selectTableSorting = (state: RootState) => state.activity.tableSorting;
export const selectAccountState = (state: RootState) => state.activity.accountState;
export const selectPaginationData = (state: RootState) => state.activity.paginationData;
export const selectFilteredPayments = (state: RootState) => state.activity.filteredPayments;
export const selectPaymentSubscriber = (state: RootState) => state.activity.paymentSubscriber;
export const selectReferenceSearchValue = (state: RootState) => state.activity.referenceSearchValue;
export const selectAreFiltersClear = (state: RootState) => state.activity.areFiltersClear;
export const selectPaymentsRefreshTime = (state: RootState) => state.activity.paymentsRefreshTime;

export default activitySlice.reducer;

export interface ActivityState {
  payments: IPayment[];
  pendingPayments: IPayment[];
  filteredPayments: IPayment[];
  filters: IActivityFilters;
  paginationData: PaginationData;
  tableSorting: SortTableState;
  accountState: AccountState;
  paymentSubscriber: string;
  rangeDateFilter: IRangeDateFilter;
  referenceSearchValue: string;
  areFiltersClear: boolean;
  paymentsRefreshTime: Date;
}

export interface IPayment {
  originatingEntityId: string;
  amount: PaymentAmount;
  activityTime: string;
  id: string;
  executingEntityId: string;
  siteUrl: string;
  requestId: string;
  fee: number;
  reportState: string;
  reference: string;
  payerEmail: string;
  giftAid: PaymentGiftAid | null;
  receiveMarketingInfo: boolean;
  notes: string;
}

export interface PaymentGiftAid {
  donorName: string,
  address: string,
  postcode: string,
  amount: string,
}

export interface SocketResponse {
  '@type': string;
  activatingEntityId: string;
  id: string;
  initiatorId: string;
  originatorId: string;
  possibleResend: Boolean;
  properties: Object;
  reportState: any;
  requestId: string;
  sequenceNumber: string;
  state: Object;
  timestamp: string;
}

export interface IActivityFilters {
  date: DateRange;
  amount: IAmountFilter;
  reference: string;
  searchQuery: string;
}

export interface IAmountFilter {
  min: string;
  max: string;
}

export interface IRangeDateFilter {
  start_date: string;
  end_date: string;
}
