import { SortingType } from "../../activity/static/activityCommonDefinitions"

export const giftAidMigration = (state:any, version:number) => {  
    switch(version) {
        case (0): {
            return {      
                ...state.giftAid,        
				filters: {          
                    ...state.giftAid.filters,
					searchQuery: ''
				},
                tableSorting: {
                    ...state.giftAid.tableSorting,
                    address: SortingType.INACTIVE,
                    donorName: SortingType.INACTIVE,
                    payersEmail: SortingType.INACTIVE,
                    postcode: SortingType.INACTIVE
                }
            }  
        }
    }
} 
