import React from 'react';
import { Button } from '../../../components/button/Button';
import { MobileHeader } from '../../../components/mobileHeader/MobileHeader';
import { MobileHeaderPadding } from '../../../components/mobileHeader/static/mobileHeaderCommonDefinitions';
import { isDesktopEnv } from '../../../helpers';
import { ButtonColor, ButtonStyle } from '../../../static/CommonDefinitions';
import styles from './StepsSubscriptionPlan.module.scss';
import mobileStyles from './StepsSubscriptionPlanMobile.module.scss';

export function StepsSubscriptionPlan({handleSingingPopupClick}: StepsSubscriptionPlanProps) {
    if (isDesktopEnv()) {
        return (
            <div className={styles.container}>
                <div className={styles.data_container}>
                    <div className={styles.steps_subscription_plan__main}>
                        <h2 className={styles.steps_subscription_plan__title}>Signing up to BOPP takes minutes.</h2>
                        <div className={styles.steps_subscription_plan__description}>
                            <ol className={styles.steps_subscription_plan__ol}>
                                <li className={styles.steps_subscription_plan__li}>
                                    Choose a subscription plan
                                </li>
                                <li className={styles.steps_subscription_plan__li}>
                                    Verify your bank account (payments will<br/>
                                    be made directly to this account)
                                </li>
                                <li className={styles.steps_subscription_plan__li}>
                                    Activate
                                </li>
                            </ol>
                            <p>Then you are ready to go!</p>
                        </div>
                    </div>
                    <Button
                        title="NEXT"
                        style={ButtonStyle.Login}
                        buttonHeight='50px'
                        color={ButtonColor.Black}
                        onClick={handleSingingPopupClick}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={mobileStyles.container}>
          <MobileHeader
            isMenu={false}
            padding={MobileHeaderPadding.small}
            backButton
          />
          <div className={mobileStyles.container__center}>
            <div className={mobileStyles.container__title_container}>
              <h1 className={mobileStyles.container__title}>
                Signing up to BOPP takes minutes.
              </h1>
            </div>
            <div className={mobileStyles.container__description}>
              <ol className={mobileStyles.container__description__ol}>
                <li className={mobileStyles.container__description__li}>
                  Choose a subscription plan
                </li>
                <li className={mobileStyles.container__description__li}>
                  Verify your bank account (payments will
                  <br />
                  be made directly to this account)
                </li>
                <li className={mobileStyles.container__description__li}>Activate</li>
              </ol>
              <p>Then you are ready to go!</p>
            </div>
          </div>
          <div className={mobileStyles.container__footer}>
            <Button
              title="NEXT"
              style={ButtonStyle.LittleRounded}
              color={ButtonColor.Black}
              handleClick={handleSingingPopupClick}
            />
          </div>
        </div>
      );
}

export interface StepsSubscriptionPlanProps {
    handleSingingPopupClick: (e: React.MouseEvent<HTMLElement>) => void,
}
