import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import { RootState } from '../store/configureStore';

const initialState: appState = {
    isSignedIn: false,
    isAccountNotFound:false
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setSignedIn: (state, action: PayloadAction<boolean>) => {
            state.isSignedIn = action.payload;
        },
        setIsAccountNotFound: (state, action: PayloadAction<boolean>) => {
            state.isAccountNotFound = action.payload;
        },
        reset: (state) => {
            state.isSignedIn = false
            state.isAccountNotFound = false;
        },
    },
});

export const {
    setSignedIn,
    setIsAccountNotFound,
    reset
} = appSlice.actions;

export const selectIsSignedIn = (state: RootState) => state.app.isSignedIn;
export const selectIsAccountNotFound = (state: RootState) => state.app.isAccountNotFound;

export default appSlice.reducer;

export interface appState {
    isSignedIn: boolean,
    isAccountNotFound: boolean
}
