import { useHistory } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ActivationCodeInput } from '../../../components/activationCodeInput/ActivationCodeInput';
import { Button } from '../../../components/button/Button';
import { ButtonStyle } from '../../../static/CommonDefinitions';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import styles from './ActivateAccount.module.scss';
import mobileStyles from './ActivateAccountMobile.module.scss';
import {
  proccessAuthCodeAction,
  updateRoute,
} from '../logic/activateAccountSaga';
import {
  selectAuthCode,
  selectAuthCodeActivationStatus,
  selectTooManyAttempts,
  setAuthCode,
} from '../logic/activateAccountSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { linkAnotherAccount } from '../../bankAccount/logic/bankAccountSaga';
import { SelectionModal } from '../../modals/SelectionModal/SelectionModal';
import { MobileHeader } from '../../../components/mobileHeader/MobileHeader';
import {
  MobileHeaderGradient,
  MobileHeaderPadding,
} from '../../../components/mobileHeader/static/mobileHeaderCommonDefinitions';
import CloseIcon from '../../../assets/images/close-arrow-24px-rounded.svg';
import BackIcon from '../../../assets/images/back.svg';
import { AuthCodeActivationStatus } from '../static/activateAccountCommonDefinitions';
import { isDesktopEnv } from '../../../helpers';
import { selectIsSignedIn } from '../../../core/reducers/appSlice';

export function ActivateAccount() {
  const dispatch = useAppDispatch();
  const authCode = useAppSelector(selectAuthCode);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const activationStatus = useAppSelector(selectAuthCodeActivationStatus);
  const tooManyAttempts = useAppSelector(selectTooManyAttempts);
  const isSignedIn = useAppSelector(selectIsSignedIn);

  const hasError =
    (activationStatus !== AuthCodeActivationStatus.success &&
      activationStatus !== AuthCodeActivationStatus.pending) ||
    tooManyAttempts;

  useEffect(() => {
    dispatch(updateRoute());
  }, []);

  if (hasError) {
    return <></>;
  }

  const handleCode = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setAuthCode(e.target.value));
  };

  const handleNextButtonClick = () => dispatch(proccessAuthCodeAction());
  const nextButtonDisabled = authCode.length !== 8;

  const leave = () => {
    history.push('/bank-account/retrieve');
    dispatch(linkAnotherAccount());
  };

  const renderModal = () => {
    return (
      showModal && (
        <SelectionModal
          text="If you leave this screen your account details will not be saved and you will need to restart the process in order to use BOPP dashboard in this browser."
          optionTop="leave anyway"
          optionBottom="cancel"
          selectOptionTop={leave}
          selectOptionBottom={() => setShowModal(false)}
        />
      )
    );
  };

  const handleBackButtonClick = () => {
    if (isSignedIn) {
      history.goBack()
    } else {
      setShowModal(true)
    }
  }

  if (isDesktopEnv()) {
    return (
      <>
        <BackgroundContainer
          onBackButtonPressed={handleBackButtonClick}
          backButton={true}>
          <div className={styles.container}>
            <div className={styles.container__header}>
              <h4 className={styles.container__title}>Activate BOPP</h4>
              <p className={styles.container__text}>
                Please enter your activation code in the box below. For further
                instructions please visit bopp.io.
              </p>
              <ActivationCodeInput
                label="Activation Code"
                value={authCode}
                handleChange={handleCode}
              />
            </div>
            <div className={styles.container__button}>
              <Button
                title="Next"
                buttonHeight="50px"
                style={ButtonStyle.Rounded}
                handleClick={handleNextButtonClick}
                disabled={nextButtonDisabled}
              />
            </div>
            {renderModal()}
          </div>
        </BackgroundContainer>
      </>
    );
  }

  return (
    <>
      <div className={mobileStyles.container}>
        <MobileHeader
          withLogo
          isMenu={false}
          leftIcon={
            <img
              src={BackIcon}
              onClick={handleBackButtonClick}
            />
          }
          rightIcon={
            <img
              src={CloseIcon}
              onClick={handleBackButtonClick}
            />
          }
          padding={MobileHeaderPadding.small}
          gradient={MobileHeaderGradient.fromTopLeft}
        />
        <div className={mobileStyles.container__center}>
          <h4 className={mobileStyles.container__title}>Activate BOPP</h4>
          <p className={mobileStyles.container__text}>
            Please enter your activation code in the box below. For further
            instructions please visit bopp.io.
          </p>
          <ActivationCodeInput
            label="Activation Code"
            value={authCode}
            handleChange={handleCode}
          />
        </div>
        <div className={mobileStyles.container__bottom}>
          <Button
            title="Next"
            style={ButtonStyle.Rounded}
            handleClick={handleNextButtonClick}
            disabled={nextButtonDisabled}
          />
        </div>
        {renderModal()}
      </div>
    </>
  );
}
