export enum AuthCodeActivationStatus {
  bankLinkedWithAnother = "bankLinkedWithAnother",
  codeLinkedWithAnother = "codeLinkedWithAnother",
  deactivated = "deactivated",
  unknownError = "unknownError",
  notExist = "notExist",
  success = "success",
  pending = "pending",
}

export enum AccountLinkingStatus {
  pending = "pending",
  error = "error",
  success = "success",
}
