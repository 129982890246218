import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../core/store/configureStore";
import {
  AccountLinkingStatus,
  AuthCodeActivationStatus,
} from "../static/activateAccountCommonDefinitions";

export interface ActivateAccountState {
  authCode: string;
  securityCode: string;
  tooManyAttempts: boolean;
  attemptsTime: number[];
  hasAttemptsTimer: boolean;
  nextAttemptsUpdateTime: number;
  restrictedTo: number;
  accountLinkingStatus: AccountLinkingStatus;
  authCodeActivationStatus: AuthCodeActivationStatus;
  authCodeUnknownError: string;
}

const initialState: ActivateAccountState = {
  authCode: "",
  securityCode: "",
  authCodeUnknownError: "",
  tooManyAttempts: false,
  attemptsTime: [],
  hasAttemptsTimer: false,
  nextAttemptsUpdateTime: 0,
  restrictedTo: 0,
  authCodeActivationStatus: AuthCodeActivationStatus.pending,
  accountLinkingStatus: AccountLinkingStatus.pending,
};

export const activateAccountSlice = createSlice({
  name: "activateAccount",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuthCode: (state, action: PayloadAction<string>) => {
      state.authCode = action.payload;
    },
    setSecurityCode: (state, action: PayloadAction<string>) => {
      state.securityCode = action.payload;
    },
    setTooManyAttempts: (state, action: PayloadAction<boolean>) => {
      state.tooManyAttempts = action.payload;
    },
    setAttemptsTime: (state, action: PayloadAction<number[]>) => {
      state.attemptsTime = [...action.payload];
    },
    setHasAttemptsTimer: (state, action: PayloadAction<boolean>) => {
      state.hasAttemptsTimer = action.payload;
    },
    setNextAttemptsUpdateTime: (state, action: PayloadAction<number>) => {
      state.nextAttemptsUpdateTime = action.payload;
    },
    setRestrictedTo: (state, action: PayloadAction<number>) => {
      state.restrictedTo = action.payload;
    },
    setAuthCodeActivationStatus: (
      state,
      action: PayloadAction<AuthCodeActivationStatus>
    ) => {
      state.authCodeActivationStatus = action.payload;
    },

    setAccountLinkingStatus: (
      state,
      action: PayloadAction<AccountLinkingStatus>
    ) => {
      state.accountLinkingStatus = action.payload;
    },
    setAuthCodeUnknownError: (state, action: PayloadAction<string>) => {
      state.authCodeUnknownError = action.payload;
    },
    clearActivateAccountState: (state) => {
      return initialState;
    }
  },
});

export const {
  setAuthCode,
  setSecurityCode,
  setTooManyAttempts,
  setAuthCodeUnknownError,
  setAttemptsTime,
  setHasAttemptsTimer,
  setNextAttemptsUpdateTime,
  setRestrictedTo,
  setAuthCodeActivationStatus,
  setAccountLinkingStatus,
  clearActivateAccountState
} = activateAccountSlice.actions;

export const selectAuthCode = (state: RootState) =>
  state.activateAccount.authCode;

export const selectSecurityCode = (state: RootState) =>
  state.activateAccount.securityCode;

export const selectAuthCodeUnknownError = (state: RootState) =>
  state.activateAccount.authCodeUnknownError;

export const selectTooManyAttempts = (state: RootState) =>
  state.activateAccount.tooManyAttempts;

export const selectAttemptsTime = (state: RootState) =>
  state.activateAccount.attemptsTime;

export const selectHasAttemptsTimer = (state: RootState) =>
  state.activateAccount.hasAttemptsTimer;

export const selectNextAttemptsUpdateTime = (state: RootState) =>
  state.activateAccount.nextAttemptsUpdateTime;

export const selectRestrictedTo = (state: RootState) =>
  state.activateAccount.restrictedTo;

export const selectAuthCodeActivationStatus = (state: RootState) =>
  state.activateAccount.authCodeActivationStatus;

export const selectAccountLinkingStatus = (state: RootState) =>
  state.activateAccount.accountLinkingStatus;

export default activateAccountSlice.reducer;
