import { ToastIcon } from "../../../components/toast/static/toastCommonDefinitions";
import { ToastProps } from "../../../components/toast/Toast";
import { KeyManagementToastInfo } from "./keyManagementTypes";

export const websiteExpression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export const ASCIIExpression = /^[\x00-\x7F]*$/g;
export const validationMessages = {
  invalidUrl: 'Please enter a valid URL!',
  invalidKeyName: 'Please enter a valid key name!',
  urlIsRequired: 'Please enter URL',
  keyNameIsRequired: "Please enter key name",
}
export const ToastDefaultInfo: KeyManagementToastInfo = {
  title: "",
  icon: ToastIcon.Success,
}
