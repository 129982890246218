import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { applyTabFocusSupport, cssVhToPx, isDesktopEnv } from '../../helpers';
import { useAppSelector } from '../../app/hooks';
import { selectSubscriptionType } from '../../pages/subscriptionManagement/logic/subscriptionManagementSlice';
import { SubscriptionType } from '../../static/CommonDefinitions';

import Logo from '../../assets/images/logo.svg';
import BankIco from '../../assets/images/menu/bank.svg';
import KeyIco from '../../assets/images/menu/key-card.svg';
import ArrowsIco from '../../assets/images/menu/transaction-arrows.svg';
import BuildingIco from '../../assets/images/menu/acc-buildings.svg';
import InfoIco from '../../assets/images/menu/info.svg';
import FaqIco from '../../assets/images/menu/faq.svg';
import ExitIco from '../../assets/images/menu/exit.svg';
import RequestIco from '../../assets/images/menu/request-menu.svg';
import GiftAidIcon from "../../assets/images/menu/gift-aid.svg";
import RequestIcon from "../../assets/images/request-icon.svg";
import CloseIcon from '../../assets/images/close-arrow-24px-rounded.svg';
import RepeatIcon from '../../assets/images/menu/recurring-requests.svg';
import UpgradeIcon from '../../assets/images/menu/upgrade.svg';

import styles from './Menu.module.scss';

export interface IMenu {
    openSignOut: () => void;
    onClose?: () => void,
}

export const Menu = ({ openSignOut, onClose }: IMenu) => {
    const history = useHistory();

    const subscriptionType = useAppSelector(selectSubscriptionType)
    const openRoute = (url: string) => {
        history.push(url);
    };

    const openNewTab = (url: string) => {
        window.open(url);
    };

    const wrapperStyle = classNames([
        styles.mWrapper,
        { [styles['mWrapper--mobile']]: !isDesktopEnv() },
    ]);

    const handleCloseButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onClose && onClose();
    }

    return (
        <div className={wrapperStyle} id="mWrapper">
            <div className={styles.mWrapper__menu} style={getInlineStyle('mWrapper__menu')}>
                <div
                    className={styles.mWrapper__header}
                    style={getInlineStyle('mWrapper__header')}
                    onClick={() => history.push('/home')}>
                    <img src={Logo} alt="BOPP Logo" className={styles.mWrapper__logo} style={getInlineStyle('mWrapper__logo')} />
                    <img src={CloseIcon} className={styles.mWrapper__header_close_icon} style={getInlineStyle('mWrapper__header_close_icon')} onClick={handleCloseButtonClick} />
                </div>
                <ul className={styles.mWrapper__list} style={getInlineStyle('mWrapper__list')}>
                <MenuItem
                    isLastChild
                    targetRoute="/upgrade-now"
                    icon={UpgradeIcon}
                    onClick={() => openRoute('/upgrade-now')}
                    title="Upgrade now"
                />
                <MenuItem
                    targetRoute="/bankAccount"
                    icon={BankIco}
                    onClick={() => openRoute('/bankAccount')}
                    title="Bank account"
                />
                {subscriptionType === SubscriptionType.Charity &&
                    <MenuItem
                    targetRoute="/gift-aid"
                    icon={GiftAidIcon}
                    onClick={() => openRoute('/gift-aid')}
                    title="gift aid"
                    />
                }
                <MenuItem
                    targetRoute="/key-management"
                    icon={KeyIco}
                    onClick={() => openRoute('/key-management')}
                    title="Key management"
                />
                <MenuItem
                    targetRoute="/payment-summary"
                    icon={ArrowsIco}
                    onClick={() => openRoute('/payment-summary')}
                    title="Payment activity"
                />
                <MenuItem
                    targetRoute="/request-activity"
                    icon={RequestIcon}
                    onClick={() => openRoute('/request-activity')}
                    title="Request activity"
                    extraTargetRoutes={['/request-summary']}
                />
                <MenuItem
                    targetRoute="/recurring-request-activity"
                    icon={RepeatIcon}
                    onClick={() => openRoute('/recurring-request-activity')}
                    title="Recurring Request activity"
                />
                <MenuItem
                    targetRoute="/payment-request"
                    icon={RequestIco}
                    onClick={() => openRoute('/payment-request')}
                    title="Request payment"
                    extraTargetRoutes={['/request-details']}
                />
                <MenuItem
                    isLastChild
                    targetRoute="/account-management"
                    icon={BuildingIco}
                    onClick={() => openRoute('/account-management')}
                    title="BOPP Account"
                />
                </ul>
                <ul className={classNames([styles.mWrapper__list, styles.mWrapper__list_bottom])} style={getInlineStyle('mWrapper__list_bottom')}>
                    <MenuItem
                        isBottomList
                        icon={InfoIco}
                        onClick={() => openNewTab('https://bopp.io/legal')}
                        title="Legal"
                    />
                    <MenuItem
                        isBottomList
                        icon={FaqIco}
                        onClick={() => openNewTab('https://help.bopp.io/portal/en/kb')}
                        title="FAQs"
                    />
                    <MenuItem
                        isBottomList
                        isLastChild
                        icon={ExitIco}
                        onClick={openSignOut}
                        title="sign out" />
                </ul>
            </div>
        </div>
    );
};

export interface IMenuItem {
    title: string;
    icon?: string;
    targetRoute?: string;
    onClick: (e: MouseEvent) => void;
    extraTargetRoutes?: string[];
    isLastChild?: boolean;
    isBottomList?: boolean;
}

export const MenuItem = ({ icon, title, targetRoute, onClick, extraTargetRoutes = [], isLastChild, isBottomList }: IMenuItem) => {
    const location = useLocation();
    const itemStyle = classNames(styles.mItem, {
        [styles['mItem--active']]: (targetRoute && targetRoute === location.pathname) || extraTargetRoutes?.includes(location.pathname),
        [styles['mItem--mobile']]: !isDesktopEnv(),
    });

    let finalInlineStyles = getInlineStyle('mItem');

    if (!isLastChild) {
        if (isBottomList) {
            finalInlineStyles = {...finalInlineStyles, ...getInlineStyle('bottom_mItem:not-last-child')};
        }
        else {
            finalInlineStyles = {...finalInlineStyles, ...getInlineStyle('mItem:not-last-child')};
        }
    }

    return (
        <li className={itemStyle} onClick={onClick} style={finalInlineStyles} {...applyTabFocusSupport()}>
            {icon && (
                <div className={styles.mItem__iWrapper}>
                    <img src={icon} alt="Icon" className={styles.mItem__icon} style={getInlineStyle('mItem__icon')} />
                </div>
            )}
            <span className={styles.mItem__title} style={getInlineStyle('mItem__title')}>{title}</span>
        </li>
    );
};


const inlineStyles: any = {
    ['mWrapper__menu']: {
        paddingBottom: cssVhToPx(4.5),
    },
    ['mWrapper__header']: {
        height: cssVhToPx(11.7),
    },
    ['mWrapper__logo']: {
        width: cssVhToPx(16.65),
        height: cssVhToPx(8.4),
    },
    ['mWrapper__header_close_icon']: {
        width: cssVhToPx(3.6),
        height: cssVhToPx(3.6),
    },
    ['mWrapper__list']: {
        marginTop: cssVhToPx(4.65),
    },
    ['mItem']: {
        height: cssVhToPx(6),
    },
    ['mItem:not-last-child']: {
        marginBottom: cssVhToPx(0.5),
    },
    ['bottom_mItem:not-last-child']: {
        marginBottom: cssVhToPx(0.4),
    },
    ['mItem__icon']: {
        height: cssVhToPx(3.6),
        width: cssVhToPx(3.6),
    },
    ['mItem__title']: {
        fontSize: cssVhToPx(1.95),
    },
    ['mWrapper__list_bottom']: {
        marginBottom: cssVhToPx(2.52)
    },
}
const getInlineStyle = (key: string) => isDesktopEnv() ? {} : inlineStyles[key];
