import React, { useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { StylizedWrapperWithRadius } from "../stylizedWrapper/StylizedWrapperWithRadius";
import IncommingTransactionIcon from "../../assets/images/incomming-transaction.svg";
import CancelTransactionIcon from "../../assets/images/cancel-transaction.svg";
import CopyIcon from "../../assets/images/copy-v2.svg";
import { Button } from "../button/Button";
import { ButtonColor, ButtonStyle } from "../../static/CommonDefinitions";
import styles from "./RecurringRequestDetailsPopup.module.scss";
import mobileStyles from "./RecurringRequestDetailsPopupMobile.module.scss";
import { formatAmountToString, isDesktopEnv, stringWithCommas } from "../../helpers";
import { PaymentRequestStatus } from "../../pages/recurringRequestsActivity/static/recurringRequestActivityCommonDefinitions";
import { RecurringPaymentRequest } from "../../pages/recurringRequestsActivity/static/recurringRequestActivityTypes";
import { formatPaylinkUrl } from "../../utils/paylinkUrl";
import { copyToClipboard } from "../../utils/clipboard";
import { Toast } from "../toast/Toast";
import { ToastIcon } from "../toast/static/toastCommonDefinitions";


export interface RecurringRequestDetailsPopupProps {
  request: RecurringPaymentRequest,
  onClose: () => void,
}

export const RecurringRequestDetailsPopup = ({ request, onClose }: RecurringRequestDetailsPopupProps) => {
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;
  const [popupTimeout, setPopupTimeout] = useState<NodeJS.Timeout>();
  const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);

  const getTransactionIcon = () => {
    if(request.status === PaymentRequestStatus.Closed) {
        return CancelTransactionIcon;
    } else {
        return IncommingTransactionIcon;
    }
  }

  const handlePaylinkCopied = () => {
    const paylink = formatPaylinkUrl(request.paylink);
    copyToClipboard(paylink);
    setShowInfoPopup(true);

    if (popupTimeout !== undefined) {
        clearTimeout(popupTimeout);
    }
    setPopupTimeout(setTimeout(() => setShowInfoPopup(false), 3000));
}

  const renderTitle = () => <span className={ targetStyles.popup__title }>{ 'Recurring Request Details' }</span>; 
  
  const renderIcon = () => {
    return <img src={getTransactionIcon()} className={targetStyles.popup__transaction_icon} alt={''} />
  }

  const renderListItem = (title: string, value:string) => {
    return (
      value ? <ListItem name={title} value={value} /> : null
    )
  }

  const renderAmount = () => {
    return renderListItem('First payment amount', request?.firstPaymentAmount?.value)
  }

  const renderPayeeName = () => {
    return renderListItem('Payee name', request?.payeeName)
  }

  const renderReference = () => {
    return renderListItem('Reference', request?.paymentReference)
  }

  const renderFirstPaymentDue = () => {
    if(request?.firstPaymentDue) {
      let date = moment(request?.firstPaymentDue).format('DD.MM.YYYY')
      return renderListItem('First payment Date', date)
    }
  }

  const renderRecurringAmount = () => {
    return renderListItem('Recurring payment Amount', request?.recurringPaymentAmount?.value)
  }

  const renderFirstRecurringDue = () => {
    if(request?.firstRecurringPaymentDue) {
      let date = moment(request?.firstRecurringPaymentDue).format('DD.MM.YYYY')
      return renderListItem('Recurring payment Date', date)
    }
  }

  const renderFinalAmount = () => {
    return renderListItem('Final payment Amount', request?.finalPaymentAmount?.value)
  }

  const renderFinalDue = () => {
    if(request?.finalPaymentDue) {
      let date = moment(request?.finalPaymentDue).format('DD.MM.YYYY')
      return renderListItem('Final payment Date', date)
    }
  }

  const renderFrequency = () => {
    return renderListItem('Frequency', request?.frequency)
  }

  const renderNumberOfPayments= () => {
    if(request?.numberOfPayments) {
      let numberOfPayments = request?.numberOfPayments.toString()
      return renderListItem('Number of payments', numberOfPayments)
    }
    
  }

  const renderCreatingDate = () => {
    let date = moment(request?.creationTime).format('DD.MM.YYYY HH:mm')
    return renderListItem('Creation Date', date)
  }

  const renderId = () => {
    return renderListItem('ID', request?.id)
  }

  const renderWebsiteURL = () => {
    return renderListItem('Website URL', request?.websiteURL)
  }

  const renderPaymentItems = () => {
    return (
      <>
        {renderAmount()}
        {renderPayeeName()}
        {renderReference()}
        {renderFirstPaymentDue()}
        {renderRecurringAmount()}
        {renderFirstRecurringDue()}
        {renderFinalAmount()}
        {renderFinalDue()}
        {renderFrequency()}
        {renderNumberOfPayments()}
        {renderCreatingDate()}
        {renderId()}
        {renderWebsiteURL()}
      </>
    )
  }

  return (
    <div className={targetStyles.popup}>
      <StylizedWrapperWithRadius style='bRadius12' className={targetStyles.popup__content}>
        <>
          <div className={targetStyles.popup__top}>
            { renderTitle() }
            { renderIcon() }
            <div className={targetStyles.popup__amount_block}>
              <span className={targetStyles.popup__amount}>{ '£' + stringWithCommas(Number(request.firstPaymentAmount.value).toFixed(2).toString()) }</span>
            </div>
            <span className={targetStyles.popup__reference}>{ request.paymentReference }</span>
            <a className={targetStyles.popup_info_link}>
              {request.paylink}
              { /*request.status === PaymentRequestStatus.Open && <img src={CopyIcon} alt="copy" onClick={() => handlePaylinkCopied()}/> */}
            </a>
          </div>
          <div className={targetStyles.popup__list}>
           { renderPaymentItems() }
          </div>
        <div className={targetStyles.popup__close_button_container}>
          <Button
            title='Close'
            style={ButtonStyle.Rounded}
            color={ButtonColor.Yellow}
            className={targetStyles.popup__close_button}
            onClick={onClose}
          />
        </div>
      </>
    </StylizedWrapperWithRadius>
    {showInfoPopup &&
      <Toast
          icon={ToastIcon.Success}
          title='Paylink Copied'
          onClose={() => setShowInfoPopup(false)}
      />
    }
    </div>
  )
}

interface ListItemProps {
  name: string,
  value: string | JSX.Element,
  className?: string,
}

const ListItem = ({ name, value, className = '' }: ListItemProps) =>{
  const targetStyles = isDesktopEnv() ? styles : mobileStyles;

  const finalClassName = classNames([
    targetStyles.list_item,
    className,
    {[targetStyles['list_item--with-element-value']]: typeof value !== 'string'}
  ]) 

  return (
    <div className={finalClassName}>
      <span className={targetStyles.list_item__name}>{ name }</span>
      <div className={targetStyles.list_item__value}>{ value }</div>
    </div>
  )
}
