import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';

import { RootState } from '../../../core/store/configureStore';

const initialState: chooseBankState = {
    bankList: [],
};

export const chooseBankSlice = createSlice({
    name: 'chooseBank',
    initialState,
    reducers: {
        setBankList: (state, action: PayloadAction<Bank[]>) => {
            state.bankList = action.payload;
        },
    },
});

export const {
    setBankList,
} = chooseBankSlice.actions;

export const selectBankList = (state: RootState) => state.chooseBank.bankList;

export const selectByAccountName = (name: string) => createSelector(selectBankList, (banks: Bank[]) => {
    return banks.filter(({accountName}: any) => accountName.toLowerCase().includes(name))
})

export default chooseBankSlice.reducer;

export interface chooseBankState {
    bankList: Bank[],
}

export interface Bank {
    accountName: string,
    api: string,
    status: string,
    update_time: string,
    friendly_name: string,
    logo_uri: string,
    short_description: string
}
