import { 
  createSlice, 
  PayloadAction 
} from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/configureStore';
import {
  RequestFilters,
  RecurringRequestsTableSortState,
  RecurringPaymentRequest
} from '../static/recurringRequestActivityTypes';
import { 
  getDateRangeByPeriodTime, 
  isDesktopEnv 
} from '../../../helpers';
import config from '../../../config';
import { PaginationData } from '../../../components/pagination/static/paginationTypes';
import { defaultTableSortState } from '../static/recurringRequestActivityConstants';
import { RangesList } from '../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions';
import { PaymentRequestStatus } from '../static/recurringRequestActivityCommonDefinitions';

const { start_date, end_date } = getDateRangeByPeriodTime(
  RangesList.YearFromToday,
);

const initialState: RecurringRequestActivityState = {
  requests: [],
  filteredRecurringRequests: [],
  filters:{
    searchQuery: '',
    reference: '',
    queryByDate: {
      start_date,
      end_date,
    },
  },
  paginationData: {
    paginationRangeStart:0,
    paginationRangeEnd:isDesktopEnv() ? config.rowsCountPerTableDesktop : config.rowsCountPerTableMobile,
    currentPage: 1,
    totalPages: 1,
    pageLimit: isDesktopEnv() ? config.rowsCountPerTableDesktop : config.rowsCountPerTableMobile,
  },
  tableSorting: defaultTableSortState,
};

export interface UpdateRecurringRequestStatusPayload {
  request: RecurringPaymentRequest,
  status: PaymentRequestStatus
}

export const recurringRequestActivitySlice = createSlice({
  name: 'recurringRequestActivity',
  initialState,
  reducers: {
    addRequest: (state, action: PayloadAction<RecurringPaymentRequest>) => {
      state.requests = [...state.requests, action.payload];
    },
    updateRequest: (state, action: PayloadAction<RecurringPaymentRequest>) => {
      state.requests = state.requests.map(obj =>
        obj.id === action.payload.id ? action.payload : obj
      );
    },
    updateHistoryRequest: (state, action: PayloadAction<RecurringPaymentRequest>) => {
      state.requests = (state.requests.length && state.requests || []).map((request) => {
        return request.id === action.payload.id ? {...request, ...action.payload} : request
      });
    },
    updateRecurringRequestStatus: (state, action: PayloadAction<UpdateRecurringRequestStatusPayload>) => {
      let nRequest = state.requests.find(request => request.id === action.payload.request.id)
      if(nRequest !== undefined) {
        nRequest.status = action.payload.status

        state.requests = (state.requests.length && state.requests || []).map((request) => {
          return request.id === nRequest?.id ? {...request, ...nRequest} : request
        });
      }
    },
    updateRequestsTableFilters:  (state, action: PayloadAction<RequestFilters>) => {
      state.filters = action.payload
    },
    setFilteredPaymentRequests: (state, action: PayloadAction<RecurringPaymentRequest[]>) => {
      state.filteredRecurringRequests = action.payload;
    },
    updateRequestsTablePaginationData: (state, action: PayloadAction<Partial<PaginationData>>) => {
      state.paginationData = { ...state.paginationData, ...action.payload };
    },
    updateRecurringRequestsTableSorting: (
      state,
      action: PayloadAction<Partial<RecurringRequestsTableSortState>>,
    ) => {
      state.tableSorting = {
        ...state.tableSorting,
        ...action.payload,
      };
    },
  },
});

export const {
  addRequest,
  updateHistoryRequest,
  updateRequestsTableFilters,
  updateRecurringRequestsTableSorting,
  setFilteredPaymentRequests,
  updateRequestsTablePaginationData,
  updateRequest,
  updateRecurringRequestStatus
} = recurringRequestActivitySlice.actions;


export const selectRecurringPaymentRequests = (state: RootState) => state.recurringRequestActivity.requests;
export const selectRequestsTableFilters = (state: RootState) => state.recurringRequestActivity.filters;
export const selectRecurringRequestsTableSorting = (state: RootState) => state.recurringRequestActivity.tableSorting;
export const selectFiltereRecurringPaymentRequests = (state: RootState) => state.recurringRequestActivity.filteredRecurringRequests;
export const selectRecurringRequestsTablePaginationData = (state: RootState) => state.recurringRequestActivity.paginationData;

export default recurringRequestActivitySlice.reducer;

export interface RecurringRequestActivityState {
  requests: RecurringPaymentRequest[];
  filteredRecurringRequests: RecurringPaymentRequest[];
  filters: RequestFilters;
  paginationData: PaginationData;
  tableSorting: RecurringRequestsTableSortState;
}
