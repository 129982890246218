import React, {
    ChangeEvent,
    useEffect,
    useState,
} from 'react';
import classNames from 'classnames';
import moment from 'moment';

import {
  IPayment,
  selectAccountState,
  selectFilteredPayments,
  selectFilters,
  selectPaginationData,
  selectPayments,
  selectPaymentSubscriber,
  selectTableSorting,
  updatePaginationData,
  selectReferenceSearchValue,
  setReferenceSearchValue,
  selectAreFiltersClear,
  resetFilters,
} from '../logic/activitySlice';

import {
    filterPaymentsAction,
    getAllPayments,
    getPayments,
    refreshActivityFiltersAction,
    setFiltersAction,
    setTableSortingAction, sortActivityTableAction,
} from '../logic/activitySaga';

import config from '../../../config';
import activityStyles from './Activity.module.scss';
import activityMobileStyles from './ActivityMobile.module.scss';
import CrossIco from '../../../assets/images/cancel-transaction.svg';
import SearchIco from '../../../assets/images/loupe.svg';
import TotalAmount from '../../../assets/images/total-amount.svg';
import TotalFee from '../../../assets/images/total-fee.svg';
import ActiveFeeInfo from '../../../assets/images/ActiveFeeInfo.svg';
import FeeInfo from '../../../assets/images/fee-info-icon.svg';
import RepeatIcon from '../../../assets/images/repeat.svg';
import { CSVPayment } from '../static/activityTypes';
import {tableTitles, tableTitlesPaymentSummaryMobile, defaultSortTableState} from '../static/activityConstants';
import {
    PaymentReportState,
} from '../../../static/CommonDefinitions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { SortingType } from '../static/activityCommonDefinitions';
import ArrowIncomeIco from '../../../assets/images/incomming-transaction.svg';
import PendingIncomeIco from '../../../assets/images/pending-transaction.svg';
import ArrowShortIco from '../../../assets/images/backspace-arrow-16px.svg';
import FilterMenuIcon from '../../../assets/images/filter-menu.svg';
import ArrowShortDisabledIco from '../../../assets/images/backspace-arrow-disabled.svg';
import { BackgroundContainer } from '../../../components/background/BackgroundContainer';
import { StylizedWrapperWithRadius } from '../../../components/stylizedWrapper/StylizedWrapperWithRadius';
import {
    applyTabFocusSupport,
    fetchPageNumbersWithSplits,
    formatAmountToString,
    getDateRangeByPeriodTime,
    isDesktopEnv,
    SPLIT,
} from '../../../helpers';
import {
    StylizedInputForFilters,
    StylizedInputStyles,
    StylizedInputStyleType,
    StylizedInputTypes,
} from '../../../components/StylizedInput/StylizedInputForFilters';
import CloseIcon from "../../../assets/images/close-arrow-24px-rounded.svg";
import { DropdownLabelPosition, DropdownWithSearch } from '../../../components/dropdownWithSearch/DropdownWithSearch';
import { DateRangeInput } from '../../../components/dateRangeInput/ui/DateRangeInput';
import { PaymentDetailsPopUp } from '../../../components/paymentDetailsPopUp/PaymentDetailsPopUp';
import HintPopup from "../../../components/hintPopup/HintPopup";
import { FilterTagsContainer } from '../../../components/filterTagsContainer/FilterTagsContainer';
import { RangesList } from '../../../components/dateRangeInput/static/dateRangePickerCommonDefinitions';
import { Header } from '../../../components/header/ui/Header';
import { decodeExecutingEntityId } from "../../../utils/paymentIdDecoder";
import { TotalBlock } from '../../../components/totalBlock/TotalBlock';

export function Activity() {
    const dispatch = useAppDispatch();
    const payments = useAppSelector(selectPayments);
    const filteredPayments = useAppSelector(selectFilteredPayments);
    const accountState = useAppSelector(selectAccountState);
    const paginationData = useAppSelector(selectPaginationData);
    const currentFilters = useAppSelector(selectFilters);
    const areFiltersClear = useAppSelector(selectAreFiltersClear);
    const visiblePayments = filteredPayments.length || !areFiltersClear ? filteredPayments : payments;
    const isDesktop = isDesktopEnv();
    const targetRowsCountPerTable = isDesktop
        ? config.rowsCountPerTableDesktop
        : config.rowsCountPerTableMobile;
    const showPagination = visiblePayments.length > targetRowsCountPerTable;
    const paymentSubscriber = useAppSelector(selectPaymentSubscriber);
    const [csvData, setCsvData] = useState<CSVPayment[]>([]);
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [showFeeInfoModal, setShowFeeInfoModal] = useState<boolean>(false);
    const referenceSearchValue = useAppSelector(selectReferenceSearchValue);

    useEffect(() => {
        function handleResize() {
            const myDiv = document.getElementById("notesHeader");
            if(myDiv) {
                if (myDiv.offsetWidth < 100) {
                    myDiv.style.width = "12ch";
                } else {
                    myDiv.style.removeProperty('width');
                }
            }
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    useEffect(() => {
        dispatch(setTableSortingAction(defaultSortTableState));
    }, []);

    useEffect(() => {
        dispatch(getPayments({ id: paymentSubscriber }));
        dispatch(refreshActivityFiltersAction());

        return () => {
            dispatch(resetFilters());
        };
    }, []);

    useEffect(() => {
        dispatch(
            updatePaginationData({
                ...paginationData,
                pageLimit: targetRowsCountPerTable,
            }),
        );
    }, [isDesktop]);

    useEffect(() => {
        dispatch(filterPaymentsAction());
    }, [payments]);

    const headersCSV = [
        { label: 'Date/Time', key: 'activityTime' },
        { label: 'ID', key: 'id' },
        { label: 'Reference', key: 'reference' },
        { label: 'Website URL', key: 'siteUrl' },
        { label: "Payer's Email", key: 'payerEmail'},
        { label: "Marketing opt-in", key: 'receiveMarketingInfo'},
        { label: "Notes", key: 'notes'},
        { label: "Gift-aid", key: 'giftAid'},
        { label: 'Payment Status', key: 'status' },
        { label: 'Amount', key: 'amount' },
    ];

    const handleReferenceSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        dispatch(setReferenceSearchValue(value));

        if (!value.length) {
            dispatch(setFiltersAction({ reference: value }));
        }
    }

    const handleReferenceValueChange = (value: string) => {
        dispatch(setReferenceSearchValue(value));
        dispatch(setFiltersAction({ reference: value }));
    }

    const paymentReferences: string[] = [];

    payments.forEach(payment => {
        if (!paymentReferences.includes(payment.reference)) {
            paymentReferences.push(payment.reference);
        }
    });

    const exportToCSV = () => {
        const data = visiblePayments.map(payment => {
            let formattedUnit = payment.amount.unit;
            if (payment.amount.unit.includes(':')) {
                formattedUnit = payment.amount.unit.split(':')[1];
            }
            return {
                amount: formatAmountToString(
                    +payment.amount.value,
                    formattedUnit,
                ),
                activityTime: moment(payment.activityTime).format('DD-MM-YYYY HH:mm'),
                id: decodeExecutingEntityId(payment.executingEntityId),
                reference: payment.reference,
                siteUrl: payment.siteUrl,
                status: payment.reportState === PaymentReportState.Activated || payment.reportState === PaymentReportState.Confirmed ? 'Received' : 'Rejected',
                payerEmail: payment.payerEmail,
                receiveMarketingInfo: payment.receiveMarketingInfo ? 'Yes' : 'No',
                notes: payment.notes,
                giftAid: payment.giftAid ? 'Enabled' : 'Disabled',
            }});
        setCsvData(data);
    }

    const handleCloseModal = () => {
        setShowFeeInfoModal(false);
    }

    const handleOpenModal = () => {
        setShowFeeInfoModal(true);
    }

    if (isDesktop) {
        return (
            <BackgroundContainer isMenu={true}>
                <div className={activityStyles.activity}>
                    <Header
                        title='Payment Activity'
                        CTAButton={{
                            CSVButtonInfo: {
                                CSVData: csvData,
                                CSVHeaders: headersCSV,
                                CSVFileName: "BOPP-payment-activity.csv",
                                onClick: exportToCSV,
                            }
                        }}
                    />
                    <div className={activityStyles.activity__content}>
                        <div className={activityStyles.activity__main}>
                            <div className={activityStyles.activity__input_wrapper}>
                                <div className={activityStyles.activity__search_wrapper}>
                                    <SearchInput />
                                </div>
                                <div className={activityStyles.activity__date_wrapper}>
                                    <DateRangeInput
                                        startDate={currentFilters.date.start_date}
                                        endDate={currentFilters.date.end_date}
                                        onRangeByDate={(date) => dispatch(setFiltersAction({ date }))}
                                    />
                                    <DropdownWithSearch
                                        label='Reference'
                                        placeholder='All'
                                        values={paymentReferences}
                                        searchValue={referenceSearchValue}
                                        onSearchValueChange={handleReferenceSearchChange}
                                        onValueChange={handleReferenceValueChange}
                                        labelPosition={DropdownLabelPosition.Left}
                                    />
                                </div>
                            </div>
                            <TotalBlock
                                amount={+accountState.amount}
                            />
                            <StylizedWrapperWithRadius>
                                <ActivityTable areFiltersClear={areFiltersClear} />
                            </StylizedWrapperWithRadius>
                        </div>
                    </div>

                    {showPagination && (
                        <div className={activityStyles.activity__pagination}>
                            <TablePagination
                                totalTableRecords={visiblePayments.length}/>
                        </div>
                    )}
                </div>
            </BackgroundContainer>
        );
    }

    const filtersContainerClassName = classNames([
        activityMobileStyles.activity__filter_container,
        {[activityMobileStyles['activity__filter_container--opened']]: filtersOpened}
    ])

    return (
        <div className={activityMobileStyles.activity}>
            <Header
                title='Payment Activity'
                CTAButton={{
                    CSVButtonInfo: {
                        CSVData: csvData,
                        CSVHeaders: headersCSV,
                        CSVFileName: "BOPP-payment-activity.csv",
                        onClick: exportToCSV,
                    }
                }}
            />
          <div className={filtersContainerClassName}>
              <div className={activityMobileStyles.activity__filter_container_top}>
                <div className={activityMobileStyles.activity__filter_container_search}>
                <SearchInput />
                </div>
                <img
                    src={filtersOpened ? CloseIcon : FilterMenuIcon}
                    alt="filter"
                    onClick={() => setFiltersOpened(!filtersOpened)}
                    className={activityMobileStyles.activity__filter_container_icon}
                />
              </div>
              <div className={activityMobileStyles.activity__filter_container_bottom}>
                <div className={activityMobileStyles.activity__filter_container_date}>
                    <DateRangeInput
                        startDate={currentFilters.date.start_date}
                        endDate={currentFilters.date.end_date}
                        onRangeByDate={(date) => dispatch(setFiltersAction({ date }))}
                    />
                </div>
                <DropdownWithSearch
                  label='Reference'
                  placeholder='All'
                  values={paymentReferences}
                  searchValue={referenceSearchValue}
                  onSearchValueChange={handleReferenceSearchChange}
                  onValueChange={handleReferenceValueChange}
                  labelPosition={DropdownLabelPosition.Top}
                />
              </div>
          </div>
          {
            !filtersOpened &&
                <FilterTagsContainer
                    referenceFilter={currentFilters.reference}
                    dateFilter={currentFilters.date}
                    onReferenceFilterClear={() => dispatch(setFiltersAction({reference: ''}))}
                    onDateFilterClear={() => dispatch(setFiltersAction({date: getDateRangeByPeriodTime(RangesList.YearFromToday)}))}
                />
          }
          <div className={activityMobileStyles.activity__graph_container}>
            <TotalBlock
                amount={isNaN(+accountState.amount) ? 0 : +accountState.amount}
            />
          </div>
          <div className={activityMobileStyles.activity__table_container}>
            <ActivityTable areFiltersClear={areFiltersClear} />
          </div>
          {showPagination && (
              <div className={activityMobileStyles.activity__pagination_container}>
                <TablePagination
                    pageNeighbors={0}
                    totalTableRecords={visiblePayments.length}
                    pageLimit={config.rowsCountPerTableMobile}
                />
              </div>
          )}
          {showFeeInfoModal &&
            <HintPopup
                handleCloseModal={handleCloseModal}
            />
          }
        </div>
    );
}

export const SearchInput = () => {
    const [searchFilter, setSearchFilter] = useState("");
    const dispatch = useAppDispatch();

    const handleSearchByQuery = (e: ChangeEvent<HTMLInputElement>) => {
        const searchQuery = e.target.value;
        setSearchFilter(searchQuery);
        dispatch(setFiltersAction({ searchQuery }));
    };

    const handleClear = () => {
        setSearchFilter('');
        dispatch(setFiltersAction({searchQuery: ''}));
    }

    return (
        <>
            <StylizedInputForFilters
                useClearButton
                handleClear={handleClear}
                name="searchByQuery"
                value={searchFilter}
                icon={SearchIco}
                placeholder="Search"
                handleChange={handleSearchByQuery}
                type={StylizedInputTypes.Text}
                style={StylizedInputStyles.Search}
                styleType={StylizedInputStyleType.Solid}
            />
        </>
    );
};

interface ActivityTableProps {
    areFiltersClear: boolean;
}

export const ActivityTable = ({ areFiltersClear }: ActivityTableProps) => {
    const dispatch = useAppDispatch();
    const isDesktop = isDesktopEnv();
    const sorting = useAppSelector(selectTableSorting);
    const payments = useAppSelector(selectPayments);
    const filteredPayments = useAppSelector(selectFilteredPayments);
    const paginationData = useAppSelector(selectPaginationData);
    const visiblePayments = filteredPayments.length || !areFiltersClear ? filteredPayments : payments;
    const [clickedPayment, setClickedPayment] = useState<IPayment | null>(null);
    const [showFeeInfoModal, setShowFeeInfoModal] = useState<boolean>(false);

    const handleSortOrder = (name: string) => {
        const value = sorting[name];
        Object.keys(sorting).forEach(key => {
            if (sorting[key] !== SortingType.INACTIVE) {
                dispatch(setTableSortingAction({ [key]: SortingType.INACTIVE }));
            }
        });

        switch (value) {
            case SortingType.DESCENDING:
                dispatch(setTableSortingAction({ [name]: SortingType.ASCENDING }));
                break;
            case SortingType.INACTIVE:
                dispatch(setTableSortingAction({ [name]: SortingType.ASCENDING }));
                break;
            case SortingType.ASCENDING:
                dispatch(setTableSortingAction({ [name]: SortingType.DESCENDING }));
                break;
            default:
                return;
        }

        dispatch(sortActivityTableAction())
    };

    const targetTitles = isDesktop ? tableTitles : tableTitlesPaymentSummaryMobile;
    const targetStyles = isDesktop ? activityStyles : activityMobileStyles;

    const handleOpenModal = () => {
        setShowFeeInfoModal(true);
    }

    const renderFeeTitleWithIcon = (title?: string) => {
        return(
            <>
                <span>{title}</span>
                <img
                    src={FeeInfo}
                    alt='Fee information'
                    className={targetStyles.tWrapper__title_icon}
                    onClick={handleOpenModal}
                />
                {
                    showFeeInfoModal &&
                        <HintPopup
                            className={targetStyles.tWrapper__icon_popup}
                            title='BOPP Fee Calculation'
                            text={`These fees are calculated using the BOPP
                                plan published rates and do not factor in
                                any promotional discounts which will be
                                applied to your account on your invoices.`
                            }
                            handleCloseModal={handleCloseModal}
                        />
                }
            </>
        )
    }

    const handleCloseModal = () => {
        setShowFeeInfoModal(false);
    }

    const Titles = () =>
        Object.keys(targetTitles).map((key: string) => {
            const titleStyles = classNames([
                targetStyles.tWrapper__title,
                {
                    [targetStyles['tWrapper__title--asc']]:
                    sorting[key] === SortingType.ASCENDING,
                    [targetStyles['tWrapper__title--desc']]:
                    sorting[key] === SortingType.DESCENDING,
                    [targetStyles['tWrapper__title--inactive']]:
                    sorting[key] === SortingType.INACTIVE,
                },
            ]);

            if(targetTitles[key] === 'BOPP Fee') {
                return null    
            }
            return (
                <th
                    { ...applyTabFocusSupport() }
                    className={titleStyles}
                    key={key}
                    style={{width:""}}
                    id={targetTitles[key] === 'Notes' ? "notesHeader" : undefined}
                    onClick={() => handleSortOrder(key)}>
                    {
                        targetTitles[key] === 'icon'
                         ? null // ? renderFeeTitleWithIcon(targetTitles[key])
                         : targetTitles[key]
                    }
                </th>
            );
        });

    return (
        <>
            
                <div className={targetStyles.tWrapper}>
                    <table className={targetStyles.tWrapper__table}>
                        <thead className={targetStyles.tWrapper__table_head}>
                        <tr className={targetStyles.tWrapper__row}>{Titles()}</tr>
                        </thead>
                        <tbody>
                        {visiblePayments.slice(paginationData.paginationRangeStart, paginationData.paginationRangeEnd).map((payment: IPayment) => (
                            <TableRow key={payment.id} payment={payment} onClick={() => setClickedPayment(payment)} />
                            ))}
                        </tbody>
                    </table>
                </div>
            {clickedPayment && <PaymentDetailsPopUp payment={clickedPayment} onClose={() => setClickedPayment(null)} />}
        </>
    );
};

export interface TableRowProps {
    payment: IPayment,
    onClick?: () => void,
    isForRequestSummary?: boolean
}

export const TableRow = ({ payment, onClick, isForRequestSummary }: TableRowProps) => {
    const isDesktop = isDesktopEnv();
    const targetStyle = isDesktop ? activityStyles : activityMobileStyles;

    const getTransactionIcon = () => {
        if(payment.reportState === PaymentReportState.Activated ||
        payment.reportState === PaymentReportState.Confirmed) {
            return ArrowIncomeIco;
        } else if(payment.reportState === PaymentReportState.PendingAccept) {
            return PendingIncomeIco;
        } else {
            return CrossIco;
        }
    }

    const getFormattedActivityTime = () => {
        return isDesktop
            ? moment(payment.activityTime).format('DD/MM/YYYY HH:mm')
            : moment(payment.activityTime).format('DD/MM/YYYY');
    }

    const renderReferenceOrFee = () => {
        if (isDesktop) {
            return null
            //return <td className={targetStyle.tableRow__value}></td>;
            // return <td className={targetStyle.tableRow__value}>{'£' + formatAmountToString(payment.fee || 0).slice(1)}</td>;
        }

        return <td className={targetStyle.tableRow__value}>{payment.reference}</td>
    }

    if (isForRequestSummary) {
        return (
            <tr className={targetStyle.tableRow} onClick={onClick}>
                <td className={targetStyle.tableRow__value}>
                    <img
                        src={getTransactionIcon()}
                        alt=""
                        className={targetStyle.tableRow__icon}
                    />
                </td>
                <td className={targetStyle.tableRow__value}>
                    <div className={targetStyle["tableRow__value-wrap"]}>
                    <span>{moment(payment.activityTime).format('DD/MM/YYYY HH:mm')}</span>
                    </div>
                </td>
                <td className={targetStyle.tableRow__value}>
                    <div className={targetStyle["tableRow__value-wrap"]}>
                        <span>{payment.reference}</span>
                    </div>
                </td>
                {isDesktop && <td className={targetStyle.tableRow__value}>
							<div className={targetStyle["tableRow__value-wrap"]}>
								<span>{payment.payerEmail}</span>
							</div>
						</td>}
                {isDesktop && <td className={targetStyle.tableRow__value}>
							<div className={targetStyle["tableRow__value-wrap"]}>
								<span>{payment.notes}</span>
							</div>
						</td>}
                <td className={targetStyle.tableRow__value}>
                    <div className={targetStyle.tableRow__valueInner}>
                        <span>{formatAmountToString(+payment.amount.value)}</span>
                    </div>
                </td>
            </tr>
        );
    }

    return (
			<tr
				className={targetStyle.tableRow}
				onClick={onClick}
				{...applyTabFocusSupport()}
			>
                {!isDesktop && (
				<td className={targetStyle.tableRow__value}>
					<img
						src={getTransactionIcon()}
						alt=""
						className={targetStyle.tableRow__icon}
					/>
					{moment(payment.activityTime).format('DD/MM/YYYY')}
				</td>)}
				{isDesktop && (
					<>
                        <td className={targetStyle.tableRow__value}>
							<div className={targetStyle["tableRow__value-wrap"]}>
                            <img
                                src={getTransactionIcon()}
                                alt=""
                                className={targetStyle.tableRow__icon}
                            />
							</div>
						</td>
                        <td className={targetStyle.tableRow__value}>
							<div className={targetStyle["tableRow__value-wrap"]}>
                            <span>{moment(payment.activityTime).format('DD/MM/YYYY HH:mm')}</span>
							</div>
						</td>
						<td className={targetStyle.tableRow__value}>
							<div className={targetStyle["tableRow__value-wrap"]}>
								<span>{payment.executingEntityId ? decodeExecutingEntityId(payment.executingEntityId) : payment.id}</span>
							</div>
						</td>
                        <td className={targetStyle.tableRow__value}>
							<div className={targetStyle["tableRow__value-wrap"]}>
								<span>{payment.reference}</span>
							</div>
						</td>
                        <td className={targetStyle.tableRow__value}>
							<div className={targetStyle["tableRow__value-wrap"]}>
								<span>{payment.siteUrl || 'https://bopp.com'}</span>
							</div>
						</td>
                        <td className={targetStyle.tableRow__value}>
							<div className={targetStyle["tableRow__value-wrap"]}>
								<span>{payment.payerEmail}</span>
							</div>
						</td>
                        <td className={targetStyle.tableRow__value}>
							<div className={targetStyle["tableRow__value-wrap"]}>
								<span>{payment.notes}</span>
							</div>
						</td>
					</>
				)}
				{renderReferenceOrFee()}
				<td className={targetStyle.tableRow__value}>
					<div className={targetStyle.tableRow__valueInner}>
						<span>{formatAmountToString(+payment.amount.value)}</span>
					</div>
				</td>
			</tr>
		);
};

export const TablePagination = ({
    totalTableRecords = 0,
    pageLimit = config.rowsCountPerTableDesktop,
    pageNeighbors = 1,
}) => {
    const dispatch = useAppDispatch();
    const isDesktop = isDesktopEnv();

    const pageButtonNeighbors = Math.max(0, Math.min(pageNeighbors, 2));
    const { currentPage } = useAppSelector(selectPaginationData);
    const totalPagesCount = Math.ceil(totalTableRecords / pageLimit);
    const isRightArrowDisabled = currentPage === totalPagesCount;
    const isLeftArrowDisabled = currentPage === 1;
    const pagesButtons = fetchPageNumbersWithSplits(
        currentPage,
        totalPagesCount,
        pageButtonNeighbors,
    );
    const targetStyle = isDesktop ? activityStyles : activityMobileStyles;

    useEffect(() => {
        if (currentPage === 0 && pagesButtons.length > 0) {
            gotoPage(1)
        } else {
            gotoPage(currentPage);
        }
    }, []);

    const gotoPage = (page: number) => {
        const currentPage = Math.max(0, Math.min(page, totalPagesCount));

        let paginationRangeStart = (currentPage -1) * pageLimit
        let paginationRangeEnd = paginationRangeStart + pageLimit

        dispatch(updatePaginationData({currentPage, pageLimit, paginationRangeStart, paginationRangeEnd}));
    };

    const changePage = (page: number) => () => {
        gotoPage(page);
    };

    const handleMoveLeft = () => {
        gotoPage(currentPage - 1);
    };

    const handleMoveRight = () => {
        gotoPage(currentPage + 1);
    };

    const blockStyles = (page: number) =>
        classNames(targetStyle.pagination__numBtm, {
            [targetStyle['pagination__numBtm--active']]: currentPage === page,
        });

    return (
        <>
            {!!totalTableRecords && totalPagesCount > 1 ? (
                <div className={targetStyle.pagination}>
                    <button
                        className={targetStyle.pagination__stepBtn}
                        onClick={handleMoveLeft}
                        disabled={isLeftArrowDisabled}>
                        <img
                            src={isLeftArrowDisabled ? ArrowShortDisabledIco : ArrowShortIco}
                            alt=""
                        />
                        <span>Prev</span>
                    </button>

                    <ul className={targetStyle.pagination__pages}>
                        {pagesButtons.map((page, index) =>
                            page === SPLIT ? (
                                <li
                                    className={classNames(
                                        targetStyle.pagination__numBtm,
                                        targetStyle['pagination__numBtm--split'],
                                    )}>
                                    ..
                                </li>
                            ) : (
                                <li
                                    key={index}
                                    { ...applyTabFocusSupport() }
                                    className={blockStyles(page)}
                                    onClick={changePage(page)}>
                                    {page}
                                </li>
                            ),
                        )}
                    </ul>

                    <button
                        className={targetStyle.pagination__stepBtn}
                        onClick={handleMoveRight}
                        disabled={isRightArrowDisabled}>
                        <span>Next</span>
                        <img
                            src={isRightArrowDisabled ? ArrowShortDisabledIco : ArrowShortIco}
                            alt=""
                        />
                    </button>
                </div>
            ) : ''}
        </>
    );
};