import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router";
import { cssVhToPx, insertSpaces, isDesktopEnv, sortCodeRemoveDash } from "../../../helpers";
import { Button } from "../../../components/button/Button";
import { ButtonStyle } from "../../../static/CommonDefinitions";
import { BackgroundContainer } from "../../../components/background/BackgroundContainer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import styles from "./BankAccountAdded.module.scss";
import mobileStyles from "./BankAccountAddedMobile.module.scss";
import { selectBankDetails } from "../../bankAccount/logic/bankAccountSlice";
import {
  selectIsReconnectWithBank,
  setIsReconnectWithBank,
} from "./../../login/logic/loginSlice";
import CloseIcon from "../../../assets/images/close-arrow-24px-rounded.svg";
import { MobileHeader } from "../../../components/mobileHeader/MobileHeader";
import {
  MobileHeaderGradient,
  MobileHeaderPadding,
} from "../../../components/mobileHeader/static/mobileHeaderCommonDefinitions";
import { selectSelectedBankSupportsAISPFlow } from "../../retrieveAccountDetails/logic/retrieveAccountDetailsSlice";

export function BankAccountAdded() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const bank = useAppSelector(selectBankDetails);
  const isReconnectWithBank = useAppSelector(selectIsReconnectWithBank);
  const supportsAISPFlow = useAppSelector(selectSelectedBankSupportsAISPFlow);

  const formattedAccountNumber = insertSpaces(bank.accountNumber, 4);
  const formattedSortCode = insertSpaces(sortCodeRemoveDash(bank.sortCode), 2);

  const handleNextButtonClick = () => {
    if (isReconnectWithBank) {
      dispatch(setIsReconnectWithBank(false));
      history.push("/create-pin");
    } else {
      if (supportsAISPFlow) {
        history.push("/bank-account/activate");
      } else {
        history.push("/bank-account/activate/success");
      }
    }
  };

  if (isDesktopEnv()) {
    return (
      <BackgroundContainer backButton={false}>
        <div className={styles.container}>
          <div className={styles.container__content}>
            <h4 className={styles.container__title}>Bank Account Added</h4>
            <div className={styles.container__block}>
              <div
                className={classNames(
                  styles.container__block__header,
                  bank.bankName?.length > 20
                    ? styles["container__block__header__small"]
                    : ""
                )}
              >
                <div>
                  <p className={styles.container__block__header_bankname}>
                    {bank.bankName}
                  </p>
                  <p className={styles.container__block__header_nickname}>
                    {bank.accountNickname}
                  </p>
                </div>
                <div className={styles.container__block__header_logo}>
                  <img src={bank.logo} alt="bank-logo" />
                </div>
              </div>
              <div className={styles.container__block__section}>
                <p>Number</p>
                <p>{formattedAccountNumber}</p>
              </div>
              <div className={styles.container__block__section}>
                <p>Sort Code</p>
                <p>{formattedSortCode}</p>
              </div>
            </div>
          </div>
          <div className={styles.container__button}>
            <Button
              style={ButtonStyle.Rounded}
              title="Next"
              buttonHeight="50px"
              handleClick={handleNextButtonClick}
            />
          </div>
        </div>
      </BackgroundContainer>
    );
  }

  return (
    <div className={mobileStyles.container}>
      <MobileHeader
        withLogo
        isMenu={false}
        padding={MobileHeaderPadding.small}
        gradient={MobileHeaderGradient.fromTopLeft}
        inlineStyles={inlineStyles['header']}
      />
      <div className={mobileStyles.container__content} style={inlineStyles['content']}>
        <h4 className={mobileStyles.container__title} style={inlineStyles['title']}>Bank Account Added</h4>
        <div className={mobileStyles.container__block} style={inlineStyles['block']}>
          <div
            style={inlineStyles['block__header']}
            className={classNames(
              mobileStyles.container__block__header,
              bank.bankName?.length > 20
                ? mobileStyles["container__block__header__small"]
                : ""
            )}
          >
            <div>
              <p className={mobileStyles.container__block__header_bankname} style={inlineStyles['bankname']}>
                {bank.bankName}
              </p>
              <p className={mobileStyles.container__block__header_nickname} style={inlineStyles['nickname']}>
                {bank.accountNickname}
              </p>
            </div>
            <div className={mobileStyles.container__block__header_logo} style={inlineStyles['logo']}>
              <img src={bank.logo} alt="bank-logo" />
            </div>
          </div>
          <div className={mobileStyles.container__block__section}>
            <p style={inlineStyles['section__p']}>Number</p>
            <p style={inlineStyles['section__p']}>{formattedAccountNumber}</p>
          </div>
          <div className={mobileStyles.container__block__section} style={inlineStyles['secondSection']}>
            <p style={inlineStyles['section__p']}>Sort Code</p>
            <p style={inlineStyles['section__p']}>{formattedSortCode}</p>
          </div>
        </div>
      </div>
      <div className={mobileStyles.container__footer} style={inlineStyles['footer']}>
        <Button
          style={ButtonStyle.Rounded}
          title="Next"
          handleClick={handleNextButtonClick}
          buttonHeight={cssVhToPx(8.1)}
        />
      </div>
    </div>
  );
}

const inlineStyles = {
  header: {
    height: cssVhToPx(15.9),
    padding: `${cssVhToPx(2.85)} 4.8% ${cssVhToPx(6.3)}`,
  },
  content: {
    height: cssVhToPx(68.8),
    padding: `${cssVhToPx(0.3)} 5.3% 0`,
  },
  title: {
    fontSize: cssVhToPx(2.7),
  },
  block: {
    marginTop: cssVhToPx(3.75),
    padding: `${cssVhToPx(3)} 4.5%`,
  },
  block__header: {
    marginBottom: cssVhToPx(2.1),
    padding: `0 1.65% ${cssVhToPx(2.2)}`,
  },
  bankname: {
    fontSize: cssVhToPx(2.7),
    marginBottom: cssVhToPx(0.3),
  },
  nickname: {
    fontSize: cssVhToPx(2.1),
  },
  logo: {
    width: cssVhToPx(6),
    height: cssVhToPx(6),
  },
  section__p: {
    fontSize: cssVhToPx(2.4),
  },
  secondSection: {
    marginTop: cssVhToPx(0.9),
  },
  footer: {
    height: cssVhToPx(15.3),
  }
}
