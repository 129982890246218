import { common } from "./index";

interface SendBankConsentRequest extends common.Activation {
    previousId: string;
    properties: {
        "@type": string;
        consent: string;
    }
}

export class SendBankConsent implements common.RequestCreator<SendBankConsentRequest>{
    requestId: string;
    authUrlRequestId: string;
    code: string;

    constructor(requestId: string, authUrlRequestId: string, code: string) {
        this.requestId = requestId;
        this.authUrlRequestId = authUrlRequestId;
        this.code = code;
    }

    createRequest() {
        return this.getRequestParams();
    }

    getRequestParams(): SendBankConsentRequest {
        return {
            "@type": "https://dvschema.io/activation/ChangeRequest",
            id: this.requestId,
            previousId: this.authUrlRequestId,
            //originatorId: "cri:0Fmx7QoPzEji9Qrx54LzndXL3MH5VP0nsX6", //tmp?
            properties: {
                "@type": "https://miapago.io/ob/accountdetails/Properties",
                consent: this.code,
            },
            timestamp: new Date(),
            possibleResend: false
        }
    }
}